import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';

const StyledApplicationFooter = styled.footer`
  position: ${(props) => props.position};
  height: max-content;
  width: max-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  font-family: ${(props) => props.theme.text.secondary};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.theme.colors.primaryColor};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  transform: ${(props) => props.transform};

  & h4 {
    font-weight: bold;
  }

  & p {
    font-size: ${(props) => props.fontDifference};
  }

  @media ${(props) => props.theme.screens.largeTablet.portrait} {
    ${(props) => (props.modifier === 'white' ? `display: none` : null)};
  }

  @media ${(props) => props.theme.screens.smallTablet.landscape} {
    ${(props) => (props.modifier === 'white' ? `display: flex` : null)};
    ${(props) => (props.modifier === 'white' ? `font-size: 1.6rem` : null)};
    ${(props) => (props.modifier === 'white' ? `margin-bottom: 1rem` : null)};
  }

  @media ${(props) => props.theme.screens.smallTablet.portrait} {
    ${(props) => (props.modifier === 'white' ? `display: none` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    height: 10%;
    font-size: 2rem;
    bottom: 1rem;
    ${(props) => (props.modifier === 'white' ? `display: none` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    width: 100vw;
    ${(props) => (props.modifier === 'white' ? `display: none` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    font-size: 2rem;
    ${(props) => (props.modifier === 'white' ? `display: none` : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.portrait} {
    font-size: 1.8rem;
    ${(props) => (props.modifier === 'white' ? `display: none` : null)};
  }

  ${(props) => (props.modifier === 'white' ? `color: ${props.theme.colors.grayScale.offWhite}` : null)};
  ${(props) => (props.position === 'relative' ? 'text-align: center' : null)};
  ${(props) => (props.position === 'relative' ? 'width: 100%' : null)};
`;

export const Footer = function (props) {
  const { theme, content } = props;
  return (
    <StyledApplicationFooter
      position={content.position}
      fontSize={content.fontSize}
      fontDifference={content.fontDifference}
      bottom={content.bottom}
      left={content.left}
      transform={content.transform}
      modifier={content.modifier}>
      <h4>{`King Richard Budget \u00a9 ${DateTime.now().year}`}</h4>
      <p>Designed and developed by PureNSpiration</p>
    </StyledApplicationFooter>
  );
};
