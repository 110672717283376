import axios from 'axios';
import qs from 'qs';

export const getWorld = async () => {
  try {
    const response = await axios({
      method: `GET`,
      url: `https://restcountries.com/v3.1/all`,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};
