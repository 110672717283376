import React from 'react';
import styled from 'styled-components';
import chroma from 'chroma-js';
import { produce } from 'immer';
import { myCalendar } from '../../../Classes/FrontEnd-Calendar';
import { Header, SubCategoryHeaderContainer } from '../Forms/Header';
import { MainCategoryDisplay, SubCategoryDisplay } from '../../Logged-In/BudgetCreationForm';
import { Button, SmallBorderlessButton } from '../Button';
import { toggleClasses } from '../../Utility';
import { StyledSubCategory } from './Categories';
import { SubCategorySection } from '../Forms/Section';
import { SubCategoryGoalInput } from '../Forms/Input';
import { TimingContainer } from './TimingContainer';
import FormSection from '../Forms/Section';

export const OverallSummaryTitles = [`Overall Budget`, `Overall Spent`, `Overall Remaining`, `Percentage Spent`, `Percentage of Month Remaining`];
const SubCategoryHeaders = [`Sub Categories`];

const MonthCategoryContainer = styled.section`
  position: relative;
  height: 42.5rem;
  max-height: 42.5rem;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  transform: translate(0, 0) rotate(0);
  padding: 2rem;
`;

const OverallMonthSummaryContainer = styled.section`
  &.overall-summary-container {
    position: relative;
    height: max-content;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    background-color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};

    & .overall-summary-container__single-summary {
      position: relative;
      height: 10rem;
      width: 19rem;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      background-color: ${(props) => props.theme.colors.grayScale.gainsboro};
      font-family: ${(props) => props.theme.text.secondary};
      color: ${(props) => props.theme.colors.primaryColor};

      & .overall-summary-container__single-summary__title {
        position: absolute;
        height: max-content;
        width: 95%;
        top: 0.5rem;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-size: 1.6rem;
      }

      & .overall-summary-container__single-summary__amount {
        position: relative;
        text-align: center;
        font-size: 2rem;
        margin-top: 4rem;
      }
    }
  }
`;

const MonthHeader = styled(Header)`
  &.month__header {
    min-height: 6rem;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-family: ${(props) => props.theme.text.secondary};
    font-size: 3.6rem;
    background-color: ${(props) => chroma(props.theme.colors.primaryColor).darken(0.25).hex()};
    border-bottom: 0.3rem solid ${(props) => props.theme.colors.tertiaryColor};
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    margin-bottom: 0.2rem;

    & .month__header__icon {
      position: absolute;
      top: 50%;
      left: 2rem;
      transform: translate(0, -50%) rotate(0);
      color: ${(props) => props.theme.colors.grayScale.offWhite};
    }

    & .month__header__title {
      color: ${(props) => props.theme.colors.grayScale.offWhite};
    }
  }
`;

const StyledMonth = styled.section`
  &.month {
    position: relative;
    height: max-content;
    min-height: 60rem;
    max-height: 60rem;
    width: 75%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    transform: translate(0, 0) rotate(0);
    border: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
    border-radius: 1.25rem;
    padding: 0.25rem;
    margin: ${(props) => props.margin};
  }
`;

export const Month = function (props) {
  const {
    monthView,
    content,
    theme,
    user,
    budget,
    setBudget,
    categoryIndex,
    setCategoryIndex,
    changeMainCategory,
    currentCategory,
    setCurrentCategory,
    handleInputChange,
    errors,
    setErrors,
    disabledValues,
    setDisabledValues,
  } = props;

  const toggleTiming = (event) => {
    event.preventDefault();
    const timingContainer = document.querySelector('.timing-container');
    toggleClasses(timingContainer, [`closed`, `open`]);
    const categoryContainer = document.querySelector('.category-container');
    if (timingContainer.classList.contains('open')) {
      const mainCategory = categoryContainer.firstChild.nextSibling.textContent;
      const subCategory = event.target.closest('.sub-category').firstChild.firstChild.textContent;
      const updated = produce(currentCategory, (roughDraft) => {
        roughDraft.mainCategory = mainCategory;
        roughDraft.subCategory = subCategory;
      });
      setCurrentCategory(updated);
    }
  };

  const getOverallNumbers = (title) => {
    // * This is where things need to be done more with.
    if (title === `Overall Budget`) {
      if (budget.overallBudget === undefined) {
        if (budget._id) {
          let total = 0;
          budget.mainCategories.forEach((category) => {
            category.subCategories.forEach((sc) => (total += Number(sc.goalAmount)));
          });
          return user.money.format(total);
        } else {
          return user.money.format(0);
        }
      } else {
        return user.money.format(budget.overallBudget);
      }
    } else if (title === `Overall Spent`) {
      if (budget.overallSpent === undefined) {
        if (budget._id) {
          let total = 0;
          budget.mainCategories.forEach((category) => {
            category.subCategories.forEach((sc) => (total += Number(sc.amountSpent)));
          });
          return isNaN(Number(total)) ? user.money.format(0) : user.money.format(total);
        } else {
          return user.money.format(0);
        }
      } else {
        return user.money.format(budget.overallSpent);
      }
    } else if (title === `Overall Remaining`) {
      if (budget.overallRemaining === undefined) {
        if (budget._id) {
          return user.money.format(0);
        } else {
          return user.money.format(0);
        }
      } else {
        return user.money.format(budget.overallRemaining);
      }
    } else if (title === `Percentage Spent`) {
      if (budget.percentageSpent === undefined) {
        if (budget._id) {
          let total = (budget.overallSpent / budget.overallBudget) * 100;
          return isNaN(Number(total)) ? `${Number(0).toFixed(2)}%` : `${Number(total).toFixed(2)}%`;
        } else {
          return `${Number(0).toFixed(2)}%`;
        }
      } else {
        return `${budget.percentageSpent.toFixed(2)}%`;
      }
    } else if (title === `Percentage of Month Remaining`) {
      return myCalendar.monthRemaining();
    }
  };

  return (
    <StyledMonth className="month" margin={content.margin}>
      <MonthHeader className="month__header">
        <i className="fas fa-file-invoice-dollar month__header__icon" />
        <h3 className="month__header__title">{myCalendar.getMonth().toUpperCase()}</h3>
      </MonthHeader>
      <OverallMonthSummaryContainer className="overall-summary-container">
        {user &&
          budget &&
          OverallSummaryTitles.map((title, index) => {
            return (
              <section className="overall-summary-container__single-summary" key={index}>
                <h4 className="overall-summary-container__single-summary__title">{title}</h4>
                <p className="overall-summary-container__single-summary__amount">{getOverallNumbers(title)}</p>
              </section>
            );
          })}
      </OverallMonthSummaryContainer>
      <MonthCategoryContainer>
        <MainCategoryDisplay className="main-category-display" height="100%" width="40%" iconFontSize="14rem" fontSize="3.6rem" color={theme.colors.primaryColor} inBudget={content.inBudget}>
          <Button
            size="extra-small"
            iconFontSize="6rem"
            color={theme.colors.primaryColor}
            hoverColor={theme.colors.primaryColor}
            activeColor={theme.colors.secondaryColor}
            onClick={(e) => changeMainCategory(e, 'decrease')}
            data-cy="monthGoalLeft">
            <i
              className="far fa-circle-left"
              onMouseOver={(e) => toggleClasses(e.target.closest('button').firstChild, [`far`, `fas`])}
              onMouseOut={(e) => toggleClasses(e.target.closest('button').firstChild, [`far`, `fas`])}
            />
          </Button>
          {budget && budget.mainCategories && budget.mainCategories.length > 0 && (
            <div className="category-container">
              <i className={`fas ${budget.mainCategories[categoryIndex].icon}`} />
              <p>{budget.mainCategories[categoryIndex].title}</p>
            </div>
          )}
          <Button
            size="extra-small"
            iconFontSize="6rem"
            color={theme.colors.primaryColor}
            hoverColor={theme.colors.primaryColor}
            activeColor={theme.colors.secondaryColor}
            onClick={(e) => changeMainCategory(e, 'increase')}
            data-cy="monthGoalRight">
            <i
              className="far fa-circle-right"
              onMouseOver={(e) => toggleClasses(e.target.closest('button').firstChild, [`far`, `fas`])}
              onMouseOut={(e) => toggleClasses(e.target.closest('button').firstChild, [`far`, `fas`])}
            />
          </Button>
        </MainCategoryDisplay>
        <SubCategoryDisplay className="sub-category-display" height="100%" width="60%" type="month" inBudget={content.inBudget}>
          <SubCategoryHeaderContainer theme={theme} headers={SubCategoryHeaders} />
          <TimingContainer
            theme={theme}
            toggleTiming={toggleTiming}
            budget={budget}
            setBudget={setBudget}
            currentCategory={currentCategory}
            setCurrentCategory={setCurrentCategory}
            errors={errors}
            setErrors={setErrors}
          />
          {budget &&
            budget.mainCategories &&
            budget.mainCategories[categoryIndex] &&
            budget.mainCategories[categoryIndex].subCategories.map((subCategory, index) => {
              return (
                <StyledSubCategory
                  height="max-content"
                  minHeight="10rem"
                  width="100%"
                  minWidth="100%"
                  flow="column nowrap"
                  justify="space-between"
                  align="center"
                  margin=".5rem 0"
                  borderBottom={`.2rem solid ${theme.colors.primaryColor}cc`}
                  bgColor={'transparent'}
                  className="sub-category"
                  data-category={categoryIndex}
                  data-subcategory={index}
                  key={index}>
                  <FormSection
                    modifier="transactional"
                    modifierTwo="sub-category"
                    sectionHeight="5rem"
                    sectionWidth="100%"
                    flow="row wrap"
                    justify="space-around"
                    align="center"
                    bgColor={chroma(props.theme.colors.primaryColor).darken(0.4).hex()}>
                    <SubCategorySection
                      className="sub-category__section"
                      width="max-content"
                      minWidth="20%"
                      maxWidth="20rem"
                      flow="row nowrap"
                      justify="space-evenly"
                      align="center"
                      textColor={theme.colors.grayScale.offWhite}>
                      {subCategory.title}
                    </SubCategorySection>
                    <SubCategorySection
                      className="sub-category__section"
                      width="max-content"
                      minWidth="20%"
                      maxWidth="20rem"
                      flow="row nowrap"
                      justify="space-evenly"
                      align="center"
                      textColor={theme.colors.grayScale.offWhite}>
                      <p className="sub-category-inner-label--white">Percentage Spent:</p>
                      {subCategory.percentageSpent.toFixed(2)}%
                    </SubCategorySection>
                    <SubCategorySection
                      className="sub-category__section"
                      width="max-content"
                      minWidth="20%"
                      maxWidth="20rem"
                      flow="row nowrap"
                      justify="space-evenly"
                      align="center"
                      textColor={theme.colors.grayScale.offWhite}>
                      <SmallBorderlessButton
                        size="extra-small"
                        modifier="timing"
                        maxWidth="100%"
                        data-cy={`${subCategory.title.split(' ')[0]}Timing`}
                        onClick={(e) => toggleTiming(e)}
                        textColor={`${theme.colors.grayScale.offWhite}cc`}
                        disabled={monthView === 'dashboard' ? true : user && user.permissions === 'Associate' ? true : false}>
                        {subCategory.timingOptions.dueDateStatement || '+ Timing'}
                      </SmallBorderlessButton>
                    </SubCategorySection>
                  </FormSection>
                  <FormSection modifier="transactional" modifierTwo="sub-category" sectionHeight="5rem" sectionWidth="100%" flow="row wrap" justify="space-around" align="center">
                    <SubCategorySection
                      className="sub-category__section"
                      width="max-content"
                      minWidth="20%"
                      maxWidth="20rem"
                      flow="row nowrap"
                      justify="space-evenly"
                      align="center"
                      textColor={theme.colors.grayScale.offWhite}>
                      <p className="sub-category-inner-label">Monthly Total</p>
                      <SubCategoryGoalInput
                        type="number"
                        name="subCategoryGoal"
                        minHeight="3rem"
                        maxHeight="60%"
                        width="10rem"
                        min={0}
                        placeholder={user && user.money.format(0)}
                        value={subCategory.goalAmount}
                        className="sub-category__section__input"
                        required
                        onChange={(e) => handleInputChange(e, content.monthType, budget, setBudget, errors, setErrors, disabledValues, setDisabledValues)}
                        readOnly={monthView === 'dashboard' ? true : ''}
                        data-cy={`goal${subCategory.title.split(' ')[0]}`}
                      />
                    </SubCategorySection>
                    <SubCategorySection
                      className="sub-category__section"
                      width="max-content"
                      minWidth="20%"
                      maxWidth="20rem"
                      flow="row nowrap"
                      justify="space-evenly"
                      align="center"
                      textColor={theme.colors.primaryColor}>
                      <p className="sub-category-inner-label">Spent: </p>
                      {user && user.money.format(subCategory.amountSpent)}
                    </SubCategorySection>
                    <SubCategorySection
                      className="sub-category__section"
                      width="max-content"
                      minWidth="20%"
                      maxWidth="20rem"
                      flow="row nowrap"
                      justify="space-evenly"
                      align="center"
                      textColor={theme.colors.primaryColor}>
                      <p className="sub-category-inner-label">Remaining: </p>
                      {user && user.money.format(subCategory.amountRemaining)}
                    </SubCategorySection>
                  </FormSection>
                </StyledSubCategory>
              );
            })}
        </SubCategoryDisplay>
      </MonthCategoryContainer>
    </StyledMonth>
  );
};
