import { produce, immerable } from 'immer';
import { DateTime } from 'luxon';

export class PlannedTransaction {
  [immerable] = true;
  constructor() {
    this.date = DateTime.now().toISO();
    this.type = 'Withdrawal';
    this.account = '';
    this.location = '';
    this.name = '';
    this.timingOptions = {
      paymentCycle: '',
      dueDates: [],
      paymentSchedule: [],
    };
    this.amountSaved = 0;
    this.surplus = false;
    this.paidStatus = 'Unpaid';
  }

  copyPlan(plan) {
    for (let key in plan) {
      this[key] = plan[key];
    }
    return this;
  }

  updateAccount(event, transaction, setter) {
    event.preventDefault();
    const { value } = event.target;
    const updated = produce(transaction, (roughDraft) => {
      let accountValue;
      value === 'Select Account' ? (accountValue = '') : (accountValue = value);
      roughDraft.account = accountValue;
    });
    setter(updated);
  }

  filterOutDate(date) {
    this.timingOptions.paymentSchedule = this.timingOptions.paymentSchedule.filter((checkDate) => {
      return DateTime.fromISO(checkDate).toLocaleString(DateTime.DATE_MED) !== date;
    });
  }
}

/*
  TRANSACTION PLAN MODEL

  {
        Thinking through the timing of the sub-categories to build transaction plans for them.
        date: {
          type: Date, // This should be the last time the sub-categories were last updated.  So..., this will require a model update.
          required: [true, `Every Transaction Must Have A Date`],
        },
        type: {
          type: String,
          enum: [`Deposit`, `Withdrawal`], // Monthly Budget planned transactions will ALWAYS be a withdrawal.
          required: [true, `Every Transaction Is Either A Deposit Or Withdrawal`],
        },
        lender: {
          type: String,
        },
        location: {
          
          I either just make the location ALWAYS be online or home, or I figure something else out.  I probably could add a feature to the transaction planner to `edit` the location.
          I will do the first thought for now, while the other one will come in the future.
          
          type: String,
          required: [true, `Every Transaction Happened Somewhere.`],
        },
        account: {
          type: String,
          enum: [`Expense Fund`, `Savings Fund`, `Debt`, `Surplus`],
          required: [true, `Every Transaction Must Come From An Account`], // Goes To Account
        },
        subAccount: {
          type: String,
          enum: [`Bill`, `Debt`, `Subscription`, `Other`, `Loan`, `Credit Card`, `Taxes`, `Expense`, `Discretionary`, `Food`, `Vacations`, `Tuition`],
          required: [true, `Every Transaction Needs A Type Given.`], // Goes To Expenditure In Recent Transactions
        },
        category: {
          type: String,
        },
        subCategory: {
          type: String,
        },
        amount: {
          type: Number,
          required: [true, `Every Transaction Had An Ammount.`],
        },
        need: {
          type: String,
          enum: [`Need`, `Surplus`],
          required: [true, `Every Transaction Must Be Deemed Needed Or Surplus.`], // Expenses AND Savings Will Need Option For Whether It Is Surplus Or Not On Enter Transaction Form.
        },
        timingOptions: {
          paymentCycle: {
            type: String,
            enum: [`Once`, `Weekly`, `Bi-Weekly`, `Bi-Monthly`, `Monthly`, `Quarterly`, `Bi-Annual`, `Annual`],
          },
          dueDates: [Date],
          paymentSchedule: {
            type: mongoose.Mixed,
          },
        },
        name: {
          type: String,
        },
        amountSaved: {
          type: Number,
          default: 0,
        },
        paid: {
          type: Boolean,
          default: false,
        },
        paidStatus: {
          type: String,
          enum: [`Overpaid`, `Paid Off`, `Partially Paid`, `Unpaid`],
        },
      },
*/
