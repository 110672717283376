import React from 'react';
import styled from 'styled-components';

export const Row = styled.div`
  position: relative;
  height: max-content;
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};
  width: max-content;
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  display: flex;
  flex-flow: row wrap;
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  margin: ${(props) => props.margin};
  border-top: ${(props) => props.borderTop};
  border-right: ${(props) => props.borderRight};
  border-bottom: ${(props) => props.borderBottom};
  border-left: ${(props) => props.borderLeft};
  border: ${(props) => props.border};
`;
