import axios from 'axios';
import qs from 'qs';
import { produce, immerable } from 'immer';
import { signup } from '../Application/API-Requests/Signup';
import { logout } from '../Application/API-Requests/Logout';
import { getWorld } from '../Application/API-Requests/World';
import { testLanguage, setWithTimeUntilExpired, capitalize } from '../Application/Utility';
import { reloadPage } from '../Application/Utility';
import { get, getAll, set, remove, useNamespace } from './Cache';

export class Person {
  [immerable] = true;
  constructor(userId) {}

  // GETTING USER'S PERSON DATA -- USED
  async _getPersonData(userId) {
    try {
      let id;
      if (!userId) {
        id = window.location.pathname.split('/')[2];
        const response = await axios({
          method: `GET`,
          url: `/Users/${id}/Me`,
        });
        return response;
      } else if (userId) {
        const response = await axios({
          method: `POST`,
          url: `/Users/${userId}/Me`,
          data: qs.stringify({
            id: userId,
          }),
        });
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  }

  // HELPER TO BUILD PROFILE -- USED
  set(entry) {
    this[entry[0]] = entry[1];
  }

  identify(user) {
    this._id = user._id;
    this.budgets = user.budgets;
    this.firstname = user.firstname;
    this.lastname = user.lastname;
    this.username = user.username;
    this.email = user.email;
    this.phoneNumber = user.phoneNumber;
    this.communicationPreference = user.communicationPreference;
    this.photo = user.photo;
    this.latterDaySaint = user.latterDaySaint;
  }

  // GETTING USER'S LOCALE -- USED
  async getLocaleInformation() {
    const worldData = await getWorld();
    const countries = worldData.data;
    let localInformation;
    countries.forEach((country) => {
      if (country.languages) {
        let countryLanguage = Object.keys(country.languages)[0];
        const language = navigator.language;
        if (testLanguage(countryLanguage, country.cca2, language)) {
          let flag;
          country.flags.svg ? (flag = country.flags.svg) : country.flags.png ? (flag = country.flags.png) : (flag = country.flag);
          const symbolKey = Object.keys(country.currencies)[0];
          localInformation = {
            countryName: country.name.common,
            locale: testLanguage(countryLanguage, country.cca2, language),
            currency: Object.keys(country.currencies)[0],
            currencySymbol: country.currencies[symbolKey].symbol,
            languages: Object.keys(country.languages).map((key) => country.languages[key]),
            flag: flag,
            startOfWeek: capitalize(country.startOfWeek),
          };
        }
      }
    });
    setWithTimeUntilExpired(`World`, localInformation, 2629800000);
    setWithTimeUntilExpired(`API`, `RESTCountriesAPI`, 2629800000);
    return localInformation;
  }

  // BUILDING THE USER FOR USE IN APPLICATION -- USED
  async build() {
    // MAKE THE USER DATA REQUEST
    let userCopy = await this._getPersonData();

    console.log(userCopy);

    // GET THE USER'S DATA
    let userInfo = userCopy.data.data.user;

    // GET USER'S LOCAL INFORMATION
    let localInformation = await this.getLocaleInformation();

    // MERGE USER'S PERSONAL AND LOCAL INFORMATION INTO THIS ONE PROFILE
    [...Object.entries(localInformation), ...Object.entries(userInfo)].forEach((entry) => this.set(entry));

    // ADD A FEW MORE VALUABLE PROPERTIES TO THIS PROFILE
    this.set([`longDate`, { day: 'numeric', month: 'long', year: 'numeric' }]);
    this.set([`shortDate`, { day: 'numeric', month: 'numeric', year: 'numeric' }]);
    this.money = new Intl.NumberFormat(this.locale, {
      style: `currency`,
      currency: this.currency,
      minimumFractionDigits: 2,
    });

    // RETURN THIS PROFILE
    return this;
  }

  addPermissionLevel(value) {
    this.permissions = value;
  }

  // SIGNING A USER UP -- USED
  async signup(values) {
    await signup(values);
  }

  // UPDATING THE USER -- USED
  async updateMe(event, type, user, setUser) {
    event.preventDefault();
    let userInfo;
    if (type === `Personal Information`) {
      userInfo = {
        firstname: this.firstname,
        lastname: this.lastname,
        username: this.username,
        latterDaySaint: this.latterDaySaint,
      };
      try {
        const response = await axios({
          method: `PATCH`,
          url: `/Users/${this._id}/UpdateMe`,
          data: qs.stringify(userInfo),
        });
      } catch (error) {
        console.log(error);
      }
    } else if (type === 'Communications') {
      userInfo = {
        email: this.newEmail || this.email,
        emailConfirmed: this.newEmailConfirmed || this.email,
        phoneNumber: this.newPhoneNumber || this.phoneNumber || '',
        phoneNumberConfirmed: this.newPhoneNumberConfirmed || this.phoneNumber || '',
        communicationPreference: this.communicationPreference,
        id: this._id,
      };
      try {
        const response = await axios({
          method: `PATCH`,
          url: `/Users/${this._id}/UpdateMe`,
          data: qs.stringify(userInfo),
        });
        if (response.statusText === 'OK') {
          const updated = produce(user, (roughDraft) => {
            roughDraft.email = this.email;
            roughDraft.newEmail = null;
            roughDraft.newEmailConfirmed = null;
            roughDraft.phoneNumber = this.phoneNumber;
            roughDraft.newPhoneNumber = null;
            roughDraft.newPhoneNumberConfirmed = null;
          });
          setUser(updated);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (type === `Password Management`) {
      userInfo = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
        newPasswordConfirmed: this.newPasswordConfirmed,
      };
      try {
        const response = await axios({
          method: `POST`,
          url: `/Users/${this._id}/UpdateMyPassword`,
          data: qs.stringify(userInfo),
        });
        if (response.statusText === 'OK') {
          const updated = produce(user, (roughDraft) => {
            roughDraft.currentPassword = null;
            roughDraft.newPassword = null;
            roughDraft.newPasswordConfirmed = null;
          });
          setUser(updated);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  // UPDATING THE USER PROFILE PHOTO -- USED
  async updatePhoto(options, user, setUser) {
    try {
      const response = await axios({
        method: `PATCH`,
        url: `/Users/${options.id}/UpdateMe`,
        data: options,
      });
      if (response.data.data.user.photo) {
        const updated = produce(user, (roughDraft) => {
          roughDraft.photo = response.data.data.user.photo;
        });
        setUser(updated);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // LOGGING A USER OUT -- USED
  async logMeOut(event, id) {
    event.preventDefault();
    await logout(id);
  }

  // DEACTIVATING A USER -- USED
  async deactivateMe(event, id) {
    event.preventDefault();
    try {
      const response = await axios({
        method: `DELETE`,
        url: `/Users/${id}/DeactivateMe`,
      });
      if (response.statusText === 'Success') {
        window.location.assign('/App');
      }
    } catch (error) {
      console.log(error);
    }
  }

  // DELETING A USER -- USED
  async deleteMe(event, id) {
    event.preventDefault();
    try {
      const response = await axios({
        method: `DELETE`,
        url: `/Users/${id}/DeleteMe`,
      });
      if (response.statusText === 'No Content') {
        window.location.assign('/App');
      }
    } catch (error) {
      console.log(error);
    }
  }

  // SETTING UP 2-FACTOR AUTHENTICATION -- USED
  async setup2FA(options) {
    try {
      const response = await axios({
        method: `POST`,
        url: `/Users/${options.id}/2FA`,
        data: qs.stringify({
          userId: options.id,
        }),
      });
      return response.data.data.qrcode;
    } catch (error) {}
  }

  async _verify2FAToken(options) {
    try {
      const response = await axios({
        method: `POST`,
        url: `/Users/${options.id}/2FA/Verify`,
        data: qs.stringify({
          userId: options.id,
          token: options.token,
        }),
      });
      if (response.data.status === `Success`) {
        // RIGHT HERE, IT WOULD BE A GOOD IDEA TO MAKE IT RELOAD TO A CERTAIN PAGE, SAY THE PREVIOUS LOCATION WHETHER THE LOGGED IN PAGE, OR THE BUDGET PAGE ITSELF.
        reloadPage();
      }
    } catch (error) {}
  }

  // VALIDATING 2FA TOKEN -- USED
  async _validate2FAToken(options) {
    try {
      const response = await axios({
        method: `POST`,
        url: `/Users/${options.id}/2FA/Validate`,
        data: qs.stringify({
          userId: options.id,
          token: options.token,
        }),
      });
      return response.data;
    } catch (error) {}
  }

  async searchForFriends(options) {
    if (options.searchTerms.setting === 'firstname' && !options.searchTerms.firstname) {
      return options.setErrors({ ...options.errors, ['search']: 'You must enter the first name to search by.' });
    } else if (options.searchTerms.setting === 'lastname' && !options.searchTerms.lastname) {
      return options.setErrors({ ...options.errors, ['search']: 'You must enter the last name to search by.' });
    } else if ((options.searchTerms.setting === 'fullname' && !options.searchTerms.firstname) || (options.searchTerms.setting === 'fullname' && !options.searchTerms.lastname)) {
      return options.setErrors({ ...options.errors, ['search']: 'You must enter both the first and last name to search by.' });
    } else if (options.searchTerms.setting === 'username' && !options.searchTerms.username) {
      return options.setErrors({ ...options.errors, ['search']: 'You must enter the username to search by.' });
    } else {
      options.setErrors({ ...options.errors, ['search']: '' });
    }

    try {
      const response = await axios({
        method: `POST`,
        url: `/Users/${options.userId}/Budgets/${options.budgetId}/Invite-Users`,
        data: qs.stringify(options.searchTerms),
      });

      let users = response.data.data.users;
      options.setUsers(users);
      // renderUsers(users, id, budgetId);
    } catch (error) {
      console.log(error);
    }
  }

  async inviteFriend(options) {
    try {
      const response = await axios({
        method: 'POST',
        url: `/Users/${options.userId}/Budgets/${options.budgetId}/Invite-Users/${options.inviteData.email}`,
        data: qs.stringify(options),
      });
    } catch (error) {
      console.error(error);
    }
  }

  _updateFirstName(firstName) {
    return (this.firstname = firstName);
  }
  _updateLastName(lastName) {
    return (this.lastname = lastName);
  }
  _updateUsername(username) {
    return (this.username = username);
  }

  _updateLatterDaySaintStatus() {
    return (this.latterDaySaint = !this.latterDaySaint);
  }

  _updateEmail(email) {
    return (this.email = email);
  }

  _updateEmailConfirmed(emailConfirmed) {
    return (this.emailConfirmed = emailConfirmed);
  }
  _updatePassword(password) {
    return (this.password = password);
  }

  _updatePasswordConfirmed(passwordConfirmed) {
    return (this.passwordConfirmed = passwordConfirmed);
  }
}
