import React, { useState } from 'react';
import styled from 'styled-components';
import { BudgetPageSection } from '../Global/Forms/Section';
import { BudgetContainerHeader } from '../Global/Forms/Header';
import { ExtraSmallContainer } from '../Global/Budget/BudgetContainers/ExtraSmallContainer';
import { getCamelCaseAccount } from '../Global/Budget/BudgetUtility';
import { Form } from '../Global/Forms/Form';
import FormSection from '../Global/Forms/Section';
import { SubCategoryGoalInput } from '../Global/Forms/Input';
import { PlanningAccountSelect } from '../Global/Forms/Select';
import { SwitchLabel } from '../Global/Forms/Label';
import { PrimaryButton } from '../Global/Button';
import { handleTransaction } from '../Global/Forms/Algorithms/InputChange';

const initialTransferObject = {
  from: '',
  to: '',
  amount: 0,
};

export const AccountManagement = function (props) {
  const { theme, user, setUser, budget, setBudget, transaction, setTransaction, handleInputChange, errors, setErrors, disabledValues, setDisabledValues } = props;
  const [transfer, setTransfer] = useState(initialTransferObject);

  const getAccountTitles = (accounts) => {
    let titles = [];
    for (let key in accounts) {
      if (key !== 'unAllocated') {
        if (user.latterDaySaint && key === 'tithing') {
          titles.push(key);
        } else {
          titles.push(key);
        }
      }
    }
    return titles;
  };

  return (
    <BudgetPageSection minHeight="85%" flow="row wrap" justify="space-evenly" align="center">
      <BudgetContainerHeader budgetPage={true} height="10rem" justify="center" bgColor={'transparent'} textColor={theme.colors.tertiaryColor} className="budget-page-header">
        Account Management
      </BudgetContainerHeader>
      <ExtraSmallContainer
        theme={theme}
        user={user}
        budget={budget}
        title={'Bank Account'}
        icon={'piggy-bank'}
        content={{
          content: <p>{user && user.money.format(budget && budget.bankAccount)}</p>,
          margin: '0 3rem 3rem',
          bgColor: theme.colors.grayScale.offWhite,
        }}
      />
      {user &&
        budget &&
        budget.accounts &&
        getAccountTitles(budget.accounts)
          .map((title, index) => {
            return title === 'monthlyBudget'
              ? { title: 'Monthly Budget', icon: 'calendar' }
              : title === 'emergencyFund'
              ? { title: 'Emergency Fund', icon: 'house-chimney-crack' }
              : title === 'savingsFund'
              ? { title: 'Savings Fund', icon: 'vault' }
              : title === 'expenseFund'
              ? { title: 'Expense Fund', icon: 'credit-card' }
              : title === 'surplus'
              ? { title: 'Surplus', icon: 'dollar-sign' }
              : title === 'investmentFund'
              ? { title: 'Investment Fund', icon: 'money-bill-trend-up' }
              : title === 'debt'
              ? { title: 'Debt', icon: 'hand-holding-dollar' }
              : title === 'tithing'
              ? { title: 'Tithing Owed', icon: 'circle-dollar-to-slot' }
              : '';
          })
          .map((title, index) => {
            return (
              <ExtraSmallContainer
                theme={theme}
                user={user}
                budget={budget}
                title={title.title}
                icon={title.icon}
                content={{
                  content: (
                    <p>
                      {title.title === 'Monthly Budget'
                        ? user.money.format(budget && budget.accounts && budget.accounts[getCamelCaseAccount(title.title)].amount)
                        : title.title === 'Emergency Fund'
                        ? user.money.format(budget && budget.accounts && budget.accounts[getCamelCaseAccount(title.title)].amount)
                        : title.title === 'Savings Fund'
                        ? user.money.format(budget && budget.accounts && budget.accounts[getCamelCaseAccount(title.title)].amount.unUsed)
                        : title.title === 'Expense Fund'
                        ? user.money.format(budget && budget.accounts && budget.accounts[getCamelCaseAccount(title.title)].amount.unUsed)
                        : title.title === 'Surplus'
                        ? user.money.format(budget && budget.accounts && budget.accounts[getCamelCaseAccount(title.title)].amount.unUsed)
                        : title.title === 'Investment Fund'
                        ? user.money.format(budget && budget.accounts && budget.accounts[getCamelCaseAccount(title.title)].amount)
                        : title.title === 'Debt'
                        ? user.money.format(budget && budget.accounts && budget.accounts[getCamelCaseAccount(title.title)].amount.unUsed)
                        : title.title === 'Tithing Owed'
                        ? user.money.format(budget && budget.accounts && budget.accounts[getCamelCaseAccount('Tithing')].amountOwed)
                        : 0}
                    </p>
                  ),
                  margin: '0 3rem 3rem',
                  bgColor: theme.colors.grayScale.offWhite,
                }}
                key={index}
              />
            );
          })}
      {user && user.latterDaySaint && user.latterDaySaint === true && (
        <ExtraSmallContainer
          theme={theme}
          user={user}
          budget={budget}
          title={'Allocation To Tithing'}
          icon={'circle-dollar-to-slot'}
          content={{
            content: <p>{user && user.money.format(budget && budget.accounts && budget.accounts.tithing.amount)}</p>,
            margin: '0 3rem 3rem',
            bgColor: theme.colors.grayScale.offWhite,
          }}
        />
      )}
      <ExtraSmallContainer
        theme={theme}
        user={user}
        budget={budget}
        title={'Transfer Funds'}
        icon={'money-bill-transfer'}
        content={{
          content: (
            <Form position="relative" bgColor={'transparent'} flow="column nowrap" justify="space-evenly" align="center">
              <FormSection flow="column-reverse nowrap" justify="center" align="center" minHeight="6.5rem" margin="0">
                <PlanningAccountSelect
                  theme={theme}
                  user={user}
                  name="transfer-from"
                  options={
                    user && user.latterDaySaint === true
                      ? [`Select Account`, `Monthly Budget`, `Emergency Fund`, `Savings Fund`, `Expense Fund`, `Surplus`, `Investment Fund`, `Debt`, `Tithing`]
                      : [`Select Account`, `Monthly Budget`, `Emergency Fund`, `Savings Fund`, `Expense Fund`, `Surplus`, `Investment Fund`, `Debt`]
                  }
                  content={{
                    width: '13rem',
                    formType: 'Transfer',
                    values: { user: user, budget: budget, transfer: transfer },
                    setters: { setUser: setUser, setBudget: setBudget, setTransfer: setTransfer },
                  }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                />
                <SwitchLabel modifier="transaction" width="20rem">
                  Transfer From
                </SwitchLabel>
              </FormSection>
              <FormSection flow="column-reverse nowrap" justify="center" align="center" minHeight="6.5rem" margin="0">
                <PlanningAccountSelect
                  theme={theme}
                  user={user}
                  name="transfer-to"
                  options={
                    user && user.latterDaySaint === true
                      ? [`Select Account`, `Monthly Budget`, `Emergency Fund`, `Savings Fund`, `Expense Fund`, `Surplus`, `Investment Fund`, `Debt`, `Tithing`]
                      : [`Select Account`, `Monthly Budget`, `Emergency Fund`, `Savings Fund`, `Expense Fund`, `Surplus`, `Investment Fund`, `Debt`]
                  }
                  content={{
                    width: '13rem',
                    formType: 'Transfer',
                    values: { user: user, budget: budget, transfer: transfer },
                    setters: { setUser: setUser, setBudget: setBudget, setTransfer: setTransfer },
                  }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                />
                <SwitchLabel modifier="transaction" width="20rem">
                  Transfer To
                </SwitchLabel>
              </FormSection>
              <FormSection flow="row nowrap" justify="center" align="center" minHeight="6.5rem" margin="0">
                <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
                  <SubCategoryGoalInput
                    type="number"
                    name="transfer-amount"
                    minHeight="4rem"
                    maxHeight="4rem"
                    width="13rem"
                    placeholder="Transferred Amount"
                    modifier="transaction"
                    onChange={(e) => handleTransaction(e, 'Transfer', { user: user, budget: budget, transfer: transfer }, { setUser: setUser, setBudget: setBudget, setTransfer: setTransfer })}
                  />
                  <SwitchLabel modifier="transaction" width="20rem">
                    Transfer Amount
                  </SwitchLabel>
                </FormSection>
                <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="center" align="center" padding=".5rem">
                  <PrimaryButton
                    size="small"
                    minWidth="10rem"
                    name="transfer"
                    onClick={(e) => {
                      e.preventDefault();
                      handleTransaction(
                        e,
                        'Transfer',
                        { user: user, budget: budget, transfer: transfer, errors: errors },
                        { setUser: setUser, setBudget: setBudget, setTransfer: setTransfer, setErrors: setErrors }
                      );
                    }}>
                    Transfer
                  </PrimaryButton>
                  <SwitchLabel modifier="transaction" width="15rem">
                    Transfer Button
                  </SwitchLabel>
                </FormSection>
              </FormSection>
            </Form>
          ),
          margin: '0 3rem 3rem',
          bgColor: theme.colors.grayScale.offWhite,
        }}
      />
    </BudgetPageSection>
  );
};
