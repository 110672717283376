import React from 'react';
import { BudgetPageSection } from '../Global/Forms/Section';
import { ExtraSmallContainer } from '../Global/Budget/BudgetContainers/ExtraSmallContainer';
import { SmallContainer } from '../Global/Budget/BudgetContainers/SmallContainer';
import { SmallWideContainer } from '../Global/Budget/BudgetContainers/SmallWideContainer';
import { Month } from '../Global/Budget/Month';
import ErrorContainer from '../Global/Forms/Errors';

export const Dashboard = function (props) {
  const {
    theme,
    user,
    setUser,
    budget,
    setBudget,
    bankAccount,
    debtAmount,
    netValue,
    transaction,
    setTransaction,
    receiptItem,
    setReceiptItem,
    errors,
    setErrors,
    currentDate,
    setCurrentDate,
    changeDate,
    weekdays,
    setWeekdays,
    categoryIndex,
    setCategoryIndex,
    changeMainCategory,
  } = props;
  return (
    <BudgetPageSection minHeight="85%" flow="row wrap" justify="space-evenly" align="center">
      {user &&
        budget &&
        budget.accounts &&
        [
          { title: 'Bank Account', icon: 'piggy-bank' },
          { title: 'Debt', icon: 'hand-holding-dollar' },
          { title: 'Net Value', icon: 'dollar-sign' },
        ].map((title, index) => {
          return (
            <ExtraSmallContainer
              theme={theme}
              user={user}
              budget={budget}
              title={title.title}
              icon={title.icon}
              content={{
                content: (
                  <p>
                    {title.title === 'Bank Account'
                      ? user.money.format(budget && budget.bankAccount)
                      : title.title === 'Debt'
                      ? user.money.format(budget && budget.accounts && budget.accounts.debt.debtAmount)
                      : title.title === 'Net Value'
                      ? user.money.format(budget && budget.accounts && budget.bankAccount - budget.accounts.debt.debtAmount)
                      : 0}
                  </p>
                ),
                margin: '0 0 5rem 0',
                bgColor: theme.colors.grayScale.offWhite,
              }}
              key={index}
            />
          );
        })}
      <ErrorContainer>
        {errors && (
          <ol>
            {Object.keys(errors).map((error, index) => {
              if (errors[error] !== '') {
                return (
                  <li className="error" key={index}>
                    {errors[error]}
                  </li>
                );
              }
            })}
          </ol>
        )}
      </ErrorContainer>
      <SmallContainer
        theme={theme}
        user={user}
        setUser={setUser}
        errors={errors}
        setErrors={setErrors}
        budget={budget}
        setBudget={setBudget}
        title={'Enter Income'}
        icon={'money-bill-1-wave'}
        content={{ margin: '0 0 5rem 0' }}
        transaction={transaction}
        setTransaction={setTransaction}
        receiptItem={receiptItem}
        setReceiptItem={setReceiptItem}
      />
      <SmallContainer
        theme={theme}
        user={user}
        setUser={setUser}
        errors={errors}
        setErrors={setErrors}
        budget={budget}
        setBudget={setBudget}
        title={'Enter Transaction'}
        icon={'money-bill-transfer'}
        content={{ margin: '0 0 5rem 0' }}
        transaction={transaction}
        setTransaction={setTransaction}
        receiptItem={receiptItem}
        setReceiptItem={setReceiptItem}
      />
      <SmallWideContainer
        theme={theme}
        titles={{ one: 'Transaction Calendar', two: 'Upcoming Transactions' }}
        icons={{ one: 'calendar-check', two: 'file-invoice-dollar' }}
        user={user}
        setUser={setUser}
        budget={budget}
        setBudget={setBudget}
        transaction={transaction}
        setTransaction={setTransaction}
        receiptItem={receiptItem}
        setReceiptItem={setReceiptItem}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        changeDate={changeDate}
        weekdays={weekdays}
        setWeekdays={setWeekdays}
      />
      {budget && user && (
        <Month
          monthView="dashboard"
          content={{ margin: '0 0 5rem 0', inBudget: true, monthType: 'Update Budget' }}
          theme={theme}
          user={user}
          budget={budget}
          setBudget={setBudget}
          categoryIndex={categoryIndex}
          setCategoryIndex={setCategoryIndex}
          changeMainCategory={changeMainCategory}
        />
      )}
    </BudgetPageSection>
  );
};
