import React from 'react';
import styled from 'styled-components';
import chroma from 'chroma-js';

export const FormContainer = styled.div`
  &.form-container {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0) rotate(0);
    background-color: ${(props) => `${props.theme.colors.grayScale.white}f2`};
    z-index: 1;
  }
`;

export const BudgetCreationFormContainer = styled(FormContainer)`
  justify-content: space-evenly;
`;

export const UserProfileFormContainer = styled(FormContainer)`
  &.form-container {
    background-color: ${(props) => `${chroma(props.theme.colors.primaryColor).darken(0.4).hex()}cc`};

    ${(props) => (props.mobile === true ? `height: 91.1%` : null)};
    ${(props) => (props.mobile === true ? `align-self: flex-end` : null)};
  }
`;
