import React from 'react';
import styled from 'styled-components';

export const FormSection = styled.section`
  position: relative;
  height: max-content;
  min-height: ${(props) => props.minHeight};
  width: 100%;
  display: flex;
  flex-flow: ${(props) => props.flow};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  border-top: ${(props) => props.borderTop};
  border-right: ${(props) => props.borderRight};
  border-bottom: ${(props) => props.borderBottom};
  border-left: ${(props) => props.borderLeft};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  overflow-x: ${(props) => props.overflowX};
  overflow-y: ${(props) => props.overflowY};
  z-index: ${(props) => props.ZIndex};

  & a {
    text-decoration: none;
  }

  & .page-number,
  .create-budget-page-number {
    font-family: ${(props) => props.theme.text.secondary};
    margin-right: 5rem;
  }

  & .page-number {
    font-size: 2.4rem;
    color: ${(props) => props.theme.colors.primaryColor};
  }

  & .create-budget-page-number {
    font-family: ${(props) => props.theme.text.secondary};
    font-size: 3rem;
    color: ${(props) => props.theme.colors.primaryColor};
  }

  & h3.investment-title,
  & p.investment-description,
  & h5.investment-title {
    font-family: ${(props) => props.theme.text.secondary};
    font-size: ${(props) => props.headerTextSize};
    color: ${(props) => props.textColor};
  }

  & p.investment-amount {
    font-family: ${(props) => props.theme.text.secondary};
    font-size: ${(props) => props.amountTextSize};
    color: ${(props) => props.textColor};
  }

  & p.investment-description {
    font-family: ${(props) => props.theme.text.secondary};
    color: ${(props) => props.descriptionColor};
  }

  & p.tp-header {
    font-family: ${(props) => props.theme.text.secondary};
    font-size: ${(props) => props.textHeaderSize};
  }

  & p.tp-text {
    font-family: ${(props) => props.theme.text.secondary};
    font-size: ${(props) => props.textSize};
  }

  & p.receipt-text {
    font-family: ${(props) => props.theme.text.secondary};
    font-size: ${(props) => props.textSize};
    margin: ${(props) => props.textMargin};
  }

  & p.investment-type,
  & p.investment-result {
    font-family: ${(props) => props.theme.text.secondary};
    font-size: ${(props) => props.textSize};
    color: ${(props) => props.textColor};
    justify-self: ${(props) => props.selfJustify};
    align-self: ${(props) => props.selfAlign};
    transform: ${(props) => props.selfTransform};
  }

  & button.view-graph {
    font-family: ${(props) => props.theme.text.secondary};
    font-size: ${(props) => props.ctaTextSize};
  }

  & i.investment-icon {
    font-size: ${(props) => props.textSize};
    color: ${(props) => props.textColor};
    &:hover {
      cursor: pointer;
    }
  }

  &.user-container {
    background-color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};
    & .image-container {
      height: 20rem;

      & .image-container__image {
        height: 100%;
        width: auto;
        margin-right: 2rem;
        border-radius: 1rem;
      }
    }

    & p.user-name {
      font-family: ${(props) => props.theme.text.secondary};
      font-size: 4rem;
      color: ${(props) => props.theme.colors.primaryColor};
      &:first-of-type {
        margin-right: 2rem;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: ${(props) => props.theme.colors.primaryColor};
      transition: background-color 0.5s;

      & p.user-name {
        color: ${(props) => props.theme.colors.grayScale.offWhite};
        transition: color 0.5s;
      }

      & section label {
        color: ${(props) => props.theme.colors.grayScale.offWhite};
        transition: background-color 0.5s, color 0.5s;
      }

      & section button {
        border-color: ${(props) => props.theme.colors.grayScale.offWhite};
        color: ${(props) => props.theme.colors.grayScale.offWhite};
        transition: background-color 0.5s, color 0.5s;

        &:hover {
          background-color: ${(props) => props.theme.colors.grayScale.offWhite};
          color: ${(props) => props.theme.colors.primaryColor};
          transition: background-color 0.5s, color 0.5s;
        }
      }
    }
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    ${(props) => (props.sectionType === 'signup' ? `margin: 0 0 3rem` : null)};

    ${(props) => (props.sectionType === 'personal-info' ? 'height: 4rem' : null)};
    ${(props) => (props.sectionType === 'personal-info' ? 'min-height: 4rem' : null)};
    ${(props) => (props.sectionType === 'personal-info' ? 'margin: 0 0 4rem' : null)};

    ${(props) => (props.sectionType === 'personal-info--phone' ? 'height: 4rem' : null)};
    ${(props) => (props.sectionType === 'personal-info--phone' ? 'min-height: 4rem' : null)};
    ${(props) => (props.sectionType === 'personal-info--phone' ? 'margin: 0 0 6rem' : null)};

    ${(props) => (props.sectionType === 'personal-info--sub' ? 'height: 4rem' : null)};
    ${(props) => (props.sectionType === 'personal-info--sub' ? 'min-height: 4rem' : null)};
    ${(props) => (props.sectionType === 'personal-info--sub' ? 'margin: 0 0 1rem' : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.landscape} {
    ${(props) => (props.sectionType === 'login' ? 'height: 5rem' : null)};
    ${(props) => (props.sectionType === 'login' ? `margin: 0 0 2rem` : null)};
    ${(props) => (props.sectionType === 'signup' ? 'height: 5rem' : null)};
    ${(props) => (props.sectionType === 'signup' ? `margin: 0 0 0rem` : null)};
    ${(props) => (props.sectionType === 'forgot-password' ? 'height: 5rem' : null)};
    ${(props) => (props.sectionType === 'reset-password' ? 'height: 5rem' : null)};
    ${(props) => (props.sectionType === 'two-factor' ? 'height: 5rem' : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.landscape} {
    ${(props) => (props.sectionType === 'login' ? 'height: 4rem' : null)};
    ${(props) => (props.sectionType === 'login' ? 'min-height: 4rem' : null)};
    ${(props) => (props.sectionType === 'login' ? `margin: 0 0 2rem` : null)};
    ${(props) => (props.sectionType === 'signup' ? 'height: 4rem' : null)};
    ${(props) => (props.sectionType === 'signup' ? 'min-height: 4rem' : null)};
    ${(props) => (props.sectionType === 'signup' ? `margin: 0 0 0rem` : null)};
    ${(props) => (props.sectionType === 'signup--username' ? `margin: 2rem 0` : null)};
    ${(props) => (props.sectionType === 'forgot-password' ? 'height: 4rem' : null)};
    ${(props) => (props.sectionType === 'forgot-password' ? 'min-height: 4rem' : null)};
    ${(props) => (props.sectionType === 'reset-password' ? 'height: 4rem' : null)};
    ${(props) => (props.sectionType === 'reset-password' ? 'min-height: 4rem' : null)};
    ${(props) => (props.sectionType === 'two-factor' ? 'height: 4rem' : null)};
    ${(props) => (props.sectionType === 'two-factor' ? 'min-height: 4rem' : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    /* ${(props) => (props.sectionType === 'signup' ? `margin: 0 0 3rem` : null)}; */
    & .page-number {
      font-size: 2rem;
      margin-right: 3rem;
    }
  }

  ${(props) => (props.receiptSection === true ? `width: ${props.width}` : null)};
  ${(props) => (props.receiptSection === true ? `& i.delete-receipt-item {font-size: 1.6rem}` : null)};
  ${(props) => (props.receiptSection === true ? `&:hover {cursor: pointer}` : null)};
  ${(props) => (props.receiptSection === true ? `&:hover {background-color: ${props.theme.colors.primaryColor}}` : null)};
  ${(props) => (props.receiptSection === true ? `&:hover {transition: background-color .5s}` : null)};
  ${(props) => (props.receiptSection === true ? `&:hover { & p {color: ${props.theme.colors.grayScale.offWhite}}}` : null)};
  ${(props) => (props.receiptSection === true ? `&:hover { & p {transition: color .5s}}` : null)};
  ${(props) => (props.receiptSection === true ? `&:hover { & i {color: ${props.theme.colors.grayScale.offWhite}cc}}` : null)};
  ${(props) => (props.receiptSection === true ? `&:hover { & i {transition: color .5s}}` : null)};
  ${(props) => (props.receiptSection === true ? `&:hover { & i:hover {color: ${props.theme.colors.grayScale.offWhite}}}` : null)};
  ${(props) => (props.receiptSection === true ? `&:hover { & i:hover {transition: color .5s}}` : null)};
  ${(props) => (props.appliedSection === true ? `width: ${props.width}` : null)};
  ${(props) => (props.modifier === 'transactional' ? `height: ${props.sectionHeight}` : null)};
  ${(props) => (props.modifier === 'transactional' ? `width: ${props.sectionWidth}` : null)};
  ${(props) => (props.modifierTwo === 'sub-category' ? `background-color: ${props.bgColor}` : null)};
  ${(props) => (props.modifierTwo === 'chart' ? `position: ${props.position}` : null)};
  ${(props) => (props.modifierTwo === 'chart' ? `background-color: ${props.bgColor}` : null)};
`;

export const SubCategorySection = styled.section`
  &.sub-category__section {
    position: relative;
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
    max-width: ${(props) => props.maxWidth};
    display: flex;
    flex-flow: ${(props) => props.flow};
    justify-content: ${(props) => props.justify};
    align-items: ${(props) => props.align};
    text-align: center;
    font-size: 1.4rem;
    color: ${(props) => props.textColor};

    &:nth-of-type(2) {
      text-align: left;
    }

    & p.sub-category-inner-label {
      color: ${(props) => props.theme.colors.primaryColor};
      margin-right: 1rem;
    }

    & p.sub-category-inner-label--white {
      color: ${(props) => props.theme.colors.grayScale.offWhite};
      margin-right: 1rem;
    }
  }
`;

export const BudgetFormSection = styled(FormSection)`
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.theme.colors.primaryColor};

  & h4 {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    font-family: ${(props) => props.theme.text.secondary};
    font-size: 1.2rem;
    color: ${(props) => props.theme.colors.primaryColor};
  }

  & p.income-amount {
    font-family: ${(props) => props.theme.text.secondary};
    font-size: 2rem;
  }

  & p.receipt-total {
    font-family: ${(props) => props.theme.text.secondary};
    font-size: 2.4rem;
  }

  & p.receipt-amount {
    font-family: ${(props) => props.theme.text.secondary};
    font-size: 2.4rem;
  }

  & i {
    position: ${(props) => props.iconPosition};
    top: ${(props) => props.iconTop};
    right: ${(props) => props.iconRight};
    bottom: ${(props) => props.iconBottom};
    left: ${(props) => props.iconLeft};
    transform: ${(props) => props.iconTransform};
  }

  ${(props) => (props.modifier === 'white' ? `& h4 {color: ${props.theme.colors.grayScale.offWhite}}` : null)};
  ${(props) => (props.modifier === 'white' ? `& p.income-amount {color: ${props.theme.colors.grayScale.offWhite}}` : null)};
  ${(props) => (props.sectionType === 'receipt total' ? `background-color: ${props.theme.colors.primaryColor}` : null)};
  ${(props) => (props.sectionType === 'receipt total' ? `& p {color: ${props.theme.colors.grayScale.offWhite}}` : null)};
`;

export const TransactionCalendarSection = styled(BudgetFormSection)`
  height: ${(props) => props.height};
  color: ${(props) => props.theme.colors.grayScale.offWhite};

  & i {
    font-size: 2.4rem;
  }

  ${(props) => (props.modifier === 'purple' ? `color: ${props.theme.colors.primaryColor}` : null)};
`;

export const BudgetPageSection = styled(FormSection)``;
export const BudgetNavigationLinkSection = styled(FormSection)``;

export default FormSection;
