import styled from 'styled-components';

export const Form = styled.form`
  position: ${(props) => props.position};
  height: 100%;
  width: 100%;
  display: flex;
  background-color: ${(props) => props.bgColor};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  flex-flow: ${(props) => props.flow};
  transform: translate(0, 0) rotate(0);
  overflow-y: auto;
  z-index: ${(props) => props.zIndex};

  & a {
    text-decoration: none;
  }

  @media ${(props) => props.theme.screens.smallTablet.landscape} {
    ${(props) => (props.containerType === 'photo' ? `width: 90%` : null)};
  }

  @media ${(props) => props.theme.screens.smallTablet.portrait} {
    ${(props) => (props.containerType === 'photo' ? `width: 90%` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    ${(props) => (props.containerType === 'personal-info' ? `justify-content: flex-start` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    ${(props) => (props.containerType === 'personal-info' ? `justify-content: flex-start` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.landscape} {
    ${(props) => (props.containerType === 'personal-info' ? `justify-content: flex-start` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    ${(props) => (props.containerType === 'photo' ? `width: 95%` : null)};
    ${(props) => (props.containerType === 'personal-info' ? `justify-content: flex-start` : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.portrait} {
    ${(props) => (props.containerType === 'photo' ? `width: 95%` : null)};
    ${(props) => (props.containerType === 'personal-info' ? `justify-content: flex-start` : null)};
  }

  ${(props) => (props.inBudget === true ? `height: ${props.formHeight}` : null)}
`;
