import axios from 'axios';
import qs from 'qs';
import { showError } from '../Utility';
import { Person } from '../../Classes/Person';

export const login = async (username, password, navigator) => {
  try {
    const options = {
      username: username,
      password: password,
      searchType: 'login',
    };
    // FIRST SEARCH FOR THE USER
    const response1 = await axios({
      method: `POST`,
      url: `/User`,
      data: qs.stringify(options),
    });
    let filteredUser = response1.data.data.user;

    if (response1.data.status === 'Success' && filteredUser.twoFactor === true) {
      navigator('/Two-Factor');

      // BLANK USER BUILT TO ACCESS THE 2FA VALIDATION METHOD.
      setTimeout(() => {
        const twoFactorSubmit = document.querySelector('.button--two-factor');
        const twoFactorInput = document.querySelector('.form__input--two-factor');
        const user = new Person();
        let newResponse;
        twoFactorSubmit.addEventListener('click', async (e) => {
          e.preventDefault();
          newResponse = await user._validate2FAToken({ id: filteredUser.id, token: twoFactorInput.value, password: password });
          if (newResponse.status === `Success` && newResponse.validated === true) {
            const options = {
              username: username,
              password: password,
              id: filteredUser.id,
            };
            // LOG IN
            const response2 = await axios({
              method: `POST`,
              url: `/Users/${options.id}`,
              data: qs.stringify(options),
            });
            if (response2.statusText === 'OK') {
              document.open(`text/html`).write(response2.data);
              // RE-ASSIGN URL ADDRESS
              window.location.assign(`/Users/${options.id}`);
            }
          }
        });
      }, 2000);
    }
    if ((response1.data.status === 'Success' && filteredUser.twoFactor === false) || (response1.data.status === 'Success' && filteredUser.twoFactor === undefined)) {
      const options = {
        username: username,
        password: password,
        id: filteredUser.id,
      };
      // LOG IN
      const response2 = await axios({
        method: `POST`,
        url: `/Users/${filteredUser.id}`,
        data: qs.stringify(options),
      });
      if (response2.statusText === 'OK') {
        document.open(`text/html`).write(response2.data);
        // RE-ASSIGN URL ADDRESS
        window.location.assign(`/Users/${filteredUser.id}`);
      }
    }
  } catch (error) {
    const loginFormHeader = document.querySelector('.form__header__title');
    console.log(error);
    showError(loginFormHeader, `${error.response.data.message}`, `Login`, `negative-centered`, 5000);
  }
};
