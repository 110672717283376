import React from 'react';
import { Link } from 'react-router-dom';
import { FormContainer } from '../FormContainer';
import { ProfileFormCloser } from '../FormCloser';
import { Form } from '../Form';
import { LandingFormHeader } from '../Header';
import FormSection from '../Section';
import { TwoFactorInput } from '../Input';
import { TwoFactorLabel } from '../Label';
import { PrimaryButton } from '../../Button';

export const TwoFactorForm = function (props) {
  return (
    <FormContainer className="form-container r__form-container">
      <Form position="absolute" bgColor="#822abae6" flow="column nowrap" justify="center" align="center" zIndex="15" className="form r__form two-factor">
        <Link to="/">
          <ProfileFormCloser modifier="profile">
            <i className="fas fa-window-close" data-cy="formCloser" />
          </ProfileFormCloser>
        </Link>
        <LandingFormHeader margin="0 0 2rem 0" textColor="white" className="form__header" headerType="two-factor">
          Two Factor Authentication
        </LandingFormHeader>
        <FormSection minHeight="10rem" flow="column-reverse nowrap" justify="center" align="center" className="form__section" sectionType="two-factorS">
          <TwoFactorInput
            type="text"
            placeholder="Enter 2-Factor Auth Token"
            inputType="two-factor"
            name="two-factor"
            className="form__input--two-factor r__form__input--two-factor"
            focusTransform="translate(-5.5rem, -7rem) scale(0.75)"
            inputWidth="20%"
            maxWidth="20%"
            id="two-factor"
            data-cy="twoFactor"
            required="required"
          />
          <TwoFactorLabel htmlFor="two-factor" className="form__label--two-factor r__form__label--two-factor" data-cy="twoFactorLabel" labelType="two-factor">
            2-Factor Authentication Token
          </TwoFactorLabel>
        </FormSection>
        <PrimaryButton size="medium" modifier="white" className="button--two-factor" data-cy="twoFactorSubmit">
          Submit Token
        </PrimaryButton>
      </Form>
    </FormContainer>
  );
};
