import { produce, immerable } from 'immer';
import { DateTime } from 'luxon';

export class RecentTransaction {
  [immerable] = true;
  constructor() {
    this.transactionDate = '';
    this.transactionType = '';
    this.location = '';
    this.total = '';
    this.receipt = '';
  }

  makeRecentTransaction(transaction) {
    for (let key in transaction) {
      this[key] = transaction[key];
    }
  }
}
