import { produce } from 'immer';
import { validate } from './ValidateInput';
import { DateTime } from 'luxon';
import { loginSchema, signupSchema, forgotPasswordSchema, createBudgetSchema, resetPasswordSchema, userProfileSchema } from '../Validate';
import { formatPhoneNumber, capitalize } from '../../../Utility';
import { buildPaymentSchedule, getCamelCaseAccount } from '../../Budget/BudgetUtility';
import { PlannedTransaction } from '../../../../Classes/PlannedTransaction';
import { calculateTithing } from './Tithing';

export const handleInputChange = (e, formType, values, setter, errors, setErrors, disabledValues, setDisabledValues, info, setInfo) => {
  const { name, value } = e.target;
  if (formType === 'login') {
    const newValues = produce(values, (roughDraft) => {
      roughDraft[name] = value;
    });
    setter(newValues);
    validate(formType, loginSchema, name, value, errors, setErrors, disabledValues, setDisabledValues, values);
  } else if (formType === 'signup') {
    const pages = [...document.querySelectorAll('.form__page')];
    const currentPage = pages.findIndex((page) => page.classList.contains('open'));

    if (currentPage === 0) {
      const newValues = produce(values, (roughDraft) => {
        roughDraft.pageOne[name] = value;
      });
      setter(newValues);
      validate(formType, signupSchema.pageOne, name, value, errors, setErrors, disabledValues, setDisabledValues, values);
    } else if (currentPage === 1) {
      const newValues = produce(values, (roughDraft) => {
        roughDraft.pageTwo[name] = value;
      });
      setter(newValues);
      validate(formType, signupSchema.pageTwo, name, value, errors, setErrors, disabledValues, setDisabledValues, values);
    } else if (currentPage === 2) {
      const newValues = produce(values, (roughDraft) => {
        roughDraft.pageThree[name] = value;
      });
      setter(newValues);
      validate(formType, signupSchema.pageThree, name, value, errors, setErrors, disabledValues, setDisabledValues, values);
    } else if (currentPage === 3) {
      const newValues = produce(values, (roughDraft) => {
        roughDraft.pageFour[name] = value;
      });
      setter(newValues);
      validate(formType, signupSchema.pageFour, name, value, errors, setErrors, disabledValues, setDisabledValues, values);
    }
  } else if (formType === 'forgot-password') {
    const newValues = produce(values, (roughDraft) => {
      roughDraft[name] = value;
    });
    setter(newValues);
    validate(formType, forgotPasswordSchema, name, value, errors, setErrors, disabledValues, setDisabledValues, values);
  } else if (formType === 'reset-password') {
    const newValues = produce(values, (roughDraft) => {
      roughDraft[name] = value;
    });
    setter(newValues);
    validate(formType, resetPasswordSchema, name, value, errors, setErrors, disabledValues, setDisabledValues, values);
  } else if (formType === 'create-budget') {
    if (name === 'budgetName') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.updateName(value);
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.pageOne[name] = value;
      });
      setInfo(updatedTwo);
      validate(formType, createBudgetSchema, name, value, errors, setErrors, disabledValues, setDisabledValues, info, setInfo);
    } else if (name === 'tithingSetting') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.updateTithingSetting(value);
      });
      setter(updated);
    } else if (name === 'subCategoryGoal') {
      const subCategory = e.target.closest('.sub-category');
      const updated = produce(values, (roughDraft) => {
        roughDraft.mainCategories[Number(subCategory.dataset.category)].subCategories[Number(subCategory.dataset.subcategory)].updateGoalAmount(Number(value));
        roughDraft.mainCategories[Number(subCategory.dataset.category)].subCategories[Number(subCategory.dataset.subcategory)].updateAmountRemaining();
        let total = 0;
        roughDraft.mainCategories.forEach((category) => {
          category.subCategories.forEach((sc) => {
            total += Number(sc.goalAmount);
          });
        });
        roughDraft.overallBudget = total;
        if (roughDraft.overallSpent === undefined || isNaN(roughDraft.overallSpent)) roughDraft.overallSpent = 0;
        roughDraft.overallRemaining = roughDraft.overallBudget - roughDraft.overallSpent;
        roughDraft.percentageSpent = (roughDraft.overallSpent / roughDraft.overallBudget) * 100;
      });
      setter(updated);
    } else if (name === 'emergencySetting') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.updateEmergencyMeasurement(value);
        roughDraft.updateEmergencyGoal(Number(0));
        if (value === 'Length Of Time' && !roughDraft.accounts.emergencyFund.emergencyFundGoalTiming) {
          roughDraft.updateEmergencyGoalTiming('Months');
        }
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.pageTwo[name] = value;
        roughDraft.pageTwo.emergencyGoal = '';
      });
      setInfo(updatedTwo);
      validate(formType, createBudgetSchema, name, value, errors, setErrors, disabledValues, setDisabledValues, info, setInfo);
    } else if (name === 'emergencyGoalTime') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.updateEmergencyGoal(Number(value));
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.pageTwo.emergencyGoal = value;
      });
      setInfo(updatedTwo);
      validate(formType, createBudgetSchema, name, value, errors, setErrors, disabledValues, setDisabledValues, info, setInfo);
    } else if (name === 'emergencyGoalTotal') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.updateEmergencyGoal(Number(value));
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.pageTwo.emergencyGoal = value;
      });
      setInfo(updatedTwo);
      validate(formType, createBudgetSchema, name, value, errors, setErrors, disabledValues, setDisabledValues, info, setInfo);
    } else if (name === 'emergencyGoalTiming') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.updateEmergencyGoalTiming(value);
      });
      setter(updated);
    } else if (name === 'savingsGoalPercentage') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.updateSavingsPercentage(value);
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.pageThree.savingsPercentage = Number(value);
      });
      setInfo(updatedTwo);
      validate(formType, createBudgetSchema, name, value, errors, setErrors, disabledValues, setDisabledValues, info, setInfo);
    } else if (name === 'savingsGoal') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.updateSavingsGoal(value);
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.pageThree.savingsGoal = Number(value);
      });
      setInfo(updatedTwo);
      validate(formType, createBudgetSchema, name, value, errors, setErrors, disabledValues, setDisabledValues, info, setInfo);
    } else if (name === 'investmentGoalPercentage') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.updateInvestmentPercentage(value);
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.pageFour.investmentPercentage = Number(value);
      });
      setInfo(updatedTwo);
      validate(formType, createBudgetSchema, name, value, errors, setErrors, disabledValues, setDisabledValues, info, setInfo);
    } else if (name === 'investmentGoal') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.updateInvestmentGoal(value);
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.pageFour.investmentGoal = Number(value);
      });
      setInfo(updatedTwo);
      validate(formType, createBudgetSchema, name, value, errors, setErrors, disabledValues, setDisabledValues, info, setInfo);
    }
  } else if (formType === 'user-profile') {
    if (name === 'firstname') {
      const updated = produce(values, (roughDraft) => {
        roughDraft[name] = value;
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.personalInformation[name] = value;
      });
      setInfo(updatedTwo);
      validate(formType, userProfileSchema.personalInformation, name, value, errors, setErrors, disabledValues, setDisabledValues, info);
    } else if (name === 'lastname') {
      const updated = produce(values, (roughDraft) => {
        roughDraft[name] = value;
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.personalInformation[name] = value;
      });
      setInfo(updatedTwo);
      validate(formType, userProfileSchema.personalInformation, name, value, errors, setErrors, disabledValues, setDisabledValues, info);
    } else if (name === 'username') {
      const updated = produce(values, (roughDraft) => {
        roughDraft[name] = value;
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.personalInformation[name] = value;
      });
      setInfo(updatedTwo);
      validate(formType, userProfileSchema.personalInformation, name, value, errors, setErrors, disabledValues, setDisabledValues, info);
    } else if (name === 'email') {
      const updated = produce(values, (roughDraft) => {
        roughDraft[name] = value;
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.communications[name] = value;
      });
      setInfo(updatedTwo);
      validate(formType, userProfileSchema.communications, name, value, errors, setErrors, disabledValues, setDisabledValues, info);
    } else if (name === 'newEmail') {
      const updated = produce(values, (roughDraft) => {
        roughDraft[name] = value;
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.communications[name] = value;
      });
      setInfo(updatedTwo);
      validate(formType, userProfileSchema.communications, name, value, errors, setErrors, disabledValues, setDisabledValues, info);
    } else if (name === 'newEmailConfirmed') {
      const updated = produce(values, (roughDraft) => {
        roughDraft[name] = value;
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.communications[name] = value;
      });
      setInfo(updatedTwo);
      validate(formType, userProfileSchema.communications, name, value, errors, setErrors, disabledValues, setDisabledValues, info);
    } else if (name === 'phoneNumber') {
      let phoneNumber = formatPhoneNumber(value);
      const updated = produce(values, (roughDraft) => {
        roughDraft[name] = phoneNumber;
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.communications[name] = value;
      });
      setInfo(updatedTwo);
      validate(formType, userProfileSchema.communications, name, value, errors, setErrors, disabledValues, setDisabledValues, info);
    } else if (name === 'newPhoneNumber') {
      let phoneNumber = formatPhoneNumber(value);
      const updated = produce(values, (roughDraft) => {
        roughDraft[name] = phoneNumber;
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.communications[name] = value;
      });
      setInfo(updatedTwo);
      validate(formType, userProfileSchema.communications, name, value, errors, setErrors, disabledValues, setDisabledValues, info);
    } else if (name === 'newPhoneNumberConfirmed') {
      let phoneNumber = formatPhoneNumber(value);
      const updated = produce(values, (roughDraft) => {
        roughDraft[name] = phoneNumber;
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.communications[name] = value;
      });
      setInfo(updatedTwo);
      validate(formType, userProfileSchema.communications, name, value, errors, setErrors, disabledValues, setDisabledValues, info);
    } else if (name === `currentPassword`) {
      const updated = produce(values, (roughDraft) => {
        roughDraft[name] = value;
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.passwordManagement[name] = value;
      });
      setInfo(updatedTwo);
      validate(formType, userProfileSchema.passwordManagement, name, value, errors, setErrors, disabledValues, setDisabledValues, info);
    } else if (name === `newPassword`) {
      const updated = produce(values, (roughDraft) => {
        roughDraft[name] = value;
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.passwordManagement[name] = value;
      });
      setInfo(updatedTwo);
      validate(formType, userProfileSchema.passwordManagement, name, value, errors, setErrors, disabledValues, setDisabledValues, info);
    } else if (name === `newPasswordConfirmed`) {
      const updated = produce(values, (roughDraft) => {
        roughDraft[name] = value;
      });
      setter(updated);
      const updatedTwo = produce(info, (roughDraft) => {
        roughDraft.passwordManagement[name] = value;
      });
      setInfo(updatedTwo);
      validate(formType, userProfileSchema.passwordManagement, name, value, errors, setErrors, disabledValues, setDisabledValues, info);
    } else if (name === 'QRCode') {
      setter(value);
    } else if (name === 'photo') {
      const photoInput = document.getElementById('photo');
      const image = document.querySelector('.profile-image');
      const reader = new FileReader();

      reader.onload = (e) => {
        image.src = e.target.result;
      };

      const [file] = e.target.files;
      reader.readAsDataURL(file);

      const updated = produce(values, (roughDraft) => {
        roughDraft[name] = photoInput.files[0].name;
      });
      setter(updated);
    }
  } else if (formType === 'Update Budget') {
    if (name === 'name') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.name = value;
      });
      setter(updated);
      if (value === '') {
        setErrors({ ...errors, ['budgetName']: 'Every budget needs a name.' });
      } else {
        setErrors({ ...errors, ['budgetName']: '' });
      }
    } else if (name === 'cover-photo') {
      const photoInput = document.getElementById('photo');
      const image = document.querySelector('.cover-image');
      const reader = new FileReader();

      reader.onload = (e) => {
        image.src = e.target.result;
      };

      const [file] = e.target.files;
      reader.readAsDataURL(file);

      const updated = produce(values, (roughDraft) => {
        roughDraft[name] = photoInput.files[0].name;
      });
      setter(updated);
    } else if (name === 'savingsGoal') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.accounts.savingsFund[name] = Number(value);
      });
      setter(updated);
      if (value === '' || value < 0) {
        setErrors({ ...errors, ['savingsGoal']: `Savings goal is both required and must be zero or more.` });
      } else {
        setErrors({ ...errors, ['savingsGoal']: '' });
      }
    } else if (name === 'savingsPercentage') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.accounts.savingsFund[name] = Number(value);
      });
      setter(updated);
      if (value === '' || value < 0) {
        setErrors({ ...errors, ['savingsPercentage']: `Savings goal percentage is both required and must be zero or more.` });
      } else {
        setErrors({ ...errors, ['savingsPercentage']: '' });
      }
    } else if (name === 'investmentGoal') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.accounts.investmentFund[name] = Number(value);
      });
      setter(updated);
      if (value === '' || value < 0) {
        setErrors({ ...errors, ['investmentGoal']: `Investment goal is both required and must be zero or more.` });
      } else {
        setErrors({ ...errors, ['investmentGoal']: '' });
      }
    } else if (name === 'investmentPercentage') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.accounts.investmentFund[name] = Number(value);
      });
      setter(updated);
      if (value === '' || value < 0) {
        setErrors({ ...errors, ['investmentGoalPercentage']: `Investment goal percentage is both required and must be zero or more.` });
      } else {
        setErrors({ ...errors, ['investmentGoalPercentage']: '' });
      }
    } else if (name === 'emergencyGoalTotal') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.updateEmergencyGoal(Number(value));
      });
      setter(updated);
      if (updated.accounts.emergencyFund.emergencyGoalMeasurement === 'Total Amount') {
        if (value === '' || value < 0) {
          setErrors({ ...errors, ['emergencyGoal']: `Emergency goal is both required and must be zero or more.` });
        } else {
          setErrors({ ...errors, ['emergencyGoal']: '' });
        }
      }
    } else if (name === 'emergencyGoalTime') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.updateEmergencyGoal(Number(value));
      });
      setter(updated);
      if (updated.accounts.emergencyFund.emergencyGoalMeasurement === 'Length Of Time') {
        if (value === '' || value < 0) {
          setErrors({ ...errors, ['emergencyGoal']: `Emergency goal is both required and must be zero or more.` });
        } else {
          setErrors({ ...errors, ['emergencyGoal']: '' });
        }
      }
    } else if (name === 'emergencySetting') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.updateEmergencyMeasurement(value);
        roughDraft.updateEmergencyGoal(Number(0));
        if (value === 'Length Of Time' && !roughDraft.accounts.emergencyFund.emergencyFundGoalTiming) {
          roughDraft.updateEmergencyGoalTiming('Months');
        } else {
          if (roughDraft.accounts.emergencyFund.emergencyFundGoalTiming) {
            delete roughDraft.accounts.emergencyFund.emergencyFundGoalTiming;
          }
        }
      });
      setter(updated);
    } else if (name === 'emergencyGoalTiming') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.updateEmergencyGoalTiming(value);
      });
      setter(updated);
    } else if (name === 'tithingSetting') {
      const updated = produce(values, (roughDraft) => {
        roughDraft.updateTithingSetting(value);
      });
      setter(updated);
    } else if (name === 'subCategoryGoal') {
      const subCategory = e.target.closest('.sub-category');
      if (value < 0) {
        return setErrors({ ...errors, [name]: 'Sub-Category goals can not be less than zero.' });
      } else {
        setErrors({ ...errors, [name]: '' });
      }
      const updated = produce(values, (roughDraft) => {
        roughDraft.mainCategories[Number(subCategory.dataset.category)].subCategories[Number(subCategory.dataset.subcategory)].updateGoalAmount(Number(value));
        roughDraft.mainCategories[Number(subCategory.dataset.category)].subCategories[Number(subCategory.dataset.subcategory)].updateAmountRemaining();
        let total = 0;
        roughDraft.mainCategories.forEach((category) => {
          category.subCategories.forEach((sc) => (total += Number(sc.goalAmount)));
        });
        roughDraft.overallBudget = total;
        if (roughDraft.overallSpent === undefined || isNaN(roughDraft.overallSpent)) roughDraft.overallSpent = 0;
        roughDraft.overallRemaining = roughDraft.overallBudget - roughDraft.overallSpent;
        roughDraft.percentageSpent = (roughDraft.overallSpent / roughDraft.overallBudget) * 100;
      });
      setter(updated);
    }
  }
};

export const handleTransaction = (e, type, values, setters, setting) => {
  const { name, value } = e.target;
  if (type === 'Deposit') {
    if (name === 'date') {
      const updated = produce(values.transaction, (roughDraft) => {
        roughDraft.transactionType = type;
        roughDraft.transactionDate = DateTime.fromISO(value).toISO();
      });
      const updatedTwo = produce(values.receiptItem, (roughDraft) => {
        if (roughDraft.isTithedEssential(type, values.user, values.budget)) {
          roughDraft.tithed = false;
        }
      });
      setters.setTransaction(updated);
      setters.setReceiptItem(updatedTwo);
    } else if (name === 'location') {
      const updated = produce(values.transaction, (roughDraft) => {
        roughDraft.type = type;
        roughDraft[name] = value;
      });
      const updatedTwo = produce(values.receiptItem, (roughDraft) => {
        if (roughDraft.isTithedEssential(type, values.user, values.budget)) {
          roughDraft.tithed = false;
        }
      });
      setters.setTransaction(updated);
      setters.setReceiptItem(updatedTwo);
    } else if (setting === 'Tithing') {
      const tithedSwitch = e.target.closest('.lds-switch');
      let nameValue;
      if (!name) nameValue = tithedSwitch.getAttribute('name');
      if (nameValue === 'tithed') {
        const updated = produce(values.receiptItem, (roughDraft) => {
          roughDraft[nameValue] = !roughDraft[nameValue];
          if (roughDraft.tithed === false && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting === 'Gross') {
            roughDraft.tithing = roughDraft.grossIncome * 0.1 || 0;
          }
          if (roughDraft.tithed === false && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting === 'Net') {
            roughDraft.tithing = roughDraft.netIncome * 0.1 || 0;
          }
          if (roughDraft[nameValue] === true) roughDraft.tithing = 0;

          if (
            (roughDraft.tithed === undefined && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting !== 'Surplus') ||
            (roughDraft.tithed === null && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting !== 'Surplus') ||
            (roughDraft.tithed === false && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting !== 'Surplus') ||
            (roughDraft.tithed === true && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting !== 'Surplus')
          ) {
            if (!roughDraft.netIncome) roughDraft.netIncome = 0;
            if (!roughDraft.invested) roughDraft.invested = 0;
            if (!roughDraft.saved) roughDraft.saved = 0;
            roughDraft.unAllocated = roughDraft.netIncome - roughDraft.invested - roughDraft.saved - roughDraft.tithing;
          }
        });
        const updatedTwo = produce(values.transaction, (roughDraft) => {
          roughDraft.type = type;
        });
        setters.setTransaction(updatedTwo);
        setters.setReceiptItem(updated);
      }
    } else if (name === 'gross-income') {
      const updated = produce(values.receiptItem, (roughDraft) => {
        // IF TITHED SWITCH IS ESSENTIAL, START TITHED OUT AS FALSE.
        if (roughDraft.isTithedEssential(type, values.user, values.budget)) {
          if (roughDraft.tithed === undefined || roughDraft.tithed === null) roughDraft.tithed = false;
        }
        // GET GROSS INCOME.
        roughDraft.grossIncome = Number(value);

        // IF INCOME HAS NOT BEEN TITHED, ADD TITHING TO RECEIPT OBJECT.
        if (
          (roughDraft.tithed === undefined && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting === 'Gross') ||
          (roughDraft.tithed === null && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting === 'Gross') ||
          (roughDraft.tithed === false && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting === 'Gross')
        ) {
          roughDraft.tithing = calculateTithing(values.budget.accounts.tithing.tithingSetting, Number(value));
        }

        // IF INCOME HAS BEEN TITHED, TITHING MUST BE ZERO.
        if (roughDraft.tithed === true && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting === 'Gross') {
          roughDraft.tithing = 0;
        }
      });
      const updatedTwo = produce(values.transaction, (roughDraft) => {
        roughDraft.type = type;
      });
      setters.setTransaction(updatedTwo);
      setters.setReceiptItem(updated);
    } else if (name === 'net-income') {
      const updated = produce(values.receiptItem, (roughDraft) => {
        // IF TITHED SWITCH IS ESSENTIAL, START TITHED OUT AS FALSE.
        if (roughDraft.isTithedEssential(type, values.user, values.budget)) {
          if (roughDraft.tithed === undefined || roughDraft.tithed === null) roughDraft.tithed = false;
        }

        // GET NET INCOME
        roughDraft.netIncome = Number(value);

        // IF INCOME HAS NOT BEEN TITHED, ADD TITHING TO RECEIPT OBJECT.
        if (
          (roughDraft.tithed === undefined && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting === 'Net') ||
          (roughDraft.tithed === null && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting === 'Net') ||
          (roughDraft.tithed === false && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting === 'Net')
        ) {
          roughDraft.tithing = calculateTithing(values.budget.accounts.tithing.tithingSetting, Number(value));
        }

        roughDraft.invested = values.budget.accounts.investmentFund.investmentPercentage * roughDraft.netIncome;
        roughDraft.saved = values.budget.accounts.savingsFund.savingsPercentage * roughDraft.netIncome;

        // IF THE USER IS A LATTER-DAY SAINT, GET THE UN-ALLOCATED AMOUNT. -- THIS IS IRREGARDLESS OF THE TITHED SETTING
        if (
          (roughDraft.tithed === undefined && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting !== 'Surplus') ||
          (roughDraft.tithed === null && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting !== 'Surplus') ||
          (roughDraft.tithed === false && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting !== 'Surplus') ||
          (roughDraft.tithed === true && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting !== 'Surplus')
        ) {
          if (!roughDraft.tithing) roughDraft.tithing = 0;
          roughDraft.unAllocated = roughDraft.netIncome - roughDraft.invested - roughDraft.saved - roughDraft.tithing;
        }

        // IF THE USER IS A LATTER-SAINT AND TITHING IS ON SURPLUS OR THE USER IS NOT A LATTER-DAY-SAINT GET THE UN-ALLOCATED AMOUNT
        if ((roughDraft.tithed === true && values.user.latterDaySaint === true && values.budget.accounts.tithing.tithingSetting === 'Surplus') || values.user.latterDaySaint === false) {
          roughDraft.unAllocated = roughDraft.netIncome - roughDraft.invested - roughDraft.saved;
        }
      });
      const updatedTwo = produce(values.transaction, (roughDraft) => {
        roughDraft.type = type;
      });
      setters.setTransaction(updatedTwo);
      setters.setReceiptItem(updated);
    }
  } else if (type === 'Withdrawal') {
    if (setting === 'Add Item') {
      const updated = produce(values.receiptItem, (roughDraft) => {
        for (let key in roughDraft) {
          if (key !== 'account' && key !== 'active' && key !== 'amount') {
            if (key === 'grossIncome' || key === 'netIncome') {
              roughDraft[key] = 0;
            } else if (key !== 'grossIncome' && key !== 'netIncome') {
              delete roughDraft[key];
            }
          }
        }
        roughDraft.account = '';
        roughDraft.active = !roughDraft.active;
      });
      const updatedTwo = produce(values.transaction, (roughDraft) => {
        roughDraft.transactionType = type;
      });
      setters.setTransaction(updatedTwo);
      setters.setReceiptItem(updated);
    } else if (name === 'date') {
      const updated = produce(values.transaction, (roughDraft) => {
        roughDraft.transactionDate = DateTime.fromISO(value).toISO();
      });
      setters.setTransaction(updated);
    } else if (name === 'location') {
      const updated = produce(values.transaction, (roughDraft) => {
        roughDraft.location = value;
      });
      setters.setTransaction(updated);
    } else if (name === 'account') {
      const updated = produce(values.receiptItem, (roughDraft) => {
        if (roughDraft[name] !== '') {
          for (let key in roughDraft) {
            if (key !== 'account' && key !== 'active' && key !== 'amount') {
              if (key === 'grossIncome' || key === 'netIncome') {
                roughDraft[key] = 0;
              } else if (key !== 'grossIncome' && key !== 'netIncome') {
                delete roughDraft[key];
              }
            }
          }
        }
        roughDraft[name] = value;
      });
      setters.setReceiptItem(updated);
    } else if (name === 'main-category') {
      const updated = produce(values.receiptItem, (roughDraft) => {
        if (roughDraft['mainCategory'] !== '') {
          for (let key in roughDraft) {
            if (key !== 'account' && key !== 'active' && key !== 'amount') {
              if (key === 'grossIncome' || key === 'netIncome') {
                roughDraft[key] = 0;
              } else if (key !== 'grossIncome' && key !== 'netIncome') {
                delete roughDraft[key];
              }
            }
          }
        }
        roughDraft['mainCategory'] = value;
        for (let key in roughDraft) {
          if (roughDraft[key] === 'Select Category') delete roughDraft[key];
        }
      });
      setters.setReceiptItem(updated);
    } else if (name === 'sub-category') {
      const updated = produce(values.receiptItem, (roughDraft) => {
        roughDraft['subCategory'] = value;
        for (let key in roughDraft) {
          if (roughDraft[key] === 'Select Category') delete roughDraft[key];
        }
      });
      setters.setReceiptItem(updated);
    } else if (name === 'type') {
      let typedTransactions;
      if (values.receiptItem.account === 'Savings Fund' || values.receiptItem.account === 'Expense Fund' || values.receiptItem.account === 'Surplus' || values.receiptItem.account === 'Debt') {
        typedTransactions = values.budget.transactions.plannedTransactions.filter((transaction) => {
          return transaction.account === values.receiptItem.account && transaction.subAccount === value;
        });
        const updated = produce(values.receiptItem, (roughDraft) => {
          if (roughDraft.transactionsOfType !== undefined) {
            for (let key in roughDraft) {
              if (key !== 'account' && key !== 'active' && key !== 'amount') {
                if (key === 'grossIncome' || key === 'netIncome') {
                  roughDraft[key] = 0;
                } else if (key !== 'grossIncome' && key !== 'netIncome') {
                  delete roughDraft[key];
                }
              }
            }
          }
          // This gets all of the debts that are of the same type and if the account is 'Debt'
          roughDraft.transactionsOfType = typedTransactions;
          roughDraft.subAccount = value;
        });
        setters.setReceiptItem(updated);
      } else if (values.receiptItem.account === 'Investment Fund') {
        const updated = produce(values.receiptItem, (roughDraft) => {
          if (roughDraft.subAccount !== '') {
            for (let key in roughDraft) {
              if (key !== 'account' && key !== 'active' && key !== 'amount') {
                if (key === 'grossIncome' || key === 'netIncome') {
                  roughDraft[key] = 0;
                } else if (key !== 'grossIncome' && key !== 'netIncome') {
                  delete roughDraft[key];
                }
              }
            }
          }
          roughDraft.subAccount = value;
        });
        setters.setReceiptItem(updated);
      }
    } else if (name === 'name') {
      const updated = produce(values.receiptItem, (roughDraft) => {
        roughDraft[name] = value;
      });
      setters.setReceiptItem(updated);
    } else if (name === 'timing') {
      let timingTransactions;
      if (values.receiptItem.account === 'Savings Fund' || values.receiptItem.account === 'Expense Fund' || values.receiptItem.account === 'Surplus' || values.receiptItem.account === 'Debt') {
        if (values.receiptItem.transactionsOfType) {
          if (values.receiptItem.transactionsOfType.length !== 0) {
            timingTransactions = values.receiptItem.transactionsOfType.filter((transaction) => {
              return transaction.timingOptions.paymentCycle === value;
            });
          } else {
            timingTransactions = values.budget.transactions.plannedTransactions.filter((transaction) => {
              return transaction.account === values.receiptItem.account && transaction.subAccount === values.receiptItem.subAccount && transaction.timingOptions.paymentCycle === value;
            });
          }
        }
      }
      const updated = produce(values.receiptItem, (roughDraft) => {
        if (timingTransactions !== undefined) {
          for (let key in roughDraft) {
            if (key !== 'account' && key !== 'active' && key !== 'subAccount' && key !== 'amount') {
              if (key === 'grossIncome' || key === 'netIncome') {
                roughDraft[key] = 0;
              } else if (key !== 'grossIncome' && key !== 'netIncome') {
                delete roughDraft[key];
              }
            }
          }
        }
        roughDraft.transactionsOfType = timingTransactions;
        roughDraft.timing = value;
      });
      setters.setReceiptItem(updated);
    } else if (name === 'dueDate') {
      let dateFilteredTransactions = [];
      if (values.receiptItem.account === 'Savings Fund' || values.receiptItem.account === 'Expense Fund' || values.receiptItem.account === 'Surplus') {
        values.receiptItem.transactionsOfType.forEach((item) => {
          item.timingOptions.dueDates.forEach((date) => {
            if (DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) === value) dateFilteredTransactions.push(item.name);
          });
        });
        const updated = produce(values.receiptItem, (roughDraft) => {
          roughDraft.filteredTransactions = dateFilteredTransactions;
          roughDraft.dueDate = value;
        });
        setters.setReceiptItem(updated);
      } else if (values.receiptItem.account === 'Debt') {
        values.receiptItem.transactionsOfType.forEach((item) => {
          item.timingOptions.dueDates.forEach((date) => {
            if (DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) === value) dateFilteredTransactions.push(item.lender);
          });
        });
        const updated = produce(values.receiptItem, (roughDraft) => {
          roughDraft.filteredTransactions = dateFilteredTransactions;
          roughDraft.dueDate = value;
        });
        setters.setReceiptItem(updated);
      }
    } else if (name === 'lender') {
      let furtherFilteredDebts = [];
      values.receiptItem.transactionsOfType.forEach((item) => {
        item.timingOptions.dueDates.forEach((date) => {
          if (DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) === values.receiptItem.dueDate && item.lender === value) furtherFilteredDebts.push(item.name);
        });
      });
      const updated = produce(values.receiptItem, (roughDraft) => {
        roughDraft.furtherFilteredDebts = furtherFilteredDebts;
        roughDraft[name] = value;
      });
      setters.setReceiptItem(updated);
    } else if (name === 'item') {
      const updated = produce(values.receiptItem, (roughDraft) => {
        roughDraft.item = value;
      });
      setters.setReceiptItem(updated);
    } else if (name === 'description') {
      const updated = produce(values.receiptItem, (roughDraft) => {
        roughDraft[name] = value;
      });
      setters.setReceiptItem(updated);
    } else if (name === 'amount') {
      const updated = produce(values.receiptItem, (roughDraft) => {
        roughDraft[name] = Number(value);
      });
      setters.setReceiptItem(updated);
    }
  } else if (type === 'Planning') {
    if (name === 'account') {
      values.plannedTransaction.updateAccount(e, values.plannedTransaction, setters.setPlannedTransaction);
    } else if (name === 'location') {
      const updated = produce(values.plannedTransaction, (roughDraft) => {
        roughDraft[name] = value;
      });
      setters.setPlannedTransaction(updated);
    } else if (name === 'type') {
      let debtFiltered = [];
      const updated = produce(values.plannedTransaction, (roughDraft) => {
        roughDraft.subAccount = value;
        if (values.plannedTransaction.account === `Debt`) {
          debtFiltered = values.budget.debts.filter((debt) => {
            if (debt.debtType === value) return debt.lender;
          });
          let lenders = [];
          debtFiltered.forEach((debt) => {
            lenders.push(debt.lender);
          });
          let refinedLenders = [...new Set(lenders)];
          roughDraft.debtFilteredTransactions = refinedLenders;
        }
        if (values.plannedTransaction.account !== 'Debt' && roughDraft.debtFilteredTransactions) {
          delete roughDraft.debtFilteredTransactions;
        }
        if (value === 'Select Type') {
          roughDraft.subAccount = '';
        }
      });
      setters.setPlannedTransaction(updated);
    } else if (name === 'lender') {
      const updated = produce(values.plannedTransaction, (roughDraft) => {
        roughDraft.lender = value;
        roughDraft.debtFilteredTransactions = roughDraft.debtFilteredTransactions;
      });
      setters.setPlannedTransaction(updated);
    } else if (name === 'name') {
      const updated = produce(values.plannedTransaction, (roughDraft) => {
        roughDraft[name] = value;
      });
      setters.setPlannedTransaction(updated);
    } else if (name === 'timing') {
      const updated = produce(values.plannedTransaction, (roughDraft) => {
        roughDraft.timingOptions.paymentCycle = value;
        roughDraft.timingOptions.dueDates = [];
        roughDraft.timingOptions.paymentSchedule = [];
      });
      setters.setPlannedTransaction(updated);
    } else if (name === 'dueDateOne') {
      const updated = produce(values.plannedTransaction, (roughDraft) => {
        // DUE DATES ARE INCORRECT WHEN GOING FROM A TWO DUE DATE SETTING TO A SINGULAR ONE.
        if (roughDraft.timingOptions.dueDates.length === 0) {
          roughDraft.timingOptions.dueDates.push(DateTime.fromISO(value).toISO());
        } else if (roughDraft.timingOptions.dueDates.length === 1) {
          roughDraft.timingOptions.dueDates.unshift(DateTime.fromISO(value).toISO());
        } else {
          roughDraft.timingOptions.dueDates[0] = DateTime.fromISO(value).toISO();
        }

        // THIS IS ONE AREA THE PAYMENT SCHEDULE SHOULD BE GOTTEN.

        if (roughDraft.timingOptions.paymentCycle !== 'Bi-Monthly' && roughDraft.timingOptions.paymentCycle !== 'Bi-Annual' && roughDraft.timingOptions.paymentCycle !== 'Weekly') {
          roughDraft.timingOptions.paymentSchedule = buildPaymentSchedule(roughDraft.timingOptions.paymentCycle, roughDraft.timingOptions.dueDates[0]);
        } else if (roughDraft.timingOptions.paymentCycle === 'Weekly') {
          roughDraft.timingOptions.paymentSchedule = buildPaymentSchedule(roughDraft.timingOptions.paymentCycle, DateTime.fromISO(roughDraft.timingOptions.dueDates[0]).weekdayLong);
        }
      });
      setters.setPlannedTransaction(updated);
    } else if (name === 'dueDateTwo') {
      const updated = produce(values.plannedTransaction, (roughDraft) => {
        if (roughDraft.timingOptions.dueDates.length === 1) {
          roughDraft.timingOptions.dueDates.push(DateTime.fromISO(value).toISO());
        } else {
          roughDraft.timingOptions.dueDates[1] = DateTime.fromISO(value).toISO();
        }
        if (roughDraft.timingOptions.paymentCycle === 'Bi-Monthly' || roughDraft.timingOptions.paymentCycle === 'Bi-Annual') {
          roughDraft.timingOptions.paymentSchedule = buildPaymentSchedule(roughDraft.timingOptions.paymentCycle, [roughDraft.timingOptions.dueDates[0], roughDraft.timingOptions.dueDates[1]]);
        }
      });
      setters.setPlannedTransaction(updated);
      // THIS IS THE OTHER AREA THE PAYMENT SCHEDULE SHOULD BE GOTTEN.
    } else if (name === 'payment') {
      if (value < 0) {
        return setters.setErrors({ ...values.errors, ['payment']: `A payment cannot be less than ${values.user && values.user.money.format(0)}` });
      } else {
        setters.setErrors({ ...values.errors, ['payment']: '' });
      }
      if (value > values.budget.accounts[getCamelCaseAccount(values.plannedTransaction.account)].amount.unUsed) {
        return setters.setErrors({ ...values.errors, ['payment']: `You do not have enough money to plan for that.` });
      } else {
        setters.setErrors({ ...values.errors, ['payment']: '' });
      }
      const updated = produce(values.plannedTransaction, (roughDraft) => {
        roughDraft.payment = Number(value);
      });
      setters.setPlannedTransaction(updated);
    } else if (values.setting !== undefined && values.setting !== null && values.setting === 'surplus') {
      const updated = produce(values.plannedTransaction, (roughDraft) => {
        roughDraft.surplus = !roughDraft.surplus;
      });
      setters.setPlannedTransaction(updated);
    }
    if (name === 'create-plan') {
      if (values.budget.accounts[getCamelCaseAccount(values.plannedTransaction.account)].amount.unUsed < values.plannedTransaction.amount) {
        return setters.setErrors({ ...values.errors, ['planning']: 'You do not have the money to make that plan.' });
      } else if (values.budget.accounts[getCamelCaseAccount(values.plannedTransaction.account)].amount.unUsed === 0) {
        return setters.setErrors({ ...values.errors, ['planning']: 'It is pointless to set a plan of paying zero dollars.' });
      } else if (values.plannedTransaction.timingOptions.dueDates === [] || !values.plannedTransaction.timingOptions.dueDates) {
        return setters.setErrors({ ...values.errors, ['planning']: 'Any transaction plan must have a due date.' });
      } else if (values.plannedTransaction.account === '' || !values.plannedTransaction.account) {
        return setters.setErrors({ ...values.errors, ['planning']: 'You must select the account for the transaction plan.' });
      } else {
        setters.setErrors({ ...values.errors, ['planning']: '' });
      }
      // SOMEHOW, I NEED TO BE ABLE TO SET THE BUDGET WITH THE PLANNED TRANSACTION, AND THEN BE ABLE TO SUBMIT THE UPDATE OBJECT TO UPDATE THE BUDGET.
      const updatedTwo = produce(values.budget, (roughDraft) => {
        roughDraft.transactions.plannedTransactions.push(values.plannedTransaction);
      });

      setters.setBudget(updatedTwo);

      const updateObject = {
        budgetId: values.budget._id,
        userId: values.user._id,
        lastUpdated: DateTime.now().toISO(),
        currentMonth: values.budget.currentMonth,
        previousMonth: values.budget.previousMonth || values.budget.currentMonth,
        transactions: {
          recentTransactions: values.budget.transactions.recentTransactions,
          plannedTransactions: updatedTwo.transactions.plannedTransactions,
        },
      };
      values.budget.updateBudget(updateObject, 'Transaction-Planner');

      setters.setPlannedTransaction(new PlannedTransaction());
    } else if (name === 'applied') {
      if (value < 0) {
        return setters.setErrors({ ...values.errors, ['applied']: 'You cannot apply a negative amount to the plan.' });
      } else {
        setters.setErrors({ ...values.errors, ['applied']: '' });
      }
    }
  } else if (type === 'Allocate') {
    if (value < 0) {
      return setters.setErrors({ ...values.errors, ['allocation']: 'You cannot allocate a negative amount to an account' });
    } else {
      setters.setErrors({ ...values.errors, ['allocation']: '' });
    }
    const updated = produce(values.allocation, (roughDraft) => {
      roughDraft[name] = Number(value);
    });
    setters.setAllocation(updated);
  } else if (type === 'Investing') {
    if (name === 'investmentType') {
      const updated = produce(values.investment, (roughDraft) => {
        roughDraft[name] = value;
      });
      setters.setInvestment(updated);
    } else if (name === 'investmentName') {
      const updated = produce(values.investment, (roughDraft) => {
        roughDraft[name] = value;
      });
      setters.setInvestment(updated);
    } else if (name === 'investmentDescription') {
      const updated = produce(values.investment, (roughDraft) => {
        roughDraft[name] = value;
      });
      setters.setInvestment(updated);
    } else if (name === 'initialInvestment') {
      if (value < 0) {
        setters.setErrors({ ...values.errors, ['investment']: 'An investment`s value can never be negative.' });
      } else {
        setters.setErrors({ ...values.errors, ['investment']: '' });
      }
      const updated = produce(values.investment, (roughDraft) => {
        roughDraft[name] = Number(value);
        roughDraft.currentValue = Number(value);
        roughDraft.valueDifference = roughDraft.currentValue - roughDraft[name];
      });
      setters.setInvestment(updated);
    } else if (name === 'newValue') {
      if (value < 0) {
        setters.setErrors({ ...values.errors, ['investment']: 'An investment`s value can never be negative.' });
      } else {
        setters.setErrors({ ...values.errors, ['investment']: '' });
      }
    }
  } else if (type === 'Debt') {
    if (name === 'lender') {
      const updated = produce(values.debt, (roughDraft) => {
        roughDraft.updateLender(value);
      });
      setters.setDebt(updated);
    } else if (name === 'debtType') {
      const updated = produce(values.debt, (roughDraft) => {
        roughDraft.updateDebtType(value);
      });
      setters.setDebt(updated);
    } else if (name === 'initialDebt') {
      if (value <= 0) {
        setters.setErrors({ ...values.errors, ['debt']: 'The initial debt is never a negative amount or even zero.' });
      } else {
        setters.setErrors({ ...values.errors, ['debt']: '' });
      }
      const updated = produce(values.debt, (roughDraft) => {
        roughDraft.updateInitialDebt(value);
        roughDraft.updateAmountOwed(value);
      });
      setters.setDebt(updated);
    } else if (name === 'regularPayment') {
      if (value <= 0) {
        setters.setErrors({ ...values.errors, ['debt']: 'The regular payment for a debt should never be negative or zero.' });
      } else {
        setters.setErrors({ ...values.errors, ['debt']: '' });
      }
      const updated = produce(values.debt, (roughDraft) => {
        roughDraft.updateRegularPayment(value);
      });
      setters.setDebt(updated);
    } else if (name === 'add-debt') {
      if (values.debt.initialDebt <= 0) {
        setters.setErrors({ ...values.errors, ['debt']: 'Debt is never started at a negative or zero balance.' });
      } else if (values.debt.amountOwed < 0) {
        setters.setErrors({ ...values.errors, ['debt']: 'Debt is never started at a negative or zero balance.' });
      } else if (values.debt.lender === '') {
        setters.setErrors({ ...values.errors, ['debt']: 'Every debt should have a lender.' });
      } else if (values.debt.date === '') {
        setters.setErrors({ ...values.errors, ['debt']: 'Every debt should have a date it began.' });
      } else if (values.debt.debtType === '') {
        setters.setErrors({ ...values.errors, ['debt']: 'Every debt starts as some type of debt.  Please add a debt type.' });
      } else {
        setters.setErrors({ ...values.errors, ['debt']: '' });
      }
      const updated = produce(values.budget, (roughDraft) => {
        roughDraft.debts.push(values.debt);
        roughDraft.accounts.debt.addToDebtAmount(values.debt.initialDebt);
      });
      setters.setBudget(updated);
      values.budget.updateBudget(
        {
          budgetId: values.budget._id,
          userId: values.user._id,
          lastUpdated: DateTime.now().toISO(),
          accounts: updated.accounts,
          debts: updated.debts,
        },
        'Debt-Manager'
      );
    } else if (name === 'pay-off-debt') {
      const updated = produce(values.budget, (roughDraft) => {
        let filteredDebts = roughDraft.debts.filter((debt) => debt.status !== 'Paid Off');
        roughDraft.accounts.debt.removeFromDebtAmount(filteredDebts[values.debtIndex].amountOwed);
        filteredDebts[values.debtIndex].updateStatus('Paid Off');
        filteredDebts[values.debtIndex].updateDatePaid();
      });
      setters.setBudget(updated);

      values.budget.updateBudget(
        {
          budgetId: values.budget._id,
          userId: values.user._id,
          lastUpdated: DateTime.now().toISO(),
          accounts: updated.accounts,
          debts: updated.debts,
        },
        'Debt-Manager'
      );
    }
  } else if (type === 'Transfer') {
    if (name === 'transfer-from') {
      if (value < 0) {
        return setters.setErrors({ ...values.errors, ['transfer']: 'You cannot transfer a negative amount.' });
      } else {
        setters.setErrors({ ...values.errors, ['transfer']: '' });
      }
      const updated = produce(values.transfer, (roughDraft) => {
        roughDraft.from = value;
      });
      setters.setTransfer(updated);
    } else if (name === 'transfer-to') {
      if (value < 0) {
        return setters.setErrors({ ...values.errors, ['transfer']: 'You cannot transfer a negative amount.' });
      } else {
        setters.setErrors({ ...values.errors, ['transfer']: '' });
      }
      const updated = produce(values.transfer, (roughDraft) => {
        roughDraft.to = value;
      });
      setters.setTransfer(updated);
    } else if (name === 'transfer-amount') {
      if (value < 0) {
        return setters.setErrors({ ...values.errors, ['transfer']: 'You cannot transfer a negative amount.' });
      } else {
        setters.setErrors({ ...values.errors, ['transfer']: '' });
      }
      const updated = produce(values.transfer, (roughDraft) => {
        roughDraft.amount = Number(value);
      });
      setters.setTransfer(updated);
    } else if (name === 'transfer') {
      if (!values.transfer.from || !values.transfer.to || !values.transfer.amount) return;
      const updated = produce(values.budget, (roughDraft) => {
        let from = getCamelCaseAccount(values.transfer.from);
        let to = getCamelCaseAccount(values.transfer.to);
        let amount = Number(values.transfer.amount);

        if (amount > roughDraft.accounts[from].amount.unUsed) {
          return setters.setErrors({ ...values.errors, ['transfer']: `Your ${values.transfer.from} does not have the funds to transfer ${values.user && values.user.money.format(amount)}` });
        } else {
          setters.setErrors({ ...values.errors, ['transfer']: '' });
        }

        if (amount <= roughDraft.accounts[from].amount.unUsed || amount <= roughDraft.accounts[from].amount) {
          roughDraft.accountTransfer(from, to, amount);
        }
      });
      setters.setBudget(updated);
      values.budget.updateBudget(
        {
          budgetId: values.budget._id,
          userId: values.user._id,
          lastUpdated: DateTime.now().toISO(),
          accounts: updated.accounts,
        },
        'Account-Management'
      );
    }
  } else if (type === 'Search') {
    if (name === 'setting') {
      setters.setSearchTerms({ ...values.searchTerms, setting: value });
    } else if (name === 'firstname') {
      setters.setSearchTerms({ ...values.searchTerms, firstname: value });
    } else if (name === 'lastname') {
      setters.setSearchTerms({ ...values.searchTerms, lastname: value });
    } else if (name === 'fullname') {
      setters.setSearchTerms({ ...values.searchTerms, fullname: value });
    } else if (name === 'username') {
      setters.setSearchTerms({ ...values.searchTerms, username: value });
    }
  }
};
