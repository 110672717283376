import React, { useState, useEffect } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import styled from 'styled-components';
import { handleInputChange } from '../Forms/Algorithms/InputChange';
import { UserProfileFormContainer } from '../Forms/FormContainer';
import { Form } from '../Forms/Form';
import { LandingFormHeader } from '../Forms/Header';
import { ProfileFormCloser } from '../Forms/FormCloser';
import FormSection from '../Forms/Section';
import { UserProfileInput } from '../Forms/Input';
import { SwitchLabel, UserProfileLabel } from '../Forms/Label';
import { CommsSwitch, LDSSwitch } from '../Forms/Switch';
import { PrimaryButton, SmallBorderlessButton } from '../Button';
import { QRCodeContainer } from '../Forms/QRCodeContainer';
import ErrorContainer from '../Forms/Errors';
import { userProfileSchema } from '../Forms/Validate';
import { getMobileForm } from '../../Utility';

const PreviewPhotoContainer = styled.section`
  position: relative;
  height: 50%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transform: translate(0, 0) rotate(0);

  & div {
    position: relative;
    height: 40rem;
    width: 40rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0) rotate(0);
    border-radius: 50%;
    background-color: ${(props) => `${props.theme.colors.grayScale.offWhite}80`};
    padding: 0.5rem;
    overflow: hidden;

    & img {
      position: relative;
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }

    @media ${(props) => props.theme.screens.smallTablet.landscape} {
      ${(props) => (props.containerType === 'photo' ? `margin: 0 0 2rem` : null)};
    }

    @media ${(props) => props.theme.screens.smallTablet.portrait} {
      ${(props) => (props.containerType === 'photo' ? `margin: 0 0 2rem` : null)};
    }

    @media ${(props) => props.theme.screens.largeMobile.landscape} {
      ${(props) => (props.containerType === 'photo' ? `width: 20rem` : null)};
    }

    @media ${(props) => props.theme.screens.largeMobile.portrait} {
      ${(props) => (props.containerType === 'photo' ? `margin: 0 0 2rem` : null)};
      ${(props) => (props.containerType === 'photo' ? `height: 20rem` : null)};
      ${(props) => (props.containerType === 'photo' ? `width: 20rem` : null)};
    }

    @media ${(props) => props.theme.screens.mediumMobile.landscape} {
      ${(props) => (props.containerType === 'photo' ? `height: 20rem` : null)};
      ${(props) => (props.containerType === 'photo' ? `width: 17rem` : null)};
    }

    @media ${(props) => props.theme.screens.mediumMobile.portrait} {
      ${(props) => (props.containerType === 'photo' ? `height: 20rem` : null)};
      ${(props) => (props.containerType === 'photo' ? `width: 20rem` : null)};
    }

    @media ${(props) => props.theme.screens.smallMobile.landscape} {
      ${(props) => (props.containerType === 'photo' ? `height: 20rem` : null)};
      ${(props) => (props.containerType === 'photo' ? `width: 15rem` : null)};
    }

    @media ${(props) => props.theme.screens.smallMobile.portrait} {
      ${(props) => (props.containerType === 'photo' ? `height: 20rem` : null)};
      ${(props) => (props.containerType === 'photo' ? `width: 20rem` : null)};
    }
  }
`;

export const ProfileContainer = function (props) {
  const { theme, mobileNavigation, user, setUser, profileInformation, setProfileInformation, errors, setErrors, disabledValues, setDisabledValues, previousLocation, setPreviousLocation } = props;

  useEffect(() => {
    userProfileSchema.personalInformation.isValid(profileInformation.personalInformation).then((valid) => {
      valid === true ? setDisabledValues({ ...disabledValues, personalInformation: false }) : setDisabledValues({ ...disabledValues, personalInformation: true });
    });
  }, [profileInformation.personalInformation]);

  useEffect(() => {
    userProfileSchema.communications.isValid(profileInformation.communications).then((valid) => {
      valid === true ? setDisabledValues({ ...disabledValues, communications: false }) : setDisabledValues({ ...disabledValues, communications: true });
    });
  }, [profileInformation.communications]);

  useEffect(() => {
    userProfileSchema.passwordManagement.isValid(profileInformation.passwordManagement).then((valid) => {
      valid === true ? setDisabledValues({ ...disabledValues, passwordManagement: false }) : setDisabledValues({ ...disabledValues, passwordManagement: true });
    });
  }, [profileInformation.passwordManagement]);

  const retrieve2FASetup = async () => {
    let qrcode = await user.setup2FA(user._id);
    const qrcodeImage = document.querySelector('.qrcode-image');
    qrcodeImage.src = qrcode;
  };

  const updateProfilePhoto = (event) => {
    event.preventDefault();
    const form = new FormData();
    form.append('userId', `${user._id}`);
    form.append('photo', document.getElementById('photo').files[0]);

    user.updatePhoto(form, user, setUser);
  };

  return (
    <Routes>
      <Route
        path="Update-Photo"
        element={
          <UserProfileFormContainer className="form-container r__form-container user-profile-form-container" mobile={mobileNavigation}>
            <Link to={`../${previousLocation || ''}`}>
              <ProfileFormCloser className="fas fa-window-close" data-cy="formCloserUserProfile" closerType="profile-form" />
            </Link>
            <Form position="absolute" flow="column nowrap" justify="center" align="center" containerType="photo">
              <LandingFormHeader textColor="white" modifier="profile" className="form__header" margin="0 0 3rem 0" headerType="profile-form--modified">
                Update Profile Photo
              </LandingFormHeader>
              <PreviewPhotoContainer containerType="photo">
                <div>
                  <img src={`/DIST/CSS/Images/Users/${user.photo}`} alt="Profile Photo" className="profile-image" />
                </div>
              </PreviewPhotoContainer>
              <FormSection minHeight="5rem" flow="row nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section">
                <UserProfileInput
                  type="file"
                  inputType="profile-photo"
                  accept="image/*"
                  id="photo"
                  name="photo"
                  modifier="white"
                  modifierTwo="profile"
                  data-cy="photo"
                  focusTransform="translate(-32.5rem,-4.25rem) scale(0.75)"
                  onChange={(e) => handleInputChange(e, 'user-profile', user, setUser, errors, setErrors, disabledValues, setDisabledValues)}
                />
                <SwitchLabel htmlFor="photo" data-cy="photoLabel" modifier="white" hoverCursor="pointer" kind="choose-photo">
                  <i className="fas fa-camera"></i>
                  <p>Choose Photo</p>
                </SwitchLabel>
                <PrimaryButton size="large" modifier="white" buttonType="profile-photo" margin="0 2rem" iconMargin="0 1rem 0 0" padding="0 1rem" onClick={(e) => updateProfilePhoto(e)}>
                  <i className="fas fa-camera"></i>
                  <p>Save And Update Photo</p>
                </PrimaryButton>
              </FormSection>
            </Form>
          </UserProfileFormContainer>
        }></Route>
      <Route
        path="Personal-Information"
        element={
          <UserProfileFormContainer className="form-container r__form-container user-profile-form-container" mobile={getMobileForm(theme)}>
            <Link to={`../${previousLocation || ''}`}>
              <ProfileFormCloser className="fas fa-window-close" data-cy="formCloserUserProfile" closerType="profile-form" />
            </Link>
            <Form position="absolute" flow="column nowrap" justify="center" align="center" containerType="personal-info">
              <LandingFormHeader textColor="white" modifier="profile" className="form__header" margin="0 0 3rem 0" headerType="profile-form">
                Personal Information
              </LandingFormHeader>
              <ErrorContainer errorContainerType="personal-info">
                {errors && (
                  <ol>
                    {Object.keys(errors).map((error, index) => {
                      if (errors[error] !== '') {
                        return (
                          <li className="error" key={index}>
                            {errors[error]}
                          </li>
                        );
                      }
                    })}
                  </ol>
                )}
              </ErrorContainer>
              <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section" sectionType="personal-info">
                <UserProfileInput
                  type="text"
                  name="firstname"
                  inputType="personal-info"
                  minLength={2}
                  maxLength={40}
                  modifier="white"
                  modifierTwo="profile"
                  className="form__input r__form__input"
                  id="firstname"
                  placeholder="Enter First Name"
                  focusTransform="translate(-3.5rem, -7rem) scale(0.75)"
                  value={user.firstname}
                  data-cy="firstname"
                  onChange={(e) => {
                    handleInputChange(e, 'user-profile', user, setUser, errors, setErrors, disabledValues, setDisabledValues, profileInformation, setProfileInformation);
                  }}
                />
                <UserProfileLabel htmlFor="firstname" data-cy="firstnameLabel" modifier="white" hoverCursor="pointer" labelType="personal-info">
                  First Name
                </UserProfileLabel>
              </FormSection>
              <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section" sectionType="personal-info">
                <UserProfileInput
                  type="text"
                  name="lastname"
                  inputType="personal-info"
                  minLength={2}
                  maxLength={40}
                  modifier="white"
                  modifierTwo="profile"
                  className="form__input r__form__input"
                  id="lastname"
                  placeholder="Enter Last Name"
                  focusTransform="translate(-3.5rem, -7rem) scale(0.75)"
                  value={user.lastname}
                  data-cy="lastname"
                  onChange={(e) => {
                    handleInputChange(e, 'user-profile', user, setUser, errors, setErrors, disabledValues, setDisabledValues, profileInformation, setProfileInformation);
                  }}
                />
                <UserProfileLabel htmlFor="lastname" data-cy="lastnameLabel" modifier="white" hoverCursor="pointer" labelType="personal-info">
                  Last Name
                </UserProfileLabel>
              </FormSection>
              <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 2rem 0" className="form__section r__form__section" sectionType="personal-info">
                <UserProfileInput
                  type="text"
                  name="username"
                  inputType="personal-info"
                  minLength={8}
                  maxLength={40}
                  modifier="white"
                  modifierTwo="profile"
                  className="form__input r__form__input"
                  id="username"
                  placeholder="Enter Username"
                  focusTransform="translate(-3.5rem, -7rem) scale(0.75)"
                  value={user.username}
                  data-cy="username"
                  onChange={(e) => {
                    handleInputChange(e, 'user-profile', user, setUser, errors, setErrors, disabledValues, setDisabledValues, profileInformation, setProfileInformation);
                  }}
                />
                <UserProfileLabel htmlFor="username" data-cy="usernameLabel" modifier="white" hoverCursor="pointer" labelType="personal-info">
                  Username
                </UserProfileLabel>
              </FormSection>
              <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0" className="form__section r__form__section" sectionType="personal-info">
                <LDSSwitch latterDaySaint={user} setLatterDaySaint={setUser} time="updating" />
                <SwitchLabel htmlFor="lds" modifier="white" labelType="profile--lds">
                  Are You A Latter Day Saint?
                </SwitchLabel>
              </FormSection>
              <PrimaryButton
                size="medium"
                modifier="white"
                buttonType="personal-info"
                onClick={async (e) => await user.updateMe(e, 'Personal Information', user, setUser)}
                disabled={disabledValues.personalInformation}>
                Submit Updates
              </PrimaryButton>
            </Form>
          </UserProfileFormContainer>
        }></Route>
      <Route
        path="Communications/*"
        element={
          <UserProfileFormContainer className="form-container r__form-container user-profile-form-container" mobile={getMobileForm(theme)}>
            <Link to={`../${previousLocation || ''}`}>
              <ProfileFormCloser className="fas fa-window-close" data-cy="formCloserUserProfile" closerType="profile-form" />
            </Link>
            <Form position="absolute" flow="column nowrap" justify="center" align="center" containerType="personal-info">
              <LandingFormHeader textColor="white" modifier="profile" className="form__header" margin="0 0 3rem 0" headerType="profile-form">
                Communications
              </LandingFormHeader>
              <ErrorContainer>
                {errors && (
                  <ol>
                    {Object.keys(errors).map((error, index) => {
                      if (errors[error] !== '') {
                        return (
                          <li className="error" key={index}>
                            {errors[error]}
                          </li>
                        );
                      }
                    })}
                  </ol>
                )}
              </ErrorContainer>
              <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3rem 0" className="form__section r__form__section" sectionType="personal-info">
                <UserProfileInput
                  type="email"
                  name="email"
                  inputType="personal-info"
                  minLength={5}
                  maxLength={40}
                  modifier="white"
                  modifierTwo="profile"
                  className="form__input r__form__input"
                  id="email"
                  placeholder="Enter Email Address"
                  focusTransform="translate(-3.5rem, -7rem) scale(0.75)"
                  value={user.email}
                  data-cy="email"
                  onChange={(e) => {
                    handleInputChange(e, 'user-profile', user, setUser, errors, setErrors, disabledValues, setDisabledValues, profileInformation, setProfileInformation);
                  }}
                  readOnly={true}
                />
                <UserProfileLabel htmlFor="email" data-cy="emailLabel" hoverCursor="pointer" labelType="personal-info">
                  Email Address
                </UserProfileLabel>
              </FormSection>
              <Link to="Enter-New-Email-Address">
                <SmallBorderlessButton size="extra-small" modifier="white" transform="translate(7rem, -2.5rem)" buttonType="personal-info--sub">
                  Enter New Email Address
                </SmallBorderlessButton>
              </Link>
              <Routes>
                <Route
                  path="Enter-New-Email-Address"
                  element={
                    <FormSection minHeight="5rem" flow="column nowrap" justify="center" align="center" margin="0 0 3rem 0" className="form__section r__form__section">
                      <FormSection
                        minHeight="5rem"
                        flow="column-reverse nowrap"
                        justify="center"
                        align="center"
                        margin="0 0 2rem 0"
                        className="form__section__sub-section r__form__section__sub-section"
                        sectionType="personal-info--sub">
                        <UserProfileInput
                          type="email"
                          name="newEmail"
                          inputType="personal-info--sub"
                          minLength={5}
                          maxLength={40}
                          modifier="white"
                          modifierTwo="profile"
                          transform="rotate(0) translate(0, 0) scale(0.9)"
                          className="form__input r__form__input"
                          id="newEmail"
                          placeholder="Enter New Email Address"
                          focusTransform="translate(-2rem, -7rem) scale(0.75)"
                          data-cy="newEmail"
                          value={user.newEmail || ''}
                          onChange={(e) => {
                            handleInputChange(e, 'user-profile', user, setUser, errors, setErrors, disabledValues, setDisabledValues, profileInformation, setProfileInformation);
                          }}
                        />
                        <UserProfileLabel htmlFor="newEmail" data-cy="newEmailLabel" transform="rotate(0) translate(0, -50%) scale(0.9)" hoverCursor="pointer" labelType="personal-info--sub">
                          New Email Address
                        </UserProfileLabel>
                      </FormSection>
                      <FormSection
                        minHeight="5rem"
                        flow="column-reverse nowrap"
                        justify="center"
                        align="center"
                        margin="0 0 2rem 0"
                        className="form__section__sub-section r__form__section__sub-section"
                        sectionType="personal-info--sub">
                        <UserProfileInput
                          type="email"
                          name="newEmailConfirmed"
                          inputType="personal-info--sub"
                          minLength={5}
                          maxLength={40}
                          modifier="white"
                          modifierTwo="profile"
                          transform="rotate(0) translate(0, 0) scale(0.9)"
                          className="form__input r__form__input"
                          id="newEmailConfirmed"
                          placeholder="Confirm New Email Address"
                          focusTransform="translate(-2rem, -7rem) scale(0.75)"
                          data-cy="newEmailConfirmed"
                          value={user.newEmailConfirmed || ''}
                          onChange={(e) => {
                            handleInputChange(e, 'user-profile', user, setUser, errors, setErrors, disabledValues, setDisabledValues, profileInformation, setProfileInformation);
                          }}
                        />
                        <UserProfileLabel
                          htmlFor="newEmailConfirmed"
                          data-cy="newEmailConfirmedLabel"
                          transform="rotate(0) translate(0, -50%) scale(0.9)"
                          hoverCursor="pointer"
                          labelType="personal-info--sub">
                          New Email Address Confirmed
                        </UserProfileLabel>
                      </FormSection>
                    </FormSection>
                  }></Route>
              </Routes>

              <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3rem 0" className="form__section r__form__section" sectionType="personal-info">
                <UserProfileInput
                  type="tel"
                  name="phoneNumber"
                  inputType="personal-info"
                  minLength={5}
                  maxLength={40}
                  modifier="white"
                  modifierTwo="profile"
                  className="form__input r__form__input"
                  id="phoneNumber"
                  placeholder="Enter Phone Number"
                  focusTransform="translate(-3.5rem, -7rem) scale(0.75)"
                  value={user.phoneNumber || ''}
                  data-cy="phoneNumber"
                  onChange={(e) => {
                    handleInputChange(e, 'user-profile', user, setUser, errors, setErrors, disabledValues, setDisabledValues, profileInformation, setProfileInformation);
                  }}
                  readOnly={true}
                />
                <UserProfileLabel htmlFor="phoneNumber" data-cy="phoneNumberLabel" hoverCursor="pointer" labelType="personal-info">
                  Phone Number
                </UserProfileLabel>
              </FormSection>
              <Link to="Enter-New-Phone-Number">
                <SmallBorderlessButton size="extra-small" modifier="white" transform="translate(7rem, -2.5rem)" buttonType="personal-info--sub">
                  Enter New Phone Number
                </SmallBorderlessButton>
              </Link>
              <Routes>
                <Route
                  path="Enter-New-Phone-Number"
                  element={
                    <FormSection
                      minHeight="5rem"
                      flow="column nowrap"
                      justify="center"
                      align="center"
                      margin="0 0 3rem 0"
                      className="form__section r__form__section"
                      sectionType="personal-info--phone">
                      <FormSection
                        minHeight="5rem"
                        flow="column-reverse nowrap"
                        justify="center"
                        align="center"
                        margin="0 0 2rem 0"
                        className="form__section__sub-section r__form__section__sub-section"
                        sectionType="personal-info--sub">
                        <UserProfileInput
                          type="tel"
                          name="newPhoneNumber"
                          inputType="personal-info--sub"
                          minLength={5}
                          maxLength={40}
                          modifier="white"
                          modifierTwo="profile"
                          transform="rotate(0) translate(0, 0) scale(0.9)"
                          className="form__input r__form__input"
                          id="newPhoneNumber"
                          placeholder="Enter New Phone Number"
                          focusTransform="translate(-2rem, -7rem) scale(0.75)"
                          data-cy="newPhoneNumber"
                          value={user.newPhoneNumber || ''}
                          onChange={(e) => {
                            handleInputChange(e, 'user-profile', user, setUser, errors, setErrors, disabledValues, setDisabledValues, profileInformation, setProfileInformation);
                          }}
                        />
                        <UserProfileLabel
                          htmlFor="newPhoneNumber"
                          data-cy="newPhoneNumberLabel"
                          transform="rotate(0) translate(0, -50%) scale(0.9)"
                          hoverCursor="pointer"
                          labelType="personal-info--sub">
                          New Phone Number
                        </UserProfileLabel>
                      </FormSection>
                      <FormSection
                        minHeight="5rem"
                        flow="column-reverse nowrap"
                        justify="center"
                        align="center"
                        margin="0 0 2rem 0"
                        className="form__section__sub-section r__form__section__sub-section"
                        sectionType="personal-info--sub">
                        <UserProfileInput
                          type="tel"
                          name="newPhoneNumberConfirmed"
                          inputType="personal-info--sub"
                          minLength={5}
                          maxLength={40}
                          modifier="white"
                          modifierTwo="profile"
                          transform="rotate(0) translate(0, 0) scale(0.9)"
                          className="form__input r__form__input"
                          id="newPhoneNumberConfirmed"
                          placeholder="Confirm New Hone Number"
                          focusTransform="translate(-2rem, -7rem) scale(0.75)"
                          data-cy="newPhoneNumberConfirmed"
                          value={user.newPhoneNumberConfirmed || ''}
                          onChange={(e) => {
                            handleInputChange(e, 'user-profile', user, setUser, errors, setErrors, disabledValues, setDisabledValues, profileInformation, setProfileInformation);
                          }}
                        />
                        <UserProfileLabel
                          htmlFor="newPhoneNumberConfirmed"
                          data-cy="newPhoneNumberConfirmedLabel"
                          transform="rotate(0) translate(0, -50%) scale(0.9)"
                          hoverCursor="pointer"
                          labelType="personal-info--sub">
                          New Phone Number Confirmed
                        </UserProfileLabel>
                      </FormSection>
                    </FormSection>
                  }></Route>
              </Routes>

              <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3rem 0" className="form__section r__form__section" sectionType="personal-info">
                <CommsSwitch theme={theme} user={user} setUser={setUser} profileInformation={profileInformation} setProfileInformation={setProfileInformation} time="updating" />
                <SwitchLabel htmlFor="commPreference" modifier="white comms" width="25%" labelType="profile--communications">
                  What is your communcation preference?
                </SwitchLabel>
              </FormSection>
              <PrimaryButton
                size="medium"
                modifier="white"
                buttonType="personal-info"
                onClick={async (e) => await user.updateMe(e, 'Communications', user, setUser)}
                disabled={disabledValues.communications}>
                Submit Updates
              </PrimaryButton>
            </Form>
          </UserProfileFormContainer>
        }></Route>
      <Route
        path="Password-Management/*"
        element={
          <UserProfileFormContainer className="form-container r__form-container user-profile-form-container" mobile={getMobileForm(theme)}>
            <Routes>
              <Route
                path="2FA"
                element={
                  <UserProfileFormContainer className="form-container r__form-container user-profile-form-container">
                    <Link to="../Password-Management">
                      <ProfileFormCloser className="fas fa-window-close" data-cy="formCloserUserProfile" closerType="profile-form" />
                    </Link>
                    <Form position="absolute" flow="column nowrap" justify="center" align="center">
                      <LandingFormHeader textColor="white" modifier="profile" className="form__header" margin="0 0 3rem 0">
                        Two-Factor Authentication Setup
                      </LandingFormHeader>
                      <QRCodeContainer theme={theme} user={user} setUser={setUser} errors={errors} setErrors={setErrors} disabledValues={disabledValues} setDisabledValues={setDisabledValues} />
                    </Form>
                  </UserProfileFormContainer>
                }></Route>
            </Routes>
            <Link to={`../${previousLocation || ''}`}>
              <ProfileFormCloser className="fas fa-window-close" data-cy="formCloserUserProfile" closerType="profile-form" />
            </Link>
            <Form position="absolute" flow="column nowrap" justify="center" align="center" containerType="personal-info">
              <LandingFormHeader textColor="white" modifier="profile" className="form__header" margin="0 0 3rem 0" headerType="profile-form">
                Password Management
              </LandingFormHeader>
              <ErrorContainer>
                {errors && (
                  <ol>
                    {Object.keys(errors).map((error, index) => {
                      if (errors[error] !== '') {
                        return (
                          <li className="error" key={index}>
                            {errors[error]}
                          </li>
                        );
                      }
                    })}
                  </ol>
                )}
              </ErrorContainer>
              <FormSection minHeight="5rem" flow="column nowrap" justify="center" align="center" margin="0 0 3rem 0" className="form__section r__form__section" sectionType="personal-info">
                <Link to="Change-Password">
                  <PrimaryButton size="large" modifier="white" margin="0 0 3rem 0">
                    Change Password
                  </PrimaryButton>
                </Link>
                <Routes>
                  <Route
                    path="Change-Password"
                    element={
                      <FormSection minHeight="5rem" flow="column nowrap" justify="center" align="center" margin="0 0 2rem 0" className="form__section__sub-section r__form__section__sub-section">
                        <FormSection
                          minHeight="5rem"
                          flow="column-reverse nowrap"
                          justify="center"
                          align="center"
                          margin="0 0 2rem 0"
                          className="form__section__sub-section r__form__section__sub-section"
                          sectionType="personal-info">
                          <UserProfileInput
                            type="password"
                            name="currentPassword"
                            minLength={5}
                            maxLength={40}
                            modifier="white"
                            modifierTwo="profile"
                            transform="rotate(0) translate(0, 0) scale(0.9)"
                            className="form__input r__form__input"
                            id="currentPassword"
                            placeholder="Enter Current Password"
                            focusTransform="translate(-2rem, -7rem) scale(0.75)"
                            data-cy="currentPassword"
                            value={user.currentPassword || ''}
                            onChange={(e) => {
                              handleInputChange(e, 'user-profile', user, setUser, errors, setErrors, disabledValues, setDisabledValues, profileInformation, setProfileInformation);
                            }}
                          />
                          <UserProfileLabel htmlFor="currentPassword" data-cy="currentPasswordLabel" transform="rotate(0) translate(0, -50%) scale(0.9)" hoverCursor="pointer">
                            Current Password
                          </UserProfileLabel>
                        </FormSection>
                        <FormSection
                          minHeight="5rem"
                          flow="column-reverse nowrap"
                          justify="center"
                          align="center"
                          margin="0 0 2rem 0"
                          className="form__section__sub-section r__form__section__sub-section"
                          sectionType="personal-info">
                          <UserProfileInput
                            type="password"
                            name="newPassword"
                            minLength={5}
                            maxLength={40}
                            modifier="white"
                            modifierTwo="profile"
                            transform="rotate(0) translate(0, 0) scale(0.9)"
                            className="form__input r__form__input"
                            id="newPassword"
                            placeholder="Enter New Password"
                            focusTransform="translate(-2rem, -7rem) scale(0.75)"
                            data-cy="newPassword"
                            value={user.newPassword || ''}
                            onChange={(e) => {
                              handleInputChange(e, 'user-profile', user, setUser, errors, setErrors, disabledValues, setDisabledValues, profileInformation, setProfileInformation);
                            }}
                          />
                          <UserProfileLabel htmlFor="newPassword" data-cy="newPasswordLabel" transform="rotate(0) translate(0, -50%) scale(0.9)" hoverCursor="pointer">
                            New Password
                          </UserProfileLabel>
                        </FormSection>
                        <FormSection
                          minHeight="5rem"
                          flow="column-reverse nowrap"
                          justify="center"
                          align="center"
                          margin="0 0 2rem 0"
                          className="form__section__sub-section r__form__section__sub-section"
                          sectionType="personal-info">
                          <UserProfileInput
                            type="password"
                            name="newPasswordConfirmed"
                            minLength={5}
                            maxLength={40}
                            modifier="white"
                            modifierTwo="profile"
                            transform="rotate(0) translate(0, 0) scale(0.9)"
                            className="form__input r__form__input"
                            id="newPasswordConfirmed"
                            placeholder="Confirm New Password"
                            focusTransform="translate(-2rem, -7rem) scale(0.75)"
                            data-cy="newPasswordConfirmed"
                            value={user.newPasswordConfirmed || ''}
                            onChange={(e) => {
                              handleInputChange(e, 'user-profile', user, setUser, errors, setErrors, disabledValues, setDisabledValues, profileInformation, setProfileInformation);
                            }}
                          />
                          <UserProfileLabel htmlFor="newPasswordConfirmed" data-cy="newPasswordConfirmedLabel" transform="rotate(0) translate(0, -50%) scale(0.9)" hoverCursor="pointer">
                            Confirm New Password
                          </UserProfileLabel>
                        </FormSection>
                        <PrimaryButton
                          size="medium"
                          modifier="white"
                          buttonType="personal-info"
                          onClick={async (e) => await user.updateMe(e, 'Password Management', user, setUser)}
                          disabled={disabledValues.passwordManagement}>
                          Submit Updates
                        </PrimaryButton>
                      </FormSection>
                    }></Route>
                </Routes>
              </FormSection>
              <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3rem 0" className="form__section r__form__section">
                <Link to="2FA">
                  <PrimaryButton size="large" modifier="white" onClick={() => retrieve2FASetup()}>
                    2-Factor Authendication Setup
                  </PrimaryButton>
                </Link>
              </FormSection>
            </Form>
          </UserProfileFormContainer>
        }></Route>
      <Route
        path="Account-Management"
        element={
          <UserProfileFormContainer className="form-container r__form-container user-profile-form-container" mobile={getMobileForm(theme)}>
            <Link to={`../${previousLocation || ''}`}>
              <ProfileFormCloser className="fas fa-window-close" data-cy="formCloserUserProfile" closerType="profile-form" />
            </Link>
            <Form position="absolute" flow="column nowrap" justify="center" align="center" containerType="personal-info">
              <LandingFormHeader textColor="white" modifier="profile" className="form__header" margin="0 0 3rem 0" headerType="profile-form--modified">
                Account Management
              </LandingFormHeader>
              <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3rem 0" className="form__section r__form__section">
                <PrimaryButton size="large" modifier="white" onClick={(e) => user.logMeOut(e, user._id)}>
                  Logout
                </PrimaryButton>
              </FormSection>
              <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3rem 0" className="form__section r__form__section">
                <PrimaryButton size="large" modifier="white" onClick={(e) => user.deactivateMe(e, user._id)}>
                  Deactivate Account
                </PrimaryButton>
              </FormSection>
              <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3rem 0" className="form__section r__form__section">
                <PrimaryButton size="large" modifier="white" onClick={(e) => user.deleteMe(e, user._id)}>
                  Delete Account
                </PrimaryButton>
              </FormSection>
            </Form>
          </UserProfileFormContainer>
        }></Route>
    </Routes>
  );
};
