import React from 'react';
import styled from 'styled-components';

export const StyledContentContainer = styled.section`
  position: relative;
  min-height: ${(props) => props.minHeight};
  width: max-content;
  min-width: ${(props) => props.minWidth};
  display: flex;
  flex-flow: ${(props) => props.flow};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  overflow-x: ${(props) => props.overflowX};
  overflow-y: ${(props) => props.overflowY};
  border-top: ${(props) => props.borderTop};
  border-right: ${(props) => props.borderRight};
  border-bottom: ${(props) => props.borderBottom};
  border-left: ${(props) => props.borderLeft};
  border: ${(props) => props.border};

  & p {
    font-size: 3rem;
    font-family: ${(props) => props.theme.text.secondary};
    color: ${(props) => props.theme.colors.primaryColor};
    height: ${(props) => props.textHeight};
  }

  ${(props) => (props.containerType === 'overall' ? `height: max-content` : null)};
  ${(props) => (props.containerType === 'transactional' ? `height: ${props.height}` : null)};
  ${(props) => (props.investmentType === 'transactional' ? `height: ${props.height}` : null)};
`;

export const StyledContainer = styled.section`
  position: relative;
  height: max-content;
  min-height: ${(props) => props.minHeight};
  width: max-content;
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  border: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
  border-radius: ${(props) => props.borderRadius};
  padding: 0.2rem;
  background-color: ${(props) => `${props.bgColor}cc`};
  margin: ${(props) => props.margin};

  &.positive {
    border: 0.3rem solid ${(props) => props.theme.colors.secondaryColor};
  }

  &.negative {
    border: 0.3rem solid ${(props) => props.theme.colors.error};
  }

  ${(props) => (props.inBudget === true ? `display: flex` : null)};
  ${(props) => (props.inBudget === true ? `flex-flow: ${props.flow}` : null)};
  ${(props) => (props.inBudget === true ? `justify-content: ${props.justify}` : null)};
  ${(props) => (props.inBudget === true ? `align-items: ${props.align}` : null)};
`;
