import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { DateTime } from 'luxon';
import { produce } from 'immer';
import { StyledLoggedInContainer } from '../Logged-In/LoggedIn';
import { Navigation, AccountLinks } from '../Global/Navigation';
import { BudgetViewport, Viewport } from '../Logged-In/ApplicationDisplay';
import { Person } from '../../Classes/Person';
import { Transaction } from '../../Classes/Transaction';
import { ReceiptItem } from '../../Classes/ReceiptItem';
import { userBudget } from '../../Classes/Budget';
import { calculateSurplus, getFullCashAndAssetValue } from '../Global/Forms/Algorithms/Tithing';

export const Budget = function (props) {
  const {
    theme,
    mobileNavigation,
    setMobileNavigation,
    profileLinks,
    setProfileLinks,
    user,
    setUser,
    budget,
    setBudget,
    categoryIndex,
    setCategoryIndex,
    changeMainCategory,
    currentCategory,
    setCurrentCategory,
    errors,
    setErrors,
    disabledValues,
    setDisabledValues,
    iconObject,
    setIconObject,
  } = props;
  const [profileInformation, setProfileInformation] = useState({});
  const [bankAccount, setBankAccount] = useState(0);
  const [debtAmount, setDebtAmount] = useState(0);
  const [netValue, setNetValue] = useState(0);
  const [surplus, setSurplus] = useState(0);
  const [transaction, setTransaction] = useState(new Transaction());
  const [receiptItem, setReceiptItem] = useState(new ReceiptItem());

  const getUserPermissions = () => {
    if (budget && budget.budgetAdmins && user && user.firstname) {
      const updated = produce(user, (roughDraft) => {
        if (budget.budgetAdmins.includes(user._id)) {
          roughDraft.addPermissionLevel('Admin');
        } else {
          roughDraft.addPermissionLevel('Associate');
        }
      });
      setUser(updated);
      setProfileInformation({
        personalInformation: {
          firstname: updated.firstname,
          lastname: updated.lastname,
          username: updated.username,
          latterDaySaint: updated.latterDaySaint,
        },
        communications: {
          emailAddress: updated.email,
          newEmailAddress: '',
          newEmailConfirmed: '',
          phoneNumber: updated.phoneNumber,
          newPhoneNumber: '',
          newPhoneNumberConfirmed: '',
          communicationPreference: updated.communicationPreference,
        },
        passwordManagement: {
          currentPassword: '',
          newPassword: '',
          newPasswordConfirmed: '',
        },
      });
    }
  };

  useEffect(() => {
    try {
      const getUser = async () => {
        let user = await new Person().build();
        setUser(user);
      };
      getUser();
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    try {
      const getBudget = async (copiedBudget) => {
        const budgetId = window.location.pathname.split('/')[5];
        const url = `/Users/${user._id}/Budgets/${budgetId}`;
        const response = await axios({
          method: 'GET',
          url: url,
        });
        const currentMonth = DateTime.now().monthLong;
        const retrievedBudget = response.data.data.budget;
        copiedBudget = copiedBudget.copyBudget(retrievedBudget);
        if (currentMonth !== copiedBudget.currentMonth) {
          const updated = produce(copiedBudget, (roughDraft) => {
            roughDraft.previousMonth = copiedBudget.currentMonth;
            roughDraft.currentMonth = currentMonth;
            // This should get the surplus total for the budget.
            surplus && surplus > 0 ? (roughDraft.accounts.surplus.amount.total = surplus) : (roughDraft.accounts.surplus.amount.total = 0);

            // If the user is a Latter-Day Saint, this should correctly subtract the tithing from the surplus
            if (user && user.latterDaySaint === true) {
              if (roughDraft.accounts.surplus.amount.total - roughDraft.accounts.tithing.amount >= 0) {
                roughDraft.accounts.surplus.amount.total -= roughDraft.accounts.tithing.amount;
              } else {
                roughDraft.accounts.surplus.amount.total = 0;
              }
            }
            let total = 0;
            roughDraft.bankAccount = getFullCashAndAssetValue(total, roughDraft.accounts, roughDraft.investments);
          });
          setBudget(updated);
          setBankAccount(updated.bankAccount);
          setDebtAmount(updated.accounts.debt.debtAmount);
          setNetValue(updated.bankAccount - updated.accounts.debt.debtAmount);
        } else {
          const updated = produce(copiedBudget, (roughDraft) => {
            let total = 0;
            roughDraft.bankAccount = getFullCashAndAssetValue(total, roughDraft.accounts, roughDraft.investments);
          });
          setBudget(updated);
          setBankAccount(updated.bankAccount);
          setDebtAmount(updated.accounts.debt.debtAmount);
          setNetValue(updated.bankAccount - updated.accounts.debt.debtAmount);
        }
        return response;
      };
      let myBudget = userBudget;
      getBudget(myBudget);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    budget && budget.accounts && setSurplus(calculateSurplus(budget));
  }, [budget]);

  useEffect(() => {
    getUserPermissions();
  }, [user]);

  return (
    <StyledLoggedInContainer className="logged-in-container column_1-24 row_1-18">
      <Navigation theme={theme} user={user} />
      {mobileNavigation && (
        <AccountLinks className="side-navigation__account-links" mobile={mobileNavigation}>
          <PrimaryButton size="medium" modifier="white" onClick={() => setProfileLinks(!profileLinks)}>
            Toggle Profile Links
          </PrimaryButton>
          {profileLinks && (
            <ul className="side-navigation__account-links__link-container">
              {['Personal Information', 'Communications', 'Password Management', 'Account Management'].map((buttonName, index) => {
                return (
                  <li className="side-navigation__account-links__link-container__link" key={index}>
                    <Link className="side-navigation__account-links__link-container__link--link" to={`${buttonName.split(' ').join('-')}`}>
                      {buttonName}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </AccountLinks>
      )}
      <BudgetViewport
        theme={theme}
        mobileNavigation={mobileNavigation}
        user={user}
        setUser={setUser}
        profileInformation={profileInformation}
        setProfileInformation={setProfileInformation}
        budget={budget}
        setBudget={setBudget}
        surplus={surplus}
        setSurplus={setSurplus}
        categoryIndex={categoryIndex}
        setCategoryIndex={setCategoryIndex}
        changeMainCategory={changeMainCategory}
        bankAccount={bankAccount}
        debtAmount={debtAmount}
        netValue={netValue}
        transaction={transaction}
        setTransaction={setTransaction}
        receiptItem={receiptItem}
        setReceiptItem={setReceiptItem}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
        errors={errors}
        setErrors={setErrors}
        disabledValues={disabledValues}
        setDisabledValues={setDisabledValues}
        iconObject={iconObject}
        setIconObject={setIconObject}
      />
    </StyledLoggedInContainer>
  );
};
