import React, { useState } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import chroma from 'chroma-js';
import { PrimaryButton } from '../Button';

const StyledRecentTransaction = styled.section`
  &.recent-transaction {
    position: relative;
    height: max-content;
    min-height: 10rem;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
    border: 0.2rem solid ${(props) => props.theme.colors.primaryColor};
    margin-bottom: 1rem;
    font-family: ${(props) => props.theme.text.secondary};

    & .recent-transaction__header {
      position: relative;
      height: max-content;
      min-height: 6rem;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
      background-color: ${(props) => chroma(props.theme.colors.primaryColor).darken(0.4).hex()};

      & i {
        font-size: 3rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }

      & p {
        font-size: 2rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }

    & .recent-transaction__action-container {
      position: relative;
      height: max-content;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
      background-color: ${(props) => props.theme.colors.grayScale.offWhite};

      & .recent-transaction__action-container__half {
        position: relative;
        height: max-content;
        min-height: 8rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-items: center;

        & p {
          font-size: 2rem;
          color: ${(props) => props.theme.colors.primaryColor};
        }
      }
    }
  }
`;

export const RecentTransactionElement = function (props) {
  const { theme, recentTransaction, user, setUser, budget, setBudget, openReceipt, setOpenReceipt, selectedTransaction, setSelectedTransaction, recentTransactionIndex } = props;
  return (
    <StyledRecentTransaction className="recent-transaction">
      <header className="recent-transaction__header">
        <i className={`fas ${recentTransaction.transactionType === 'Deposit' ? 'fa-vault' : 'fa-credit-card'}`} />
        <p>{recentTransaction && `Type: ${recentTransaction.transactionType}`}</p>
        <p>{recentTransaction && `Location: ${recentTransaction.location}`}</p>
        <p>{recentTransaction && DateTime.fromISO(recentTransaction.transactionDate).toLocaleString(DateTime.DATE_HUGE)}</p>
      </header>
      <div className="recent-transaction__action-container">
        <div className="recent-transaction__action-container__half">
          <p>{user && recentTransaction && `Account: ${recentTransaction.transactionType === 'Deposit' ? 'Un-Allocated' : 'Multiple'}`}</p>
          <p>{user && recentTransaction && `Transaction Cost: ${user.money.format(recentTransaction.total)}`}</p>
        </div>
        <div className="recent-transaction__action-container__half"></div>

        {recentTransaction && (
          <PrimaryButton
            size="small"
            minWidth="10rem"
            maxWidth="20rem"
            name="view-receipt"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setOpenReceipt(!openReceipt);
              setSelectedTransaction(recentTransaction);
            }}>
            View Full Transaction
          </PrimaryButton>
        )}
      </div>
    </StyledRecentTransaction>
  );
};
