import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font: inherit;
    vertical-align: baseline;
  }

  html {
    font-size: 62.5%;
    font-family: Purely Me, Century Gothic, Verdana, Sans-Serif;
    font-variant-ligatures: none;
    scroll-behavior: smooth;
  }
  
  body {
    position: relative;
    height: 100%;
    width: 100vw;
    box-sizing: border-box;
    background-color: $colorOffWhite;
    overflow-x: hidden;
  }
  .closed {
    display: none !important;
  }
  .open {
    display: flex;
  }

  .error {
    color: ${(props) => props.theme.colors.error};
    &--centered {
      text-align: center;
    }
  }

  @font-face {
  font-family: 'Icomoon';
  src: url('./../../../SCSS/Vendors/icomoon/fonts/icomoon.eot');
  src: url('./../../../SCSS/Vendors/icomoon/fonts/icomoon.eot') format('embedded-opentype'),
    url('./../../../SCSS/Vendors/icomoon/fonts/icomoon.ttf') format('truetype'),
    url('./../../../SCSS/Vendors/icomoon/fonts/icomoon.woff') format('woff'),
    url('./../../../SCSS/Vendors/icomoon/fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
`;

export default GlobalStyle;
