import React from 'react';
import styled from 'styled-components';
import { DateTime, Info } from 'luxon';
import { PrimaryButton } from '../Button';
import { LandingFormCloser } from '../Forms/FormCloser';
import { SubCategoryGoalInput } from '../Forms/Input';
import { TimingLabel } from '../Forms/Label';
import FormSection from '../Forms/Section';
import { TimingSelect } from '../Forms/Select';
import { replaceClassName } from '../../Utility';
import { buildPaymentSchedule, getCategoryIndex, getMostRecentPayment, verifyBiMonthlyDates, verifyDate } from './BudgetUtility';
import { myCalendar } from '../../../Classes/FrontEnd-Calendar';

const TimingIcon = styled.span`
  &[class^='icon-'],
  &[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Icomoon;
    font-size: 3rem;
    & .path1:before {
      color: ${(props) => props.theme.colors.primaryColor};
    }
  }
`;

export const MonthTimingIcon = styled(TimingIcon)`
  &.icon-calendar-month {
    & .path1:before {
      /* content: '\e905'; */
      /* color: rgb(0, 0, 0); */
      color: ${(props) => props.theme.colors.primaryColor};
    }
    & .path2:before {
      /* content: '\e906'; */
      margin-left: -1.251953125em;
      color: rgb(255, 255, 255);
      opacity: 0.75;
    }
  }
`;

export const BiMonthlyTimingIcon = styled(TimingIcon)`
  &.icon-calendar-bi-month {
    & .path1:before {
      /* content: '\e900'; */
      /* color: rgb(0, 0, 0); */
      color: ${(props) => props.theme.colors.primaryColor};
    }
    & .path2:before {
      /* content: '\e901'; */
      margin-left: -1.251953125em;
      color: rgb(255, 255, 255);
      opacity: 0.75;
    }
    & .path3:before {
      /* content: '\e902'; */
      margin-left: -1.251953125em;
      color: rgb(255, 255, 255);
      opacity: 0.75;
    }
  }
`;

export const BiWeeklyTimingIcon = styled(TimingIcon)`
  &.icon-calendar-bi-weekly {
    & .path1:before {
      /* content: '\e903'; */
      /* color: rgb(0, 0, 0); */
      color: ${(props) => props.theme.colors.primaryColor};
    }
    & .path2:before {
      /* content: '\e904'; */
      margin-left: -1.251953125em;
      color: rgb(255, 255, 255);
      opacity: 0.75;
    }
  }
`;
export const WeeklyTimingIcon = styled(TimingIcon)`
  &.icon-calendar-weekly {
    & .path1:before {
      /* content: '\e907'; */
      /* color: rgb(0, 0, 0); */
      color: ${(props) => props.theme.colors.primaryColor};
    }
    & .path2:before {
      /* content: '\e908'; */
      margin-left: -1.251953125em;
      color: rgb(255, 255, 255);
      opacity: 0.75;
    }
  }
`;

const TimingSection = styled(FormSection)`
  padding-top: 2rem;
`;

const StyledTimingContainer = styled.section`
  position: sticky;
  top: 0;
  height: max-content;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  transform: translate(0, 0) rotate(0);
  background-color: ${(props) => `${props.theme.colors.grayScale.offWhite}f2`};
  overflow-y: auto;
  z-index: 10;
`;

export const TimingContainer = function (props) {
  const { theme, toggleTiming, budget, setBudget, currentCategory, setCurrentCategory, errors, setErrors } = props;

  const updateSubCategoryTiming = (event) => {
    event.preventDefault();
    const timing = event.target.closest('.timing-container__section').firstChild.firstChild.nextSibling.textContent;
    const timingInputs = document.querySelectorAll('.timing-input');
    const mainIndex = getCategoryIndex('main', budget, currentCategory.mainCategory);
    const subIndex = getCategoryIndex('sub', budget, currentCategory.subCategory, mainIndex);
    if (timing === 'Monthly') {
      const monthlyDate = timingInputs[0].value;
      if (verifyDate(monthlyDate)) return setErrors({ ...errors, ['subCategoryTiming']: `The selected date is in the past.  Please select a future date.` });
      const paymentSchedule = buildPaymentSchedule(timing, monthlyDate);
      // * FROM HERE IT IS UPDATING THE CORRECT SUB-CATEGORY WITH THE SCHEDULE, PAYMENT CYCLE, DUE DATES, AND STATEMENT
      budget.updateSubCategoryTiming({
        budget: budget,
        setBudget: setBudget,
        paymentCycle: timing,
        paymentSchedule: paymentSchedule,
        index: mainIndex,
        subCategoryIndex: subIndex,
        statement: `Due the ${DateTime.fromISO(paymentSchedule[0]).day}${myCalendar.getDaySuffix(DateTime.fromISO(paymentSchedule[0]).day)} of every month.`,
      });
    } else if (timing === 'Bi-Monthly') {
      const biMonthlyDateOne = timingInputs[1].value;
      const biMonthlyDateTwo = timingInputs[2].value;
      if (verifyDate(biMonthlyDateOne) || verifyDate(biMonthlyDateTwo)) {
        return setErrors({ ...errors, ['subCategoryTiming']: `One of the selected dates is in the past.  Please select a future date.` });
      } else {
        setErrors({ ...errors, ['subCategoryTiming']: `` });
      }
      if (!verifyBiMonthlyDates(biMonthlyDateOne, biMonthlyDateTwo)) {
        return setErrors({ ...errors, ['subCategoryTiming']: `The dates selected must be in the same month.` });
      } else {
        setErrors({ ...errors, ['subCategoryTiming']: `` });
      }
      const paymentSchedule = buildPaymentSchedule(timing, [biMonthlyDateOne, biMonthlyDateTwo]);
      let statement;
      if (paymentSchedule[0].length === 2) {
        statement = `Due the ${DateTime.fromISO(paymentSchedule[0][0]).day}${myCalendar.getDaySuffix(DateTime.fromISO(paymentSchedule[0][0]).day)} and ${
          DateTime.fromISO(paymentSchedule[0][1]).day
        }${myCalendar.getDaySuffix(DateTime.fromISO(paymentSchedule[0][1]).day)} of every month.`;
      } else {
        statement = `Due the ${DateTime.fromISO(paymentSchedule[0][0]).day}${myCalendar.getDaySuffix(DateTime.fromISO(paymentSchedule[0][0]).day)} and ${
          DateTime.fromISO(paymentSchedule[1][0]).day
        }${myCalendar.getDaySuffix(DateTime.fromISO(paymentSchedule[1][0]).day)} of every month.`;
      }
      budget.updateSubCategoryTiming({
        budget: budget,
        setBudget: setBudget,
        paymentCycle: timing,
        paymentSchedule: paymentSchedule,
        index: mainIndex,
        subCategoryIndex: subIndex,
        statement: statement,
      });
    } else if (timing === 'Bi-Weekly') {
      const biWeeklyDate = timingInputs[3].value;
      if (verifyDate(biWeeklyDate)) return setErrors({ ...errors, ['subCategoryTiming']: `The selected date is in the past.  Please select a future date.` });
      const paymentSchedule = buildPaymentSchedule(timing, biWeeklyDate);
      const mostRecentPayment = getMostRecentPayment(paymentSchedule);
      let statement;
      if (mostRecentPayment === undefined || mostRecentPayment === null) {
        statement = `Due every two weeks, starting the ${DateTime.fromISO(paymentSchedule[0]).day}${myCalendar.getDaySuffix(DateTime.fromISO(paymentSchedule[0]).day)} of this month`;
      } else if (mostRecentPayment !== undefined || mostRecentPayment !== null) {
        statement = `Due every two weeks. The last payment was the ${DateTime.fromISO(mostRecentPayment).day}${myCalendar.getDaySuffix(DateTime.fromISO(mostRecentPayment))} of this month`;
      }
      budget.updateSubCategoryTiming({
        budget: budget,
        setBudget: setBudget,
        paymentCycle: timing,
        paymentSchedule: paymentSchedule,
        index: mainIndex,
        subCategoryIndex: subIndex,
        statement: statement,
      });
    } else if (timing === 'Weekly') {
      const weeklyDate = timingInputs[4];
      const paymentSchedule = buildPaymentSchedule(timing, weeklyDate);
      if (verifyDate(paymentSchedule[0])) return setErrors({ ...errors, ['subCategoryTiming']: `The selected date is in the past.  Please select a future date.` });
      budget.updateSubCategoryTiming({
        budget: budget,
        setBudget: setBudget,
        paymentCycle: timing,
        paymentSchedule: paymentSchedule,
        index: mainIndex,
        subCategoryIndex: subIndex,
        statement: `Starting the ${DateTime.fromISO(paymentSchedule[0]).day}${myCalendar.getDaySuffix(DateTime.fromISO(paymentSchedule[0]).day)}, due every ${weeklyDate.value}.`,
      });
    }
    toggleTiming(event);
  };

  const toggleTimingSections = (event, labelIndexes, buttonIndex) => {
    event.preventDefault();
    const labels = document.querySelectorAll('.timing-container__section__label');
    const submitButtons = document.querySelectorAll('.timing-container__section__button');
    labels.forEach((label) => replaceClassName(label, `open`, `closed`));
    submitButtons.forEach((button) => replaceClassName(button, `open`, `closed`));
    labelIndexes.forEach((index) => replaceClassName(labels[index], `closed`, `open`));
    submitButtons.forEach((button) => replaceClassName(submitButtons[buttonIndex], `closed`, `open`));
  };

  return (
    <StyledTimingContainer className="timing-container closed">
      <LandingFormCloser onClick={(e) => toggleTiming(e)}>
        <i className="fas fa-times timing-container-close" />
      </LandingFormCloser>
      <TimingSection className="timing-container__section" flow="column nowrap" justify="flex-start" align="center">
        <PrimaryButton
          size="medium"
          modifier="timing"
          minHeight="5rem"
          margin="0 0 1rem 0"
          color={theme.colors.primaryColor}
          hoverColor={theme.colors.grayScale.offWhite}
          activeColor={theme.colors.grayScale.raisinBlack}
          onClick={(e) => toggleTimingSections(e, [0], 0)}
          data-cy="monthlyTiming">
          <MonthTimingIcon className="icon icon-calendar-month">
            <span className="path1"></span>
            <span className="path2"></span>
          </MonthTimingIcon>
          <p>Monthly</p>
        </PrimaryButton>
        <TimingLabel className="timing-container__section__label closed">
          <p>Due Date</p>
          <SubCategoryGoalInput type="date" minHeight="4rem" width="13rem" className="timing-input" data-cy="monthlyTimingInput" />
        </TimingLabel>
        <PrimaryButton
          size="medium"
          modifier="timing"
          minHeight="5rem"
          transform="scale(.75)"
          className="timing-container__section__button closed"
          color={theme.colors.primaryColor}
          hoverColor={theme.colors.grayScale.offWhite}
          activeColor={theme.colors.grayScale.raisinBlack}
          onClick={(e) => updateSubCategoryTiming(e)}
          data-cy="monthlyTimingSubmit">
          Submit
        </PrimaryButton>
      </TimingSection>
      <TimingSection className="timing-container__section" flow="column nowrap" justify="space-evenly" align="center">
        <PrimaryButton
          size="medium"
          modifier="timing"
          minHeight="5rem"
          margin="0 0 1rem 0"
          color={theme.colors.primaryColor}
          hoverColor={theme.colors.grayScale.offWhite}
          activeColor={theme.colors.raisinBlack}
          onClick={(e) => toggleTimingSections(e, [1, 2], 1)}
          data-cy="bi-MonthlyTiming">
          <BiMonthlyTimingIcon className="icon icon-calendar-bi-month">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </BiMonthlyTimingIcon>
          <p>Bi-Monthly</p>
        </PrimaryButton>
        <TimingLabel className="timing-container__section__label closed">
          <p>Due Date One</p>
          <SubCategoryGoalInput type="date" minHeight="4rem" width="13rem" className="timing-input" data-cy="bi-MonthlyTimingInputOne" />
        </TimingLabel>
        <TimingLabel className="timing-container__section__label closed">
          <p>Due Date Two</p>
          <SubCategoryGoalInput type="date" minHeight="4rem" width="13rem" className="timing-input" data-cy="bi-MonthlyTimingInputTwo" />
        </TimingLabel>
        <PrimaryButton
          size="medium"
          modifier="timing"
          minHeight="5rem"
          transform="scale(.75)"
          className="timing-container__section__button closed"
          color={theme.colors.primaryColor}
          hoverColor={theme.colors.grayScale.offWhite}
          activeColor={theme.colors.grayScale.raisinBlack}
          onClick={(e) => updateSubCategoryTiming(e)}
          data-cy="bi-MonthlyTimingSubmit">
          Submit
        </PrimaryButton>
      </TimingSection>
      <TimingSection className="timing-container__section" flow="column nowrap" justify="flex-start" align="center">
        <PrimaryButton
          size="medium"
          modifier="timing"
          minHeight="5rem"
          margin="0 0 1rem 0"
          color={theme.colors.primaryColor}
          hoverColor={theme.colors.grayScale.offWhite}
          activeColor={theme.colors.raisinBlack}
          onClick={(e) => toggleTimingSections(e, [3], 2)}
          data-cy="bi-WeeklyTiming">
          <BiWeeklyTimingIcon className="icon icon-calendar-bi-weekly">
            <span className="path1"></span>
            <span className="path2"></span>
          </BiWeeklyTimingIcon>
          <p>Bi-Weekly</p>
        </PrimaryButton>
        <TimingLabel className="timing-container__section__label closed">
          <p>Start Date</p>
          <SubCategoryGoalInput type="date" minHeight="4rem" width="13rem" className="timing-input" data-cy="bi-WeeklyTimingInput" />
        </TimingLabel>
        <PrimaryButton
          size="medium"
          modifier="timing"
          minHeight="5rem"
          transform="scale(.75)"
          className="timing-container__section__button closed"
          color={theme.colors.primaryColor}
          hoverColor={theme.colors.grayScale.offWhite}
          activeColor={theme.colors.grayScale.raisinBlack}
          onClick={(e) => updateSubCategoryTiming(e)}
          data-cy="bi-WeeklyTimingSubmit">
          Submit
        </PrimaryButton>
      </TimingSection>
      <TimingSection className="timing-container__section" flow="column nowrap" justify="flex-start" align="center">
        <PrimaryButton
          size="medium"
          modifier="timing"
          minHeight="5rem"
          margin="0 0 1rem 0"
          color={theme.colors.primaryColor}
          hoverColor={theme.colors.grayScale.offWhite}
          activeColor={theme.colors.raisinBlack}
          onClick={(e) => toggleTimingSections(e, [4], 3)}
          data-cy="weeklyTiming">
          <WeeklyTimingIcon className="icon icon-calendar-week">
            <span className="path1"></span>
            <span className="path2"></span>
          </WeeklyTimingIcon>
          <p>Weekly</p>
        </PrimaryButton>
        <TimingLabel className="timing-container__section__label closed">
          <p>Weekday</p>
          <TimingSelect theme={theme} options={Info.weekdays('long')} />
        </TimingLabel>
        <PrimaryButton
          size="medium"
          modifier="timing"
          minHeight="5rem"
          transform="scale(.75)"
          className="timing-container__section__button closed"
          color={theme.colors.primaryColor}
          hoverColor={theme.colors.grayScale.offWhite}
          activeColor={theme.colors.grayScale.raisinBlack}
          onClick={(e) => updateSubCategoryTiming(e)}
          data-cy="weeklyTimingSubmit">
          Submit
        </PrimaryButton>
      </TimingSection>
    </StyledTimingContainer>
  );
};

/*
section.timing-container.r__timing-container.closed
          i.fas.fa-times.timing-container__closure-icon.r__timing-container__closure-icon
          section.timing-container__section.r__timing-container__section
            button.button--timing-button.r__button--timing-button
              span.icon-calendar-month.button--timing-button__icon.r__button--timing-button__icon
                span.path1
                span.path2
              p.button--timing-button__text.r__button--timing-button__text Monthly
            label.timing-container__section__label.r__timing-container__section__label.closed Due Date
              input.timing-container__section__label__input.r__timing-container__section__label__input(type="date")
            button.button--timing-button-submit.r__button--timing-button-submit.timing-submit-button.closed Submit

          section.timing-container__section.r__timing-container__section
            button.button--timing-button.r__button--timing-button
              span.icon-calendar-bi-month.button--timing-button__icon.r__button--timing-button__icon
                span.path1
                span.path2
                span.path3
              p.button--timing-button__text.r__button--timing-button__text Bi-Monthly
            label.timing-container__section__label.r__timing-container__section__label.closed First Due Date
              input.timing-container__section__label__input.r__timing-container__section__label__input(type="date") 
            label.timing-container__section__label.r__timing-container__section__label.closed Second Due Date
              input.timing-container__section__label__input.r__timing-container__section__label__input(type="date") 
            button.button--timing-button-submit.r__button--timing-button-submit.timing-submit-button.closed Submit
          section.timing-container__section.r__timing-container__section
            button.button--timing-button.r__button--timing-button
              span.icon-calendar-bi-weekly.button--timing-button__icon.r__button--timing-button__icon
                span.path1
                span.path2
              p.button--timing-button__text.r__button--timing-button__text Bi-Weekly
            label.timing-container__section__label.r__timing-container__section__label.closed Start Date
              input.timing-container__section__label__input.r__timing-container__section__label__input(type="date") 
            button.button--timing-button-submit.r__button--timing-button-submit.timing-submit-button.closed Submit
          section.timing-container__section.r__timing-container__section
            button.button--timing-button.r__button--timing-button
              span.icon-calendar-week.button--timing-button__icon.r__button--timing-button__icon
                span.path1
                span.path2
              p.button--timing-button__text.r__button--timing-button__text Weekly
            label.timing-container__section__label.r__timing-container__section__label.closed Weekday
              - const weekdays = [`Sunday`, `Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`, `Saturday`]
              select.timing-container__section__label__select.r__timing-container__section__label__select
                each day, index in weekdays
                  option.timing-container__section__label__select__option.timing-container__section__label__select__option(value=`${day}` data.dayIndex=`${index}`)= `${day}`
            button.button--timing-button-submit.r__button--timing-button-submit.timing-submit-button.closed Submit
*/

/*
.timing-container {
  @include SizeAndPositioning(sticky, 100%, 100%, flex, column, nowrap, flex-start, center, 0, 0, 0);
  background-color: rgba($colorOffWhite, 0.9);
  min-height: 100%;
  overflow-y: auto;
  top: 4rem;
  left: 0;
  z-index: 10;
  &__closure-icon {
    @include SizeAndPositioning(absolute, 4rem, 4rem, flex, row, nowrap, center, center, 0, 0, 0);
    top: 2rem;
    right: 2rem;
    &:hover {
      cursor: pointer;
      transition: color 0.5s;
    }
  }
  &__section {
    @include SizeAndPositioning(relative, min-content, 80%, flex, column, nowrap, center, center, 0, 0, 0);
    &__label {
      @include SizeAndPositioning(relative, 5rem, 60%, flex, row, nowrap, space-evenly, center, 0, 0, 0);
      border: 0.3rem solid rgba($primaryColor, 0.8);
      border-radius: 0.8rem;
      padding: 1rem;
      margin: 0.25rem 0;
      &:first-of-type {
        margin-top: 0.5rem;
      }
      &:last-of-type {
        margin-bottom: 0.5rem;
      }
      &:focus {
        border: 0.3rem solid $primaryColor;
      }
      &__input,
      &__select {
        background-color: rgba($colorOffWhite, 0.25);
        border: 0.2rem solid rgba($primaryColor, 0.8);
        border-radius: 0.6rem;
        padding: 0.2rem;
        &:focus {
          border: 0.2rem solid $primaryColor;
          outline: none;
        }
      }
    }
    .element-hidden {
      display: none;
    }
  }
  &__hidden {
    display: none;
  }
}
*/
