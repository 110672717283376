import React from 'react';
import styled from 'styled-components';
import chroma from 'chroma-js';
import { SwitchLabel } from './Label';
import { handleTransaction } from './Algorithms/InputChange';

const StyledTimingSelect = styled.select`
  height: max-content;
  min-height: ${(props) => props.minHeight};
  width: ${(props) => props.width};
  min-width: 6rem;
  border: 0.2rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
  border-radius: 0.5rem;
  margin: ${(props) => props.margin};
  background-color: ${(props) => `${props.theme.colors.grayScale.raisinBlack}99`};
  font-family: ${(props) => props.theme.text.secondary};
  font-size: 1.4rem;
  color: ${(props) => `${chroma(props.theme.colors.grayScale.offWhite).brighten(0.4).hex()}cc`};
  padding-left: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  text-align: center;

  &:focus {
    border-color: ${(props) => props.theme.colors.primaryColor};
    background-color: ${(props) => props.theme.colors.grayScale.raisinBlack};
    color: ${(props) => props.theme.colors.secondaryColor};
  }
`;

export const CreateBudgetSelect = styled(StyledTimingSelect)`
  min-width: ${(props) => props.minWidth};
  font-family: ${(props) => props.theme.text.secondary};
  font-size: 2rem;
  border-radius: ${(props) => props.borderRadius};

  &:focus {
    border-radius: ${(props) => props.borderRadius};
    border-color: ${(props) => props.theme.colors.primaryColor};
    background-color: ${(props) => props.theme.colors.grayScale.raisinBlack};
    color: ${(props) => props.theme.colors.secondaryColor};
  }
`;

export const TimingSelect = function (props) {
  const { theme, options } = props;
  return (
    <StyledTimingSelect minHeight="4rem" width="13rem" className="timing-input" data-cy="weeklyTimingInput">
      {options &&
        options.map((day, index) => {
          return (
            <option key={index} value={day}>
              {day}
            </option>
          );
        })}
    </StyledTimingSelect>
  );
};

export const StyledAccountSelect = styled(StyledTimingSelect)`
  padding-left: 0;
  padding: ${(props) => props.padding};
`;

export const AccountSelect = function (props) {
  const { theme, user, options, name, content, setUser, budget, setBudget, transaction, setTransaction, receiptItem, setReceiptItem } = props;
  return (
    <>
      <StyledAccountSelect
        minHeight="4rem"
        width={content.width}
        padding={content.padding}
        className="account-select"
        data-cy="weeklyTimingInput"
        data-date={content.date}
        name={name}
        onChange={(e) =>
          handleTransaction(
            e,
            'Withdrawal',
            { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
            { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
          )
        }>
        {options &&
          options.map((account, index) => {
            return (
              <option key={index} value={account}>
                {account}
              </option>
            );
          })}
      </StyledAccountSelect>
    </>
  );
};

export const PlanningAccountSelect = function (props) {
  const { theme, user, options, name, content, setUser, budget, setBudget, plannedTransaction, setPlannedTransaction, receiptItem, setReceiptItem } = props;
  return (
    <>
      <StyledAccountSelect
        minHeight="4rem"
        width={content.width}
        padding={content.padding}
        className="account-select"
        data-cy="weeklyTimingInput"
        data-date={content.date}
        name={name}
        onChange={(e) => handleTransaction(e, `${content.formType || 'Planning'}`, content.values, content.setters)}>
        {options &&
          options.map((account, index) => {
            return (
              <option key={index} value={account}>
                {account}
              </option>
            );
          })}
      </StyledAccountSelect>
    </>
  );
};
