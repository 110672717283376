import { Category } from './Category';
import { DateTime } from 'luxon';
import { immerable, produce } from 'immer';

////////////////////////////////////////
// SUB CATEGORY -- CHILD CLASS
export class SubCategory extends Category {
  [immerable] = true;
  constructor(options) {
    const superOpts = { ...options };
    super(superOpts);
    this.timingOptions = {};
    this.goalAmount = 0;
    this.amountSaved = 0;
    this.amountSpent = 0;
    this.amountRemaining = 0;
    this.percentageSpent = 0;
    this.surplus = false;
    this.createdAt = DateTime.now().toBSON();
  }

  makeCopy(subCategory) {
    for (let key in subCategory) {
      this[key] = subCategory[key];
    }
    return this;
  }

  toggleSurplus() {
    this.surplus = !this.surplus;
  }

  updateLastUpdated() {
    this.lastUpdated = DateTime.now().toBSON();
  }

  updateGoalAmount(value) {
    this.goalAmount = value;
  }

  updateAmountSpent(value) {
    this.amountSpent = value;
  }

  addToAmountSpent(value) {
    this.amountSpent += value;
  }

  updateAmountRemaining() {
    this.amountRemaining = this.goalAmount - this.amountSpent;
    if (this.goalAmount - this.amountSpent < 0) this.amountRemaining = 0;
  }

  updatePercentageSpent() {
    this.percentageSpent = this.amountSpent / this.goalAmount;
    if (isNaN(this.percentageSpent)) this.percentageSpent = 0;
  }

  updateAmountSaved(value) {
    this.amountSaved = value;
  }

  updateTiming(options) {

    this.timingOptions.paymentCycle = options.paymentCycle;
    this.timingOptions.paymentSchedule = options.paymentSchedule;
    let timingArray;
    if (options.paymentCycle !== 'Bi-Monthly') {
      timingArray = [this.timingOptions.paymentSchedule[0]];
    } else {
      timingArray = this.timingOptions.paymentSchedule[0];
    }
    this.timingOptions.dueDates = timingArray;
    this.timingOptions.dueDateStatement = options.statement;
  }
}
