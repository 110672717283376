import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { Chart as ChartJS, LineController, LineElement, PointElement, CategoryScale, LinearScale, Filler, Title, SubTitle, Tooltip, Legend } from 'chart.js';
import chroma from 'chroma-js';
import { Line } from 'react-chartjs-2';
import { produce } from 'immer';
import { BudgetPageSection } from '../../Global/Forms/Section';
import { BudgetContainerHeader } from '../../Global/Forms/Header';
import FormSection from '../../Global/Forms/Section';
import { StyledExtraLargeContainer } from '../../Global/Budget/BudgetContainers/ExtraLargeContainer';
import { ExtraSmallContainer } from '../../Global/Budget/BudgetContainers/ExtraSmallContainer';
import { StyledContentContainer } from '../../Global/Budget/BudgetContainers/BasicContainer';
import { Form } from '../../Global/Forms/Form';
import { PlanningAccountSelect } from '../../Global/Forms/Select';
import { SwitchLabel } from '../../Global/Forms/Label';
import { SubCategoryGoalInput } from '../../Global/Forms/Input';
import { PrimaryButton, SmallBorderlessButton } from '../../Global/Button';
import { handleTransaction } from '../../Global/Forms/Algorithms/InputChange';
import { InvestmentChartCloser } from '../../Global/Forms/FormCloser';
import { StyledModal } from '../../Global/Modal';
import { Investment } from '../../../Classes/Investment';
import { getFullCashAndAssetValue } from '../../Global/Forms/Algorithms/Tithing';
import ErrorContainer from '../../Global/Forms/Errors';

ChartJS.register(LineController, LineElement, PointElement, CategoryScale, LinearScale, Filler, Title, SubTitle, Tooltip, Legend);

const initialInvestment = {
  investmentType: '',
  investmentName: '',
  investmentDescription: '',
  initialInvestment: 0,
  currentValue: 0,
  settled: false,
  valueDifference: 0,
  values: [],
  dates: [],
};

const initialOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '',
      color: '',
      font: {
        size: 50,
      },
    },
    filler: {
      propagate: true,
    },
  },
};

const initialData = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      fill: true,
      borderColor: '',
      backgroundColor: '',
    },
  ],
};

export const InvestmentManager = function (props) {
  const { theme, user, setUser, budget, setBudget, transaction, setTransaction, handleInputChange, errors, setErrors, disabledValues, setDisabledValues } = props;
  const [investing, setInvesting] = useState(false);
  const [investment, setInvestment] = useState(initialInvestment);
  const [options, setOptions] = useState(initialOptions);
  const [data, setData] = useState(initialData);
  const [newValue, setNewValue] = useState(0);
  const [seeChart, setSeeChart] = useState(false);

  return (
    <BudgetPageSection minHeight="85%" flow="row wrap" justify="space-evenly" align="center">
      <BudgetContainerHeader budgetPage={true} height="10rem" justify="center" bgColor={'transparent'} textColor={theme.colors.tertiaryColor} className="budget-page-header">
        Investment Manager
      </BudgetContainerHeader>
      <ErrorContainer>
        {errors && (
          <ol>
            {Object.keys(errors).map((error, index) => {
              if (errors[error] !== '') {
                return (
                  <li className="error" key={index}>
                    {errors[error]}
                  </li>
                );
              }
            })}
          </ol>
        )}
      </ErrorContainer>
      <StyledExtraLargeContainer borderRadius=".9rem" inBudget={true} flow="column nowrap" justify="flex-start" align="center" margin="0 0 5rem">
        {seeChart && (
          <StyledModal
            position="absolute"
            height="99%"
            width="100%"
            flow="column nowrap"
            justify="center"
            align="center"
            borderRadius=".5rem"
            bgColor={`${theme.colors.grayScale.offWhite}f2`}
            ZIndex="15"
            className="investment-modal">
            <InvestmentChartCloser
              onClick={(e) => {
                e.preventDefault();
                setSeeChart(!seeChart);
                setOptions(initialOptions);
                setData(initialData);
              }}>
              <i className="fas fa-window-close" />
            </InvestmentChartCloser>
            <Line options={options} data={data} />
          </StyledModal>
        )}

        <BudgetContainerHeader minHeight="5rem" justify="space-between" align="center" iconSize="2.6rem" textSize="3rem" borderRadius=".5rem .5rem 0 0">
          <i className="fas fa-money-bill-trend-up" />
          <p>Investment Manager</p>
        </BudgetContainerHeader>
        <FormSection
          modifier="transactional"
          sectionHeight="40rem"
          sectionWidth="100%"
          flow="column nowrap"
          justify="space-evenly"
          align="center"
          padding="0 0 3rem 0"
          borderBottom={`.2rem solid ${theme.colors.primaryColor}`}>
          <BudgetContainerHeader
            budgetPage={true}
            investmentHeader={true}
            textSize="5rem"
            height="5rem"
            justify="center"
            bgColor={'transparent'}
            textColor={theme.colors.primaryColor}
            className="budget-page-header">
            Active Investments
          </BudgetContainerHeader>
          <FormSection modifier="transactional" sectionHeight="80%" sectionWidth="100%" flow="row nowrap" justify="flex-start" align="center" padding="0 0 0 3rem" overflowX="auto">
            <ExtraSmallContainer
              theme={theme}
              user={user}
              budget={budget}
              title={`Investment Fund`}
              icon={'money-bill-trend-up'}
              content={{
                content: (
                  <StyledContentContainer containerType="transactional" height="100%" minHeight="100%" minWidth="100%" flow="column nowrap" justify="space-evenly" align="center">
                    <FormSection minHeight="14rem" flow="row nowrap" justify="space-evenly" align="center">
                      <p>{user && budget && budget.accounts && user.money.format(budget.accounts.investmentFund.amount + budget.accounts.investmentFund.investedAmount)}</p>
                    </FormSection>
                    <FormSection minHeight="6rem" flow="row nowrap" justify="space-evenly" align="center" borderTop={`.2rem solid ${theme.colors.primaryColor}`}>
                      <FormSection minHeight="6rem" width="50%" flow="column nowrap" justify="space-evenly" align="center" appliedSection={true} textHeaderSize="1.6rem" textSize="1.2rem">
                        <p className="tp-header">Applied</p>
                        <p className="tp-text">{user && budget && budget.accounts && user.money.format(budget.accounts.investmentFund.investedAmount)}</p>
                      </FormSection>
                      <FormSection
                        minHeight="6rem"
                        width="50%"
                        flow="column nowrap"
                        justify="space-evenly"
                        align="center"
                        appliedSection={true}
                        textHeaderSize="1.6rem"
                        textSize="1.2rem"
                        borderLeft={`.2rem solid ${theme.colors.primaryColor}`}>
                        <p className="tp-header">Un-Applied</p>
                        <p className="tp-text">{user && budget && budget.accounts && user.money.format(budget.accounts.investmentFund.amount)}</p>
                      </FormSection>
                    </FormSection>
                  </StyledContentContainer>
                ),
                margin: '0 0 0 3rem',
                bgColor: theme.colors.grayScale.offWhite,
              }}
            />
            {budget &&
              budget.investments &&
              budget.investments
                .filter((investment) => investment.settled === false)
                .map((investment, index) => {
                  return (
                    <ExtraSmallContainer
                      theme={theme}
                      user={user}
                      budget={budget}
                      title={investment.investmentName}
                      icon={
                        investment.investmentType === 'Stock Market'
                          ? 'money-bill-trend-up'
                          : investment.investmentType === 'Real Estate'
                          ? 'tree-city'
                          : investment.investmentType === 'Timeshare'
                          ? 'clock'
                          : investment.investmentType === 'Other'
                          ? 'arrow-trend-up'
                          : ''
                      }
                      content={{
                        content: (
                          <StyledContentContainer containerType="transactional" height="100%" minHeight="100%" minWidth="100%" flow="column nowrap" justify="space-evenly" align="center">
                            <FormSection
                              modifier="transactional"
                              minHeight="5rem"
                              sectionHeight="100%"
                              flow="column nowrap"
                              justify="space-evenly"
                              align="center"
                              padding=".5rem"
                              headerTextSize="2rem">
                              <p className="investment-description">{investment.investmentDescription}</p>
                            </FormSection>
                            <FormSection
                              modifier="transactional"
                              minHeight="5rem"
                              sectionHeight="100%"
                              flow="column nowrap"
                              justify="space-evenly"
                              align="center"
                              padding=".5rem"
                              ctaTextSize="1.2rem"
                              descriptionColor={theme.colors.primaryColor}>
                              <PrimaryButton
                                size="small"
                                minWidth="10rem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const updated = produce(budget, (roughDraft) => {
                                    let filteredInvestments = roughDraft.investments.filter((investment) => {
                                      return investment.settled === false;
                                    });
                                    filteredInvestments[index].updateSettled();

                                    roughDraft.accounts.unAllocated.addToAmount(filteredInvestments[index].currentValue);
                                    roughDraft.accounts.investmentFund.subtractInvestment(filteredInvestments[index].initialInvestment);
                                    let total = 0;
                                    roughDraft.bankAccount = getFullCashAndAssetValue(total, roughDraft.accounts, roughDraft.investments);
                                  });
                                  setBudget(updated);
                                  let updateObject = {
                                    budgetId: budget._id,
                                    userId: user._id,
                                    lastUpdated: DateTime.now().toISO(),
                                    currentMonth: budget.currentMonth,
                                    previousMonth: budget.previousMonth || budget.currentMonth,
                                    accounts: updated.accounts,
                                    investments: updated.investments,
                                  };
                                  budget.updateBudget(updateObject, 'Investment-Manager');
                                }}
                                disabled={user && user.permissions === 'Associate'}>
                                Settle
                              </PrimaryButton>
                              <SmallBorderlessButton
                                size="extra-small"
                                className="view-graph"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const updated = produce(options, (roughDraft) => {
                                    roughDraft.plugins.title.text = investment.investmentName;
                                    roughDraft.plugins.title.color = theme.colors.primaryColor;
                                  });
                                  const updatedTwo = produce(data, (roughDraft) => {
                                    roughDraft.labels = investment.dates.map((date) => DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY));
                                    roughDraft.datasets[0].label = `Value in [ ${user && user.currencySymbol ? `${user.currencySymbol} |` : ''} ${user && user.currency} ]`;
                                    roughDraft.datasets[0].data = investment.values;
                                    roughDraft.datasets[0].borderColor = theme.colors.primaryColor;
                                    roughDraft.datasets[0].backgroundColor = chroma(theme.colors.primaryColor).darken(0.4).alpha(0.5).hex();
                                  });
                                  setOptions(updated);
                                  setData(updatedTwo);
                                  setSeeChart(!seeChart);
                                }}>
                                View Line Chart
                              </SmallBorderlessButton>
                            </FormSection>
                            <FormSection
                              modifier="transactional"
                              minHeight="5rem"
                              sectionHeight="100%"
                              flow="row nowrap"
                              justify="space-evenly"
                              align="center"
                              padding=".5rem"
                              borderTop={`.2rem solid ${theme.colors.primaryColor}`}>
                              <FormSection
                                modifier="transactional"
                                minHeight="100%"
                                sectionHeight="7rem"
                                flow="column nowrap"
                                justify="space-evenly"
                                align="center"
                                borderRight={`.2rem solid ${theme.colors.primaryColor}`}
                                headerTextSize="1.6rem"
                                amountTextSize="1.4rem"
                                textColor={theme.colors.primaryColor}>
                                <h5 className="investment-title">Initial Investment</h5>
                                <p className="investment-amount">{user && user.money.format(investment.initialInvestment)}</p>
                              </FormSection>
                              <FormSection
                                modifier="transactional"
                                minHeight="100%"
                                sectionHeight="7rem"
                                flow="column nowrap"
                                justify="space-evenly"
                                align="center"
                                headerTextSize="1.6rem"
                                textColor={theme.colors.primaryColor}>
                                <h5 className="investment-title">Current Value</h5>
                                <FormSection
                                  modifier="transactional"
                                  minHeight="3rem"
                                  sectionHeight="3rem"
                                  sectionWidth="max-content"
                                  flow="row nowrap"
                                  justify="center"
                                  align="center"
                                  padding=".5rem">
                                  <SubCategoryGoalInput
                                    type="number"
                                    name="newValue"
                                    minHeight="3rem"
                                    maxHeight="3rem"
                                    width="10rem"
                                    placeholder={investment.currentValue}
                                    modifier="transaction"
                                    investment={true}
                                    min={0}
                                    borderRadius=".5rem 0 0 .5rem"
                                    className="newInvestmentValue"
                                    data-newvalue={index}
                                    onChange={(e) =>
                                      handleTransaction(
                                        e,
                                        'Investing',
                                        { user: user, budget: budget, newValue: newValue, errors: errors },
                                        { setUser: setUser, setBudget: setBudget, setNewValue: setNewValue, setErrors: setErrors }
                                      )
                                    }
                                  />
                                  <PrimaryButton
                                    size="extra-small"
                                    padding=".5rem"
                                    modifier="investment"
                                    borderRadius="0 .5rem .5rem 0"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      const newValues = document.querySelectorAll('.newInvestmentValue');
                                      let valueToUse = Number(newValues[index].value);
                                      if (valueToUse === undefined || valueToUse === null) return;
                                      if (valueToUse < 0) {
                                        return setErrors({ ...errors, ['investment']: 'An investment`s value can never be a negative.' });
                                      } else {
                                        setErrors({ ...errors, ['investment']: '' });
                                      }

                                      const updated = produce(budget, (roughDraft) => {
                                        let newlyFilteredInvestments = roughDraft.investments.filter((investment) => {
                                          return investment.settled === false;
                                        });

                                        newlyFilteredInvestments[index].updateCurrentValue(valueToUse);
                                        newlyFilteredInvestments[index].values.push(valueToUse);
                                        newlyFilteredInvestments[index].valueDifference = Number(valueToUse) - newlyFilteredInvestments[index].initialInvestment;
                                        newlyFilteredInvestments[index].dates.push(DateTime.now().toISO());

                                        let total = 0;
                                        roughDraft.bankAccount = getFullCashAndAssetValue(total, roughDraft.accounts, roughDraft.investments);
                                      });

                                      setBudget(updated);
                                      let updateObject = {
                                        budgetId: budget._id,
                                        userId: user._id,
                                        lastUpdated: DateTime.now().toISO(),
                                        currentMonth: budget.currentMonth,
                                        previousMonth: budget.previousMonth || budget.currentMonth,
                                        investments: updated.investments,
                                      };
                                      budget.updateBudget(updateObject, 'Investment-Manager');
                                    }}
                                    disabled={user && user.permissions === 'Associate'}>
                                    <i className="fas fa-check" />
                                  </PrimaryButton>
                                </FormSection>
                              </FormSection>
                            </FormSection>
                          </StyledContentContainer>
                        ),
                        investment: true,
                        investmentType: investment.investmentType,
                        investmentHeader: true,
                        investmentPadding: '0 2rem',
                        textSize: `1.2rem`,
                        margin: '0 0 0 3rem',
                        bgColor: theme.colors.grayScale.offWhite,
                      }}
                      key={index}
                    />
                  );
                })}
            <ExtraSmallContainer
              theme={theme}
              user={user}
              budget={budget}
              title={`Add Investment`}
              icon={'money-bill-trend-up'}
              content={{
                content: (
                  <StyledContentContainer containerType="transactional" height="20rem" minHeight="100%" minWidth="100%" flow="column nowrap" justify="space-evenly" align="center">
                    <FormSection minHeight="14rem" flow="row nowrap" justify="space-evenly" align="center" textSize="8rem" textColor={theme.colors.primaryColor}>
                      {!investing && <i className="fas fa-plus investment-icon" onClick={() => setInvesting(!investing)} />}
                      {investing && (
                        <Form flow="column nowrap" justify="space-evenly" align="center" bgColor={theme.colors.grayScale.offWhite}>
                          <FormSection flow="row nowrap" justify="space-evenly" align="center">
                            <FormSection
                              modifier="transactional"
                              minHeight="5rem"
                              sectionHeight="100%"
                              sectionWidth="max-content"
                              flow="column-reverse"
                              justify="space-evenly"
                              align="center"
                              padding=".5rem">
                              <PlanningAccountSelect
                                theme={theme}
                                user={user}
                                name="investmentType"
                                value={investment.investmentType}
                                options={user && [`Select Type`, `Stock Market`, `Real Estate`, `Timeshare`, `Other`]}
                                content={{
                                  width: '13rem',
                                  formType: 'Investing',
                                  values: { user: user, budget: budget, investment: investment },
                                  setters: { setUser: setUser, setBudget: setBudget, setInvestment: setInvestment },
                                }}
                                setUser={setUser}
                                budget={budget}
                                setBudget={setBudget}
                              />
                              <SwitchLabel modifier="transaction" width="17rem">
                                Investment Type
                              </SwitchLabel>
                            </FormSection>
                            <FormSection
                              modifier="transactional"
                              minHeight="5rem"
                              sectionHeight="100%"
                              sectionWidth="max-content"
                              flow="column-reverse"
                              justify="space-evenly"
                              align="center"
                              padding=".5rem">
                              <SubCategoryGoalInput
                                type="text"
                                name="investmentName"
                                minHeight="4rem"
                                maxHeight="4rem"
                                width="13rem"
                                placeholder="Investment Name"
                                modifier="transaction"
                                value={investment.investmentName}
                                onChange={(e) =>
                                  handleTransaction(e, 'Investing', { user: user, budget: budget, investment: investment }, { setUser: setUser, setBudget: setBudget, setInvestment: setInvestment })
                                }
                              />
                              <SwitchLabel modifier="transaction" width="17rem">
                                Investment Name
                              </SwitchLabel>
                            </FormSection>
                          </FormSection>
                          <FormSection flow="row nowrap" justify="space-evenly" align="center">
                            <FormSection
                              modifier="transactional"
                              minHeight="5rem"
                              sectionHeight="100%"
                              sectionWidth="max-content"
                              flow="column-reverse"
                              justify="space-evenly"
                              align="center"
                              padding=".5rem">
                              <SubCategoryGoalInput
                                type="text"
                                name="investmentDescription"
                                minHeight="4rem"
                                maxHeight="4rem"
                                width="13rem"
                                placeholder="Description"
                                modifier="transaction"
                                value={investment.investmentDescription}
                                onChange={(e) =>
                                  handleTransaction(e, 'Investing', { user: user, budget: budget, investment: investment }, { setUser: setUser, setBudget: setBudget, setInvestment: setInvestment })
                                }
                              />
                              <SwitchLabel modifier="transaction" width="17rem">
                                Description
                              </SwitchLabel>
                            </FormSection>
                            <FormSection
                              modifier="transactional"
                              minHeight="5rem"
                              sectionHeight="100%"
                              sectionWidth="max-content"
                              flow="column-reverse"
                              justify="space-evenly"
                              align="center"
                              padding=".5rem">
                              <SubCategoryGoalInput
                                type="number"
                                name="initialInvestment"
                                minHeight="4rem"
                                maxHeight="4rem"
                                width="13rem"
                                placeholder="0"
                                modifier="transaction"
                                min={0}
                                onChange={(e) =>
                                  handleTransaction(
                                    e,
                                    'Investing',
                                    { user: user, budget: budget, investment: investment, errors: errors },
                                    { setUser: setUser, setBudget: setBudget, setInvestment: setInvestment, setErrors: setErrors }
                                  )
                                }
                              />
                              <SwitchLabel modifier="transaction" width="17rem">
                                Initial Investment
                              </SwitchLabel>
                            </FormSection>
                          </FormSection>
                          <FormSection flow="row nowrap" justify="space-evenly" align="center">
                            <FormSection
                              modifier="transactional"
                              minHeight="5rem"
                              sectionHeight="100%"
                              sectionWidth="max-content"
                              flow="column-reverse"
                              justify="space-evenly"
                              align="center"
                              padding=".5rem">
                              <PrimaryButton
                                size="small"
                                minWidth="15rem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (investment.initialInvestment > budget.accounts.investmentFund.amount) {
                                    return setErrors({
                                      ...errors,
                                      ['failed-investment']: `You do not have enough money to make that investment of ${user && user.money.format(investment.initialInvestment)}.`,
                                    });
                                  } else {
                                    setErrors({
                                      ...errors,
                                      ['failed-investment']: ``,
                                    });
                                  }
                                  if (investment.initialInvestment < 0) {
                                    return setErrors({
                                      ...errors,
                                      ['failed-investment']: `You cannot invest a negative amount into anything.`,
                                    });
                                  } else {
                                    setErrors({
                                      ...errors,
                                      ['failed-investment']: ``,
                                    });
                                  }
                                  if (!investment.investmentType) {
                                    return setErrors({
                                      ...errors,
                                      ['failed-investment']: `You must select an investment type.`,
                                    });
                                  } else {
                                    setErrors({
                                      ...errors,
                                      ['failed-investment']: ``,
                                    });
                                  }
                                  if (!investment.investmentName) {
                                    return setErrors({
                                      ...errors,
                                      ['failed-investment']: `You must select an investment name.`,
                                    });
                                  } else {
                                    setErrors({
                                      ...errors,
                                      ['failed-investment']: ``,
                                    });
                                  }
                                  if (!investment.investmentDescription || investment.investmentDescription === '') {
                                    return setErrors({
                                      ...errors,
                                      ['failed-investment']: `You must select an investment description.`,
                                    });
                                  } else {
                                    setErrors({
                                      ...errors,
                                      ['failed-investment']: ``,
                                    });
                                  }
                                  const updated = produce(investment, (roughDraft) => {
                                    roughDraft.values.push(investment.initialInvestment);
                                    roughDraft.dates.push(DateTime.now().toISO());
                                  });
                                  const newInvestment = new Investment(updated);
                                  const updatedTwo = produce(budget, (roughDraft) => {
                                    roughDraft.investments.push(newInvestment);
                                    roughDraft.bankAccount += updated.initialInvestment;
                                    roughDraft.accounts.investmentFund.addInvestment(updated.initialInvestment);
                                  });
                                  setInvestment(updated);
                                  setBudget(updatedTwo);
                                  setInvestment(initialInvestment);
                                  budget.updateBudget(
                                    {
                                      budgetId: budget._id,
                                      userId: user._id,
                                      lastUpdated: DateTime.now().toISO(),
                                      investments: updatedTwo.investments,
                                      bankAccount: updated.bankAccount,
                                    },
                                    'Investment-Manager'
                                  );
                                }}
                                disabled={user && user.permissions === 'Associate'}>
                                Add Investment
                              </PrimaryButton>
                            </FormSection>
                            <FormSection
                              modifier="transactional"
                              minHeight="5rem"
                              sectionHeight="100%"
                              sectionWidth="max-content"
                              flow="column-reverse"
                              justify="space-evenly"
                              align="center"
                              padding=".5rem">
                              <PrimaryButton
                                size="small"
                                minWidth="15rem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setInvesting(!investing);
                                  setInvestment(initialInvestment);
                                }}>
                                Close
                              </PrimaryButton>
                            </FormSection>
                          </FormSection>
                        </Form>
                      )}
                    </FormSection>
                  </StyledContentContainer>
                ),
                margin: '0 0 0 3rem',
                bgColor: theme.colors.grayScale.offWhite,
              }}
            />
          </FormSection>
        </FormSection>
        <FormSection modifier="transactional" sectionHeight="40rem" sectionWidth="100%" flow="column nowrap" justify="space-evenly" align="center" padding="0 0 3rem 0">
          <BudgetContainerHeader
            budgetPage={true}
            investmentHeader={true}
            textSize="5rem"
            height="5rem"
            justify="center"
            bgColor={'transparent'}
            textColor={theme.colors.primaryColor}
            className="budget-page-header">
            Settled Investments
          </BudgetContainerHeader>
          <FormSection modifier="transactional" sectionHeight="80%" sectionWidth="100%" flow="row nowrap" justify="flex-start" align="center" padding="0 0 0 3rem" overFlowX="auto">
            {budget &&
              budget.investments &&
              budget.investments
                .filter((investment) => investment.settled === true)
                .map((investment, index) => {
                  return (
                    <ExtraSmallContainer
                      theme={theme}
                      user={user}
                      budget={budget}
                      title={investment.investmentName}
                      icon={
                        investment.investmentType === 'Stock Market'
                          ? 'money-bill-trend-up'
                          : investment.investmentType === 'Real Estate'
                          ? 'tree-city'
                          : investment.investmentType === 'Timeshare'
                          ? 'clock'
                          : investment.investmentType === 'Other'
                          ? 'arrow-trend-up'
                          : ''
                      }
                      content={{
                        content: (
                          <StyledContentContainer containerType="transactional" height="20rem" minHeight="100%" minWidth="100%" flow="column nowrap" justify="space-evenly" align="center">
                            <FormSection
                              modifier="transactional"
                              minHeight="5rem"
                              sectionHeight="100%"
                              flow="column nowrap"
                              justify="space-evenly"
                              align="center"
                              padding=".5rem"
                              headerTextSize="2rem">
                              <p className="investment-description">{investment.investmentDescription}</p>
                            </FormSection>
                            <FormSection
                              modifier="transactional"
                              minHeight="5rem"
                              sectionHeight="100%"
                              flow="column nowrap"
                              justify="space-evenly"
                              align="center"
                              padding=".5rem"
                              ctaTextSize="1.2rem"
                              descriptionColor={
                                investment.valueDifference > 0
                                  ? theme.colors.secondaryColor
                                  : investment.valueDifference === 0
                                  ? theme.colors.primaryColor
                                  : investment.valueDifference < 0
                                  ? theme.colors.error
                                  : theme.colors.primaryColor
                              }>
                              <SmallBorderlessButton
                                size="extra-small"
                                className="view-graph"
                                investmentColor={
                                  investment.valueDifference > 0
                                    ? theme.colors.secondaryColor
                                    : investment.valueDifference === 0
                                    ? theme.colors.primaryColor
                                    : investment.valueDifference < 0
                                    ? theme.colors.error
                                    : theme.colors.primaryColor
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  const updated = produce(options, (roughDraft) => {
                                    roughDraft.plugins.title.text = investment.investmentName;
                                    roughDraft.plugins.title.color = theme.colors.primaryColor;
                                  });
                                  const updatedTwo = produce(data, (roughDraft) => {
                                    roughDraft.labels = investment.dates.map((date) => DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY));
                                    roughDraft.datasets[0].label = `Value in [ ${user && user.currencySymbol ? `${user.currencySymbol} |` : ''} ${user && user.currency} ]`;
                                    roughDraft.datasets[0].data = investment.values;
                                    roughDraft.datasets[0].borderColor =
                                      investment.valueDifference > 0
                                        ? theme.colors.secondaryColor
                                        : investment.valueDifference === 0
                                        ? theme.colors.primaryColor
                                        : investment.valueDifference < 0
                                        ? theme.colors.error
                                        : theme.colors.primaryColor;
                                    roughDraft.datasets[0].backgroundColor = chroma(
                                      investment.valueDifference > 0
                                        ? theme.colors.secondaryColor
                                        : investment.valueDifference === 0
                                        ? theme.colors.primaryColor
                                        : investment.valueDifference < 0
                                        ? theme.colors.error
                                        : theme.colors.primaryColor
                                    )
                                      .darken(0.4)
                                      .alpha(0.5)
                                      .hex();
                                  });
                                  setOptions(updated);
                                  setData(updatedTwo);
                                  setSeeChart(!seeChart);
                                }}>
                                <p className="view-graph-text">View Line Chart</p>
                              </SmallBorderlessButton>
                            </FormSection>
                            <FormSection
                              modifier="transactional"
                              minHeight="5rem"
                              sectionHeight="100%"
                              flow="row nowrap"
                              justify="space-evenly"
                              align="center"
                              padding=".5rem"
                              textSize="2.4rem"
                              textColor={
                                investment.valueDifference > 0
                                  ? theme.colors.secondaryColor
                                  : investment.valueDifference === 0
                                  ? theme.colors.primaryColor
                                  : investment.valueDifference < 0
                                  ? theme.colors.error
                                  : theme.colors.primaryColor
                              }
                              borderTop={`.2rem solid ${
                                investment.valueDifference > 0
                                  ? theme.colors.secondaryColor
                                  : investment.valueDifference === 0
                                  ? theme.colors.primaryColor
                                  : investment.valueDifference < 0
                                  ? theme.colors.error
                                  : theme.colors.primaryColor
                              }`}>
                              <p className="investment-result">
                                {investment.valueDifference > 0
                                  ? `Gained ${user && user.money.format(investment.valueDifference)}`
                                  : investment.valueDifference === 0
                                  ? `Broke Even`
                                  : investment.valueDifference < 0
                                  ? `Lost ${user && user.money.format(Math.abs(investment.valueDifference))}`
                                  : ''}
                              </p>
                            </FormSection>
                          </StyledContentContainer>
                        ),
                        investment: true,
                        investmentType: investment.investmentType,
                        investmentHeader: true,
                        investmentPadding: '0 2rem',
                        valueDifference: investment.valueDifference,
                        investmentContainerType: 'transactional',
                        textSize: `1.2rem`,
                        margin: '0 3rem 0',
                        bgColor: theme.colors.grayScale.offWhite,
                      }}
                      key={index}
                    />
                  );
                })}
          </FormSection>
        </FormSection>
      </StyledExtraLargeContainer>
    </BudgetPageSection>
  );
};
