import React from 'react';
import styled from 'styled-components';

const StyledLogo = styled.div`
  position: relative;
  height: max-content;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transform: translate(0, 0) rotate(0);
`;

const StyledLogoImage = styled.img`
  position: relative;
  height: auto;
  width: 20%;
  transform: translateX(-13.25%);
  margin-bottom: 2rem;

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    width: 40%;
  }

  @media ${(props) => props.theme.screens.mediumMobile.landscape} {
    width: 20%;
  }

  @media ${(props) => props.theme.screens.smallMobile.landscape} {
    margin-bottom: 1rem;
  }
`;

const StyledLogoText = styled.p`
  font-size: 5rem;
  color: ${(props) => props.theme.colors.primaryColor};

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    margin-bottom: 1rem;
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    font-size: 4rem;
  }

  @media ${(props) => props.theme.screens.smallMobile.landscape} {
    margin-bottom: 1rem;
    font-size: 3rem;
  }

  @media ${(props) => props.theme.screens.smallMobile.portrait} {
    font-size: 3.2rem;
  }
`;

function Logo(props) {
  const { theme } = props;
  return (
    <StyledLogo>
      <StyledLogoImage src={theme.logo.image} alt="King Richard Budget Logo" />
      <StyledLogoText>King Richard</StyledLogoText>
    </StyledLogo>
  );
}

export default Logo;
