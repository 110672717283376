import React from 'react';
import chroma from 'chroma-js';
import { produce, immerable } from 'immer';
import { DateTime } from 'luxon';
import { StyledContainer, StyledContentContainer } from './BasicContainer';
import { PrimaryButton } from '../../Button';
import { Row } from '../../Forms/Row';
import FormSection, { BudgetFormSection } from '../../Forms/Section';
import { BudgetContainerHeader, ReceiptHeader } from '../../Forms/Header';
import { SubCategoryGoalInput } from '../../Forms/Input';
import { SwitchLabel } from '../../Forms/Label';
import { TithedSwitch } from '../../Forms/Switch';
import { handleTransaction } from '../../Forms/Algorithms/InputChange';
import { AccountSelect } from '../../Forms/Select';
import { getCategoryIndex } from '../BudgetUtility';
import { toggleClasses } from '../../../Utility';
import { calculateTithing } from '../../Forms/Algorithms/Tithing';

export const SmallContainer = function (props) {
  const { theme, user, setUser, budget, setBudget, title, icon, content, transaction, setTransaction, receiptItem, setReceiptItem, errors, setErrors } = props;

  const hideReceiptItemDelete = (e) => {
    e.preventDefault();
    const deleteButton = e.target.closest('.receipt-item').firstChild.nextSibling.firstChild.nextSibling;
    toggleClasses(deleteButton, [`closed`, `open`]);
  };

  const renderReceiptItemDelete = (e) => {
    e.preventDefault();
    const deleteButton = e.target.closest('.receipt-item').firstChild.nextSibling.firstChild.nextSibling;
    toggleClasses(deleteButton, [`closed`, `open`]);
  };

  const getDueDates = (array) => {
    let dates = array.map((item) => {
      return item.timingOptions.dueDates;
    });
    let returnedDates = [];
    dates.forEach((dateArray) => {
      dateArray.forEach((date) => returnedDates.push(date));
    });
    return returnedDates;
  };

  return (
    <StyledContainer minHeight="45rem" minWidth="55.8rem" maxWidth="60rem" borderRadius=".5rem" bgColor={theme.colors.grayScale.offWhite} margin={content.margin}>
      <BudgetContainerHeader minHeight="5rem" justify="space-between" borderRadius=".25rem .25rem 0 0" iconSize="2.4rem" textSize="2rem">
        <i className={`fas fa-${icon}`} />
        <p>{title}</p>
        <PrimaryButton
          size="small"
          modifier="white"
          onClick={(e) => {
            let title = e.target.closest('header').firstChild.nextSibling.textContent;
            if (title === 'Enter Income') {
              transaction.submitDeposit(e, transaction, setTransaction, receiptItem, setReceiptItem, user, setUser, errors, setErrors, budget, setBudget);
            } else if (title === 'Enter Transaction') {
              console.log('Entering Transaction');
              transaction.submitTransaction(e, transaction, setTransaction, receiptItem, setReceiptItem, user, setUser, errors, setErrors, budget, setBudget);
            }
          }}
          disabled={user && user.permissions === 'Associate'}>
          Submit
        </PrimaryButton>
      </BudgetContainerHeader>
      {title === 'Enter Income' && (
        <StyledContentContainer height="40rem" minHeight="40rem" containerType="transactional" minWidth="100%" flow="column nowrap" justify="center" align="center">
          <Row minHeight="33%" minWidth="100%" justify="flex-start" align="center" borderBottom={`.2rem solid ${`${theme.colors.grayScale.raisinBlack}20`}`}>
            <BudgetFormSection minHeight="100%" width="33%" flow="column-reverse nowrap" justify="center" align="center">
              <SubCategoryGoalInput
                type="date"
                name="date"
                minHeight="4rem"
                maxHeight="4rem"
                width="15rem"
                onChange={(e) =>
                  handleTransaction(
                    e,
                    'Deposit',
                    { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                    { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                  )
                }
              />
              <SwitchLabel modifier="transaction" width="15rem">
                Paycheck Date
              </SwitchLabel>
            </BudgetFormSection>
            <BudgetFormSection minHeight="100%" width="33%" flow="column-reverse nowrap" justify="center" align="center">
              <SubCategoryGoalInput
                type="text"
                name="location"
                minHeight="4rem"
                maxHeight="4rem"
                width="15rem"
                placeholder="Location"
                modifier="transaction"
                onChange={(e) =>
                  handleTransaction(
                    e,
                    'Deposit',
                    { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                    { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                  )
                }
              />
              <SwitchLabel modifier="transaction" width="15rem">
                Paycheck From
              </SwitchLabel>
            </BudgetFormSection>
            <BudgetFormSection minHeight="100%" width="33%" flow="column-reverse nowrap" justify="center" align="center">
              {user && user.latterDaySaint && budget && budget.accounts && budget.accounts.tithing && budget.accounts.tithing.tithingSetting !== 'Surplus' && (
                <TithedSwitch
                  theme={theme}
                  user={user}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {user && user.latterDaySaint && budget && budget.accounts && budget.accounts.tithing && budget.accounts.tithing.tithingSetting !== 'Surplus' && <SwitchLabel>Tithed?</SwitchLabel>}
            </BudgetFormSection>
          </Row>
          <Row minHeight="33%" minWidth="100%" justify="flex-start" align="center" borderBottom={`.2rem solid ${`${theme.colors.grayScale.raisinBlack}20`}`}>
            <BudgetFormSection minHeight="100%" width="33%" flow="column-reverse nowrap" justify="center" align="center">
              <SubCategoryGoalInput
                type="number"
                minHeight="4rem"
                maxHeight="4rem"
                width="15rem"
                placeholder={`0.00`}
                value={receiptItem.grossIncome}
                modifier="transaction"
                name="gross-income"
                onChange={(e) =>
                  handleTransaction(
                    e,
                    'Deposit',
                    { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                    { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                  )
                }
              />
              <SwitchLabel modifier="transaction" width="15rem">
                Gross Income
              </SwitchLabel>
            </BudgetFormSection>
            <BudgetFormSection minHeight="100%" width="33%" flow="column-reverse nowrap" justify="center" align="center">
              <SubCategoryGoalInput
                type="number"
                minHeight="4rem"
                maxHeight="4rem"
                width="15rem"
                placeholder={`0.00`}
                value={receiptItem.netIncome}
                modifier="transaction"
                name="net-income"
                onChange={(e) =>
                  handleTransaction(
                    e,
                    'Deposit',
                    { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                    { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                  )
                }
              />
              <SwitchLabel modifier="transaction" width="15rem">
                Net Income
              </SwitchLabel>
            </BudgetFormSection>
          </Row>
          <Row minHeight="33%" minWidth="100%" justify="flex-start" align="center">
            <BudgetFormSection minHeight="100%" width={user.latterDaySaint ? `25%` : `33%`} flow="column nowrap" justify="center" align="center">
              <h4>Investment Account</h4>
              <p className="income-amount">{(user && receiptItem && receiptItem.invested && user.money.format(receiptItem.invested)) || (user && user.money.format(0))}</p>
            </BudgetFormSection>
            <BudgetFormSection
              minHeight="100%"
              width={user.latterDaySaint ? `25%` : `33%`}
              borderLeft={`.2rem solid ${`${theme.colors.grayScale.raisinBlack}20`}`}
              flow="column nowrap"
              justify="space-evenly"
              align="center">
              <h4>Savings Account</h4>
              <p className="income-amount">{(user && receiptItem && receiptItem.saved && user.money.format(receiptItem.saved)) || (user && user.money.format(0))}</p>
            </BudgetFormSection>
            {user && user.latterDaySaint && budget && budget.accounts && budget.accounts.tithing && budget.accounts.tithing.tithingSetting && budget.accounts.tithing.tithingSetting !== 'Surplus' && (
              <BudgetFormSection
                minHeight="100%"
                width={user.latterDaySaint ? `25%` : `33%`}
                borderLeft={`.2rem solid ${`${theme.colors.grayScale.raisinBlack}20`}`}
                flow="column nowrap"
                justify="center"
                align="center">
                <h4>Tithing</h4>
                <p className="income-amount">{(user && receiptItem && receiptItem.tithing && user.money.format(receiptItem.tithing)) || (user && user.money.format(0))}</p>
              </BudgetFormSection>
            )}
            <BudgetFormSection
              minHeight="100%"
              width={user.latterDaySaint ? `25%` : `33%`}
              bgColor={chroma(theme.colors.primaryColor).darken(0.2).hex()}
              flow="column nowrap"
              justify="center"
              align="center"
              modifier="white">
              <h4>Total Un-Allocated</h4>
              <p className="income-amount">{(user && receiptItem && receiptItem.unAllocated && user.money.format(receiptItem.unAllocated)) || (user && user.money.format(0))}</p>
            </BudgetFormSection>
          </Row>
        </StyledContentContainer>
      )}
      {title === 'Enter Transaction' && (
        <StyledContentContainer
          height="35rem"
          minHeight="35rem"
          containerType="transactional"
          minWidth="100%"
          flow="column nowrap"
          justify="flex-start"
          align="center"
          overflowX="hidden"
          overflowY="auto">
          <Row minHeight="25%" minWidth="100%" maxWidth="58.5rem" justify="space-evenly" align="center" borderBottom={`.2rem solid ${`${theme.colors.grayScale.raisinBlack}20`}`}>
            <BudgetFormSection minHeight="100%" width="25%" flow="column-reverse nowrap" justify="center" align="center">
              <SubCategoryGoalInput
                type="date"
                name="date"
                minHeight="4rem"
                maxHeight="4rem"
                width="13rem"
                onChange={(e) =>
                  handleTransaction(
                    e,
                    'Withdrawal',
                    { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                    { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                  )
                }
              />
              <SwitchLabel modifier="transaction" width="20rem">
                Transaction Date
              </SwitchLabel>
            </BudgetFormSection>
            <BudgetFormSection minHeight="100%" width="25%" flow="column-reverse nowrap" justify="center" align="center">
              <SubCategoryGoalInput
                type="text"
                name="location"
                minHeight="4rem"
                maxHeight="4rem"
                width="13rem"
                placeholder="Location"
                modifier="transaction"
                onChange={(e) =>
                  handleTransaction(
                    e,
                    'Withdrawal',
                    { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                    { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                  )
                }
              />
              <SwitchLabel modifier="transaction" width="20rem">
                Transaction Location
              </SwitchLabel>
            </BudgetFormSection>
            <BudgetFormSection minHeight="100%" width="25%" flow="column-reverse nowrap" justify="center" align="center">
              <PrimaryButton
                size="small"
                modifier="transaction"
                onClick={(e) =>
                  handleTransaction(
                    e,
                    'Withdrawal',
                    { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                    { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem },
                    'Add Item'
                  )
                }>
                Add Item
              </PrimaryButton>
            </BudgetFormSection>
          </Row>
          <Row minHeight="25%" minWidth="100%" maxWidth="58.5rem" justify="flex-start" align="center" borderBottom={`.2rem solid ${`${theme.colors.grayScale.raisinBlack}20`}`}>
            <BudgetFormSection minHeight="100%" width="25%" flow="column-reverse nowrap" justify="center" align="center">
              {receiptItem.active && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="account"
                  options={
                    user && user.latterDaySaint
                      ? [`Select Account`, `Monthly Budget`, `Emergency Fund`, `Savings Fund`, `Expense Fund`, `Surplus`, `Investment Fund`, `Debt`, `Tithing`]
                      : [`Select Account`, `Monthly Budget`, `Emergency Fund`, `Savings Fund`, `Expense Fund`, `Surplus`, `Investment Fund`, `Debt`]
                  }
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {receiptItem.active && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Account Selection
                </SwitchLabel>
              )}
            </BudgetFormSection>
            <BudgetFormSection minHeight="100%" width="25%" flow="column-reverse nowrap" justify="center" align="center">
              {budget && budget.mainCategories && receiptItem.account === 'Monthly Budget' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="main-category"
                  options={[{ title: 'Select Category' }, ...budget.mainCategories].map((mc) => mc.title)}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {budget && budget.mainCategories && receiptItem.account === 'Monthly Budget' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Main Categories
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.account === 'Emergency Fund' && (
                <SubCategoryGoalInput
                  type="text"
                  name="description"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder="Description"
                  modifier="transaction"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {receiptItem && receiptItem.account === 'Emergency Fund' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Description
                </SwitchLabel>
              )}
              {budget && budget.transactions && budget.transactions.plannedTransactions && receiptItem.account === 'Savings Fund' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="type"
                  options={['Select Category', ...[`Expense`, `Subscription`, `Vacations`, `Tuition`, `Other`]].map((timing) => timing)}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {budget && budget.transactions && budget.transactions.plannedTransactions && receiptItem.account === 'Savings Fund' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Transaction Type
                </SwitchLabel>
              )}
              {budget && budget.transactions && budget.transactions.plannedTransactions && receiptItem.account === 'Expense Fund' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="type"
                  options={['Select Category', ...[`Expense`, `Bill`, `Subscription`, `Other`]].map((timing) => timing)}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {budget && budget.transactions && budget.transactions.plannedTransactions && receiptItem.account === 'Expense Fund' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Transaction Type
                </SwitchLabel>
              )}
              {budget && budget.transactions && budget.transactions.plannedTransactions && receiptItem.account === 'Surplus' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="type"
                  options={['Select Category', ...[`Expense`, `Subscription`, `Discretionary`, `Food`, `Other`]].map((timing) => timing)}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {budget && budget.transactions && budget.transactions.plannedTransactions && receiptItem.account === 'Surplus' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Transaction Type
                </SwitchLabel>
              )}
              {budget && budget.transactions && budget.transactions.plannedTransactions && receiptItem.account === 'Investment Fund' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="type"
                  options={['Select Category', ...[`Stock Market`, `Real Estate`, `Timeshare`, `Other`]].map((timing) => timing)}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {budget && budget.transactions && budget.transactions.plannedTransactions && receiptItem.account === 'Investment Fund' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Transaction Type
                </SwitchLabel>
              )}
              {budget && budget.transactions && budget.transactions.plannedTransactions && receiptItem.account === 'Debt' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="type"
                  options={['Select Category', ...[`Credit Card`, `Loan`, `Taxes`, `Debt`, `Other`]].map((timing) => timing)}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {budget && budget.transactions && budget.transactions.plannedTransactions && receiptItem.account === 'Debt' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Transaction Type
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.account === 'Tithing' && (
                <SubCategoryGoalInput
                  type="text"
                  name="description"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder="Description"
                  modifier="transaction"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {receiptItem && receiptItem.account === 'Tithing' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Description
                </SwitchLabel>
              )}
            </BudgetFormSection>
            <BudgetFormSection minHeight="100%" width="25%" flow="column-reverse nowrap" justify="center" align="center">
              {budget && budget.mainCategories && receiptItem.mainCategory && receiptItem.account === 'Monthly Budget' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="sub-category"
                  options={[{ title: 'Select Category' }, ...budget.mainCategories[getCategoryIndex('main', budget, receiptItem.mainCategory)].subCategories].map((mc) => mc.title)}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {budget && budget.mainCategories && receiptItem.mainCategory && receiptItem.account === 'Monthly Budget' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Sub Category
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.account === 'Emergency Fund' && (
                <SubCategoryGoalInput
                  type="number"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder={`0.00`}
                  value={receiptItem.amount}
                  modifier="transaction"
                  name="amount"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {receiptItem && receiptItem.account === 'Emergency Fund' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Amount
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.transactionsOfType && receiptItem.account === 'Savings Fund' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="timing"
                  options={['Select Category', ...[`Once`, `Weekly`, `Bi-Weekly`, `Bi-Monthly`, `Monthly`, `Quarterly`, `Bi-Annual`, `Annual`]].map((timing) => timing)}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {receiptItem && receiptItem.transactionsOfType && receiptItem.account === 'Savings Fund' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Transaction Timing
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.transactionsOfType && receiptItem.account === 'Expense Fund' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="timing"
                  options={['Select Category', ...[`Once`, `Weekly`, `Bi-Weekly`, `Bi-Monthly`, `Monthly`, `Quarterly`, `Bi-Annual`, `Annual`]].map((timing) => timing)}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {receiptItem && receiptItem.transactionsOfType && receiptItem.account === 'Expense Fund' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Transaction Timing
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.transactionsOfType && receiptItem.account === 'Surplus' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="timing"
                  options={['Select Category', ...[`Once`, `Weekly`, `Bi-Weekly`, `Bi-Monthly`, `Monthly`, `Quarterly`, `Bi-Annual`, `Annual`]].map((timing) => timing)}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {receiptItem && receiptItem.transactionsOfType && receiptItem.account === 'Surplus' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Transaction Timing
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.subAccount && receiptItem.account === 'Investment Fund' && (
                <SubCategoryGoalInput
                  type="text"
                  name="name"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder="Investment Name"
                  modifier="transaction"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {receiptItem && receiptItem.subAccount && receiptItem.account === 'Investment Fund' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Name
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.transactionsOfType && receiptItem.account === 'Debt' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="timing"
                  options={['Select Category', ...[`Once`, `Weekly`, `Bi-Weekly`, `Bi-Monthly`, `Monthly`, `Quarterly`, `Bi-Annual`, `Annual`]].map((timing) => timing)}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {receiptItem && receiptItem.transactionsOfType && receiptItem.account === 'Debt' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Transaction Timing
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.account === 'Tithing' && (
                <SubCategoryGoalInput
                  type="number"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder={`0.00`}
                  value={receiptItem.amount}
                  modifier="transaction"
                  name="amount"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {receiptItem && receiptItem.account === 'Tithing' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Amount
                </SwitchLabel>
              )}
            </BudgetFormSection>

            <BudgetFormSection minHeight="100%" width="25%" flow="column-reverse nowrap" justify="center" align="center">
              {receiptItem && receiptItem.account === 'Emergency Fund' && (
                <PrimaryButton size="small" modifier="transaction" onClick={(e) => transaction.addToReceipt(e, transaction, setTransaction, receiptItem, setReceiptItem)}>
                  Save Item
                </PrimaryButton>
              )}
              {receiptItem && receiptItem.account === 'Tithing' && (
                <PrimaryButton size="small" modifier="transaction" onClick={(e) => transaction.addToReceipt(e, transaction, setTransaction, receiptItem, setReceiptItem)}>
                  Save Item
                </PrimaryButton>
              )}
              {receiptItem && receiptItem.timing && receiptItem.account === 'Savings Fund' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="dueDate"
                  options={['Select Due Date', ...new Set(getDueDates(receiptItem.transactionsOfType))].map((timing, i) => {
                    if (i === 0) return timing;
                    return DateTime.fromISO(timing).toLocaleString(DateTime.DATE_MED);
                  })}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {receiptItem && receiptItem.timing && receiptItem.account === 'Savings Fund' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Due Dates
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.timing && receiptItem.account === 'Expense Fund' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="dueDate"
                  options={['Select Due Date', ...new Set(getDueDates(receiptItem.transactionsOfType))].map((timing, i) => {
                    if (i === 0) return timing;
                    return DateTime.fromISO(timing).toLocaleString(DateTime.DATE_MED);
                  })}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {receiptItem && receiptItem.timing && receiptItem.account === 'Expense Fund' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Due Dates
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.timing && receiptItem.account === 'Surplus' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="dueDate"
                  options={['Select Due Date', ...new Set(getDueDates(receiptItem.transactionsOfType))].map((timing, i) => {
                    if (i === 0) return timing;
                    return DateTime.fromISO(timing).toLocaleString(DateTime.DATE_MED);
                  })}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {receiptItem && receiptItem.timing && receiptItem.account === 'Surplus' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Due Dates
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.subAccount && receiptItem.account === 'Investment Fund' && (
                <SubCategoryGoalInput
                  type="text"
                  name="description"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder="Description"
                  modifier="transaction"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {receiptItem && receiptItem.subAccount && receiptItem.account === 'Investment Fund' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Description
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.timing && receiptItem.account === 'Debt' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="dueDate"
                  options={['Select Due Date', ...new Set(getDueDates(receiptItem.transactionsOfType))].map((timing, i) => {
                    if (i === 0) return timing;
                    return DateTime.fromISO(timing).toLocaleString(DateTime.DATE_MED);
                  })}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {receiptItem && receiptItem.timing && receiptItem.account === 'Debt' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Due Dates
                </SwitchLabel>
              )}
            </BudgetFormSection>
          </Row>
          <Row minHeight="25%" minWidth="100%" maxWidth="58.5rem" justify="flex-start" align="center" borderBottom={`.2rem solid ${`${theme.colors.grayScale.raisinBlack}20`}`}>
            <BudgetFormSection minHeight="100%" width="25%" flow="column-reverse nowrap" justify="center" align="center">
              {budget && budget.mainCategories && receiptItem.subCategory && receiptItem.account === 'Monthly Budget' && (
                <SubCategoryGoalInput
                  type="text"
                  name="description"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder="Description"
                  modifier="transaction"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {budget && budget.mainCategories && receiptItem.subCategory && receiptItem.account === 'Monthly Budget' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Description
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.filteredTransactions && receiptItem.account === 'Savings Fund' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="item"
                  options={['Select Item', ...receiptItem.filteredTransactions]}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {receiptItem && receiptItem.filteredTransactions && receiptItem.account === 'Savings Fund' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Item | Goal | Expense
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.filteredTransactions && receiptItem.account === 'Expense Fund' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="item"
                  options={['Select Item', ...receiptItem.filteredTransactions]}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {receiptItem && receiptItem.filteredTransactions && receiptItem.account === 'Expense Fund' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Item | Goal | Expense
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.filteredTransactions && receiptItem.account === 'Surplus' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="item"
                  options={['Select Item', ...receiptItem.filteredTransactions]}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {receiptItem && receiptItem.filteredTransactions && receiptItem.account === 'Surplus' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Item | Goal | Expense
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.subAccount && receiptItem.account === 'Investment Fund' && (
                <SubCategoryGoalInput
                  type="number"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder={`0.00`}
                  value={receiptItem.amount}
                  modifier="transaction"
                  name="amount"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {receiptItem && receiptItem.subAccount && receiptItem.account === 'Investment Fund' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Amount
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.filteredTransactions && receiptItem.account === 'Debt' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="lender"
                  options={['Select Lender', ...new Set(receiptItem.filteredTransactions)].map((lender, i) => {
                    return lender;
                  })}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {receiptItem && receiptItem.filteredTransactions && receiptItem.account === 'Debt' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Lender
                </SwitchLabel>
              )}
            </BudgetFormSection>
            <BudgetFormSection minHeight="100%" width="25%" flow="column-reverse nowrap" justify="center" align="center">
              {budget && budget.mainCategories && receiptItem.subCategory && receiptItem.account === 'Monthly Budget' && (
                <SubCategoryGoalInput
                  type="number"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder={`0.00`}
                  value={receiptItem.amount}
                  modifier="transaction"
                  name="amount"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {budget && budget.mainCategories && receiptItem.subCategory && receiptItem.account === 'Monthly Budget' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Amount
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Savings Fund' && (
                <SubCategoryGoalInput
                  type="text"
                  name="description"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder="Description"
                  modifier="transaction"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Savings Fund' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Description
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Expense Fund' && (
                <SubCategoryGoalInput
                  type="text"
                  name="description"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder="Description"
                  modifier="transaction"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Expense Fund' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Description
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Surplus' && (
                <SubCategoryGoalInput
                  type="text"
                  name="description"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder="Description"
                  modifier="transaction"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Surplus' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Description
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.subAccount && receiptItem.account === 'Investment Fund' && (
                <PrimaryButton size="small" modifier="transaction" onClick={(e) => transaction.addToReceipt(e, transaction, setTransaction, receiptItem, setReceiptItem)}>
                  Save Item
                </PrimaryButton>
              )}
              {receiptItem && receiptItem.filteredTransactions && receiptItem.account === 'Debt' && (
                <AccountSelect
                  theme={theme}
                  user={user}
                  name="item"
                  options={receiptItem && receiptItem.furtherFilteredDebts && ['Select Item', ...receiptItem.furtherFilteredDebts]}
                  content={{ width: '13rem' }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                />
              )}
              {receiptItem && receiptItem.filteredTransactions && receiptItem.account === 'Debt' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Item | Goal | Expense
                </SwitchLabel>
              )}
            </BudgetFormSection>
            <BudgetFormSection minHeight="100%" width="25%" flow="column-reverse nowrap" justify="center" align="center">
              {budget && budget.mainCategories && receiptItem.subCategory && receiptItem.account === 'Monthly Budget' && (
                <PrimaryButton size="small" modifier="transaction" onClick={(e) => transaction.addToReceipt(e, transaction, setTransaction, receiptItem, setReceiptItem)}>
                  Save Item
                </PrimaryButton>
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Savings Fund' && (
                <SubCategoryGoalInput
                  type="number"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder={`0.00`}
                  value={receiptItem.amount}
                  modifier="transaction"
                  name="amount"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Savings Fund' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Amount
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Expense Fund' && (
                <SubCategoryGoalInput
                  type="number"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder={`0.00`}
                  value={receiptItem.amount}
                  modifier="transaction"
                  name="amount"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Expense Fund' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Amount
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Surplus' && (
                <SubCategoryGoalInput
                  type="number"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder={`0.00`}
                  value={receiptItem.amount}
                  modifier="transaction"
                  name="amount"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Surplus' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Amount
                </SwitchLabel>
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Debt' && (
                <SubCategoryGoalInput
                  type="text"
                  name="description"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder="Description"
                  modifier="transaction"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Debt' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Description
                </SwitchLabel>
              )}
            </BudgetFormSection>
            <BudgetFormSection minHeight="100%" width="25%" flow="column-reverse nowrap" justify="center" align="center">
              {receiptItem && receiptItem.item && receiptItem.account === 'Savings Fund' && (
                <PrimaryButton size="small" modifier="transaction" onClick={(e) => transaction.addToReceipt(e, transaction, setTransaction, receiptItem, setReceiptItem)}>
                  Save Item
                </PrimaryButton>
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Expense Fund' && (
                <PrimaryButton size="small" modifier="transaction" onClick={(e) => transaction.addToReceipt(e, transaction, setTransaction, receiptItem, setReceiptItem)}>
                  Save Item
                </PrimaryButton>
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Surplus' && (
                <PrimaryButton size="small" modifier="transaction" onClick={(e) => transaction.addToReceipt(e, transaction, setTransaction, receiptItem, setReceiptItem)}>
                  Save Item
                </PrimaryButton>
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Debt' && (
                <SubCategoryGoalInput
                  type="number"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder={`0.00`}
                  value={receiptItem.amount}
                  modifier="transaction"
                  name="amount"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Withdrawal',
                      { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
                      { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem }
                    )
                  }
                />
              )}
              {receiptItem && receiptItem.item && receiptItem.account === 'Debt' && (
                <SwitchLabel modifier="transaction" width="15rem">
                  Amount
                </SwitchLabel>
              )}
            </BudgetFormSection>
          </Row>
          <Row minHeight="25%" minWidth="100%" maxWidth="58.5rem" justify="flex-start" align="center" borderBottom={`.2rem solid ${`${theme.colors.grayScale.raisinBlack}20`}`}>
            <BudgetFormSection minHeight="100%" width="25%" flow="column-reverse nowrap" justify="center" align="center">
              {receiptItem && receiptItem.item && receiptItem.account === 'Debt' && (
                <PrimaryButton size="small" modifier="transaction" onClick={(e) => transaction.addToReceipt(e, transaction, setTransaction, receiptItem, setReceiptItem)}>
                  Save Item
                </PrimaryButton>
              )}
            </BudgetFormSection>
          </Row>
          <Row minHeight="max-content" minWidth="100%" maxWidth="58.5rem" justify="flex-start" align="center" borderBottom={`.2rem solid ${`${theme.colors.grayScale.raisinBlack}20`}`}>
            <BudgetFormSection width="100%" flow="column nowrap" justify="flex-start" align="center" borderBottom={`.3rem solid ${chroma(theme.colors.primaryColor).darken(0.4).hex()}`}>
              <ReceiptHeader
                headerWidth="25%"
                minWidth="20%"
                justify="center"
                borderBottom={`.3rem solid ${theme.colors.primaryColor}`}
                textSize="3rem"
                textVariant="small-caps"
                textWeight="bold"
                margin="0 0 2rem">
                receipt
              </ReceiptHeader>
              <ReceiptHeader headerWidth="90%" minWidth="20%" justify="space-between" borderBottom={`.2rem solid ${theme.colors.primaryColor}`} textSize="1.6rem">
                <p className="receipt-sub-header">Transaction Details</p>
                <p className="receipt-sub-header">Item Total</p>
              </ReceiptHeader>
              <BudgetFormSection width="100%" flow="column nowrap" justify="flex-start" align="center">
                {transaction &&
                  transaction.transactionType === 'Withdrawal' &&
                  transaction.receipt &&
                  transaction.receipt.length > 0 &&
                  transaction.receipt.map((item, index) => {
                    return (
                      <FormSection
                        minHeight="5rem"
                        width="100%"
                        flow="row nowrap"
                        justify="space-between"
                        align="center"
                        padding="0 3rem"
                        receiptSection={true}
                        key={index}
                        data-item={index}
                        className="receipt-item"
                        onMouseOver={(e) => {
                          renderReceiptItemDelete(e);
                        }}
                        onMouseOut={(e) => {
                          hideReceiptItemDelete(e);
                        }}>
                        <FormSection minHeight="100%" width="70%" flow="row nowrap" justify="flex-start" padding="0" textSize="1.6rem" textMargin="0 1rem" receiptSection={true}>
                          {item.account === 'Monthly Budget' && <p className="receipt-text">{item.mainCategory}</p>}
                          {item.account === 'Monthly Budget' && <p className="receipt-text">{item.subCategory}</p>}
                          {item.account === 'Tithing' && <p className="receipt-text">{item.account}</p>}
                          {(item.account === 'Debt' || item.account === 'Investment Fund') && <p className="receipt-text">{item.type}</p>}
                          {item.account === 'Investment Fund' && <p className="receipt-text">{item.investment}</p>}
                          {item.account === 'Debt' && <p className="receipt-text">{item.lender}</p>}
                          {(item.account === 'Debt' || item.account === 'Savings Fund' || item.account === 'Expense Fund' || item.account === 'Surplus') && (
                            <p className="receipt-text">{item.expense}</p>
                          )}
                          {(item.account === 'Monthly Budget' || item.account === 'Emergency Fund' || item.account === 'Tithing' || item.account === 'Investment Fund') && (
                            <p className="receipt-text">{item.description}</p>
                          )}
                        </FormSection>
                        <FormSection minHeight="100%" width="30%" flow="row nowrap" justify="flex-end" padding="0" textSize="1.6rem" textMargin="0 1rem" receiptSection={true}>
                          {(item.account === 'Monthly Budget' ||
                            item.account === 'Emergency Fund' ||
                            item.account === 'Savings Fund' ||
                            item.account === 'Expense Fund' ||
                            item.account === 'Surplus' ||
                            item.account === 'Investment Fund' ||
                            item.account === 'Debt' ||
                            item.account === 'Tithing') && <p className="receipt-text">{user.money.format(item.amount)}</p>}
                          <i className="fas fa-trash-alt delete-receipt-item closed" onClick={(e) => transaction.removeFromReceipt(e, index, transaction, setTransaction)} />
                        </FormSection>
                      </FormSection>
                    );
                  })}
              </BudgetFormSection>
            </BudgetFormSection>
          </Row>
        </StyledContentContainer>
      )}
      {title === 'Enter Transaction' && (
        <BudgetFormSection sectionType="receipt total" minHeight="6rem" flow="row nowrap" justify="space-evenly" align="center">
          <p className="receipt-total">Total:</p>
          <p className="receipt-amount">
            {(transaction &&
              transaction.type !== 'Deposit' &&
              transaction.receipt &&
              transaction.receipt.length > 0 &&
              user &&
              user.money.format(
                transaction.receipt.reduce((total, current) => {
                  return (total += current.amount);
                }, 0)
              )) ||
              (user && user.money.format(0))}
          </p>
        </BudgetFormSection>
      )}
    </StyledContainer>
  );
};
