import { produce, immerable } from 'immer';
import { DateTime } from 'luxon';

export class ReceiptItem {
  [immerable] = true;
  constructor() {
    this.active = false;
    this.account = '';
    this.grossIncome = 0;
    this.netIncome = 0;
    this.investment = '';
    this.amount = 0;
  }

  isTithedEssential(type, user, budget) {
    return type === 'Deposit' && user.latterDaySaint && budget.accounts.tithing && budget.accounts.tithing.tithingSetting !== 'Surplus';
  }
}
