import React, { useState, useEffect } from 'react';
import { StyledContainer, StyledContentContainer } from './BasicContainer';
import { MainCategorySelectContainer, IconsContainer, SubCategoryCreationContainer, MainCategoryDisplay, SubCategoryDisplay } from '../../../Logged-In/BudgetCreationForm';
import { PrimaryButton, SmallBorderlessButton, Button } from '../../Button';
import { MainCategory, SubCategory } from '../Categories';
import { processMainCategory, processSubCategory, toggleSubCategoryCreation } from '../BudgetUtility';
import { icons } from '../../../../Budget-Creation/Category-Icons';
import FormSection from '../../Forms/Section';
import { CategoryTitleCreationInput } from '../../Forms/Input';
import { toggleClasses } from '../../../Utility';

export const MediumContainer = function (props) {
  const { theme, user, setUser, budget, setBudget, categoryIndex, setCategoryIndex, iconObject, setIconObject, changeMainCategory } = props;
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    let iconObj = {};
    const iconContainers = document.querySelectorAll('.icon-container');
    iconContainers.forEach((container, i) => (iconObj[container.firstChild.nextSibling.textContent] = container));
    setIconObject(iconObj);
  }, []);

  return (
    <StyledContainer
      minHeight="60rem"
      minWidth="110rem"
      maxWidth="110rem"
      borderRadius="1rem"
      inBudget={true}
      flow="row nowrap"
      justify="space-evenly"
      align="center"
      bgColor={theme.colors.grayScale.offWhite}>
      <StyledContentContainer containerType="transactional" height="60rem" minHeight="100%" minWidth="50%" borderRight={`.2rem solid ${theme.colors.primaryColor}`}>
        <MainCategorySelectContainer className="main-category-select" inBudget={true} height="100%" width="100%" minHeight="60rem" maxHeight="60rem" border="none" borderRadius=".55rem">
          <PrimaryButton
            className={`main-category-start-create ${creating ? 'closed' : 'open'}`}
            size="medium square"
            modifier="square"
            borderTopLeftRadius=".9rem"
            hoverColor={theme.colors.grayScale.offWhite}
            onClick={() => setCreating(!creating)}
            data-cy="createMainCategoryButton"
            disabled={user && user.permissions === 'Associate'}>
            <i className="fas fa-plus main-category-create-icon" />
            <p className="main-category-create-text">Create</p>
          </PrimaryButton>
          {budget.mainCategories &&
            budget.mainCategories.map((category, index) => (
              <MainCategory
                key={index}
                className={`main-category ${categoryIndex === index ? 'selected' : ''} ${creating ? 'closed' : 'open'}`}
                height="25%"
                width="25%"
                border={`.2rem solid ${theme.colors.primaryColor}cc`}
                borderRadius=".9rem"
                flow="column-reverse nowrap"
                justify="space-evenly"
                align="center"
                data-cy="mainCategory"
                onClick={() => setCategoryIndex(index)}>
                <p className="main-category__title">{category.title}</p>
                <i className={`fas ${category.icon} main-category__icon`} />
              </MainCategory>
            ))}
          <IconsContainer className={`icons-container ${creating ? 'open' : 'closed'}`} inBudget={true}>
            {icons &&
              icons.map((iconImage, index) => (
                <section className="icon-container" key={index} onClick={(e) => budget.selectIcon(e, iconObject, setIconObject)}>
                  <i className={`fas fa-${iconImage} icon-container__icon`} />
                  <p className="icon-container__text" data-cy={icons[index].split('-').join(' ')}>
                    {icons[index].split('-').join(' ')}
                  </p>
                </section>
              ))}
          </IconsContainer>
          <FormSection
            className={`main-category-title-creation ${creating ? 'open' : 'closed'}`}
            minHeight="20%"
            flow="row nowrap"
            justify="space-evenly"
            align="center"
            borderTop={`.3rem solid ${theme.colors.primaryColor}`}>
            <CategoryTitleCreationInput type="text" className="main-category-title-input" data-cy="mainCategoryTitleInput" />
            <PrimaryButton size="small" onClick={(e) => processMainCategory(e, 'create', budget, setBudget, iconObject, creating, setCreating)} data-cy="mainCategoryTitleCreateButton">
              Create
            </PrimaryButton>
            <PrimaryButton size="small" onClick={(e) => setCreating(!creating)} data-cy="mainCategoryTitleCloseButton">
              Close
            </PrimaryButton>
          </FormSection>
        </MainCategorySelectContainer>
      </StyledContentContainer>
      <StyledContentContainer containerType="transactional" height="60rem" minHeight="100%" minWidth="50%">
        <SubCategoryCreationContainer className="sub-category-creation-container" inBudget={true} height="100%" width="100%" minHeight="60rem" maxHeight="60rem" border="none" borderRadius=".55rem">
          <MainCategoryDisplay className="main-category-display" height="55%" width="100%" iconFontSize="12rem" fontSize="3rem" color={theme.colors.primaryColor}>
            <Button
              size="extra-small"
              iconFontSize="6rem"
              color={theme.colors.primaryColor}
              hoverColor={theme.colors.primaryColor}
              activeColor={theme.colors.secondaryColor}
              onClick={(e) => changeMainCategory(e, 'decrease')}
              data-cy="changeMainCategoryLeft">
              <i
                className="far fa-circle-left"
                onMouseOver={(e) => toggleClasses(e.target.closest('button').firstChild, [`far`, `fas`])}
                onMouseOut={(e) => toggleClasses(e.target.closest('button').firstChild, [`far`, `fas`])}
              />
            </Button>
            {budget && budget.mainCategories && budget.mainCategories.length > 0 && (
              <div className="category-container">
                <i className={`fas ${budget.mainCategories[categoryIndex].icon}`} />
                <p>{budget.mainCategories[categoryIndex].title}</p>
              </div>
            )}
            <Button
              size="extra-small"
              iconFontSize="6rem"
              color={theme.colors.primaryColor}
              hoverColor={theme.colors.primaryColor}
              activeColor={theme.colors.secondaryColor}
              onClick={(e) => changeMainCategory(e, 'increase')}
              data-cy="changeMainCategoryRight">
              <i
                className="far fa-circle-right"
                onMouseOver={(e) => toggleClasses(e.target.closest('button').firstChild, [`far`, `fas`])}
                onMouseOut={(e) => toggleClasses(e.target.closest('button').firstChild, [`far`, `fas`])}
              />
            </Button>
            <PrimaryButton
              type="button"
              size="medium square"
              modifier="medium square"
              position="absolute"
              top="0"
              right="0"
              textSize="2rem"
              borderRadius=".9rem"
              onClick={() => budget.removeMainCategory(budget.mainCategories[categoryIndex].title, budget, setBudget, categoryIndex, setCategoryIndex)}
              disabled={user && user.permissions === 'Associate'}>
              <i className="fas fa-trash-alt delete-main-category" />
              <p className="delete-text">Delete</p>
            </PrimaryButton>
          </MainCategoryDisplay>
          <SubCategoryDisplay className="sub-category-display" height="45%" width="100%">
            {budget &&
              budget.mainCategories &&
              budget.mainCategories[categoryIndex] &&
              budget.mainCategories[categoryIndex].subCategories.map((subCategory, index) => {
                return (
                  <SubCategory
                    theme={theme}
                    user={user}
                    budget={budget}
                    setBudget={setBudget}
                    subCategory={subCategory}
                    processSubCategory={processSubCategory}
                    mainCategoryIndex={categoryIndex}
                    key={index}
                    content={{ inBudget: true, bgColor: `${theme.colors.primaryColor}`, textSize: `1.6rem`, textColor: theme.colors.grayScale.offWhite }}
                  />
                );
              })}
            <FormSection
              className="sub-category-title-creation closed"
              minHeight="30%"
              flow="row nowrap"
              justify="space-evenly"
              align="center"
              borderBottom={`.3rem solid ${theme.colors.primaryColor}`}>
              <CategoryTitleCreationInput className="sub-category-title-input" data-cy="subCategoryTitleInput" />
              <PrimaryButton size="small" onClick={(e) => processSubCategory(e, 'create', categoryIndex, budget, setBudget)} data-cy="subCategoryCreationButton">
                Create
              </PrimaryButton>
              <PrimaryButton size="small" onClick={(e) => toggleSubCategoryCreation(e)} data-cy="subCategoryCreationClose">
                Close
              </PrimaryButton>
            </FormSection>
            <SmallBorderlessButton
              margin="1rem 0 0 0"
              className="sub-category-start-creation"
              onClick={(e) => toggleSubCategoryCreation(e)}
              data-cy="startSubCategoryCreation"
              disabled={user && user.permissions === 'Associate'}>
              + Add Sub Category
            </SmallBorderlessButton>
          </SubCategoryDisplay>
        </SubCategoryCreationContainer>
      </StyledContentContainer>
    </StyledContainer>
  );
};
