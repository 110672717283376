import React from 'react';
import styled from 'styled-components';
import chroma from 'chroma-js';

const FormInput = styled.input`
  /*
    height, min-height, width, min-width, font-size, padding, and color need to be set by a different component.
  */
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: ${(props) => props.margin};
  transform: rotate(0) translate(0, 0);
  border: 0.2rem solid transparent;
  background-color: transparent;
  font-family: ${(props) => props.theme.text.secondary};
  color: transparent;
  transition: background-color 0.5s, border 0.5s, color 0.5s;

  // Set opacity of placeholder to zero immediately.
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &::placeholder {
    opacity: 0;
  }
  &::placeholder {
    opacity: 0;
  }
  &:focus {
    &::-webkit-input-placeholder {
      opacity: 1;
    }
  }
  &:focus {
    outline: none;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &::placeholder {
      opacity: 1;
    }
    &:valid {
      border: 0.2rem solid ${(props) => props.theme.colors.secondaryColor};
    }
    &:invalid {
      border: 0.2rem solid ${(props) => props.theme.colors.error};
    }
  }

  &:not(:placeholder-shown) + label,
  &:focus + label {
    transform: ${(props) => props.focusTransform};

    @media ${(props) => props.theme.screens.smallTablet.landscape} {
      ${(props) => (props.inputType === 'profile-photo' ? `transform: translate(-27.5rem,-4.25rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'personal-info' ? `transform: translate(-6.5rem,-7rem) scale(0.75)` : null)};
    }

    @media ${(props) => props.theme.screens.smallTablet.portrait} {
      transform: translate(-3.5rem, -7rem) scale(0.75);
      ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-4.5rem, -7rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'profile-photo' ? `transform: translate(-27.5rem,-4.25rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'personal-info' ? `transform: translate(-6.5rem,-7rem) scale(0.75)` : null)};
    }

    @media ${(props) => props.theme.screens.largeMobile.landscape} {
      transform: translate(-3.5rem, -7rem) scale(0.75);
      ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-4.5rem, -7rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'profile-photo' ? `transform: translate(-26.5rem,-4.25rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'personal-info' ? `transform: translate(-1.5rem,-6rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'personal-info--sub' ? `transform: translate(-2.5rem,-5.5rem) scale(0.75)` : null)};
    }
    @media ${(props) => props.theme.screens.largeMobile.portrait} {
      ${(props) => (props.inputType === 'profile-photo' ? `transform: translate(-12.5rem,-4.25rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'personal-info' ? `transform: translate(-1.5rem,-7rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'personal-info--sub' ? `transform: translate(-3rem,-6.5rem) scale(0.75)` : null)};
    }

    @media ${(props) => props.theme.screens.mediumMobile.landscape} {
      transform: translate(-3.5rem, -6.5rem) scale(0.75);
      ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-4.5rem, -7rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'profile-photo' ? `transform: translate(-21.5rem,-4.25rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'personal-info' ? `transform: translate(-2rem,-5.8rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'personal-info--sub' ? `transform: translate(-2rem,-5.6rem) scale(0.75)` : null)};
    }

    @media ${(props) => props.theme.screens.mediumMobile.portrait} {
      transform: translate(-3.5rem, -6.5rem) scale(0.75);
      ${(props) => (props.inputType === 'signup' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)};
      ${(props) => (props.transformType === 'signup--username' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'profile-photo' ? `transform: translate(-11.5rem,-4rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'personal-info' ? `transform: translate(-1.5rem,-7rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'personal-info--sub' ? `transform: translate(-2rem,-6rem) scale(0.75)` : null)};
    }

    @media ${(props) => props.theme.screens.smallMobile.landscape} {
      transform: translate(-3.5rem, -5.7rem) scale(0.75);
      ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-4.5rem, -7rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'profile-photo' ? `transform: translate(-21.5rem,-4.25rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'personal-info' ? `transform: translate(-2rem,-5.8rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'personal-info--sub' ? `transform: translate(-2rem,-5.6rem) scale(0.75)` : null)};
    }

    @media ${(props) => props.theme.screens.smallMobile.portrait} {
      ${(props) => (props.inputType === 'login' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'signup' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'forgot-password' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'reset-password' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)};
      ${(props) => (props.transformType === 'signup--username' ? `transform: translate(-3.5rem, -6.5rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'profile-photo' ? `transform: translate(-9.5rem,-4rem) scale(0.75)` : null)};
      ${(props) => (props.inputType === 'personal-info' ? `transform: translate(-1.5rem,-7rem) scale(0.75)` : null)};
    }

    ${(props) => (props.modifier === 'white' ? `color: ${props.theme.colors.grayScale.offWhite}` : null)}
  }

  ${(props) => (props.modifier === 'transaction' ? '&::placeholder {opacity: 1}' : null)};
  ${(props) => (props.modifier === 'purple-transform' ? `&:not(:placeholder-shown) + label {color: ${props.theme.colors.primaryColor}}` : null)}
  ${(props) => (props.modifier === 'purple-transform' ? `&:focus + label {color: ${props.theme.colors.primaryColor}}` : null)}
`;

export const LandingFormInput = styled(FormInput)`
  height: 6rem;
  min-height: 3rem;
  width: ${(props) => props.inputWidth};
  min-width: 20%;
  max-width: ${(props) => props.maxWidth};
  background-color: ${(props) => `${props.theme.colors.grayScale.offWhite}40`};
  border-color: ${(props) => `${props.theme.colors.primaryColor}cc`};
  border-radius: 0.75rem;
  padding: 1rem;
  font-size: 2rem;
  color: ${(props) => `${props.theme.colors.primaryColor}cc`};

  &:focus {
    border-color: ${(props) => `${props.theme.colors.primaryColor}`};
    color: ${(props) => `${props.theme.colors.primaryColor}`};
  }

  @media ${(props) => props.theme.screens.largeTablet.landscape} {
    ${(props) => (props.inputType === 'login' ? `width: 30%` : null)};
    ${(props) => (props.inputType === 'login' ? `min-width: 30%` : null)};
    ${(props) => (props.inputType === 'signup' ? `width: 30%` : null)};
    ${(props) => (props.inputType === 'signup' ? `min-width: 30%` : null)};
    ${(props) => (props.inputType === 'forgot-password' ? `width: 30%` : null)};
    ${(props) => (props.inputType === 'forgot-password' ? `min-width: 30%` : null)};
    ${(props) => (props.inputType === 'two-factor' ? `width: 30%` : null)};
    ${(props) => (props.inputType === 'two-factor' ? `min-width: 30%` : null)};
    ${(props) => (props.inputType === 'reset-password' ? `width: 30%` : null)};
    ${(props) => (props.inputType === 'reset-password' ? `min-width: 30%` : null)};
  }

  @media ${(props) => props.theme.screens.largeTablet.portrait} {
    ${(props) => (props.inputType === 'login' ? `width: 31.25%` : null)};
    ${(props) => (props.inputType === 'login' ? `min-width: 31.25%` : null)};
    ${(props) => (props.inputType === 'signup' ? `width: 31.25%` : null)};
    ${(props) => (props.inputType === 'signup' ? `min-width: 31.25%` : null)};
    ${(props) => (props.inputType === 'forgot-password' ? `width: 31.25%` : null)};
    ${(props) => (props.inputType === 'forgot-password' ? `min-width: 31.25%` : null)};
    ${(props) => (props.inputType === 'two-factor' ? `width: 31.25%` : null)};
    ${(props) => (props.inputType === 'two-factor' ? `min-width: 31.25%` : null)};
    ${(props) => (props.inputType === 'reset-password' ? `width: 31.25%` : null)};
    ${(props) => (props.inputType === 'reset-password' ? `min-width: 31.25%` : null)};
  }

  @media ${(props) => props.theme.screens.smallTablet.landscape} {
    ${(props) => (props.inputType === 'login' ? `width: 35%` : null)};
    ${(props) => (props.inputType === 'login' ? `min-width: 35%` : null)};
    ${(props) => (props.inputType === 'signup' ? `width: 35%` : null)};
    ${(props) => (props.inputType === 'signup' ? `min-width: 35%` : null)};
    ${(props) => (props.inputType === 'forgot-password' ? `width: 35%` : null)};
    ${(props) => (props.inputType === 'forgot-password' ? `min-width: 35%` : null)};
    ${(props) => (props.inputType === 'two-factor' ? `width: 35%` : null)};
    ${(props) => (props.inputType === 'two-factor' ? `min-width: 35%` : null)};
    ${(props) => (props.inputType === 'reset-password' ? `width: 35%` : null)};
    ${(props) => (props.inputType === 'reset-password' ? `min-width: 35%` : null)};
  }

  @media ${(props) => props.theme.screens.smallTablet.portrait} {
    ${(props) => (props.inputType === 'login' ? `width: 37.5%` : null)};
    ${(props) => (props.inputType === 'login' ? `min-width: 37.5%` : null)};
    ${(props) => (props.inputType === 'signup' ? `width: 37.5%` : null)};
    ${(props) => (props.inputType === 'signup' ? `min-width: 37.5%` : null)};
    ${(props) => (props.inputType === 'forgot-password' ? `width: 37.5%` : null)};
    ${(props) => (props.inputType === 'forgot-password' ? `min-width: 37.5%` : null)};
    ${(props) => (props.inputType === 'two-factor' ? `width: 40%` : null)};
    ${(props) => (props.inputType === 'two-factor' ? `min-width: 40%` : null)};
    ${(props) => (props.inputType === 'reset-password' ? `width: 37.5%` : null)};
    ${(props) => (props.inputType === 'reset-password' ? `min-width: 37.5%` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    ${(props) => (props.inputType === 'personal-info' ? `font-size: 1.6rem` : null)};
    ${(props) => (props.inputType === 'personal-info--sub' ? `font-size: 1.4rem` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    ${(props) => (props.inputType === 'login' ? `width: 80%` : null)};
    ${(props) => (props.inputType === 'login' ? `min-width: 80%` : null)};
    ${(props) => (props.inputType === 'signup' ? `width: 80%` : null)};
    ${(props) => (props.inputType === 'signup' ? `min-width: 80%` : null)};
    ${(props) => (props.inputType === 'forgot-password' ? `width: 80%` : null)};
    ${(props) => (props.inputType === 'forgot-password' ? `min-width: 80%` : null)};
    ${(props) => (props.inputType === 'two-factor' ? `width: 80%` : null)};
    ${(props) => (props.inputType === 'two-factor' ? `min-width: 80%` : null)};
    ${(props) => (props.inputType === 'reset-password' ? `width: 80%` : null)};
    ${(props) => (props.inputType === 'reset-password' ? `min-width: 80%` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    ${(props) => (props.inputType === 'login' ? `width: 85%` : null)};
    ${(props) => (props.inputType === 'login' ? `min-width: 85%` : null)};
    ${(props) => (props.inputType === 'signup' ? `width: 85%` : null)};
    ${(props) => (props.inputType === 'signup' ? `min-width: 85%` : null)};
    ${(props) => (props.inputType === 'forgot-password' ? `width: 85%` : null)};
    ${(props) => (props.inputType === 'forgot-password' ? `min-width: 85%` : null)};
    ${(props) => (props.inputType === 'two-factor' ? `width: 85%` : null)};
    ${(props) => (props.inputType === 'two-factor' ? `min-width: 85%` : null)};
    ${(props) => (props.inputType === 'reset-password' ? `width: 85%` : null)};
    ${(props) => (props.inputType === 'reset-password' ? `min-width: 85%` : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.landscape} {
    ${(props) => (props.inputType === 'login' ? `font-size: 1.4rem` : null)};
    ${(props) => (props.inputType === 'signup' ? `font-size: 1.4rem` : null)};
    ${(props) => (props.inputType === 'forgot-password' ? `font-size: 1.4rem` : null)};
    ${(props) => (props.inputType === 'two-factor' ? `font-size: 1.4rem` : null)};
    ${(props) => (props.inputType === 'reset-password' ? `font-size: 1.4rem` : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.portrait} {
    ${(props) => (props.inputType === 'login' ? `width: 90%` : null)};
    ${(props) => (props.inputType === 'login' ? `min-width: 90%` : null)};
    ${(props) => (props.inputType === 'signup' ? `width: 90%` : null)};
    ${(props) => (props.inputType === 'signup' ? `min-width: 90%` : null)};
    ${(props) => (props.inputType === 'forgot-password' ? `width: 90%` : null)};
    ${(props) => (props.inputType === 'forgot-password' ? `min-width: 90%` : null)};
    ${(props) => (props.inputType === 'two-factor' ? `width: 90%` : null)};
    ${(props) => (props.inputType === 'two-factor' ? `min-width: 90%` : null)};
    ${(props) => (props.inputType === 'reset-password' ? `width: 90%` : null)};
    ${(props) => (props.inputType === 'reset-password' ? `min-width: 90%` : null)};
  }

  ${(props) => (props.modifier === 'mainCategoryTitle' ? `width: 35%` : null)};
  ${(props) => (props.modifier === 'mainCategoryTitle' ? `border-radius: 1.25rem` : null)};
  ${(props) => (props.modifier === 'mainCategoryTitle' ? `background-color: ${(props) => `${props.theme.colors.grayScale.raisinBlack}cc`}` : null)};
`;

export const CategoryTitleCreationInput = styled(LandingFormInput)`
  height: 4rem;
  width: 35%;
  color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};
  border-radius: 1.25rem;
  background-color: ${(props) => `${props.theme.colors.grayScale.sonicSilver}cc`};

  &:focus {
    color: ${(props) => props.theme.colors.tertiaryColor};
    background-color: ${(props) => `${props.theme.colors.grayScale.raisinBlack}`};
  }
`;

export const UserProfileInput = styled(LandingFormInput)`
  color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};
  background-color: ${(props) => `${props.theme.colors.grayScale.raisinBlack}cc`};
  transform: ${(props) => props.transform};
  min-width: 23%;

  &:focus {
    background-color: ${(props) => `${props.theme.colors.grayScale.raisinBlack}`};
    color: ${(props) => `${props.theme.colors.tertiaryColor}`};
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    ${(props) => (props.inputType === 'profile-photo' ? 'width: 50%' : null)}
  }

  ${(props) => (props.inputType === 'profile-photo' ? 'width: max-content' : null)}
`;

export const TwoFactorInput = styled(LandingFormInput)`
  &.form__input--two-factor {
    background-color: ${(props) => `${props.theme.colors.grayScale.raisinBlack}cc`};
    color: ${(props) => chroma(`${props.theme.colors.primaryColor}cc`).brighten(0.4).hex()};

    &:focus {
      background-color: ${(props) => `${props.theme.colors.grayScale.raisinBlack}`};
      border-color: ${(props) => `${props.theme.colors.tertiaryColor}`};
      color: ${(props) => `${props.theme.colors.tertiaryColor}`};
    }
  }
`;

export const CreateBudgetFormInput = styled(FormInput)`
  height: max-content;
  min-height: 7rem;
  width: max-content;
  min-width: ${(props) => props.minWidth};
  font-size: 2rem;
  background-color: ${(props) => `${props.theme.colors.grayScale.offWhite}40`};
  border-color: ${(props) => `${props.theme.colors.primaryColor}cc`};
  border-radius: ${(props) => props.borderRadius};
  padding: 1rem;
  font-size: 2rem;
  color: ${(props) => `${props.theme.colors.primaryColor}cc`};
  margin: ${(props) => props.margin};

  &:focus {
    border-color: ${(props) => `${props.theme.colors.primaryColor}`};
    color: ${(props) => `${props.theme.colors.primaryColor}`};
  }

  ${(props) => (props.inBudget === true ? `min-height: ${props.minHeight}` : null)};
  ${(props) => (props.inBudget === true ? `height: ${props.inputHeight}` : null)};
  ${(props) => (props.inBudget === true ? `width: ${props.inputWidth}` : null)};
`;

export const SubCategoryGoalInput = styled(FormInput)`
  height: max-content;
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};
  width: ${(props) => props.width};
  min-width: 6rem;
  border: 0.2rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
  border-radius: 0.5rem;
  margin: ${(props) => props.margin};
  caret-color: ${(props) => props.theme.colors.primaryColor};
  background-color: ${(props) => `${props.theme.colors.grayScale.raisinBlack}99`};
  font-size: 1.4rem;
  color: ${(props) => `${chroma(props.theme.colors.grayScale.offWhite).brighten(0.4).hex()}cc`};
  padding-left: 1rem;

  &:focus {
    border-color: ${(props) => props.theme.colors.primaryColor};
    background-color: ${(props) => props.theme.colors.grayScale.raisinBlack};
    color: ${(props) => props.theme.colors.tertiaryColor};
  }

  ${(props) => (props.modifier === 'transaction' ? `&::placeholder {color: ${props.theme.colors.grayScale.offWhite}cc}` : null)};
  ${(props) => (props.investment === true ? `border-radius: ${props.borderRadius}` : null)};
`;
