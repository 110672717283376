import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { LoggedIn } from '../Logged-In/LoggedIn';
import Landing from './Landing';
import { MainCategory } from '../../Classes/Categories/Main-Category';
import { Budget } from '../Budget/Budget';
import { ResetPassword } from '../Global/Forms/CompleteForms/ResetPassword';
import NotFound from '../Global/NotFound';
import { addClasses, removeClasses, setGridSize, isMobileNavNeeded } from '../Utility';

const initialCurrentCategory = {
  mainCategory: '',
  subCategory: '',
};

const initialDisabledValues = {
  loginDisabled: true,
  signupDisabled: true,
  forgotPasswordDisabled: true,
  resetPasswordDisabled: true,
  twoFactorDisabled: true,
  createBudgetDisabled: true,
  personalInformation: true,
  communications: true,
  passwordManagement: true,
};

function Application(props) {
  const { theme } = props;
  const [mobileNavigation, setMobileNavigation] = useState(false);
  const [profileLinks, setProfileLinks] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState('');
  const [budget, setBudget] = useState({});
  const [currentCategory, setCurrentCategory] = useState(initialCurrentCategory);
  const [latterDaySaint, setLatterDaySaint] = useState(false);
  const [newMainCategory, setNewMainCategory] = useState(new MainCategory({ icon: '', title: '' }));
  const [disabledValues, setDisabledValues] = useState(initialDisabledValues);
  const [errors, setErrors] = useState([]);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [iconObject, setIconObject] = useState(); // This needs to be in a shared parent of the budget and budget creation form.

  useEffect(() => {
    setMobileNavigation(isMobileNavNeeded(theme));
  }, []);

  const changeMainCategory = (event, change) => {
    event.preventDefault();
    if (change === `decrease`) {
      return categoryIndex === 0 ? null : setCategoryIndex(categoryIndex - 1);
    } else if (change === `increase`) {
      return categoryIndex === budget.mainCategories.length - 1 || budget.mainCategories.length === 0 ? null : setCategoryIndex(categoryIndex + 1);
    }
  };

  useEffect(() => {
    const masterGrid = document.querySelector('.master-grid-container');
    window.addEventListener('resize', () => {
      console.log(masterGrid.classList[2], masterGrid.classList);
      removeClasses(masterGrid, [...masterGrid.classList]);
      addClasses(masterGrid, ['master-grid-container', 'r__master-grid-container', setGridSize('outer', theme)]);
    });
  }, []);

  return (
    <section className={`master-grid-container r__master-grid-container ${setGridSize('outer', theme)}`}>
      <Routes>
        <Route
          path="/*"
          element={
            <Landing
              theme={theme}
              mobileNavigation={mobileNavigation}
              setMobileNavigation={setMobileNavigation}
              isLoggedIn={isLoggedIn}
              latterDaySaint={latterDaySaint}
              setLatterDaySaint={setLatterDaySaint}
              errors={errors}
              setErrors={setErrors}
              disabledValues={disabledValues}
              setDisabledValues={setDisabledValues}
              initialDisabledValues={initialDisabledValues}
            />
          }></Route>
        <Route
          path="/Users/:id/*"
          element={
            <LoggedIn
              theme={theme}
              mobileNavigation={mobileNavigation}
              setMobileNavigation={setMobileNavigation}
              profileLinks={profileLinks}
              setProfileLinks={setProfileLinks}
              user={user}
              setUser={setUser}
              budget={budget}
              setBudget={setBudget}
              categoryIndex={categoryIndex}
              setCategoryIndex={setCategoryIndex}
              changeMainCategory={changeMainCategory}
              currentCategory={currentCategory}
              setCurrentCategory={setCurrentCategory}
              errors={errors}
              setErrors={setErrors}
              disabledValues={disabledValues}
              setDisabledValues={setDisabledValues}
              newMainCategory={newMainCategory}
              setNewMainCategory={setNewMainCategory}
              iconObject={iconObject}
              setIconObject={setIconObject}
            />
          }></Route>
        <Route
          path="/Users/ResetPassword/:token/*"
          element={
            <ResetPassword theme={theme} errors={errors} setErrors={setErrors} disabledValues={disabledValues} setDisabledValues={setDisabledValues} initialDisabledValues={initialDisabledValues} />
          }></Route>
        <Route
          path="/Users/:id/Budgets/:budgetID/*"
          element={
            <Budget
              theme={theme}
              mobileNavigation={mobileNavigation}
              setMobileNavigation={setMobileNavigation}
              profileLinks={profileLinks}
              setProfileLinks={setProfileLinks}
              user={user}
              setUser={setUser}
              budget={budget}
              setBudget={setBudget}
              categoryIndex={categoryIndex}
              setCategoryIndex={setCategoryIndex}
              changeMainCategory={changeMainCategory}
              currentCategory={currentCategory}
              setCurrentCategory={setCurrentCategory}
              errors={errors}
              setErrors={setErrors}
              disabledValues={disabledValues}
              setDisabledValues={setDisabledValues}
              newMainCategory={newMainCategory}
              setNewMainCategory={setNewMainCategory}
              iconObject={iconObject}
              setIconObject={setIconObject}
            />
          }></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </section>
  );
}

export default Application;
