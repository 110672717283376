import React from 'react';
import { DateTime } from 'luxon';
import { BudgetPageSection } from '../Global/Forms/Section';
import { BudgetContainerHeader } from '../Global/Forms/Header';
import { Month } from '../Global/Budget/Month';
import { PrimaryButton } from '../Global/Button';
import { MediumContainer } from '../Global/Budget/BudgetContainers/MediumContainer';
import ErrorContainer from '../Global/Forms/Errors';

export const EditCategoryGoals = function (props) {
  const { theme, user, budget, setBudget, categoryIndex, changeMainCategory, currentCategory, setCurrentCategory, handleInputChange, errors, setErrors, disabledValues, setDisabledValues } = props;
  return (
    <BudgetPageSection minHeight="85%" flow="column wrap" justify="space-evenly" align="center">
      <BudgetContainerHeader budgetPage={true} height="10rem" justify="center" bgColor={'transparent'} textColor={theme.colors.tertiaryColor} className="budget-page-header">
        Edit Category Goals
      </BudgetContainerHeader>
      <ErrorContainer>
        {errors && (
          <ol>
            {Object.keys(errors).map((error, index) => {
              if (errors[error] !== '') {
                return (
                  <li className="error" key={index}>
                    {errors[error]}
                  </li>
                );
              }
            })}
          </ol>
        )}
      </ErrorContainer>
      <Month
        theme={theme}
        user={user}
        budget={budget}
        setBudget={setBudget}
        categoryIndex={categoryIndex}
        changeMainCategory={changeMainCategory}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
        handleInputChange={handleInputChange}
        errors={errors}
        setErrors={setErrors}
        disabledValues={disabledValues}
        setDisabledValues={setDisabledValues}
        content={{ margin: '0', monthType: 'Update Budget' }}
      />
      <PrimaryButton
        size="medium"
        margin="2rem 0 0"
        onClick={() => {
          budget.updateBudget(
            {
              budgetId: budget._id,
              userId: user._id,
              lastUpdated: DateTime.now().toISO(),
              currentMonth: budget.currentMonth,
              previousMonth: budget.previousMonth || budget.currentMonth,
              mainCategories: budget.mainCategories,
            },
            'Edit-Category-Goals'
          );
        }}
        disabled={user && user.permissions === 'Associate'}>
        Update
      </PrimaryButton>
    </BudgetPageSection>
  );
};

export const ManageCategories = function (props) {
  const { theme, user, setUser, budget, setBudget, categoryIndex, setCategoryIndex, iconObject, setIconObject, changeMainCategory } = props;
  return (
    <BudgetPageSection minHeight="85%" flow="column wrap" justify="space-evenly" align="center">
      <BudgetContainerHeader budgetPage={true} height="10rem" justify="center" bgColor={'transparent'} textColor={theme.colors.tertiaryColor} className="budget-page-header">
        Manage Categories
      </BudgetContainerHeader>
      <MediumContainer
        theme={theme}
        user={user}
        setUser={setUser}
        budget={budget}
        setBudget={setBudget}
        categoryIndex={categoryIndex}
        setCategoryIndex={setCategoryIndex}
        iconObject={iconObject}
        setIconObject={setIconObject}
        changeMainCategory={changeMainCategory}
      />
      <PrimaryButton
        size="medium"
        margin="2rem 0 0"
        onClick={() => {
          budget.updateBudget(
            {
              budgetId: budget._id,
              userId: user._id,
              lastUpdated: DateTime.now().toISO(),
              currentMonth: budget.currentMonth,
              previousMonth: budget.previousMonth || budget.currentMonth,
              mainCategories: budget.mainCategories,
            },
            'Manage-Categories'
          );
        }}
        disabled={user && user.permissions === 'Associate'}>
        Update
      </PrimaryButton>
    </BudgetPageSection>
  );
};
