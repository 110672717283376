import { Category } from './Category';
import { DateTime } from 'luxon';
import { immerable, produce } from 'immer';

////////////////////////////////////////
// MAIN CATEGORY -- CHILD CLASS
export class MainCategory extends Category {
  [immerable] = true;
  constructor(options) {
    const superOpts = { ...options };
    super(superOpts);
    this.createdAt = DateTime.now().toBSON();
    // this.lastUpdated = DateTime.now().toBSON();
    this.subCategories = [];
  }

  makeCopy(category) {
    for (let key in category) {
      this[key] = category[key];
    }
    return this;
  }

  _delete(budget, category) {
    // ~ REMOVE CATEGORY FROM BUDGET
    budget.mainCategories = budget.mainCategories.filter((mc) => mc.title !== this.title);
    // ~ REMOVE CATEGORY FROM USER INTERFACE
    category.remove();
  }

  deleteSubCategory(title) {
    return (this.subCategories = this.subCategories.filter((sc) => {
      return sc.title !== title;
    }));
  }

  updateLastUpdated() {
    this.lastUpdated = DateTime.now().toBSON();
  }
}
