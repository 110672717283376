import React, { useState } from 'react';
import { BudgetPageSection } from '../../Global/Forms/Section';
import { StyledContentContainer } from '../../Global/Budget/BudgetContainers/BasicContainer';
import { ExtraSmallContainer } from '../../Global/Budget/BudgetContainers/ExtraSmallContainer';
import { BudgetContainerHeader } from '../../Global/Forms/Header';
import { Form } from '../../Global/Forms/Form';
import FormSection from '../../Global/Forms/Section';
import { StyledLargeContainer } from '../../Global/Budget/BudgetContainers/LargeContainer';
import { SubCategoryGoalInput } from '../../Global/Forms/Input';
import { PlanningAccountSelect } from '../../Global/Forms/Select';
import { SwitchLabel } from '../../Global/Forms/Label';
import { PrimaryButton, SmallBorderlessButton } from '../../Global/Button';
import { PlannedTransaction } from '../../../Classes/PlannedTransaction';
import { handleTransaction } from '../../Global/Forms/Algorithms/InputChange';
import { SurplusTransactionSwitch } from '../../Global/Forms/Switch';
import { TransactionPlan } from '../../Global/Budget/TransactionPlan';
import ErrorContainer from '../../Global/Forms/Errors';

export const TransactionPlanner = function (props) {
  const { theme, user, setUser, budget, setBudget, surplus, setSurplus, transaction, setTransaction, handleInputChange, errors, setErrors, disabledValues, setDisabledValues } = props;
  const [isPlanning, setIsPlanning] = useState(false);
  const [plannedTransaction, setPlannedTransaction] = useState(new PlannedTransaction());

  const resetTransactionPlan = () => {
    setIsPlanning(!isPlanning);
    setPlannedTransaction(new PlannedTransaction());
  };

  return (
    <BudgetPageSection minHeight="85%" flow="row wrap" justify="space-evenly" align="center">
      <BudgetContainerHeader budgetPage={true} height="10rem" justify="center" bgColor={'transparent'} textColor={theme.colors.tertiaryColor} className="budget-page-header">
        Transaction Planner
      </BudgetContainerHeader>
      <ExtraSmallContainer
        theme={theme}
        user={user}
        budget={budget}
        title={`Expense Fund`}
        icon={'money-check-dollar'}
        content={{
          content: (
            <StyledContentContainer containerType="transactional" height="100%" minHeight="100%" minWidth="100%" flow="column nowrap" justify="space-evenly" align="center">
              <FormSection minHeight="14rem" flow="row nowrap" justify="space-evenly" align="center">
                <p>{user && budget && budget.accounts && user.money.format(budget.accounts.expenseFund.amount.total)}</p>
              </FormSection>
              <FormSection minHeight="6rem" flow="row nowrap" justify="space-evenly" align="center" borderTop={`.2rem solid ${theme.colors.primaryColor}`}>
                <FormSection minHeight="6rem" width="50%" flow="column nowrap" justify="space-evenly" align="center" appliedSection={true} textHeaderSize="1.6rem" textSize="1.2rem">
                  <p className="tp-header">Applied</p>
                  <p className="tp-text">{user && budget && budget.accounts && user.money.format(budget.accounts.expenseFund.amount.used)}</p>
                </FormSection>
                <FormSection
                  minHeight="6rem"
                  width="50%"
                  flow="column nowrap"
                  justify="space-evenly"
                  align="center"
                  appliedSection={true}
                  textHeaderSize="1.6rem"
                  textSize="1.2rem"
                  borderLeft={`.2rem solid ${theme.colors.primaryColor}`}>
                  <p className="tp-header">Un-Applied</p>
                  <p className="tp-text">{user && budget && budget.accounts && user.money.format(budget.accounts.expenseFund.amount.unUsed)}</p>
                </FormSection>
              </FormSection>
            </StyledContentContainer>
          ),
          margin: '0 0 3rem 0',
          bgColor: theme.colors.grayScale.offWhite,
        }}
      />
      <ExtraSmallContainer
        theme={theme}
        user={user}
        budget={budget}
        title={`Savings Fund`}
        icon={'piggy-bank'}
        content={{
          content: (
            <StyledContentContainer containerType="transactional" height="100%" minHeight="100%" minWidth="100%" flow="column nowrap" justify="space-evenly" align="center">
              <FormSection minHeight="14rem" flow="row nowrap" justify="space-evenly" align="center">
                <p>{user && budget && budget.accounts && user.money.format(budget.accounts.savingsFund.amount.total)}</p>
              </FormSection>

              <FormSection minHeight="6rem" flow="row nowrap" justify="space-evenly" align="center" borderTop={`.2rem solid ${theme.colors.primaryColor}`}>
                <FormSection minHeight="6rem" width="50%" flow="column nowrap" justify="space-evenly" align="center" appliedSection={true} textHeaderSize="1.6rem" textSize="1.2rem">
                  <p className="tp-header">Applied</p>
                  <p className="tp-text">{user && budget && budget.accounts && user.money.format(budget.accounts.savingsFund.amount.used)}</p>
                </FormSection>
                <FormSection
                  minHeight="6rem"
                  width="50%"
                  flow="column nowrap"
                  justify="space-evenly"
                  align="center"
                  appliedSection={true}
                  textHeaderSize="1.6rem"
                  textSize="1.2rem"
                  borderLeft={`.2rem solid ${theme.colors.primaryColor}`}>
                  <p className="tp-header">Un-Applied</p>
                  <p className="tp-text">{user && budget && budget.accounts && user.money.format(budget.accounts.savingsFund.amount.unUsed)}</p>
                </FormSection>
              </FormSection>
            </StyledContentContainer>
          ),
          margin: '0 0 3rem 0',
          bgColor: theme.colors.grayScale.offWhite,
        }}
      />
      <ExtraSmallContainer
        theme={theme}
        user={user}
        budget={budget}
        title={`Debt`}
        icon={'hand-holding-dollar'}
        content={{
          content: (
            <StyledContentContainer containerType="transactional" height="100%" minHeight="100%" minWidth="100%" flow="column nowrap" justify="space-evenly" align="center">
              <FormSection minHeight="14rem" flow="row nowrap" justify="space-evenly" align="center">
                <p>{user && budget && budget.accounts && user.money.format(budget.accounts.debt.amount.total)}</p>
              </FormSection>

              <FormSection minHeight="6rem" flow="row nowrap" justify="space-evenly" align="center" borderTop={`.2rem solid ${theme.colors.primaryColor}`}>
                <FormSection minHeight="6rem" width="50%" flow="column nowrap" justify="space-evenly" align="center" appliedSection={true} textHeaderSize="1.6rem" textSize="1.2rem">
                  <p className="tp-header">Applied</p>
                  <p className="tp-text">{user && budget && budget.accounts && user.money.format(budget.accounts.debt.amount.used)}</p>
                </FormSection>
                <FormSection
                  minHeight="6rem"
                  width="50%"
                  flow="column nowrap"
                  justify="space-evenly"
                  align="center"
                  appliedSection={true}
                  textHeaderSize="1.6rem"
                  textSize="1.2rem"
                  borderLeft={`.2rem solid ${theme.colors.primaryColor}`}>
                  <p className="tp-header">Un-Applied</p>
                  <p className="tp-text">{user && budget && budget.accounts && user.money.format(budget.accounts.debt.amount.unUsed)}</p>
                </FormSection>
              </FormSection>
            </StyledContentContainer>
          ),
          margin: '0 0 3rem 0',
          bgColor: theme.colors.grayScale.offWhite,
        }}
      />
      <ExtraSmallContainer
        theme={theme}
        user={user}
        budget={budget}
        title={`Surplus`}
        icon={'money-bill-wheat'}
        content={{
          content: (
            <StyledContentContainer containerType="transactional" height="100%" minHeight="100%" minWidth="100%" flow="column nowrap" justify="space-evenly" align="center">
              <FormSection minHeight="14rem" flow="row nowrap" justify="space-evenly" align="center">
                <p>{user && budget && budget.accounts && user.money.format(budget.accounts.surplus.amount.total)}</p>
              </FormSection>

              <FormSection minHeight="6rem" flow="row nowrap" justify="space-evenly" align="center" borderTop={`.2rem solid ${theme.colors.primaryColor}`}>
                <FormSection minHeight="6rem" width="50%" flow="column nowrap" justify="space-evenly" align="center" appliedSection={true} textHeaderSize="1.6rem" textSize="1.2rem">
                  <p className="tp-header">Applied</p>
                  <p className="tp-text">{user && budget && budget.accounts && user.money.format(budget.accounts.surplus.amount.used)}</p>
                </FormSection>
                <FormSection
                  minHeight="6rem"
                  width="50%"
                  flow="column nowrap"
                  justify="space-evenly"
                  align="center"
                  appliedSection={true}
                  textHeaderSize="1.6rem"
                  textSize="1.2rem"
                  borderLeft={`.2rem solid ${theme.colors.primaryColor}`}>
                  <p className="tp-header">Un-Applied</p>
                  <p className="tp-text">
                    {user && budget && budget.accounts && user.money.format(budget && budget.accounts && budget.accounts.surplus.amount.total - budget.accounts.surplus.amount.used)}
                  </p>
                </FormSection>
              </FormSection>
            </StyledContentContainer>
          ),
          margin: '0 0 3rem 0',
          bgColor: theme.colors.grayScale.offWhite,
        }}
      />
      <StyledLargeContainer borderRadius=".9rem" inBudget={true} flow="column nowrap" justify="flex-start" align="center" margin="0 0 5rem">
        <BudgetContainerHeader minHeight="5rem" justify="space-between" align="center" iconSize="2.6rem" textSize="3rem" borderRadius=".5rem .5rem 0 0">
          <i className="fas fa-money-bill-transfer" />
          <p>Transaction Planner</p>
        </BudgetContainerHeader>
        <ErrorContainer>
          {errors && (
            <ol>
              {Object.keys(errors).map((error, index) => {
                if (errors[error] !== '') {
                  return (
                    <li className="error" key={index}>
                      {errors[error]}
                    </li>
                  );
                }
              })}
            </ol>
          )}
        </ErrorContainer>
        {budget &&
          budget.transactions &&
          budget.transactions.plannedTransactions &&
          budget.transactions.plannedTransactions.map((plan, index) => {
            return (
              <TransactionPlan
                theme={theme}
                user={user}
                setUser={setUser}
                budget={budget}
                setBudget={setBudget}
                transaction={plan}
                transactionIndex={index}
                errors={errors}
                setErrors={setErrors}
                key={index}
              />
            );
          })}
        {isPlanning && (
          <Form inBudget={true} formHeight="max-content" flow="row wrap" justify="flex-start" align="center" bgColor={`${theme.colors.grayScale.offWhite}cc`}>
            <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
              <PlanningAccountSelect
                theme={theme}
                user={user}
                name="account"
                options={user && [`Select Account`, `Savings Fund`, `Expense Fund`, `Surplus`, `Debt`]}
                content={{
                  width: '13rem',
                  values: { user: user, budget: budget, plannedTransaction: plannedTransaction },
                  setters: { setUser: setUser, setBudget: setBudget, setPlannedTransaction: setPlannedTransaction },
                }}
                setUser={setUser}
                budget={budget}
                setBudget={setBudget}
                plannedTransaction={plannedTransaction}
                setPlannedTransaction={setPlannedTransaction}
              />
              <SwitchLabel modifier="transaction" width="20rem">
                Account Selection
              </SwitchLabel>
            </FormSection>
            <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
              {plannedTransaction && plannedTransaction.account === 'Expense Fund' && (
                <PlanningAccountSelect
                  theme={theme}
                  user={user}
                  name="type"
                  options={['Select Type', ...[`Expense`, `Bill`, `Subscription`, `Other`]].map((timing) => timing)}
                  content={{
                    width: '13rem',
                    values: { user: user, budget: budget, plannedTransaction: plannedTransaction },
                    setters: { setUser: setUser, setBudget: setBudget, setPlannedTransaction: setPlannedTransaction },
                  }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  plannedTransaction={plannedTransaction}
                  setPlannedTransaction={setPlannedTransaction}
                />
              )}
              {plannedTransaction && plannedTransaction.account === 'Expense Fund' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Transaction Type
                </SwitchLabel>
              )}
              {plannedTransaction && plannedTransaction.account === 'Savings Fund' && (
                <PlanningAccountSelect
                  theme={theme}
                  user={user}
                  name="type"
                  options={['Select Type', ...[`Expense`, `Subscription`, `Vacations`, `Tuition`, `Other`]].map((timing) => timing)}
                  content={{
                    width: '13rem',
                    values: { user: user, budget: budget, plannedTransaction: plannedTransaction },
                    setters: { setUser: setUser, setBudget: setBudget, setPlannedTransaction: setPlannedTransaction },
                  }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  plannedTransaction={plannedTransaction}
                  setPlannedTransaction={setPlannedTransaction}
                />
              )}
              {plannedTransaction && plannedTransaction.account === 'Savings Fund' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Transaction Type
                </SwitchLabel>
              )}
              {plannedTransaction && plannedTransaction.account === 'Surplus' && (
                <PlanningAccountSelect
                  theme={theme}
                  user={user}
                  name="type"
                  options={['Select Type', ...[`Expense`, `Subscription`, `Discretionary`, `Food`, `Other`]].map((timing) => timing)}
                  content={{
                    width: '13rem',
                    values: { user: user, budget: budget, plannedTransaction: plannedTransaction },
                    setters: { setUser: setUser, setBudget: setBudget, setPlannedTransaction: setPlannedTransaction },
                  }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  plannedTransaction={plannedTransaction}
                  setPlannedTransaction={setPlannedTransaction}
                />
              )}
              {plannedTransaction && plannedTransaction.account === 'Surplus' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Transaction Type
                </SwitchLabel>
              )}
              {plannedTransaction && plannedTransaction.account === 'Debt' && (
                <PlanningAccountSelect
                  theme={theme}
                  user={user}
                  name="type"
                  options={['Select Type', ...[`Credit Card`, `Loan`, `Taxes`, `Debt`, `Other`]].map((type) => type)}
                  content={{
                    width: '13rem',
                    values: { user: user, budget: budget, plannedTransaction: plannedTransaction },
                    setters: { setUser: setUser, setBudget: setBudget, setPlannedTransaction: setPlannedTransaction },
                  }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  plannedTransaction={plannedTransaction}
                  setPlannedTransaction={setPlannedTransaction}
                />
              )}
              {plannedTransaction && plannedTransaction.account === 'Debt' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Transaction Type
                </SwitchLabel>
              )}
            </FormSection>
            <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
              {plannedTransaction && plannedTransaction.account !== 'Debt' && (
                <SubCategoryGoalInput
                  type="text"
                  name="location"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder="Location"
                  modifier="transaction"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Planning',
                      { user: user, budget: budget, plannedTransaction: plannedTransaction },
                      { setUser: setUser, setBudget: setBudget, setPlannedTransaction: setPlannedTransaction }
                    )
                  }
                />
              )}
              {plannedTransaction && plannedTransaction.account !== 'Debt' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Location
                </SwitchLabel>
              )}
              {plannedTransaction && plannedTransaction.account === 'Debt' && plannedTransaction.debtFilteredTransactions && (
                <PlanningAccountSelect
                  theme={theme}
                  user={user}
                  name="lender"
                  options={['Select Lender', ...plannedTransaction.debtFilteredTransactions].map((lender) => lender)}
                  content={{
                    width: '13rem',
                    values: { user: user, budget: budget, plannedTransaction: plannedTransaction },
                    setters: { setUser: setUser, setBudget: setBudget, setPlannedTransaction: setPlannedTransaction },
                  }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  plannedTransaction={plannedTransaction}
                  setPlannedTransaction={setPlannedTransaction}
                />
              )}
              {plannedTransaction && plannedTransaction.account === 'Debt' && plannedTransaction.debtFilteredTransactions && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Lender
                </SwitchLabel>
              )}
            </FormSection>
            <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
              {plannedTransaction && plannedTransaction.account && (
                <SubCategoryGoalInput
                  type="text"
                  name="name"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder="Enter Item Name"
                  modifier="transaction"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Planning',
                      { user: user, budget: budget, plannedTransaction: plannedTransaction },
                      { setUser: setUser, setBudget: setBudget, setPlannedTransaction: setPlannedTransaction }
                    )
                  }
                />
              )}
              {plannedTransaction && plannedTransaction.account && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Transaction Name
                </SwitchLabel>
              )}
            </FormSection>
            <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
              {plannedTransaction && plannedTransaction.subAccount && (
                <PlanningAccountSelect
                  theme={theme}
                  user={user}
                  name="timing"
                  options={[`Once`, `Weekly`, `Bi-Weekly`, `Bi-Monthly`, `Monthly`, `Quarterly`, `Bi-Annual`, `Annual`].map((timing) => timing)}
                  content={{
                    width: '13rem',
                    values: { user: user, budget: budget, plannedTransaction: plannedTransaction },
                    setters: { setUser: setUser, setBudget: setBudget, setPlannedTransaction: setPlannedTransaction },
                  }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  plannedTransaction={plannedTransaction}
                  setPlannedTransaction={setPlannedTransaction}
                />
              )}
              {plannedTransaction && plannedTransaction.subAccount && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Transaction Timing
                </SwitchLabel>
              )}
            </FormSection>
            <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
              {plannedTransaction && plannedTransaction.timingOptions.paymentCycle && (
                <SubCategoryGoalInput
                  type="date"
                  name="dueDateOne"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  modifier="transaction"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Planning',
                      { user: user, budget: budget, plannedTransaction: plannedTransaction },
                      { setUser: setUser, setBudget: setBudget, setPlannedTransaction: setPlannedTransaction }
                    )
                  }
                />
              )}
              {plannedTransaction && plannedTransaction.timingOptions.paymentCycle && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Due Date One
                </SwitchLabel>
              )}
            </FormSection>
            <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
              {(plannedTransaction &&
                plannedTransaction.timingOptions.paymentCycle === 'Bi-Monthly' &&
                plannedTransaction.timingOptions.dueDates &&
                plannedTransaction.timingOptions.dueDates.length > 0 && (
                  <SubCategoryGoalInput
                    type="date"
                    name="dueDateTwo"
                    minHeight="4rem"
                    maxHeight="4rem"
                    width="13rem"
                    modifier="transaction"
                    onChange={(e) =>
                      handleTransaction(
                        e,
                        'Planning',
                        { user: user, budget: budget, plannedTransaction: plannedTransaction },
                        { setUser: setUser, setBudget: setBudget, setPlannedTransaction: setPlannedTransaction }
                      )
                    }
                  />
                )) ||
                (plannedTransaction &&
                  plannedTransaction.timingOptions.paymentCycle === 'Bi-Annual' &&
                  plannedTransaction.timingOptions.dueDates &&
                  plannedTransaction.timingOptions.dueDates.length > 0 && (
                    <SubCategoryGoalInput
                      type="date"
                      name="dueDateTwo"
                      minHeight="4rem"
                      maxHeight="4rem"
                      width="13rem"
                      modifier="transaction"
                      onChange={(e) =>
                        handleTransaction(
                          e,
                          'Planning',
                          { user: user, budget: budget, plannedTransaction: plannedTransaction },
                          { setUser: setUser, setBudget: setBudget, setPlannedTransaction: setPlannedTransaction }
                        )
                      }
                    />
                  ))}
              {(plannedTransaction &&
                plannedTransaction.timingOptions.paymentCycle === 'Bi-Monthly' &&
                plannedTransaction.timingOptions.dueDates &&
                plannedTransaction.timingOptions.dueDates.length > 0 && (
                  <SwitchLabel modifier="transaction" width="20rem">
                    Due Date Two
                  </SwitchLabel>
                )) ||
                (plannedTransaction &&
                  plannedTransaction.timingOptions.paymentCycle === 'Bi-Annual' &&
                  plannedTransaction.timingOptions.dueDates &&
                  plannedTransaction.timingOptions.dueDates.length > 0 && (
                    <SwitchLabel modifier="transaction" width="20rem">
                      Due Date Two
                    </SwitchLabel>
                  ))}
            </FormSection>
            <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
              {plannedTransaction && plannedTransaction.timingOptions && plannedTransaction.timingOptions.dueDates && plannedTransaction.timingOptions.dueDates.length >= 1 && (
                <SubCategoryGoalInput
                  type="number"
                  name="payment"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder="Individual Payment"
                  modifier="transaction"
                  onChange={(e) =>
                    handleTransaction(
                      e,
                      'Planning',
                      { user: user, budget: budget, plannedTransaction: plannedTransaction, errors: errors },
                      { setUser: setUser, setBudget: setBudget, setPlannedTransaction: setPlannedTransaction, setErrors: setErrors }
                    )
                  }
                />
              )}
              {plannedTransaction && plannedTransaction.timingOptions && plannedTransaction.timingOptions.dueDates && plannedTransaction.timingOptions.dueDates.length >= 1 && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Individual Payment
                </SwitchLabel>
              )}
            </FormSection>
            <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
              {(plannedTransaction && plannedTransaction.account === 'Expense Fund' && (
                <SurplusTransactionSwitch
                  theme={theme}
                  user={user}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  plannedTransaction={plannedTransaction}
                  setPlannedTransaction={setPlannedTransaction}
                />
              )) ||
                (plannedTransaction && plannedTransaction.account === 'Savings Fund' && (
                  <SurplusTransactionSwitch
                    theme={theme}
                    user={user}
                    setUser={setUser}
                    budget={budget}
                    setBudget={setBudget}
                    plannedTransaction={plannedTransaction}
                    setPlannedTransaction={setPlannedTransaction}
                  />
                ))}
              {(plannedTransaction && plannedTransaction.account === 'Expense Fund' && (
                <SwitchLabel modifier="transaction" width="20rem">
                  Surplus?
                </SwitchLabel>
              )) ||
                (plannedTransaction && plannedTransaction.account === 'Savings Fund' && (
                  <SwitchLabel modifier="transaction" width="20rem">
                    Surplus?
                  </SwitchLabel>
                ))}
            </FormSection>
            <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column" justify="space-evenly" align="center" padding=".5rem">
              <PrimaryButton
                size="small"
                minWidth="10rem"
                name="create-plan"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleTransaction(
                    e,
                    'Planning',
                    { user: user, budget: budget, plannedTransaction: plannedTransaction, errors: errors },
                    { setUser: setUser, setBudget: setBudget, setPlannedTransaction: setPlannedTransaction, setErrors: setErrors }
                  );
                }}>
                Create
              </PrimaryButton>
            </FormSection>
            <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column" justify="space-evenly" align="center" padding=".5rem">
              <PrimaryButton size="small" minWidth="10rem" onClick={() => resetTransactionPlan()}>
                Close
              </PrimaryButton>
            </FormSection>
          </Form>
        )}
        {isPlanning === false && (
          <SmallBorderlessButton size="extra-small" onClick={() => setIsPlanning(!isPlanning)} disabled={user && user.permissions === 'Associate'}>
            + Add Transaction Plan
          </SmallBorderlessButton>
        )}
      </StyledLargeContainer>
    </BudgetPageSection>
  );
};
