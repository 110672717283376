import React from 'react';
import styled from 'styled-components';
import chroma from 'chroma-js';

export const Header = styled.header`
  position: relative;
  height: max-content;
  width: max-content;
`;

export const LandingFormHeader = styled(Header)`
  &.form__header {
    font-family: ${(props) => `${props.theme.text.secondary} Bold`};
    font-size: 10rem;
    color: ${(props) => props.theme.colors.primaryColor};
    margin: ${(props) => props.margin};

    @media ${(props) => props.theme.screens.largeTablet.landscape} {
      ${(props) => (props.headerType === 'forgot-password' ? `font-size: 8rem` : null)};
      ${(props) => (props.headerType === 'two-factor' ? `font-size: 8rem` : null)};
    }

    @media ${(props) => props.theme.screens.largeTablet.portrait} {
      ${(props) => (props.headerType === 'forgot-password' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'two-factor' ? `font-size: 6rem` : null)};
    }

    @media ${(props) => props.theme.screens.smallTablet.landscape} {
      ${(props) => (props.headerType === 'profile-form--modified' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'profile-form' ? `font-size: 6rem` : null)};
    }

    @media ${(props) => props.theme.screens.smallTablet.portrait} {
      width: 100%;
      text-align: center;
      ${(props) => (props.headerType === 'profile-form--modified' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'profile-form' ? `font-size: 6rem` : null)};
    }

    @media ${(props) => props.theme.screens.largeMobile.landscape} {
      ${(props) => (props.headerType === 'login' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'login' ? `margin: 0 0 1rem` : null)};
      ${(props) => (props.headerType === 'signup' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'signup' ? `margin: 0 0 1rem` : null)};
      ${(props) => (props.headerType === 'reset-password' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'reset-password' ? `margin: 0 0 1rem` : null)};
      ${(props) => (props.headerType === 'forgot-password' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'two-factor' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'profile-form' ? `font-size: 3.6rem` : null)};
      ${(props) => (props.headerType === 'profile-form' ? `margin: 0rem` : null)};
      ${(props) => (props.headerType === 'profile-form--modified' ? `font-size: 3.6rem` : null)};
    }

    @media ${(props) => props.theme.screens.largeMobile.portrait} {
      ${(props) => (props.headerType === 'login' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'login' ? `margin: 0 0 1rem` : null)};
      ${(props) => (props.headerType === 'signup' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'signup' ? `margin: 0 0 1rem` : null)};
      ${(props) => (props.headerType === 'reset-password' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'reset-password' ? `margin: 0 0 1rem` : null)};
      ${(props) => (props.headerType === 'forgot-password' ? `font-size: 3.6rem` : null)};
      ${(props) => (props.headerType === 'two-factor' ? `font-size: 3.6rem` : null)};
      ${(props) => (props.headerType === 'profile-form' ? `font-size: 3rem` : null)};
      ${(props) => (props.headerType === 'profile-form' ? `margin: 6rem 0 0` : null)};
      ${(props) => (props.headerType === 'profile-form--modified' ? `font-size: 3rem` : null)};
      ${(props) => (props.headerType === 'profile-form--modified' ? `margin: 6rem 0 3rem` : null)};
    }

    @media ${(props) => props.theme.screens.mediumMobile.landscape} {
      ${(props) => (props.headerType === 'login' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'login' ? `margin: 0 0 0rem` : null)};
      ${(props) => (props.headerType === 'signup' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'signup' ? `margin: 0 0 0rem` : null)};
      ${(props) => (props.headerType === 'reset-password' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'reset-password' ? `margin: 0 0 0rem` : null)};
      ${(props) => (props.headerType === 'forgot-password' ? `font-size: 3.6rem` : null)};
      ${(props) => (props.headerType === 'two-factor' ? `font-size: 3.6rem` : null)};
      ${(props) => (props.headerType === 'profile-form--modified' ? `margin: 6rem 0 3rem` : null)};
    }

    @media ${(props) => props.theme.screens.mediumMobile.portrait} {
      ${(props) => (props.headerType === 'login' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'login' ? `margin: 0 0 1rem` : null)};
      ${(props) => (props.headerType === 'signup' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'signup' ? `margin: 0 0 1rem` : null)};
      ${(props) => (props.headerType === 'reset-password' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'reset-password' ? `margin: 0 0 1rem` : null)};
      ${(props) => (props.headerType === 'forgot-password' ? `font-size: 3.2rem` : null)};
      ${(props) => (props.headerType === 'two-factor' ? `font-size: 3.2rem` : null)};
      ${(props) => (props.headerType === 'profile-form' ? `margin: 6rem 0 0` : null)};
      ${(props) => (props.headerType === 'profile-form--modified' ? `margin: 6rem 0 3rem` : null)};
    }

    @media ${(props) => props.theme.screens.smallMobile.portrait} {
      ${(props) => (props.headerType === 'login' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'login' ? `margin: 0 0 1rem` : null)};
      ${(props) => (props.headerType === 'signup' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'signup' ? `margin: 0 0 1rem` : null)};
      ${(props) => (props.headerType === 'reset-password' ? `font-size: 6rem` : null)};
      ${(props) => (props.headerType === 'reset-password' ? `margin: 0 0 1rem` : null)};
      ${(props) => (props.headerType === 'forgot-password' ? `font-size: 3rem` : null)};
      ${(props) => (props.headerType === 'two-factor' ? `font-size: 3rem` : null)};
      ${(props) => (props.headerType === 'profile-form' ? `margin: 6rem 0 0` : null)};
      ${(props) => (props.headerType === 'profile-form--modified' ? `font-size: 2.6rem` : null)};
    }

    ${(props) => (props.textColor === `white` ? `color: ${props.theme.colors.grayScale.offWhite}` : null)};
    ${(props) => (props.modifier === `profile` ? `font-size: 8rem` : null)};
  }
`;

const SubCategoryDisplayHeader = styled.header`
  position: relative;
  height: 100%;
  min-height: 4rem;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transform: translate(0, 0) rotate(0);
  background-color: ${(props) => chroma(props.theme.colors.primaryColor).darken(0.25).hex()};
  border-bottom: 0.2rem solid ${(props) => props.theme.colors.tertiaryColor};
  font-family: ${(props) => props.theme.text.secondary};
  font-size: 2rem;
  color: ${(props) => props.theme.colors.grayScale.offWhite};
  text-align: center;
`;

export const BudgetContainerHeader = styled(Header)`
  height: ${(props) => props.height};
  min-height: ${(props) => props.minHeight};
  min-width: 100%;
  border-radius: ${(props) => props.borderRadius};
  border-bottom: 0.3rem solid ${(props) => props.theme.colors.tertiaryColor};
  background-color: ${(props) => chroma(props.theme.colors.primaryColor).darken(0.2).hex()};
  color: ${(props) => props.theme.colors.grayScale.offWhite};
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${(props) => props.justify};
  align-items: center;
  padding: 1rem 2rem;
  &.budget-page-header {
    font-family: ${(props) => props.theme.text.secondary};
    font-size: 7rem;
    font-weight: bold;
  }

  &.negative {
    background-color: ${(props) => chroma(props.theme.colors.error).darken(0.2).hex()};
  }

  &.positive {
    background-color: ${(props) => chroma(props.theme.colors.secondaryColor).darken(0.2).hex()};
  }

  & i {
    font-size: ${(props) => props.iconSize};
    &:hover {
      cursor: pointer;
      transition: color 0.5s;
    }
  }

  & p {
    font-family: ${(props) => props.theme.text.secondary};
    font-size: ${(props) => props.textSize};
    &.weekday {
      height: 100%;
      width: ${(props) => props.weekdayWidth};
      background-color: ${(props) => props.theme.colors.primaryColor};
      color: ${(props) => props.theme.colors.grayScale.offWhite};
      display: flex;
      justify-content: center;
      align-items: center;
      &__day {
        height: ${(props) => props.weekdayHeight};
        width: ${(props) => props.weekdayWidth};
        background-color: transparent;
        border-top: ${(props) => props.borderTop};
        border-right: ${(props) => props.borderRight};
        border-bottom: ${(props) => props.borderBottom};
        border-left: ${(props) => props.borderLeft};
        border: ${(props) => props.border};
        color: ${(props) => props.theme.colors.primaryColor};
        display: flex;
        justify-content: center;
        align-items: center;
        &.current-day {
          background-color: ${(props) => props.theme.colors.tertiaryColor};
          color: ${(props) => props.theme.colors.grayScale.raisinBlack};
        }
        &.same-week {
          background-color: ${(props) => `${chroma(props.theme.colors.primaryColor).darken(0.2).hex()}cc`};
          color: ${(props) => props.theme.colors.grayScale.offWhite};
        }
        &.un-used-day {
          background-color: ${(props) => `${chroma(props.theme.colors.grayScale.raisinBlack).darken(0.2).hex()}80`};
          background-color: ${(props) => `${chroma(props.theme.colors.primaryColor).darken(0.2).hex()}80`};
          color: ${(props) => props.theme.colors.grayScale.offWhite};
          &:hover {
            background-color: ${(props) => `${chroma(props.theme.colors.grayScale.raisinBlack).darken(0.2).hex()}80`};
            background-color: ${(props) => `${chroma(props.theme.colors.primaryColor).darken(0.2).hex()}80`};
          }
        }
        &:hover {
          cursor: pointer;
          background-color: ${(props) => props.theme.colors.primaryColor};
          color: ${(props) => props.theme.colors.grayScale.offWhite};
          transition: background-color 0.5s, color 0.5s;
        }
      }
    }
  }

  ${(props) => (props.modifier === 'bill-calendar' ? `background-color: ${props.bgColor}` : null)};
  ${(props) => (props.modifierTwo === 'purple' ? `color: ${props.theme.colors.primaryColor}` : null)};
  ${(props) => (props.modifierTwo === 'purple' ? `& i {color: ${props.theme.colors.primaryColor}40}` : null)};
  ${(props) => (props.modifierTwo === 'purple' ? `& i {&:hover { color: ${props.theme.colors.primaryColor}}} ` : null)};
  ${(props) => (props.modifierTwo === 'purple' ? `& p {margin: ${props.textMargin}}` : null)};
  ${(props) => (props.modifierTwo === 'weekday' ? `padding: ${props.weekdayPadding}` : null)};
  ${(props) => (props.modifierTwo === 'weekday purple' ? `padding: ${props.weekdayPadding}` : null)};
  ${(props) => (props.modifierTwo === 'weekday purple' ? `color: ${props.theme.colors.primaryColor}` : null)};
  ${(props) => (props.week === true ? `border-bottom: transparent` : null)};
  ${(props) => (props.week === true ? `height: 100%` : null)};
  ${(props) => (props.budgetPage === true ? `background-color: ${props.bgColor}` : null)};
  ${(props) => (props.budgetPage === true ? `border-bottom: transparent` : null)};
  ${(props) => (props.budgetPage === true ? `color: ${props.textColor}` : null)};
  ${(props) => (props.investmentHeader === true ? `&.budget-page-header {font-size: ${props.textSize}}` : null)}
  ${(props) => (props.investmentHeader === true ? `padding: ${props.investmentPadding}` : null)};
`;

const StyledSubCategoryHeaderContainer = styled.section`
  position: relative;
  height: max-content;
  min-height: 4rem;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  transform: translate(0, 0) rotate(0);
`;

export const SubCategoryHeaderContainer = function (props) {
  const { theme, headers } = props;
  return (
    <StyledSubCategoryHeaderContainer>
      {headers.map((header, index) => {
        return <SubCategoryDisplayHeader key={index}>{header}</SubCategoryDisplayHeader>;
      })}
    </StyledSubCategoryHeaderContainer>
  );
};

export const ReceiptHeader = styled(BudgetContainerHeader)`
  width: ${(props) => props.headerWidth};
  min-width: ${(props) => props.minWidth};
  padding: 0 2rem;
  border-bottom: ${(props) => props.borderBottom};
  margin: ${(props) => props.margin};
  background-color: transparent;
  font-family: ${(props) => props.theme.text.secondary};
  font-size: ${(props) => props.textSize};
  font-variant: ${(props) => props.textVariant};
  font-weight: ${(props) => props.textWeight};
  color: ${(props) => props.theme.colors.primaryColor};

  & p.receipt-sub-header {
    font-size: ${(props) => props.textSize};
  }
`;
