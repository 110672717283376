import React from 'react';
import styled from 'styled-components';

const FormCloser = styled.i`
  position: absolute;
  height: 4rem;
  width: 5rem;
  top: 3rem;
  right: 3rem;
  font-size: 4rem;
  z-index: 10;
  &:hover {
    cursor: pointer;
    transition: background-color 0.5s, color 0.5s, border 0.5s;
  }
`;

export const LandingFormCloser = styled(FormCloser)`
  color: ${(props) => `${props.theme.colors.primaryColor}cc`};
  &:hover {
    color: ${(props) => `${props.theme.colors.primaryColor}`};
    ${(props) => (props.modifier === 'profile' ? `color: ${(props) => props.theme.colors.grayScale.offWhite}` : null)};
  }
`;

export const ProfileFormCloser = styled(FormCloser)`
  color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};

  &:hover {
    color: ${(props) => `${props.theme.colors.grayScale.offWhite}`};
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    ${(props) => (props.closerType === 'profile-form' ? `font-size: 3rem` : null)};
    ${(props) => (props.closerType === 'profile-form' ? `top: 3rem` : null)};
    ${(props) => (props.closerType === 'profile-form' ? `right: 0rem` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    ${(props) => (props.closerType === 'profile-form' ? `font-size: 3rem` : null)};
    ${(props) => (props.closerType === 'profile-form' ? `top: 3rem` : null)};
    ${(props) => (props.closerType === 'profile-form' ? `right: 0rem` : null)};
  }
`;

export const InvestmentChartCloser = styled(FormCloser)`
  height: 2rem;
  width: 2rem;
  font-size: 4rem;
  top: 2rem;
  right: 5rem;
  color: ${(props) => `${props.theme.colors.primaryColor}cc`};
  &:hover {
    color: ${(props) => `${props.theme.colors.primaryColor}`};
    ${(props) => (props.modifier === 'profile' ? `color: ${(props) => props.theme.colors.grayScale.offWhite}` : null)};
    ${(props) => (props.modifier === 'white' ? `color: ${props.theme.colors.grayScale.offWhite}` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    ${(props) => (props.closerType === 'lds-help' ? `font-size: 3rem` : null)};
    ${(props) => (props.closerType === 'lds-help' ? `right: 3rem` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.landscape} {
    ${(props) => (props.closerType === 'lds-help' ? `font-size: 3rem` : null)};
    ${(props) => (props.closerType === 'lds-help' ? `right: 3rem` : null)};
    ${(props) => (props.closerType === 'lds-help' ? `top: 2rem` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    ${(props) => (props.closerType === 'lds-help' ? `font-size: 2.4rem` : null)};
    ${(props) => (props.closerType === 'lds-help' ? `right: 2rem` : null)};
    ${(props) => (props.closerType === 'lds-help' ? `top: 1rem` : null)};
  }

  ${(props) => (props.modifier === 'white' ? `color: ${props.theme.colors.grayScale.offWhite}cc` : null)};
`;
