import React, { useState } from 'react';
import styled from 'styled-components';
import { handleInputChange } from './Algorithms/InputChange';
import FormSection from './Section';
import { PrimaryButton } from '../Button';
import { UserProfileInput } from './Input';
import { UserProfileLabel } from './Label';

const QRCode = styled.section`
  position: relative;
  height: max-content;
  min-height: 25rem;
  width: max-content;
  min-width: 25rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  margin: 0 0 2rem;
  transform: translate(0, 0) rotate(0);

  & img {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 2rem;
  }
`;

const StyledQRCodeContainer = styled.section`
  position: relative;
  height: 80%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transform: translate(0, 0) rotate(0);
  bottom: 0;

  & h3 {
    font-family: ${(props) => props.theme.text.secondary};
    font-size: 3rem;
    color: ${(props) => props.theme.colors.grayScale.offWhite};
    text-align: center;
    margin-bottom: 3rem;
  }
`;

export const QRCodeContainer = function (props) {
  const { theme, user, setUser, errors, setErrors, disabledValues, setDisabledValues } = props;
  const [qrToken, setQRToken] = useState('');
  return (
    <StyledQRCodeContainer>
      <QRCode>
        <img src={``} alt="QR Code" className="qrcode-image" />
      </QRCode>
      <h3>Scan To Setup 2FA With Your OTP Authentication App</h3>
      <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3rem 0" className="form__section r__form__section open">
        <UserProfileInput
          type="text"
          name="QRCode"
          minLength={5}
          maxLength={40}
          modifier="white"
          modifierTwo="profile"
          className="form__input r__form__input"
          id="QRCode"
          placeholder="Enter QRCode Token"
          focusTransform="translate(-3.5rem, -7rem) scale(0.75)"
          // value={user.QRCode || ''}
          data-cy="QRCode"
          onChange={(e) => {
            handleInputChange(e, 'user-profile', qrToken, setQRToken, errors, setErrors, disabledValues, setDisabledValues);
          }}
        />
        <UserProfileLabel htmlFor="QRCode" data-cy="QRCodeLabel" hoverCursor="pointer">
          QRCode Token
        </UserProfileLabel>
      </FormSection>
      <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3rem 0" className="form__section r__form__section open">
        <PrimaryButton
          size="large"
          modifier="white"
          onClick={(e) => {
            e.preventDefault();
            user._verify2FAToken({ id: user._id, token: qrToken });
          }}>
          Submit Token
        </PrimaryButton>
      </FormSection>
    </StyledQRCodeContainer>
  );
};
