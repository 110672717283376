import { produce, immerable } from 'immer';
import { DateTime } from 'luxon';

export class Debt {
  [immerable] = true;
  constructor() {
    this.date = DateTime.now().toISO();
    this.lender = '';
    this.debtType = '';
    this.regularPayment = 0;
    this.initialDebt = 0;
    this.amountOwed = 0;
    this.status = 'Unpaid';
  }

  copyDebt(debt) {
    for (let key in debt) {
      this[key] = debt[key];
    }
  }

  updateLender(value) {
    this.lender = value;
  }

  updateDebtType(value) {
    this.debtType = value;
  }

  updateRegularPayment(value) {
    this.regularPayment = Number(value);
  }

  updateInitialDebt(value) {
    this.initialDebt = Number(value);
  }

  updateAmountOwed(value) {
    this.amountOwed = Number(value);
  }

  updateStatus(value) {
    this.status = value;
  }

  updateDatePaid() {
    this.datePaid = DateTime.now().toISO();
  }
}
