import React from 'react';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  position: relative;
  height: max-content;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  margin-bottom: 2rem;

  & ol .error {
    font-family: ${(props) => props.theme.text.secondary};
    font-size: 1.6rem;
    color: ${(props) => props.theme.colors.error};
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    ${(props) => (props.errorContainerType === 'landing' ? `text-align: center` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    ${(props) => (props.errorContainerType === 'landing' ? `margin-bottom: 1rem` : null)};
    ${(props) => (props.errorContainerType === 'landing' ? `text-align: center` : null)};
    ${(props) => (props.errorContainerType === 'personal-info' ? `margin: 0rem` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.landscape} {
    ${(props) => (props.errorContainerType === 'landing' ? `margin-bottom: 0rem` : null)};
    ${(props) => (props.errorContainerType === 'landing' ? `text-align: center` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    ${(props) => (props.errorContainerType === 'landing' ? `text-align: center` : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.landscape} {
    ${(props) => (props.errorContainerType === 'landing' ? `margin-bottom: 0rem` : null)};
    ${(props) => (props.errorContainerType === 'landing' ? `padding: 1rem` : null)};
    ${(props) => (props.errorContainerType === 'landing' ? `text-align: center` : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.portrait} {
    ${(props) => (props.errorContainerType === 'landing' ? `text-align: center` : null)};
  }
`;

export const BudgetCreationErrorContainer = styled(ErrorContainer)`
  padding: 0;
`;

export default ErrorContainer;
