import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from '../Form';
import { LandingFormCloser } from '../FormCloser';
import ErrorContainer from '../Errors';
import { LandingFormHeader } from '../Header';
import FormSection from '../Section';
import { LandingFormInput } from '../Input';
import { LandingFormLabel } from '../Label';
import { PrimaryButton, SmallBorderlessButton } from '../../Button';
import { handleInputChange } from '../Algorithms/InputChange';
import { FormContainer } from '../FormContainer';
import { loginSchema } from '../Validate';
import { login } from '../../../API-Requests/Login';

export const LoginForm = function (props) {
  const { theme, flow, justify, align, loginFormValues, setLoginFormValues, errors, setErrors, disabledValues, setDisabledValues } = props;

  const navigate = useNavigate();

  const getLoggedIn = async (e, loginFormValues) => {
    e.preventDefault();
    const username = loginFormValues.username;
    const password = loginFormValues.password;
    await login(username, password, navigate);
  };

  useEffect(() => {
    loginSchema.isValid(loginFormValues).then((valid) => {
      valid === true ? setDisabledValues({ ...disabledValues, loginDisabled: false }) : setDisabledValues({ ...disabledValues, loginDisabled: true });
    });
  }, [loginFormValues]);

  return (
    <FormContainer className="form-container r__form-container">
      <Form position="relative" justify={justify} align={align} flow={flow} bgColor={`${theme.colors.grayScale.offWhite}f2`} className="form r__form">
        <Link to="..">
          <LandingFormCloser onClick={() => setErrors([])}>
            <i className="fas fa-window-close" data-cy="forgottenPasswordFormCloser" />
          </LandingFormCloser>
        </Link>
        <LandingFormHeader className="form__header r__form__header" margin="0rem 0rem 2rem" headerType="login">
          Login
        </LandingFormHeader>
        <ErrorContainer errorContainerType="landing">
          {errors && (
            <ol>
              {Object.keys(errors).map((error, index) => {
                if (errors[error] !== '') {
                  return (
                    <li className="error" key={index}>
                      {errors[error]}
                    </li>
                  );
                }
              })}
            </ol>
          )}
        </ErrorContainer>
        <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3rem 0" className="form__section r__form__section" sectionType="login">
          <LandingFormInput
            type="text"
            inputType="login"
            name="username"
            minLength={8}
            maxLength={40}
            inputWidth="20%"
            className="form__input r__form__input"
            id="username"
            placeholder="Enter Username"
            focusTransform="translate(-5.5rem, -7rem) scale(0.75)"
            onChange={(e) => handleInputChange(e, 'login', loginFormValues, setLoginFormValues, errors, setErrors, disabledValues, setDisabledValues)}
            required
            data-cy="loginUsername"
          />
          <LandingFormLabel htmlFor="username" data-cy="loginUsernameLabel" labelType="login">
            Username
          </LandingFormLabel>
        </FormSection>
        <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3rem 0" className="form__section r__form__section" sectionType="login">
          <LandingFormInput
            type="password"
            inputType="login"
            name="password"
            minLength={8}
            maxLength={40}
            inputWidth="20%"
            className="form__input r__form__input"
            id="password"
            placeholder="Enter Password"
            focusTransform={`translate(-5.5rem, -7rem) scale(0.75)`}
            onChange={(e) => handleInputChange(e, 'login', loginFormValues, setLoginFormValues, errors, setErrors, disabledValues, setDisabledValues)}
            required
            data-cy="loginPassword"
          />
          <LandingFormLabel htmlFor="password" data-cy="loginPasswordLabel" labelType="login">
            Password
          </LandingFormLabel>
        </FormSection>
        <FormSection minHeight="5rem" flow="column nowrap" justify="space-evenly" align="center" className="form__section r__form__section">
          <PrimaryButton
            size="medium"
            margin="0 0 1rem 0"
            type="submit"
            disabled={disabledValues.loginDisabled}
            onClick={(e) => {
              getLoggedIn(e, loginFormValues);
            }}
            data-cy="loginSubmit">
            Submit
          </PrimaryButton>
          <Link to="../Forgot-Password">
            <SmallBorderlessButton size="extra-small" data-cy="forgotPasswordButton" onClick={() => setErrors([])}>
              Forgot Username Or Password
            </SmallBorderlessButton>
          </Link>
        </FormSection>
      </Form>
    </FormContainer>
  );
};
