import React, { useState } from 'react';
import styled from 'styled-components';
import { BudgetPageSection } from '../../Global/Forms/Section';
import { BudgetContainerHeader } from '../../Global/Forms/Header';
import { StyledLargeContainer } from '../../Global/Budget/BudgetContainers/LargeContainer';
import { RecentTransaction } from '../../../Classes/RecentTransaction';
import { RecentTransactionElement } from '../../Global/Budget/RecentTransaction';
import { StyledModal } from '../../Global/Modal';
import { InvestmentChartCloser } from '../../Global/Forms/FormCloser';
import { Receipt } from '../../Global/Budget/DigitalReceipt';
import FormSection from '../../Global/Forms/Section';

export const RecentTransactions = function (props) {
  const { theme, user, setUser, budget, setBudget, transaction, setTransaction, handleInputChange, errors, setErrors, disabledValues, setDisabledValues } = props;
  const [openReceipt, setOpenReceipt] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState({});

  return (
    <BudgetPageSection minHeight="85%" flow="row wrap" justify="space-evenly" align="center">
      <BudgetContainerHeader budgetPage={true} height="10rem" justify="center" bgColor={'transparent'} textColor={theme.colors.tertiaryColor} className="budget-page-header">
        Recent Transactions
      </BudgetContainerHeader>
      {openReceipt && (
        <StyledModal position="absolute" height="100%" width="100%" bgColor={`${theme.colors.grayScale.raisinBlack}f2`} flow="column nowrap" justify="center" align="center" ZIndex={10}>
          <InvestmentChartCloser
            onClick={(e) => {
              e.preventDefault();
              setOpenReceipt(!openReceipt);
              setSelectedTransaction({});
            }}>
            <i className="fas fa-window-close" />
          </InvestmentChartCloser>
          {/* This is where the digital receipt will be in the modal */}
          <Receipt className="receipt">
            <section className="receipt__contents">
              <header className="receipt__contents__top">
                <p className="receipt__contents__top__location">{(selectedTransaction && selectedTransaction.location) || 'Location'}</p>
                <div className="receipt__contents__top__headers">
                  <p className="receipt__contents__top__headers__header">Transaction Details</p>
                  <p className="receipt__contents__top__headers__header">Total</p>
                </div>
              </header>
              <section className="receipt__contents__middle">
                {selectedTransaction &&
                  selectedTransaction.receipt &&
                  selectedTransaction.receipt.map((item, index) => {
                    return (
                      <FormSection
                        minHeight="5rem"
                        width="100%"
                        flow="row nowrap"
                        justify="space-around"
                        align="center"
                        padding="0"
                        receiptSection={true}
                        key={index}
                        className="receipt__contents__middle__item">
                        <FormSection
                          minHeight="100%"
                          width="70%"
                          flow="row nowrap"
                          justify="flex-start"
                          padding="0"
                          textSize="1.6rem"
                          textMargin="0 1rem"
                          receiptSection={true}
                          className="receipt__contents__middle__item__section">
                          {item.account === 'Un-Allocated' && <p className="receipt-text receipt__contents__middle__item__section__text">{selectedTransaction.transactionType}</p>}
                          {item.account === 'Un-Allocated' && <p className="receipt-text receipt__contents__middle__item__section__text">{item.description}</p>}
                          {item.account === 'Monthly Budget' && <p className="receipt-text receipt__contents__middle__item__section__text">{item.mainCategory}</p>}
                          {item.account === 'Monthly Budget' && <p className="receipt-text receipt__contents__middle__item__section__text">{item.subCategory}</p>}
                          {item.account === 'Tithing' && <p className="receipt-text receipt__contents__middle__item__section__text">{item.account}</p>}
                          {(item.account === 'Debt' || item.account === 'Investment Fund') && <p className="receipt-text receipt__contents__middle__item__section__text">{item.type}</p>}
                          {item.account === 'Investment Fund' && <p className="receipt-text receipt__contents__middle__item__section__text">{item.investment}</p>}
                          {item.account === 'Debt' && <p className="receipt-text receipt__contents__middle__item__section__text">{item.lender}</p>}
                          {(item.account === 'Debt' || item.account === 'Savings Fund' || item.account === 'Expense Fund' || item.account === 'Surplus') && (
                            <p className="receipt-text receipt__contents__middle__item__section__text">{item.expense}</p>
                          )}
                          {(item.account === 'Monthly Budget' || item.account === 'Emergency Fund' || item.account === 'Tithing' || item.account === 'Investment Fund') && (
                            <p className="receipt-text receipt__contents__middle__item__section__text">{item.description}</p>
                          )}
                        </FormSection>
                        <FormSection
                          minHeight="100%"
                          width="30%"
                          flow="row nowrap"
                          justify="flex-end"
                          padding="0"
                          textSize="1.6rem"
                          textMargin="0 1rem"
                          receiptSection={true}
                          className="receipt__contents__middle__item__section">
                          {item.account === 'Un-Allocated' && <p className="receipt-text receipt__contents__middle__item__section__text">{user.money.format(item.netIncome)}</p>}
                          {(item.account === 'Monthly Budget' ||
                            item.account === 'Emergency Fund' ||
                            item.account === 'Savings Fund' ||
                            item.account === 'Expense Fund' ||
                            item.account === 'Surplus' ||
                            item.account === 'Investment Fund' ||
                            item.account === 'Debt' ||
                            item.account === 'Tithing') && <p className="receipt-text receipt__contents__middle__item__section__text">{user.money.format(item.amount)}</p>}
                        </FormSection>
                      </FormSection>
                    );
                  })}
              </section>
              <footer className="receipt__contents__bottom">
                <p className="receipt__contents__bottom__text">Total</p>
                <p className="receipt__contents__bottom__cost">
                  {(selectedTransaction && user && !isNaN(selectedTransaction.total) && user.money.format(selectedTransaction.total)) || (user && user.money.format(0))}
                </p>
              </footer>
            </section>
          </Receipt>
        </StyledModal>
      )}

      <StyledLargeContainer borderRadius=".9rem" inBudget={true} flow="column nowrap" justify="flex-start" align="center" margin="0 0 5rem">
        <BudgetContainerHeader minHeight="5rem" justify="space-between" align="center" iconSize="2.6rem" textSize="3rem" borderRadius=".5rem .5rem 0 0">
          <i className="fas fa-receipt" />
          <p>Recent Transactions</p>
        </BudgetContainerHeader>
        {budget &&
          budget.transactions &&
          budget.transactions.recentTransactions &&
          budget.transactions.recentTransactions.map((transaction, index) => {
            return (
              <RecentTransactionElement
                theme={theme}
                recentTransaction={transaction}
                user={user}
                setUser={setUser}
                budget={budget}
                setBudget={setBudget}
                openReceipt={openReceipt}
                setOpenReceipt={setOpenReceipt}
                selectedTransaction={selectedTransaction}
                setSelectedTransaction={setSelectedTransaction}
                recentTransactionIndex={index}
                key={index}
              />
            );
          })}
      </StyledLargeContainer>
    </BudgetPageSection>
  );
};
