import axios from 'axios';
import qs from 'qs';

export const forgotPassword = async (email) => {
  if (email === '') return;
  try {
    const response = await axios({
      method: 'POST',
      url: `/Users/ForgotPassword`,
      data: qs.stringify({ forgottenEmail: email }),
    });
  } catch (error) {
    console.error(error);
  }
};
