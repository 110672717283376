import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Routes, Route, Link, useMatches, useParams, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { myCalendar } from '../../Classes/FrontEnd-Calendar';
import { BudgetCreationFormContainer, UserProfileFormContainer } from '../Global/Forms/FormContainer';
import Logo from '../Global/Logo';
import { BudgetCardContainer } from './BudgetCardContainer';
import { Form } from '../Global/Forms/Form';
import FormSection from '../Global/Forms/Section';
import { LandingFormCloser, ProfileFormCloser } from '../Global/Forms/FormCloser';
import { BudgetCreationForm } from './BudgetCreationForm';
import { LandingFormHeader } from '../Global/Forms/Header';
import { UserProfileInput } from '../Global/Forms/Input';
import { SwitchLabel } from '../Global/Forms/Label';
import { Budget } from '../../Classes/Budget';
import { ProfileContainer } from '../Global/Profile/ProfileContainer';
import { NavigationButton, PrimaryButton } from '../Global/Button';
import { BudgetNavigation } from '../Global/Navigation';
import { handleInputChange, handleTransaction } from '../Global/Forms/Algorithms/InputChange';
import { Dashboard } from '../Budget/Dashboard';
import { BudgetManagement } from '../Budget/BudgetManagement';
import { EditCategoryGoals, ManageCategories } from '../Budget/EditBudget';
import { AllocateIncome } from '../Budget/IncomeManagement';
import { TransactionPlanner } from '../Budget/Transactions/TransactionPlanner';
import { InvestmentManager } from '../Budget/Transactions/InvestmentManager';
import { DebtManager } from '../Budget/Transactions/DebtManager';
import { RecentTransactions } from '../Budget/Transactions/RecentTransactions';
import { AccountManagement } from '../Budget/AccountManagement';
import { InviteUsers } from '../Budget/InviteUsers';
import { reloadPage } from '../Utility';

const PreviewCoverPhotoContainer = styled.section`
  position: relative;
  height: 50%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transform: translate(0, 0) rotate(0);

  & div {
    position: relative;
    height: 40rem;
    width: 40rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0) rotate(0);
    border-radius: 5rem;
    background-color: ${(props) => `${props.theme.colors.grayScale.offWhite}80`};
    padding: 0.5rem;
    overflow: hidden;

    & img {
      position: relative;
      height: 100%;
      width: 100%;
      border-radius: 4.5rem;
    }
  }
`;

const BudgetContainer = styled.section`
  &.budget-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    transform: translate(0, 0) rotate(0);
    overflow-y: auto;

    & .budget-container__header {
      position: relative;
      height: 10%;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      transform: translate(0, 0) rotate(0);

      & a {
        text-decoration: none;
      }
    }
  }
`;

const StyledApplicationDisplay = styled.section`
  &.application-display {
    position: absolute;
    background-color: ${(props) => `${props.theme.colors.grayScale.offWhite}e6`};
    overflow-y: auto;
  }
`;

const StyledApplicationViewPort = styled.section`
  position: relative;
  height: 100%;
  width: 80%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transform: translate(0, 0) rotate(0);
  background-color: ${(props) => `${props.theme.colors.grayScale.offWhite}f2`};
  overflow-y: auto;

  @media ${(props) => props.theme.screens.largeTablet.landscape} {
    width: 75%;
  }

  @media ${(props) => props.theme.screens.largeTablet.portrait} {
    width: 100%;
  }

  @media ${(props) => props.theme.screens.smallTablet.landscape} {
    width: 72.5%;
  }

  @media ${(props) => props.theme.screens.smallTablet.portrait} {
    width: 100%;
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    width: 75%;
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    width: 100%;
  }

  @media ${(props) => props.theme.screens.mediumMobile.landscape} {
    width: 75%;
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    width: 100%;
  }

  @media ${(props) => props.theme.screens.smallMobile.landscape} {
    width: 75%;
  }

  @media ${(props) => props.theme.screens.smallMobile.portrait} {
    width: 100%;
  }
`;

export const Viewport = function (props) {
  const {
    theme,
    mobileNavigation,
    user,
    setUser,
    profileInformation,
    setProfileInformation,
    budget,
    setBudget,
    categoryIndex,
    setCategoryIndex,
    changeMainCategory,
    bankAccount,
    debtAmount,
    netValue,
    transaction,
    setTransaction,
    currentCategory,
    setCurrentCategory,
    errors,
    setErrors,
    disabledValues,
    setDisabledValues,
    iconObject,
    setIconObject,
  } = props;
  const [page, setPage] = useState(1);
  const [previousLocation, setPreviousLocation] = useState(useParams()['*']);
  const [coverPhoto, setCoverPhoto] = useState('');
  const [budgetId, setBudgetId] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [creatingBudget, setCreatingBudget] = useState(false);
  let navigate = useNavigate();

  const updateCoverPhoto = (event) => {
    event.preventDefault();
    const form = new FormData();
    form.append('budgetId', `${budgetId}`);
    form.append('photo', document.getElementById('photo').files[0]);

    const budgetProxy = new Budget(user);

    budgetProxy.updateCoverPhoto(form, user, budget, setBudget, navigate, setCoverPhoto);
    navigate(`/Users/${user.Id}`);
    window.location.assign(`/Users/${user._id}`);
    reloadPage();
  };

  return (
    <StyledApplicationViewPort>
      <Logo theme={theme} />
      <StyledApplicationDisplay className="application-display r__application-display">
        {user && user.communicationPreference && (
          <ProfileContainer
            theme={theme}
            mobileNavigation={mobileNavigation}
            user={user}
            setUser={setUser}
            profileInformation={profileInformation}
            setProfileInformation={setProfileInformation}
            errors={errors}
            setErrors={setErrors}
            disabledValues={disabledValues}
            setDisabledValues={setDisabledValues}
          />
        )}

        <Routes>
          <Route
            path="Update-Cover-Photo"
            element={
              <UserProfileFormContainer className="form-container r__form-container user-profile-form-container">
                <Link to={`../${previousLocation || ''}`}>
                  <ProfileFormCloser className="fas fa-window-close" data-cy="formCloserUserProfile" />
                </Link>
                <Form position="absolute" flow="column nowrap" justify="center" align="center">
                  <LandingFormHeader textColor="white" modifier="profile" className="form__header" margin="0 0 3rem 0">
                    Update Budget Cover Photo
                  </LandingFormHeader>
                  <PreviewCoverPhotoContainer>
                    <div>
                      <img src={`/DIST/CSS/Images/Budgets/${coverPhoto}`} alt="Budget Cover Photo" className="cover-image" />
                    </div>
                  </PreviewCoverPhotoContainer>
                  <FormSection minHeight="5rem" flow="row nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section">
                    <UserProfileInput
                      type="file"
                      inputType="profile-photo"
                      accept="image/*"
                      id="photo"
                      name="coverPhoto"
                      modifier="white"
                      modifierTwo="profile"
                      data-cy="coverPhoto"
                      focusTransform="translate(-32.5rem,-4.25rem) scale(0.75)"
                      onChange={(e) => handleInputChange(e, 'Update-Budget', user, setUser, errors, setErrors, disabledValues, setDisabledValues)}
                    />
                    <SwitchLabel htmlFor="photo" data-cy="photoLabel" modifier="white" hoverCursor="pointer" kind="choose-photo">
                      <i className="fas fa-camera"></i>
                      <p>Choose Photo</p>
                    </SwitchLabel>
                    <PrimaryButton size="large" modifier="white" margin="0 2rem" iconMargin="0 1rem 0 0" padding="0 1rem" onClick={(e) => updateCoverPhoto(e)} disabled={isDisabled}>
                      <i className="fas fa-camera"></i>
                      <p>Save And Update Photo</p>
                    </PrimaryButton>
                  </FormSection>
                </Form>
              </UserProfileFormContainer>
            }></Route>
          <Route
            path="Create-Budget"
            element={
              creatingBudget && (
                <BudgetCreationFormContainer className="form-container r__form-container">
                  <Link to=".." onClick={() => setBudget(new Budget(user))}>
                    <LandingFormCloser
                      onClick={() => {
                        setPage(1);
                        setCreatingBudget(false);
                      }}>
                      <i className="fas fa-window-close" data-cy="formCloser"></i>
                    </LandingFormCloser>
                  </Link>
                  <BudgetCreationForm
                    theme={theme}
                    user={user}
                    budget={budget}
                    setBudget={setBudget}
                    categoryIndex={categoryIndex}
                    setCategoryIndex={setCategoryIndex}
                    changeMainCategory={changeMainCategory}
                    page={page}
                    setPage={setPage}
                    currentCategory={currentCategory}
                    setCurrentCategory={setCurrentCategory}
                    errors={errors}
                    setErrors={setErrors}
                    disabledValues={disabledValues}
                    setDisabledValues={setDisabledValues}
                    iconObject={iconObject}
                    setIconObject={setIconObject}
                  />
                </BudgetCreationFormContainer>
              )
            }></Route>
        </Routes>
        <BudgetCardContainer
          theme={theme}
          user={user}
          setUser={setUser}
          budget={budget}
          setBudget={setBudget}
          coverPhoto={coverPhoto}
          setCoverPhoto={setCoverPhoto}
          budgetId={budgetId}
          setBudgetId={setBudgetId}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          creatingBudget={creatingBudget}
          setCreatingBudget={setCreatingBudget}
        />
      </StyledApplicationDisplay>
    </StyledApplicationViewPort>
  );
};

export const BudgetViewport = function (props) {
  const {
    theme,
    mobileNavigation,
    user,
    setUser,
    profileInformation,
    setProfileInformation,
    budget,
    setBudget,
    surplus,
    setSurplus,
    categoryIndex,
    setCategoryIndex,
    changeMainCategory,
    bankAccount,
    debtAmount,
    netValue,
    transaction,
    setTransaction,
    receiptItem,
    setReceiptItem,
    currentCategory,
    setCurrentCategory,
    errors,
    setErrors,
    disabledValues,
    setDisabledValues,
    iconObject,
    setIconObject,
  } = props;
  const [currentDate, setCurrentDate] = useState(DateTime.now().toISO());
  const [weekdays, setWeekdays] = useState(myCalendar.days);
  let [previousLocation, setPreviousLocation] = useState(useParams()['*']);

  const changeDate = (event, change, value) => {
    event.preventDefault();
    if (value === '') return;
    if (value === 'decrease' && change === 'month') {
      setCurrentDate(DateTime.fromISO(currentDate).minus({ months: 1 }).toISO());
    } else if (value === 'increase' && change === 'month') {
      setCurrentDate(DateTime.fromISO(currentDate).plus({ months: 1 }).toISO());
    } else if (change === 'day') {
      setCurrentDate(DateTime.fromISO(currentDate).set({ days: value }).toISO());
    }
  };

  useEffect(() => {
    if (previousLocation === 'Personal-Information' || previousLocation === 'Communications' || previousLocation === 'Password-Management' || previousLocation === 'Account-Management') {
      setPreviousLocation('Dashboard');
    }
  }, []);

  const matches = useMatches();
  const budgetURL = matches[0].pathname.split('/').slice(0, 6).join('/');
  return (
    <StyledApplicationViewPort>
      <Logo theme={theme} />
      <StyledApplicationDisplay className="application-display r__application-display">
        {user && user.communicationPreference && (
          <ProfileContainer
            theme={theme}
            mobileNavigation={mobileNavigation}
            user={user}
            setUser={setUser}
            profileInformation={profileInformation}
            setProfileInformation={setProfileInformation}
            errors={errors}
            setErrors={setErrors}
            disabledValues={disabledValues}
            setDisabledValues={setDisabledValues}
            previousLocation={previousLocation}
            setPreviousLocation={setPreviousLocation}
          />
        )}

        <BudgetContainer className="budget-container column_1-20 row_1-16">
          <div className="budget-container__header">
            <Link to={`${budgetURL}/Navigation`}>
              <NavigationButton size="large long" className="navigation-button">
                <div className="navigation-button__icon"></div>
                {budget && budget.name && <p>{budget.name}</p>}
              </NavigationButton>
            </Link>
          </div>
          <Routes>
            <Route
              path={`Navigation/*`}
              element={
                <BudgetNavigation theme={theme} user={user} budget={budget} previousLocation={previousLocation} setPreviousLocation={setPreviousLocation} errors={errors} setErrors={setErrors} />
              }
            />
            <Route
              path="Dashboard"
              element={
                <Dashboard
                  theme={theme}
                  user={user}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  receiptItem={receiptItem}
                  setReceiptItem={setReceiptItem}
                  errors={errors}
                  setErrors={setErrors}
                  bankAccount={bankAccount}
                  debtAmount={debtAmount}
                  netValue={netValue}
                  currentDate={currentDate}
                  setCurrentDate={setCurrentDate}
                  changeDate={changeDate}
                  changeMainCategory={changeMainCategory}
                  weekdays={weekdays}
                  setWeekdays={setWeekdays}
                  categoryIndex={categoryIndex}
                  setCategoryIndex={setCategoryIndex}
                />
              }
            />
            <Route
              path={`Budget-Management`}
              element={
                budget &&
                budget.accounts && (
                  <BudgetManagement
                    theme={theme}
                    user={user}
                    setUser={setUser}
                    budget={budget}
                    setBudget={setBudget}
                    errors={errors}
                    setErrors={setErrors}
                    disabledValues={disabledValues}
                    setDisabledValues={setDisabledValues}
                  />
                )
              }
            />
            <Route
              path={`Edit-Category-Goals`}
              element={
                <EditCategoryGoals
                  theme={theme}
                  user={user}
                  budget={budget}
                  setBudget={setBudget}
                  categoryIndex={categoryIndex}
                  changeMainCategory={changeMainCategory}
                  currentCategory={currentCategory}
                  setCurrentCategory={setCurrentCategory}
                  handleInputChange={handleInputChange}
                  errors={errors}
                  setErrors={setErrors}
                  disabledValues={disabledValues}
                  setDisabledValues={setDisabledValues}
                />
              }
            />
            <Route
              path={`Manage-Categories`}
              element={
                <ManageCategories
                  theme={theme}
                  user={user}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  categoryIndex={categoryIndex}
                  setCategoryIndex={setCategoryIndex}
                  iconObject={iconObject}
                  setIconObject={setIconObject}
                  changeMainCategory={changeMainCategory}
                />
              }
            />
            <Route
              path={`Allocate-Income`}
              element={
                <AllocateIncome
                  theme={theme}
                  user={user}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  handleTransaction={handleTransaction}
                  errors={errors}
                  setErrors={setErrors}
                  disabledValues={disabledValues}
                  setDisabledValues={setDisabledValues}
                />
              }
            />
            <Route
              path={`Transaction-Planner`}
              element={
                <TransactionPlanner
                  theme={theme}
                  user={user}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  surplus={surplus}
                  setSurplus={setSurplus}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  handleInputChange={handleInputChange}
                  errors={errors}
                  setErrors={setErrors}
                  disabledValues={disabledValues}
                  setDisabledValues={setDisabledValues}
                />
              }
            />
            <Route
              path={`Investment-Manager`}
              element={
                <InvestmentManager
                  theme={theme}
                  user={user}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  handleInputChange={handleInputChange}
                  errors={errors}
                  setErrors={setErrors}
                  disabledValues={disabledValues}
                  setDisabledValues={setDisabledValues}
                />
              }
            />
            <Route
              path={`Debt-Manager`}
              element={
                <DebtManager
                  theme={theme}
                  user={user}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  handleInputChange={handleInputChange}
                  errors={errors}
                  setErrors={setErrors}
                  disabledValues={disabledValues}
                  setDisabledValues={setDisabledValues}
                />
              }
            />
            <Route
              path={`Recent-Transactions`}
              element={
                <RecentTransactions
                  theme={theme}
                  user={user}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  handleInputChange={handleInputChange}
                  errors={errors}
                  setErrors={setErrors}
                  disabledValues={disabledValues}
                  setDisabledValues={setDisabledValues}
                />
              }
            />
            <Route
              path={`Budget-Account-Management`}
              element={
                <AccountManagement
                  theme={theme}
                  user={user}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  handleInputChange={handleInputChange}
                  errors={errors}
                  setErrors={setErrors}
                  disabledValues={disabledValues}
                  setDisabledValues={setDisabledValues}
                />
              }
            />
            <Route
              path={`Invite-Users`}
              element={
                <InviteUsers
                  theme={theme}
                  user={user}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  handleInputChange={handleInputChange}
                  errors={errors}
                  setErrors={setErrors}
                  disabledValues={disabledValues}
                  setDisabledValues={setDisabledValues}
                />
              }
            />
          </Routes>
        </BudgetContainer>
      </StyledApplicationDisplay>
    </StyledApplicationViewPort>
  );
};
