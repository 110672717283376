import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import chroma from 'chroma-js';
import { getIndividualPayment } from './BudgetUtility';

const StyledTransactionContainer = styled.section`
  &.transaction-day {
    position: relative;
    height: max-content;
    width: 100%;
    display: flex;
    flex-flow: ${(props) => props.flow};
    justify-content: ${(props) => props.justify};
    align-items: ${(props) => props.align};
    padding: 2rem 0;
    border-bottom: 0.3rem solid ${(props) => props.theme.colors.primaryColor};

    & .transaction-day__date-header {
      font-family: ${(props) => props.theme.text.secondary};
      font-size: 3rem;
      color: ${(props) => chroma(props.theme.colors.primaryColor).darken(0.4).hex()};
    }

    & .transaction-day__transaction-item {
      position: relative;
      height: max-content;
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-evenly;
      align-items: center;

      & .transaction-day__transaction-item__headers {
        position: relative;
        height: max-content;
        width: 100%;
        min-width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0.5rem;

        & .transaction-day__transaction-item__headers__header {
          position: relative;
          height: 3rem;
          width: ${`${100 / 6}%`};
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          background-color: ${(props) => props.theme.colors.primaryColor};
          font-family: ${(props) => props.theme.text.secondary};
          & h5 {
            font-size: 1.6rem;
            color: ${(props) => props.theme.colors.grayScale.offWhite};
            text-align: center;
          }

          ${(props) => (props.debt === true ? `width: ${100 / 7}%` : null)};
        }
      }

      & .transaction-day__transaction-item__values {
        position: relative;
        height: max-content;
        width: 100%;
        min-width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0.5rem;

        & .transaction-day__transaction-item__values__item {
          position: relative;
          height: 3rem;
          width: ${`${100 / 6}%`};
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          font-family: ${(props) => props.theme.text.secondary};
          & .transaction-day__transaction-item__values__item__value {
            font-size: 1.3rem;
            color: ${(props) => props.theme.colors.primaryColor};
            text-align: center;
            &.negative {
              color: ${(props) => props.theme.colors.error};
            }
          }
          ${(props) => (props.debt === true ? `width: ${100 / 7}%` : null)};
        }
      }
    }

    & p {
      font-family: ${(props) => props.theme.text.secondary};
      font-size: 1.6rem;
      color: ${(props) => `${props.theme.colors.primaryColor}cc`};
      margin: 0.25rem 0;
    }
  }
`;

export const TransactionContainer = function (props) {
  const { theme, user, transaction, budget, content } = props;

  const areThereAny = (transactionArray, date) => {
    let outcome = false;
    transactionArray.forEach((transaction) => {
      transaction.timingOptions.paymentSchedule.forEach((payment) => {
        if (
          DateTime.local(DateTime.fromISO(payment).year, DateTime.fromISO(payment).month, DateTime.fromISO(payment).day).toISO() ===
          DateTime.local(DateTime.fromISO(date).year, DateTime.fromISO(date).month, DateTime.fromISO(date).day).toISO()
        ) {
          outcome = true;
        }
      });
    });
    return outcome;
  };

  return (
    transaction &&
    DateTime.fromISO(transaction.date).invalid === null && (
      <StyledTransactionContainer className="transaction-day" flow={content.flow} justify={content.justify} align={content.align} data-date={DateTime.fromISO(transaction.date).toBSON()}>
        <header className="transaction-day__date-header">{DateTime.fromISO(transaction.date).toLocaleString(DateTime.DATE_HUGE)}</header>
        {budget && budget.transactions && areThereAny(budget.transactions.plannedTransactions, transaction.date) ? (
          budget.transactions.plannedTransactions.map((budgetTransaction, i) => {
            return budgetTransaction.timingOptions.paymentSchedule.map((payment, index) => {
              return (
                DateTime.local(DateTime.fromISO(payment).year, DateTime.fromISO(payment).month, DateTime.fromISO(payment).day).toISO() ===
                  DateTime.local(DateTime.fromISO(transaction.date).year, DateTime.fromISO(transaction.date).month, DateTime.fromISO(transaction.date).day).toISO() && (
                  <section className="transaction-day__transaction-item" key={index}>
                    <header className="transaction-day__transaction-item__headers">
                      {budgetTransaction.account === 'Monthly Budget' &&
                        ['Account', 'Main Category', 'Sub Category', 'Due Date', 'Amount', 'Paid?'].map((heading, index) => {
                          return (
                            <div className="transaction-day__transaction-item__headers__header" key={index}>
                              <h5 className="transaction-day__transaction-item__headers__header__heading">{heading}</h5>
                            </div>
                          );
                        })}
                      {budgetTransaction.account === 'Expense Fund' &&
                        ['Account', 'Type', 'Item', 'Due Date', 'Amount', 'Paid?'].map((heading, index) => {
                          return (
                            <div className="transaction-day__transaction-item__headers__header" key={index}>
                              <h5 className="transaction-day__transaction-item__headers__header__heading">{heading}</h5>
                            </div>
                          );
                        })}
                      {budgetTransaction.account === 'Savings Fund' &&
                        ['Account', 'Type', 'Item', 'Due Date', 'Amount', 'Paid?'].map((heading, index) => {
                          return (
                            <div className="transaction-day__transaction-item__headers__header" key={index}>
                              <h5 className="transaction-day__transaction-item__headers__header__heading">{heading}</h5>
                            </div>
                          );
                        })}
                      {budgetTransaction.account === 'Surplus' &&
                        ['Account', 'Type', 'Item', 'Due Date', 'Amount', 'Paid?'].map((heading, index) => {
                          return (
                            <div className="transaction-day__transaction-item__headers__header" key={index}>
                              <h5 className="transaction-day__transaction-item__headers__header__heading">{heading}</h5>
                            </div>
                          );
                        })}
                      {budgetTransaction.account === 'Debt' &&
                        ['Account', 'Lender', 'Type', 'Item', 'Due Date', 'Amount', 'Paid?'].map((heading, index) => {
                          return (
                            <div className="transaction-day__transaction-item__headers__header" debt={+true} key={index}>
                              <h5 className="transaction-day__transaction-item__headers__header__heading">{heading}</h5>
                            </div>
                          );
                        })}
                    </header>
                    <section className="transaction-day__transaction-item__values">
                      {budgetTransaction.account === 'Monthly Budget' &&
                        [
                          budgetTransaction.account,
                          budgetTransaction.category,
                          budgetTransaction.subCategory,
                          DateTime.fromISO(transaction.date).toLocaleString(DateTime.DATE_FULL),
                          user.money.format(budgetTransaction.payment),
                          budgetTransaction.paidStatus,
                        ].map((heading, index) => {
                          return (
                            <div className="transaction-day__transaction-item__values__item" key={index}>
                              {index !== 3 && <h5 className="transaction-day__transaction-item__values__item__value">{heading}</h5>}
                              {index === 3 && (
                                <h5
                                  className={`${
                                    transaction.date < DateTime.now().toISO()
                                      ? 'transaction-day__transaction-item__values__item__value negative'
                                      : 'transaction-day__transaction-item__values__item__value'
                                  }`}>
                                  {transaction.date < DateTime.now().toISO() ? 'OVERDUE' : heading}
                                </h5>
                              )}
                            </div>
                          );
                        })}
                      {budgetTransaction.account === 'Expense Fund' &&
                        [
                          budgetTransaction.account,
                          budgetTransaction.subAccount,
                          budgetTransaction.name,
                          DateTime.fromISO(transaction.date).toLocaleString(DateTime.DATE_FULL),
                          user.money.format(budgetTransaction.payment),
                          budgetTransaction.paidStatus,
                        ].map((heading, index) => {
                          return (
                            <div className="transaction-day__transaction-item__values__item" key={index}>
                              {index !== 3 && <h5 className="transaction-day__transaction-item__values__item__value">{heading}</h5>}
                              {index === 3 && (
                                <h5
                                  className={`${
                                    transaction.date < DateTime.now().toISO()
                                      ? 'transaction-day__transaction-item__values__item__value negative'
                                      : 'transaction-day__transaction-item__values__item__value'
                                  }`}>
                                  {transaction.date < DateTime.now().toISO() ? 'OVERDUE' : heading}
                                </h5>
                              )}
                            </div>
                          );
                        })}
                      {budgetTransaction.account === 'Savings Fund' &&
                        [
                          budgetTransaction.account,
                          budgetTransaction.subAccount,
                          budgetTransaction.name,
                          DateTime.fromISO(transaction.date).toLocaleString(DateTime.DATE_FULL),
                          user.money.format(budgetTransaction.payment),
                          budgetTransaction.paidStatus,
                        ].map((heading, index) => {
                          return (
                            <div className="transaction-day__transaction-item__values__item" key={index}>
                              {index !== 3 && <h5 className="transaction-day__transaction-item__values__item__value">{heading}</h5>}
                              {index === 3 && (
                                <h5
                                  className={`${
                                    transaction.date < DateTime.now().toISO()
                                      ? 'transaction-day__transaction-item__values__item__value negative'
                                      : 'transaction-day__transaction-item__values__item__value'
                                  }`}>
                                  {transaction.date < DateTime.now().toISO() ? 'OVERDUE' : heading}
                                </h5>
                              )}
                            </div>
                          );
                        })}
                      {budgetTransaction.account === 'Surplus' &&
                        [
                          budgetTransaction.account,
                          budgetTransaction.subAccount,
                          budgetTransaction.name,
                          DateTime.fromISO(transaction.date).toLocaleString(DateTime.DATE_FULL),
                          user.money.format(budgetTransaction.payment),
                          budgetTransaction.paidStatus,
                        ].map((heading, index) => {
                          return (
                            <div className="transaction-day__transaction-item__values__item" key={index}>
                              {index !== 3 && <h5 className="transaction-day__transaction-item__values__item__value">{heading}</h5>}
                              {index === 3 && (
                                <h5
                                  className={`${
                                    transaction.date < DateTime.now().toISO()
                                      ? 'transaction-day__transaction-item__values__item__value negative'
                                      : 'transaction-day__transaction-item__values__item__value'
                                  }`}>
                                  {transaction.date < DateTime.now().toISO() ? 'OVERDUE' : heading}
                                </h5>
                              )}
                            </div>
                          );
                        })}
                      {budgetTransaction.account === 'Debt' &&
                        [
                          budgetTransaction.account,
                          budgetTransaction.lender,
                          budgetTransaction.subAccount,
                          budgetTransaction.name,
                          DateTime.fromISO(transaction.date).toLocaleString(DateTime.DATE_FULL),
                          user.money.format(budgetTransaction.payment),
                          budgetTransaction.paidStatus,
                        ].map((heading, index) => {
                          return (
                            <div className="transaction-day__transaction-item__values__item" debt={+true} key={index}>
                              {index !== 4 && <h5 className="transaction-day__transaction-item__values__item__value">{heading}</h5>}
                              {index === 4 && (
                                <h5
                                  className={`${
                                    transaction.date < DateTime.now().toISO()
                                      ? 'transaction-day__transaction-item__values__item__value negative'
                                      : 'transaction-day__transaction-item__values__item__value'
                                  }`}>
                                  {transaction.date < DateTime.now().toISO() ? 'OVERDUE' : heading}
                                </h5>
                              )}
                            </div>
                          );
                        })}
                    </section>
                  </section>
                )
              );
            });
          })
        ) : (
          <p>No Upcoming Transactions</p>
        )}
      </StyledTransactionContainer>
    )
  );
};
