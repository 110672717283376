import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { BudgetPageSection } from '../Global/Forms/Section';
import { BudgetContainerHeader } from '../Global/Forms/Header';
import { handleInputChange } from '../Global/Forms/Algorithms/InputChange';
import { ExtraSmallContainer } from '../Global/Budget/BudgetContainers/ExtraSmallContainer';
import { Form } from '../Global/Forms/Form';
import FormSection from '../Global/Forms/Section';
import { SubCategoryGoalInput, CreateBudgetFormInput } from '../Global/Forms/Input';
import { EmergencyRadio, TithingRadio } from '../Global/Forms/Radio';
import { CreateBudgetSelect } from '../Global/Forms/Select';
import { SmallContainerLabel, EmeregencyLabel, TithingLabel } from '../Global/Forms/Label';
import { PrimaryButton } from '../Global/Button';
import { Budget } from '../../Classes/Budget';
import ErrorContainer from '../Global/Forms/Errors';

export const BudgetManagement = function (props) {
  const { theme, user, setUser, budget, setBudget, errors, setErrors, disabledValues, setDisabledValues } = props;
  const navigate = useNavigate();
  return (
    <BudgetPageSection minHeight="85%" flow="row wrap" justify="space-evenly" align="center">
      <BudgetContainerHeader budgetPage={true} height="10rem" justify="center" bgColor={'transparent'} textColor={theme.colors.tertiaryColor} className="budget-page-header">
        Budget Management
      </BudgetContainerHeader>
      <ErrorContainer>
        {errors && (
          <ol>
            {Object.keys(errors).map((error, index) => {
              if (errors[error] !== '') {
                return (
                  <li className="error" key={index}>
                    {errors[error]}
                  </li>
                );
              }
            })}
          </ol>
        )}
      </ErrorContainer>
      <ExtraSmallContainer
        theme={theme}
        user={user}
        budget={budget}
        title={`Edit Budget Name`}
        icon={`pencil`}
        content={{
          content: (
            <Form inBudget={true} formHeight="20rem" bgColor={`${theme.colors.grayScale.offWhite}f2`} flow="column nowrap" justify="center" align="center">
              <FormSection flow="column-reverse nowrap" justify="center" align="center" margin="1.5rem 0">
                <SubCategoryGoalInput
                  type="text"
                  placeholder="New Budget Name"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="80%"
                  focusTransform="translate(-3rem, -5.25rem) scale(.75)"
                  modifier="purple-transform"
                  id="budgetName"
                  name="name"
                  value={(budget && budget.name && budget.name) || ''}
                  minLength={4}
                  maxLength={40}
                  onChange={(e) => handleInputChange(e, 'Update Budget', budget, setBudget, errors, setErrors)}
                />
                <SmallContainerLabel height="4rem" width="80%" fromLeft="4rem" textColor={`${theme.colors.grayScale.offWhite}cc`} htmlFor="budgetName">
                  New Budget Name
                </SmallContainerLabel>
              </FormSection>
              <PrimaryButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  budget.updateBudget(
                    {
                      budgetId: budget._id,
                      userId: user._id,
                      lastUpdated: DateTime.now().toISO(),
                      currentMonth: budget.currentMonth,
                      previousMonth: budget.previousMonth || budget.currentMonth,
                      name: budget.name,
                    },
                    'Budget-Management'
                  );
                }}
                disabled={user && user.permissions === 'Associate'}>
                Update
              </PrimaryButton>
            </Form>
          ),
          margin: '0 2rem 2rem',
          bgColor: theme.colors.grayScale.offWhite,
        }}
      />
      <ExtraSmallContainer
        theme={theme}
        user={user}
        budget={budget}
        title={`Edit Savings Goal`}
        icon={`pencil`}
        content={{
          content: (
            <Form inBudget={true} formHeight="20rem" bgColor={`${theme.colors.grayScale.offWhite}f2`} flow="column nowrap" justify="center" align="center">
              <FormSection flow="column-reverse nowrap" justify="center" align="center" margin="1.5rem 0">
                <SubCategoryGoalInput
                  type="number"
                  placeholder="New Savings Goal"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="80%"
                  focusTransform="translate(-2.6rem, -5.25rem) scale(.75)"
                  modifier="purple-transform"
                  id="savingsGoal"
                  name="savingsGoal"
                  min={0}
                  value={(budget && budget.accounts && budget.accounts.savingsFund.savingsGoal) || ''}
                  onChange={(e) => handleInputChange(e, 'Update Budget', budget, setBudget, errors, setErrors)}
                />
                <SmallContainerLabel height="4rem" width="80%" fromLeft="4rem" textColor={`${theme.colors.grayScale.offWhite}cc`} htmlFor="savingsGoal">
                  Savings Goal
                </SmallContainerLabel>
              </FormSection>
              <FormSection flow="column-reverse nowrap" justify="center" align="center" margin="1.5rem 0">
                <SubCategoryGoalInput
                  type="number"
                  placeholder="New Savings Percentage"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="80%"
                  focusTransform="translate(-3.5rem, -5.25rem) scale(.75)"
                  modifier="purple-transform"
                  id="savingsPercentage"
                  name="savingsPercentage"
                  min={0}
                  max={100}
                  defaultValue={(budget && budget.accounts && budget.accounts.savingsFund.savingsPercentage * 100) || ''}
                  onChange={(e) => handleInputChange(e, 'Update Budget', budget, setBudget, errors, setErrors)}
                />
                <SmallContainerLabel height="4rem" width="80%" fromLeft="4rem" textColor={`${theme.colors.grayScale.offWhite}cc`} htmlFor="savingsPercentage">
                  Savings Percentage
                </SmallContainerLabel>
              </FormSection>
              <PrimaryButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  let updateObject = {
                    budgetId: budget._id,
                    userId: user._id,
                    lastUpdated: DateTime.now().toISO(),
                    currentMonth: budget.currentMonth,
                    previousMonth: budget.previousMonth || budget.currentMonth,
                    accounts: {
                      unAllocated: budget.accounts.unAllocated,
                      monthlyBudget: budget.accounts.monthlyBudget,
                      emergencyFund: budget.accounts.emergencyFund,
                      savingsFund: {
                        savingsGoal: budget.accounts.savingsFund.savingsGoal,
                        savingsPercentage: Number(budget.accounts.savingsFund.savingsPercentage / 100),
                        amount: {
                          total: budget.accounts.savingsFund.amount.total,
                          used: budget.accounts.savingsFund.amount.used,
                          unUsed: budget.accounts.savingsFund.amount.unUsed,
                        },
                      },
                      expenseFund: budget.accounts.expenseFund,
                      surplus: budget.accounts.surplus,
                      investmentFund: budget.accounts.investmentFund,
                      debt: budget.accounts.debt,
                    },
                  };
                  if (user.latterDaySaint === true) {
                    updateObject.accounts.tithing = budget.accounts.tithing;
                  }
                  budget.updateBudget(updateObject, 'Budget-Management');
                }}
                disabled={user && user.permissions === 'Associate'}>
                Update
              </PrimaryButton>
            </Form>
          ),
          margin: '0 2rem 2rem',
          bgColor: theme.colors.grayScale.offWhite,
        }}
      />
      <ExtraSmallContainer
        theme={theme}
        user={user}
        budget={budget}
        title={`Edit Investment Goal`}
        icon={`pencil`}
        content={{
          content: (
            <Form inBudget={true} formHeight="20rem" bgColor={`${theme.colors.grayScale.offWhite}f2`} flow="column nowrap" justify="center" align="center">
              <FormSection flow="column-reverse nowrap" justify="center" align="center" margin="1.5rem 0">
                <SubCategoryGoalInput
                  type="number"
                  placeholder="New Investment Goal"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="80%"
                  focusTransform="translate(-2.6rem, -5.25rem) scale(.75)"
                  modifier="purple-transform"
                  id="investmentGoal"
                  name="investmentGoal"
                  min={0}
                  value={(budget && budget.accounts && budget.accounts.investmentFund.investmentGoal) || ''}
                  onChange={(e) => handleInputChange(e, 'Update Budget', budget, setBudget, errors, setErrors)}
                />
                <SmallContainerLabel height="4rem" width="80%" fromLeft="4rem" textColor={`${theme.colors.grayScale.offWhite}cc`} htmlFor="investmentGoal">
                  Investment Goal
                </SmallContainerLabel>
              </FormSection>
              <FormSection flow="column-reverse nowrap" justify="center" align="center" margin="1.5rem 0">
                <SubCategoryGoalInput
                  type="number"
                  placeholder="New Investment Percentage"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="80%"
                  focusTransform="translate(-3.5rem, -5.25rem) scale(.75)"
                  modifier="purple-transform"
                  id="investmentPercentage"
                  name="investmentPercentage"
                  min={0}
                  max={100}
                  defaultValue={(budget && budget.accounts && budget.accounts.investmentFund.investmentPercentage * 100) || ''}
                  onChange={(e) => handleInputChange(e, 'Update Budget', budget, setBudget, errors, setErrors)}
                />
                <SmallContainerLabel height="4rem" width="80%" fromLeft="4rem" textColor={`${theme.colors.grayScale.offWhite}cc`} htmlFor="investmentPercentage">
                  Investment Percentage
                </SmallContainerLabel>
              </FormSection>
              <PrimaryButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  let updateObject = {
                    budgetId: budget._id,
                    userId: user._id,
                    lastUpdated: DateTime.now().toISO(),
                    currentMonth: budget.currentMonth,
                    previousMonth: budget.previousMonth || budget.currentMonth,
                    accounts: {
                      unAllocated: budget.accounts.unAllocated,
                      monthlyBudget: budget.accounts.monthlyBudget,
                      emergencyFund: budget.accounts.emergencyFund,
                      savingsFund: budget.accounts.savingsFund,
                      expenseFund: budget.accounts.expenseFund,
                      surplus: budget.accounts.surplus,
                      investmentFund: {
                        investmentGoal: budget.accounts.investmentFund.investmentGoal,
                        investmentPercentage: Number(budget.accounts.investmentFund.investmentPercentage / 100),
                        amount: budget.accounts.investmentFund.amount,
                        investedAmount: budget.accounts.investmentFund.investedAmount,
                      },
                      debt: budget.accounts.debt,
                    },
                  };
                  if (user.latterDaySaint === true) {
                    updateObject.accounts.tithing = budget.accounts.tithing;
                  }
                  budget.updateBudget(updateObject, 'Budget-Management');
                }}
                disabled={user && user.permissions === 'Associate'}>
                Update
              </PrimaryButton>
            </Form>
          ),
          margin: '0 2rem 2rem',
          bgColor: theme.colors.grayScale.offWhite,
        }}
      />
      <ExtraSmallContainer
        theme={theme}
        user={user}
        budget={budget}
        title={`Edit Emergency Fund Goal`}
        icon={`pencil`}
        content={{
          content: (
            <Form inBudget={true} formHeight="20rem" bgColor={`${theme.colors.grayScale.offWhite}f2`} flow="column nowrap" justify="center" align="center">
              <FormSection flow="row nowrap" justify="center" align="center" margin="1rem 0">
                <EmeregencyLabel position="absolute" transform="translate(-1rem, -3.5rem) scale(.75)">
                  Update Emergency Setting
                </EmeregencyLabel>
                <EmergencyRadio
                  type="radio"
                  className="lengthOfTime"
                  id="lengthOfTime"
                  name="emergencySetting"
                  height="5rem"
                  width="40%"
                  value={'Length Of Time' || ''}
                  checked={budget && budget.accounts && budget.accounts.emergencyFund.emergencyGoalMeasurement === 'Length Of Time'}
                  onChange={(e) => handleInputChange(e, 'Update Budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues)}
                />
                <EmeregencyLabel htmlFor="lengthOfTime" className="lengthOfTimeLabel" inBudget={true} textSize="1.6rem">
                  Length Of Time
                </EmeregencyLabel>
                <EmergencyRadio
                  type="radio"
                  name="emergencySetting"
                  className="totalAmount"
                  id="totalAmount"
                  height="5rem"
                  width="40%"
                  value={'Total Amount' || ''}
                  checked={budget && budget.accounts && budget.accounts.emergencyFund.emergencyGoalMeasurement === 'Total Amount'}
                  onChange={(e) => handleInputChange(e, 'Update Budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues)}
                />
                <EmeregencyLabel htmlFor="totalAmount" className="totalAmountLabel" inBudget={true} textSize="1.6rem">
                  Total Amount
                </EmeregencyLabel>
              </FormSection>
              {budget && budget.accounts && budget.accounts.emergencyFund.emergencyGoalMeasurement === 'Total Amount' && (
                <FormSection flow="column-reverse nowrap" justify="center" align="center" margin="1rem 0">
                  <SubCategoryGoalInput
                    type="number"
                    placeholder="New Emergency Goal"
                    minHeight="4rem"
                    maxHeight="4rem"
                    width="80%"
                    focusTransform="translate(-2.6rem, -5.25rem) scale(.75)"
                    modifier="purple-transform"
                    id="emergencyGoalTotal"
                    name="emergencyGoalTotal"
                    min={0}
                    value={(budget && budget.accounts && budget.accounts.emergencyFund.emergencyFundGoal) || ''}
                    onChange={(e) => handleInputChange(e, 'Update Budget', budget, setBudget, errors, setErrors)}
                  />
                  <SmallContainerLabel height="4rem" width="80%" fromLeft="4rem" textColor={`${theme.colors.grayScale.offWhite}cc`} htmlFor="emergencyGoalTotal">
                    Emergency Goal
                  </SmallContainerLabel>
                </FormSection>
              )}
              {budget && budget.accounts && budget.accounts.emergencyFund.emergencyGoalMeasurement === 'Length Of Time' && (
                <FormSection flow="row nowrap" justify="center" align="center" margin="1rem 0">
                  <CreateBudgetFormInput
                    type="number"
                    inBudget={true}
                    minHeight="4rem"
                    inputHeight="4rem"
                    minWidth="40%"
                    inputWidth="40%"
                    borderRadius=".5rem 0 0 .5rem"
                    id="emergencyGoalTime"
                    name="emergencyGoalTime"
                    placeholder={`Enter Goal Time`}
                    value={(budget && budget.accounts && budget.accounts.emergencyFund.emergencyFundGoal) || 0}
                    onChange={(e) => handleInputChange(e, 'Update Budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues)}
                  />
                  <CreateBudgetSelect
                    id="emergencyGoalTiming"
                    name="emergencyGoalTiming"
                    data-cy="emergencyGoalTiming"
                    minHeight="4rem"
                    width="40%"
                    minWidth="40%"
                    borderRadius="0 .5rem .5rem 0"
                    value={'Months' || ''}
                    onChange={(e) => handleInputChange(e, 'Update Budget', budget, setBudget)}>
                    {[`Months`, `Years`].map((option, index) => {
                      return (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </CreateBudgetSelect>
                </FormSection>
              )}

              <PrimaryButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  let updateObject = {
                    budgetId: budget._id,
                    userId: user._id,
                    lastUpdated: DateTime.now().toISO(),
                    currentMonth: budget.currentMonth,
                    previousMonth: budget.previousMonth || budget.currentMonth,
                    accounts: {
                      unAllocated: budget.accounts.unAllocated,
                      monthlyBudget: budget.accounts.monthlyBudget,
                      emergencyFund: {
                        amount: budget.accounts.emergencyFund.amount,
                        emergencyFundGoal: budget.accounts.emergencyFund.emergencyFundGoal,
                        emergencyGoalMeasurement: budget.accounts.emergencyFund.emergencyGoalMeasurement,
                      },
                      savingsFund: budget.accounts.savingsFund,
                      expenseFund: budget.accounts.expenseFund,
                      surplus: budget.accounts.surplus,
                      investmentFund: budget.accounts.investmentFund,
                      debt: budget.accounts.debt,
                    },
                  };
                  if (budget.accounts.emergencyFund.emergencyGoalMeasurement === 'Length Of Time') {
                    updateObject.accounts.emergencyFund.emergencyFundGoalTiming = budget.accounts.emergencyFund.emergencyFundGoalTiming;
                  }
                  if (user.latterDaySaint === true) {
                    updateObject.accounts.tithing = budget.accounts.tithing;
                  }
                  budget.updateBudget(updateObject, 'Budget-Management');
                }}
                disabled={user && user.permissions === 'Associate'}>
                Update
              </PrimaryButton>
            </Form>
          ),
          margin: '0 2rem 2rem',
          bgColor: theme.colors.grayScale.offWhite,
        }}
      />
      {user && user.latterDaySaint === true && (
        <ExtraSmallContainer
          theme={theme}
          user={user}
          budget={budget}
          title={`Edit Tithing Setting`}
          icon={`pencil`}
          content={{
            content: (
              <Form inBudget={true} formHeight="20rem" bgColor={`${theme.colors.grayScale.offWhite}f2`} flow="column nowrap" justify="center" align="center">
                <TithingLabel position="absolute" transform="translate(0rem, -6.5rem)" textSize="1.6rem" inBudget={true}>
                  What will you be paying tithing on?
                </TithingLabel>
                <FormSection flow="row nowrap" justify="center" align="center" margin="1rem 0">
                  <TithingRadio
                    type="radio"
                    name="tithingSetting"
                    className="grossOption"
                    id="grossOption"
                    height="6rem"
                    width="30%"
                    value={'Gross' || ''}
                    checked={budget && budget.accounts && budget.accounts.tithing.tithingSetting === 'Gross'}
                    onChange={(e) => handleInputChange(e, 'Update Budget', budget, setBudget)}
                  />
                  <TithingLabel className="grossOptionLabel" htmlFor="grossOption">
                    Gross
                  </TithingLabel>

                  <TithingRadio
                    type="radio"
                    name="tithingSetting"
                    className="netOption"
                    id="netOption"
                    height="6rem"
                    width="30%"
                    value={'Net' || ''}
                    checked={budget && budget.accounts && budget.accounts.tithing.tithingSetting === 'Net'}
                    onChange={(e) => handleInputChange(e, 'Update Budget', budget, setBudget)}
                  />
                  <TithingLabel className="netOptionLabel" htmlFor="netOption">
                    Net
                  </TithingLabel>
                  <TithingRadio
                    type="radio"
                    name="tithingSetting"
                    className="surplusOption"
                    id="surplusOption"
                    height="6rem"
                    width="30%"
                    value={'Surplus' || ''}
                    checked={budget && budget.accounts && budget.accounts.tithing.tithingSetting === 'Surplus'}
                    onChange={(e) => handleInputChange(e, 'Update Budget', budget, setBudget)}
                  />
                  <TithingLabel className="surplusOptionLabel" htmlFor="surplusOption" data-cy="surplusTithingLabel">
                    Surplus
                  </TithingLabel>
                </FormSection>
                <PrimaryButton
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    let updateObject = {
                      budgetId: budget._id,
                      userId: user._id,
                      lastUpdated: DateTime.now().toISO(),
                      currentMonth: budget.currentMonth,
                      previousMonth: budget.previousMonth || budget.currentMonth,
                      accounts: {
                        unAllocated: budget.accounts.unAllocated,
                        monthlyBudget: budget.accounts.monthlyBudget,
                        emergencyFund: budget.accounts.emergencyFund,
                        savingsFund: budget.accounts.savingsFund,
                        expenseFund: budget.accounts.expenseFund,
                        surplus: budget.accounts.surplus,
                        investmentFund: budget.accounts.investmentFund,
                        debt: budget.accounts.debt,
                        tithing: {
                          tithingSetting: budget.accounts.tithing.tithingSetting,
                          amount: budget.accounts.tithing.amount,
                          amountOwed: budget.accounts.tithing.amountOwed,
                        },
                      },
                    };
                    budget.updateBudget(updateObject, 'Budget-Management');
                  }}
                  disabled={user && user.permissions === 'Associate'}>
                  Update
                </PrimaryButton>
              </Form>
            ),
            margin: '0 2rem 2rem',
            bgColor: theme.colors.grayScale.offWhite,
          }}
        />
      )}

      <ExtraSmallContainer
        theme={theme}
        user={user}
        budget={budget}
        title={`Exit or Delete Budget`}
        icon={`pencil`}
        content={{
          content: (
            <FormSection flow="column nowrap" justify="center" align="center" margin="1.5rem 0">
              <PrimaryButton size="medium" margin="1rem 0" onClick={() => new Budget().exitBudget(navigate, user)}>
                Exit Budget
              </PrimaryButton>
              <PrimaryButton size="medium" margin="1rem 0" onClick={() => new Budget().deleteBudget(budget, user)} disabled={user && user.permissions === 'Associate'}>
                Delete Budget
              </PrimaryButton>
            </FormSection>
          ),
          margin: '0 2rem 2rem',
          bgColor: theme.colors.grayScale.offWhite,
        }}
      />
    </BudgetPageSection>
  );
};
