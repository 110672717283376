import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import chroma from 'chroma-js';
import { PrimaryButton } from '../Button';
import { handleTransaction } from '../Forms/Algorithms/InputChange';

const StyledDebt = styled.section`
  &.debt {
    position: relative;
    height: max-content;
    min-height: 10rem;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
    border: 0.2rem solid ${(props) => props.theme.colors.primaryColor};
    margin-bottom: 1rem;
    font-family: ${(props) => props.theme.text.secondary};

    & .debt__header {
      position: relative;
      height: max-content;
      min-height: 6rem;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
      background-color: ${(props) => chroma(props.theme.colors.primaryColor).darken(0.4).hex()};

      & p {
        font-size: 2rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }
    & .debt__action-container {
      position: relative;
      height: max-content;
      min-height: 6rem;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
      background-color: ${(props) => props.theme.colors.grayScale.offWhite};

      & p {
        font-size: 2rem;
        color: ${(props) => props.theme.colors.primaryColor};
      }
    }
  }
`;

export const DebtElement = function (props) {
  const { theme, debt, setDebt, debtIndex, user, setUser, budget, setBudget } = props;
  return (
    <StyledDebt className="debt">
      <header className="debt__header">
        <p>{debt && DateTime.fromISO(debt.date).toLocaleString(DateTime.DATE_HUGE)}</p>
        <p>{debt && `Lender: ${debt.lender}`}</p>
        <p>{debt && `Debt Type: ${debt.debtType}`}</p>
        <p>{debt && `Debt Status: ${debt.status}`}</p>
      </header>
      <div className="debt__action-container">
        <p>{user && debt && `Initial Debt: ${user.money.format(debt.initialDebt)}`}</p>
        <p>{user && debt && `Current Amount Owed: ${user.money.format(debt.amountOwed)}`}</p>
        {debt && debt.status !== 'Paid Off' && (
          <PrimaryButton
            size="small"
            minWidth="10rem"
            maxWidth="10rem"
            name="pay-off-debt"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              handleTransaction(e, 'Debt', { user: user, budget: budget, debt: debt, debtIndex: debtIndex }, { setUser: setUser, setBudget: setBudget, setDebt: setDebt });
            }}
            disabled={user && user.permissions === 'Associate'}>
            Pay Off
          </PrimaryButton>
        )}
      </div>
    </StyledDebt>
  );
};
