import React from 'react';
import { StyledContainer, StyledContentContainer } from './BasicContainer';
import { BudgetContainerHeader } from '../../Forms/Header';
import FormSection from '../../Forms/Section';

export const ExtraSmallContainer = function (props) {
  const { theme, user, budget, title, icon, content } = props;

  return (
    <StyledContainer
      minHeight="25rem"
      minWidth="35rem"
      borderRadius=".5rem"
      margin={content.margin || null}
      bgColor={content.bgColor}
      className={content.valueDifference < 0 ? 'negative' : content.valueDifference === 0 ? 'neutral' : content.valueDifference > 0 ? 'positive' : ''}>
      <BudgetContainerHeader
        minHeight="5rem"
        justify="space-between"
        borderRadius=".25rem .25rem 0 0"
        iconSize="2.4rem"
        textSize={`${content.textSize || '2rem'}`}
        investmentHeader={content.investmentHeader}
        investmentPadding={content.investmentPadding}
        className={content.valueDifference < 0 ? 'negative' : content.valueDifference === 0 ? 'neutral' : content.valueDifference > 0 ? 'positive' : ''}>
        <i className={`fas fa-${icon}`} />
        {content && content.investment === true ? (
          <FormSection
            modifier="transactional"
            minHeight="5rem"
            sectionHeight="100%"
            sectionWidth="max-content"
            flow="column nowrap"
            justify="space-evenly"
            align="center"
            padding=".5rem"
            headerTextSize="2rem">
            <h3 className="investment-title">{title}</h3>
            <p className="investment-type">{content.investmentType}</p>
          </FormSection>
        ) : (
          <p>{title}</p>
        )}
      </BudgetContainerHeader>
      <StyledContentContainer
        investmentContainerType={content.investmentContainerType}
        height={content.investmentContainerType === 'transactional' && content.investment === true ? '20rem' : ''}
        minHeight="20rem"
        minWidth="100%"
        containerType="overall"
        flow="column nowrap"
        justify="center"
        align="center">
        {content.content}
      </StyledContentContainer>
    </StyledContainer>
  );
};
