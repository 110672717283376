import { loginSchema, signupSchema, forgotPasswordSchema, createBudgetSchema, resetPasswordSchema, userProfileSchema } from '../Validate';
import * as yup from 'yup';

export const validate = (type, schema, name, value, errors, setErrors, disabledValues, setDisabledValues, values) => {
  if (type === `login`) {
    if (schema === loginSchema) {
      yup
        .reach(loginSchema, name)
        .validate(value)
        .then(() => {
          setErrors({ ...errors, [name]: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, [name]: error.errors[0] });
          setDisabledValues({ ...disabledValues, loginDisabled: true });
        });
    }
  }

  if (type == `signup`) {
    if (schema === signupSchema.pageOne) {
      yup
        .reach(schema, name)
        .validate(value)
        .then(() => {
          setErrors({ ...errors, [name]: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, [name]: error.errors[0] });
          setDisabledValues({ ...disabledValues, signupDisabled: true });
        });
    } else if (schema === signupSchema.pageTwo) {
      yup
        .reach(schema, name)
        .validate(value)
        .then(() => {
          setErrors({ ...errors, [name]: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, [name]: error.errors[0] });
          setDisabledValues({ ...disabledValues, signupDisabled: true });
        });
    } else if (schema === signupSchema.pageThree) {
      yup
        .reach(schema, name)
        .validate(value)
        .then(() => {
          setErrors({ ...errors, [name]: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, [name]: error.errors[0] });
          setDisabledValues({ ...disabledValues, signupDisabled: true });
        });
    } else if (schema === signupSchema.pageFour) {
      yup
        .reach(schema, name)
        .validate(value)
        .then(() => {
          setErrors({ ...errors, [name]: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, [name]: error.errors[0] });
          setDisabledValues({ ...disabledValues, signupDisabled: true });
        });
    }
  }
  if (type === 'forgot-password') {
    if (schema === forgotPasswordSchema) {
      yup
        .reach(forgotPasswordSchema, name)
        .validate(value)
        .then(() => {
          setErrors({ ...errors, [name]: '' });
        })
        .catch((error) => {
          console.log(error.errors[0]);
          setErrors({ ...errors, [name]: error.errors[0] });
          setDisabledValues({ ...disabledValues, forgotPasswordDisabled: true });
        });
    }
  }
  if (type === 'create-budget') {
    if (name === 'budgetName') {
      yup
        .reach(createBudgetSchema.pageOne, name)
        .validate(value)
        .then(() => {
          setErrors({ ...errors, [name]: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, [name]: error.errors[0] });
          setDisabledValues({ ...disabledValues, createBudgetDisabled: true });
        });
    } else if (name === 'emergencySetting') {
      yup
        .reach(createBudgetSchema.pageTwo, name)
        .validate(value)
        .then(() => {
          setErrors({ ...errors, [name]: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, [name]: error.errors[0] });
          setDisabledValues({ ...disabledValues, createBudgetDisabled: true });
        });
    } else if (name === 'emergencyGoalTime' || name === 'emergencyGoalTotal') {
      yup
        .reach(createBudgetSchema.pageTwo, 'emergencyGoal')
        .validate(value)
        .then(() => {
          setErrors({ ...errors, ['emergencyGoal']: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, ['emergencyGoal']: error.errors[0] });
          setDisabledValues({ ...disabledValues, createBudgetDisabled: true });
        });
    } else if (name === 'savingsGoalPercentage') {
      yup
        .reach(createBudgetSchema.pageThree, 'savingsPercentage')
        .validate(value)
        .then(() => {
          setErrors({ ...errors, ['savingsPercentage']: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, ['savingsPercentage']: error.errors[0] });
          setDisabledValues({ ...disabledValues, createBudgetDisabled: true });
        });
    } else if (name === 'savingsGoal') {
      yup
        .reach(createBudgetSchema.pageThree, name)
        .validate(value)
        .then(() => {
          setErrors({ ...errors, [name]: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, [name]: error.errors[0] });
          setDisabledValues({ ...disabledValues, createBudgetDisabled: true });
        });
    } else if (name === 'investmentGoalPercentage') {
      yup
        .reach(createBudgetSchema.pageFour, 'investmentPercentage')
        .validate(value)
        .then(() => {
          setErrors({ ...errors, [name]: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, [name]: error.errors[0] });
          setDisabledValues({ ...disabledValues, createBudgetDisabled: true });
        });
    } else if (name === 'investmentGoal') {
      yup
        .reach(createBudgetSchema.pageFour, name)
        .validate(value)
        .then(() => {
          setErrors({ ...errors, ['investmentPercentage']: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, ['investmentPercentage']: error.errors[0] });
          setDisabledValues({ ...disabledValues, createBudgetDisabled: true });
        });
    }
  }
  if (type === 'reset-password') {
    if (schema === resetPasswordSchema) {
      yup
        .reach(resetPasswordSchema, name)
        .validate(value)
        .then(() => {
          setErrors({ ...errors, [name]: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, [name]: error.errors[0] });
          setDisabledValues({ ...disabledValues, resetPasswordDisabled: true });
        });
    }
  }

  if (type === 'user-profile') {
    if (schema === userProfileSchema.personalInformation) {
      yup
        .reach(schema, name)
        .validate(value)
        .then(() => {
          setErrors({ ...errors, [name]: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, [name]: error.errors[0] });
          setDisabledValues({ ...disabledValues, personalInformation: true });
        });
    } else if (schema === userProfileSchema.communications) {
      yup
        .reach(schema, name)
        .validate(value)
        .then(() => {
          setErrors({ ...errors, [name]: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, [name]: error.errors[0] });
          setDisabledValues({ ...disabledValues, communications: true });
        });
    } else if (schema === userProfileSchema.passwordManagement) {
      yup
        .reach(schema, name)
        .validate(value)
        .then(() => {
          setErrors({ ...errors, [name]: '', ['newPasswordConfirmed']: '' });
        })
        .catch((error) => {
          setErrors({ ...errors, [name]: error.errors[0] });
          setDisabledValues({ ...disabledValues, passwordManagement: true });
        });
    }
  }
};
