import React, { useState } from 'react';
import styled from 'styled-components';
import { BudgetPageSection } from '../Global/Forms/Section';
import { BudgetContainerHeader } from '../Global/Forms/Header';
import { StyledExtraLargeContainer } from '../Global/Budget/BudgetContainers/ExtraLargeContainer';
import { ExtraSmallContainer } from '../Global/Budget/BudgetContainers/ExtraSmallContainer';
import { Form } from '../Global/Forms/Form';
import FormSection from '../Global/Forms/Section';
import { SearchRadio } from '../Global/Forms/Radio';
import { SearchLabel, SwitchLabel } from '../Global/Forms/Label';
import { SubCategoryGoalInput } from '../Global/Forms/Input';
import { handleTransaction } from '../Global/Forms/Algorithms/InputChange';
import { PrimaryButton } from '../Global/Button';
import ErrorContainer from '../Global/Forms/Errors';

const initialSearchTerms = {
  firstname: '',
  lastname: '',
  username: '',
  setting: 'fullname',
};

export const InviteUsers = function (props) {
  const { theme, user, setUser, budget, setBudget, transaction, setTransaction, handleInputChange, errors, setErrors, disabledValues, setDisabledValues } = props;
  const [searchTerms, setSearchTerms] = useState(initialSearchTerms);
  const [users, setUsers] = useState([]);
  return (
    <BudgetPageSection minHeight="85%" flow="row wrap" justify="space-evenly" align="center">
      <BudgetContainerHeader budgetPage={true} height="10rem" justify="center" bgColor={'transparent'} textColor={theme.colors.tertiaryColor} className="budget-page-header">
        Invite Users
      </BudgetContainerHeader>
      <ErrorContainer>
        {errors && (
          <ol>
            {Object.keys(errors).map((error, index) => {
              if (errors[error] !== '') {
                return (
                  <li className="error" key={index}>
                    {errors[error]}
                  </li>
                );
              }
            })}
          </ol>
        )}
      </ErrorContainer>
      <ExtraSmallContainer
        theme={theme}
        user={user}
        budget={budget}
        title={'Search Users'}
        icon={'magnifying-glass'}
        content={{
          content: (
            <Form position="relative" flow="column nowrap" justify="center" align="center">
              <FormSection modifier="transactional" minHeight="5rem" sectionHeight="max-content" sectionWidth="100%" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
                <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="100%" flow="row wrap" justify="center" align="center" padding=".5rem">
                  <SearchRadio
                    type="radio"
                    name="setting"
                    value="firstname"
                    id="firstname"
                    checked={searchTerms && searchTerms.setting === 'firstname'}
                    onChange={(e) =>
                      handleTransaction(e, 'Search', { user: user, budget: budget, searchTerms: searchTerms }, { setUser: setUser, setBudget: setBudget, setSearchTerms: setSearchTerms })
                    }
                  />
                  <SearchLabel className="firstnameLabel" htmlFor="firstname">
                    First Name
                  </SearchLabel>
                  <SearchRadio
                    type="radio"
                    name="setting"
                    value="lastname"
                    id="lastname"
                    checked={searchTerms && searchTerms.setting === 'lastname'}
                    onChange={(e) =>
                      handleTransaction(e, 'Search', { user: user, budget: budget, searchTerms: searchTerms }, { setUser: setUser, setBudget: setBudget, setSearchTerms: setSearchTerms })
                    }
                  />
                  <SearchLabel className="lastnameLabel" htmlFor="lastname">
                    Last Name
                  </SearchLabel>
                  <SearchRadio
                    type="radio"
                    name="setting"
                    value="fullname"
                    id="fullname"
                    checked={searchTerms && searchTerms.setting === 'fullname'}
                    onChange={(e) =>
                      handleTransaction(e, 'Search', { user: user, budget: budget, searchTerms: searchTerms }, { setUser: setUser, setBudget: setBudget, setSearchTerms: setSearchTerms })
                    }
                  />
                  <SearchLabel className="fullnameLabel" htmlFor="fullname">
                    Full Name
                  </SearchLabel>
                  <SearchRadio
                    type="radio"
                    name="setting"
                    value="username"
                    id="username"
                    checked={searchTerms && searchTerms.setting === 'username'}
                    onChange={(e) =>
                      handleTransaction(e, 'Search', { user: user, budget: budget, searchTerms: searchTerms }, { setUser: setUser, setBudget: setBudget, setSearchTerms: setSearchTerms })
                    }
                  />
                  <SearchLabel className="usernameLabel" htmlFor="username">
                    Username
                  </SearchLabel>
                </FormSection>
                <SwitchLabel modifier="transaction" width="20rem">
                  What To Search By?
                </SwitchLabel>
              </FormSection>
              <FormSection modifier="transactional" minHeight="5rem" sectionHeight="max-content" sectionWidth="max-content" flow="row wrap" justify="space-evenly" align="center" padding=".5rem">
                {(searchTerms && searchTerms.setting === 'firstname' && (
                  <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
                    <SubCategoryGoalInput
                      type="text"
                      name="firstname"
                      minHeight="4rem"
                      maxHeight="4rem"
                      width="13rem"
                      placeholder="First Name"
                      modifier="transaction"
                      onChange={(e) =>
                        handleTransaction(e, 'Search', { user: user, budget: budget, searchTerms: searchTerms }, { setUser: setUser, setBudget: setBudget, setSearchTerms: setSearchTerms })
                      }
                    />
                    <SwitchLabel modifier="transaction" width="20rem">
                      First Name
                    </SwitchLabel>
                  </FormSection>
                )) ||
                  (searchTerms && searchTerms.setting === 'fullname' && (
                    <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
                      <SubCategoryGoalInput
                        type="text"
                        name="firstname"
                        minHeight="4rem"
                        maxHeight="4rem"
                        width="13rem"
                        placeholder="First Name"
                        modifier="transaction"
                        onChange={(e) =>
                          handleTransaction(e, 'Search', { user: user, budget: budget, searchTerms: searchTerms }, { setUser: setUser, setBudget: setBudget, setSearchTerms: setSearchTerms })
                        }
                      />
                      <SwitchLabel modifier="transaction" width="20rem">
                        First Name
                      </SwitchLabel>
                    </FormSection>
                  ))}
                {(searchTerms && searchTerms.setting === 'lastname' && (
                  <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
                    <SubCategoryGoalInput
                      type="text"
                      name="lastname"
                      minHeight="4rem"
                      maxHeight="4rem"
                      width="13rem"
                      placeholder="Last Name"
                      modifier="transaction"
                      onChange={(e) =>
                        handleTransaction(e, 'Search', { user: user, budget: budget, searchTerms: searchTerms }, { setUser: setUser, setBudget: setBudget, setSearchTerms: setSearchTerms })
                      }
                    />
                    <SwitchLabel modifier="transaction" width="20rem">
                      Last Name
                    </SwitchLabel>
                  </FormSection>
                )) ||
                  (searchTerms && searchTerms.setting === 'fullname' && (
                    <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
                      <SubCategoryGoalInput
                        type="text"
                        name="lastname"
                        minHeight="4rem"
                        maxHeight="4rem"
                        width="13rem"
                        placeholder="Last Name"
                        modifier="transaction"
                        onChange={(e) =>
                          handleTransaction(e, 'Search', { user: user, budget: budget, searchTerms: searchTerms }, { setUser: setUser, setBudget: setBudget, setSearchTerms: setSearchTerms })
                        }
                      />
                      <SwitchLabel modifier="transaction" width="20rem">
                        Last Name
                      </SwitchLabel>
                    </FormSection>
                  ))}
                {searchTerms && searchTerms.setting === 'username' && (
                  <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
                    <SubCategoryGoalInput
                      type="text"
                      name="username"
                      minHeight="4rem"
                      maxHeight="4rem"
                      width="13rem"
                      placeholder="Username"
                      modifier="transaction"
                      onChange={(e) =>
                        handleTransaction(e, 'Search', { user: user, budget: budget, searchTerms: searchTerms }, { setUser: setUser, setBudget: setBudget, setSearchTerms: setSearchTerms })
                      }
                    />
                    <SwitchLabel modifier="transaction" width="20rem">
                      Username
                    </SwitchLabel>
                  </FormSection>
                )}
                <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
                  <PrimaryButton
                    className="searchButton"
                    size="small"
                    minWidth="10rem"
                    onClick={(e) => {
                      e.preventDefault();
                      user.searchForFriends({ budgetId: budget._id, userId: user._id, searchTerms: searchTerms, setUsers: setUsers, errors: errors, setErrors: setErrors });
                    }}>
                    Search
                  </PrimaryButton>
                  <SwitchLabel modifier="transaction" width="20rem">
                    Search Button
                  </SwitchLabel>
                </FormSection>
              </FormSection>
            </Form>
          ),
          margin: '0 0 5rem 0',
          bgColor: theme.colors.grayScale.offWhite,
        }}
      />
      <StyledExtraLargeContainer borderRadius=".9rem" inBudget={true} flow="column nowrap" justify="flex-start" align="center" margin="0 0 5rem">
        <BudgetContainerHeader minHeight="5rem" justify="space-between" align="center" iconSize="2.6rem" textSize="3rem" borderRadius=".5rem .5rem 0 0">
          <i className="fas fa-address-book" />
          <p>Users Found</p>
        </BudgetContainerHeader>
        {users &&
          users
            .filter((foundUser) => foundUser.username !== user.username)
            .map((foundUser, index) => {
              return (
                <FormSection
                  modifier="transactional"
                  minHeight="5rem"
                  sectionHeight="100%"
                  sectionWidth="100%"
                  flow="row wrap"
                  justify="center"
                  align="center"
                  padding="2rem"
                  borderBottom={`.3rem groove ${theme.colors.grayScale.raisinBlack}cc`}
                  className="user-container"
                  data-email={foundUser.email}
                  key={index}>
                  <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="50%" flow="row wrap" justify="flex-start" align="center">
                    <FormSection
                      modifier="transactional"
                      minHeight="20rem"
                      sectionHeight="100%"
                      sectionWidth="max-content"
                      flow="row wrap"
                      justify="flex-start"
                      align="center"
                      className="image-container">
                      <img src={`/DIST/CSS/Images/Users/${foundUser.photo}`} alt={`${foundUser.firstname} ${foundUser.lastname}'s Photo`} className="image-container__image" />
                    </FormSection>
                    <p className="user-name">{foundUser.firstname}</p>
                    <p className="user-name">{foundUser.lastname}</p>
                  </FormSection>
                  <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="50%" flow="row wrap" justify="center" align="center" padding=".5rem">
                    <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="50%" flow="column-reverse wrap" justify="center" align="center" padding=".5rem">
                      <PrimaryButton
                        size="medium"
                        data-connection="Associate"
                        onClick={async (e) => {
                          e.preventDefault();
                          let email = e.target.closest('.user-container').dataset.email;
                          let connection = e.target.closest('button').dataset.connection;
                          await user.inviteFriend({
                            budgetId: budget._id,
                            userId: user._id,
                            inviteData: {
                              email: email,
                              connection: connection,
                            },
                          });
                        }}>
                        Associate Invite
                      </PrimaryButton>
                      <SwitchLabel modifier="transaction" width="25rem">
                        Invite To Be Associated
                      </SwitchLabel>
                    </FormSection>
                    <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="50%" flow="column-reverse wrap" justify="center" align="center" padding=".5rem">
                      <PrimaryButton
                        size="medium"
                        data-connection="Admin"
                        onClick={async (e) => {
                          e.preventDefault();
                          let email = e.target.closest('.user-container').dataset.email;
                          let connection = e.target.closest('button').dataset.connection;
                          await user.inviteFriend({
                            budgetId: budget._id,
                            userId: user._id,
                            inviteData: {
                              email: email,
                              connection: connection,
                            },
                          });
                        }}>
                        Admin Invite
                      </PrimaryButton>
                      <SwitchLabel modifier="transaction" width="20rem">
                        Invite To Be An Admin
                      </SwitchLabel>
                    </FormSection>
                  </FormSection>
                </FormSection>
              );
            })}
      </StyledExtraLargeContainer>
    </BudgetPageSection>
  );
};
