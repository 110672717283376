import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  username: yup
    .string()
    .min(8, 'Your username must be at least eight characters long.')
    .matches(/^[A-Z][A-Za-z0-9]*$/, `Your name must start with an uppercase character and have only letters or numbers afterwards.`)
    .required(),

  password: yup
    .string()
    .min(8, `Your password must be at least eight characters in length.`)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%&\-\_])[A-Za-z\d@$!%&\-\_&]{8,}$/,
      `Your password must have at least one uppercase letter, one lowercase letter, one number, one special character, and be at least eight characters long.`
    )
    .required(),
});

export const signupSchema = {
  pageOne: yup.object().shape({
    firstname: yup
      .string()
      .min(2, `Your first name must have at least two characters`)
      .matches(/^[A-Za-z]+$/, `Your first name must start with an uppercase character and be only letters.`)
      .required(`Your first name is required.`),

    lastname: yup
      .string()
      .min(2, `Your last name must have at least two characters`)
      .matches(/^[A-Za-z]+$/, `Your last name must start with an uppercase character and be only letters.`)
      .required(`Your last name is required.`),
  }),
  pageTwo: yup.object().shape({
    username: yup
      .string()
      .min(8, `Your username must be at least eight characters long.`)
      .matches(/^[A-Z][A-Za-z0-9]*$/, `Your name must start with an uppercase character and have only letters or numbers afterwards.`)
      .required(`A username is required.`),

    isLDS: yup.boolean(),
  }),
  pageThree: yup.object().shape({
    email: yup
      .string()
      .email(`Your email address is in an invalid format.`)
      .matches(/[^@]+@[^@]+[\.]+(com|net|org|io|edu|(co.uk)|me|tech|money|gov)+$/)
      .required(`Your email address is required.`),
    emailConfirmed: yup
      .string()
      .email(`Your email address is in an invalid format.`)
      .matches(/[^@]+@[^@]+[\.]+(com|net|org|io|edu|(co.uk)|me|tech|money|gov)+$/)
      .required(`You must confirm your email address..`),
  }),
  pageFour: yup.object().shape({
    password: yup
      .string()
      .min(8, `Your password must be eight characters in length.`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%&\-\_])[A-Za-z\d@$!%&\-\_&]{8,}$/,
        `Your password must have at least one uppercase letter, one lowercase letter, one number, one special character, and be at least eight characters long.`
      )
      .required(),

    passwordConfirmed: yup
      .string()
      .min(8, `Your password must be eight characters in length.`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%&\-\_])[A-Za-z\d@$!%&\-\_&]{8,}$/,
        `Your password must have at least one uppercase letter, one lowercase letter, one number, one special character, and be at least eight characters long.`
      )
      .required(),
  }),
};

export const forgotPasswordSchema = yup.object().shape({
  forgottenEmail: yup
    .string()
    .email(`Your email address is in an invalid format.`)
    .matches(/[^@]+@[^@]+[\.]+(com|net|org|io|edu|(co.uk)|me|tech|money|gov)+$/)
    .required(`Your email address is required.`),
});

export const resetPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, `Your password must be eight characters in length.`)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%&\-\_])[A-Za-z\d@$!%&\-\_&]{8,}$/,
      `Your password must have at least one uppercase letter, one lowercase letter, one number, one special character, and be at least eight characters long.`
    )
    .required(),

  newPasswordConfirmed: yup
    .string()
    .min(8, `Your password must be eight characters in length.`)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%&\-\_])[A-Za-z\d@$!%&\-\_&]{8,}$/,
      `Your password must have at least one uppercase letter, one lowercase letter, one number, one special character, and be at least eight characters long.`
    )
    .required(),
});

export const createBudgetSchema = {
  pageOne: yup.object().shape({
    budgetName: yup
      .string()
      .min(6, `Your budget's name must be at least six characters long.`)
      .matches(/[A-Za-z\ \'\-\.\`\_\(\)\!0-9][^*\+]+$/, `Your budget must have only letters, numbers, or either of the following characters: ', -, ., _, (, ), |.`)
      .required(`Your budget must have a name.`),
  }),
  pageTwo: yup.object().shape({
    emergencySetting: yup.string().oneOf(['Length Of Time', 'Total Amount'], 'You must have a way to measure your emergency fund.').required(`You must have a emergency goal setting.`),
    emergencyGoal: yup
      .number(`Emergency goal must be a number.`)
      .min(0, `You either need to set a goal, or it needs to be greater than or equal to zero.`)
      .required(`You must have an emergency goal, whether it is an amount or a length of time.`),
  }),
  pageThree: yup.object().shape({
    savingsPercentage: yup
      .number(`Savings percentage must be a number.`)
      .min(0, `You either need to set a goal, or it needs to be greater than or equal to zero.`)
      .max(100, `You can not go above 100% of anything.`)
      .required(`You must put a number as the percentage going to savings, even if zero.`),
    savingsGoal: yup
      .number(`Savings goal must be a number.`)
      .min(0, `You either need to set a goal, or it needs to be greater than or equal to zero.`)
      .required(`You must have a goal for savings, even if zero.`),
  }),
  pageFour: yup.object().shape({
    investmentPercentage: yup
      .number(`Investment percentage must be a number.`)
      .min(0, `You either need to set a goal, or it needs to be greater than or equal to zero.`)
      .max(100, `You can not go above 100% of anything.`)
      .required(`You must put a number as the percentage going to investments, even if it is zero.`),
    investmentGoal: yup
      .number(`Investment goal must be a number.`)
      .min(0, `You either need to set a goal, or it needs to be greater than or equal to zero.`)
      .required(`You must have a goal for your investment fund, even if it is zero.`),
  }),
};

export const userProfileSchema = {
  personalInformation: yup.object().shape({
    firstname: yup
      .string()
      .min(2, `Your first name must be at least two letters long.`)
      .matches(/^[A-Z][A-Za-z]+$/, `Your first name must start with a capital and be only letters`)
      .required(`You must have a first name.`),
    lastname: yup
      .string()
      .min(2, `Your last name must be at least two letters long.`)
      .matches(/^[A-Z][A-Za-z]+$/, `Your last name must start with a capital and be only letters`)
      .required(`You must have a last name.`),
    username: yup
      .string()
      .min(8, `Your username must be at least eight characters long.`)
      .matches(/^[A-Z][A-Za-z0-9]*$/, `Your username must start with a capital and must have only numbers and letters.`)
      .required(`Every user must have a username.`),
    latterDaySaint: yup.boolean().required(`You are either a Latter Day Saint or you are not.`),
  }),
  communications: yup.object().shape({
    email: yup.string().matches(/[^@]+@[^@]+[\.]+(com|net|org|io|edu|(co.uk)|me|tech|money|gov)+$/, `Your email must follow the following format: email@validDomain.validExtension.`),
    newEmail: yup
      .string()
      .matches(/[^@]+@[^@]+[\.]+(com|net|org|io|edu|(co.uk)|me|tech|money|gov)+$/, {
        message: `Your email must follow the following format: email@validDomain.validExtension.`,
        excludeEmptyString: true,
      })
      .notRequired(),
    newEmailConfirmed: yup
      .string()
      .matches(/[^@]+@[^@]+[\.]+(com|net|org|io|edu|(co.uk)|me|tech|money|gov)+$/, {
        message: `Your email must follow the following format: email@validDomain.validExtension.`,
        excludeEmptyString: true,
      })
      .notRequired(),
    phoneNumber: yup
      .string()
      .matches(/[\(][0-9]{3}[\)][ ]{1}[0-9]{3}[ ]{1}[\-]{1}[ ]{1}[0-9]{4}/, { message: `Phone number must be 10 digits in length.`, excludeEmptyString: true })
      .notRequired(),
    newPhoneNumber: yup
      .string()
      .matches(/[\(][0-9]{3}[\)][ ]{1}[0-9]{3}[ ]{1}[\-]{1}[ ]{1}[0-9]{4}/, { message: `Phone number must be 10 digits in length.`, excludeEmptyString: true })
      .notRequired(),
    newPhoneNumberConfirmed: yup
      .string()
      .matches(/[\(][0-9]{3}[\)][ ]{1}[0-9]{3}[ ]{1}[\-]{1}[ ]{1}[0-9]{4}/, { message: `Phone number must be 10 digits in length.`, excludeEmptyString: true })
      .notRequired(),
    communicationPreference: yup.string().oneOf(['Email', 'Text']),
  }),
  passwordManagement: yup.object().shape({
    currentPassword: yup
      .string()
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%&\-\_])[A-Za-z\d@$!%&\-\_&]{8,}$/, {
        message: 'Passwords must be eight characters in length and have at least one uppercase letter, one lowercase letter, one number, and one special character.',
        excludeEmptyString: true,
      })
      .notRequired(),
    newPassword: yup
      .string()
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%&\-\_])[A-Za-z\d@$!%&\-\_&]{8,}$/, {
        message: 'Passwords must be eight characters in length and have at least one uppercase letter, one lowercase letter, one number, and one special character.',
        excludeEmptyString: true,
      })
      .notRequired(),
    newPasswordConfirmed: yup
      .string()
      .oneOf([yup.ref('newPassword')], `Passwords must match.`)
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%&\-\_])[A-Za-z\d@$!%&\-\_&]{8,}$/, {
        message: 'Passwords must be eight characters in length and have at least one uppercase letter, one lowercase letter, one number, and one special character.',
        excludeEmptyString: true,
      })
      .notRequired(),
  }),
};
