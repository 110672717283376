import { produce, immerable } from 'immer';
import { DateTime } from 'luxon';
import { ReceiptItem } from './ReceiptItem';
import { calculateSurplus, calculateTithing, setTithed } from '../Application/Global/Forms/Algorithms/Tithing';
import { getFullCashAndAssetValue } from '../Application/Global/Forms/Algorithms/Tithing';
import { RecentTransaction } from './RecentTransaction';
import { getCamelCaseAccount, getCategoryIndex } from '../Application/Global/Budget/BudgetUtility';
import { Investment } from './Investment';

export class Transaction {
  [immerable] = true;

  constructor() {
    this.active = false;
    this.transactionDate = '';
    this.transactionType = '';
    this.location = '';
    this.receipt = [];
    this.total = 0;
  }

  submitTransaction(event, transaction, setTransaction, receiptItem, setReceiptItem, user, setUser, errors, setErrors, budget, setBudget) {
    event.preventDefault();

    if (!transaction.transactionType) return setErrors({ ...errors, ['transactionType']: 'All transactions are either a deposit or withdrawal.' });

    if (!transaction.transactionDate) {
      return setErrors({ ...errors, ['transactionDate']: 'Every transaction needs a date.' });
    } else if (!transaction.location) {
      return setErrors({ ...errors, ['transactionLocation']: 'Every transaction needs a location.' });
    } else if (transaction.total === 0) {
      return setErrors({ ...errors, ['transactionTotal']: 'Every transaction has a cost or amount, otherwise, do not worry about recording it.' });
    } else if (transaction.receipt.length === 0) {
      return setErrors({ ...errors, ['transactionReceipt']: 'Receipts need to have at least one item.' });
    }

    let numberOfErrors = 0;

    const updated =
      budget &&
      produce(budget, (roughDraft) => {
        transaction.receipt.forEach((item) => {
          if (item.account === 'Monthly Budget') {
            if (roughDraft.accounts[getCamelCaseAccount(item.account)].amount < item.amount) {
              numberOfErrors++;

              return setErrors({ ...errors, ['transaction']: 'There is not enough money allocated for this transaction.' });
            } else {
              setErrors({ ...errors, ['transaction']: '' });
            }
            roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromAmount(item.amount);
            let mainIndex = getCategoryIndex('main', budget, item.mainCategory);
            let subIndex = getCategoryIndex('sub', budget, item.subCategory, mainIndex);
            roughDraft.mainCategories[mainIndex].subCategories[subIndex].addToAmountSpent(item.amount);
            roughDraft.mainCategories[mainIndex].subCategories[subIndex].updateAmountRemaining();
            roughDraft.mainCategories[mainIndex].subCategories[subIndex].updatePercentageSpent();
          } else if (item.account === 'Emergency Fund') {
            roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromAmount(item.amount);
          } else if (item.account === 'Savings Fund') {
            // GET PLANS THAT ARE OF THE SAME ACCOUNT
            const accountFilteredPlans = roughDraft.transactions.plannedTransactions.filter((plan) => plan.account === item.account);

            // FILTER PLANS TO SAME TYPE OF PLAN
            const typeFilteredPlans = accountFilteredPlans.filter((plan) => plan.subAccount === item.type);

            // FILTER PLANS TO THE SAME TIMING
            const timingFilteredPlans = typeFilteredPlans.filter((plan) => plan.timingOptions.paymentCycle === item.paymentCycle);

            const nameFilteredPlans = timingFilteredPlans.filter((plan) => plan.name.toLowerCase() === item.expense.toLowerCase());

            let selectedPlan = nameFilteredPlans[0];

            if (item.amount > roughDraft.accounts[getCamelCaseAccount(item.account)].amount.total) {
              numberOfErrors++;

              return setErrors({ ...errors, ['transaction']: 'There is not enough money allocated for this transaction.' });
            } else {
              setErrors({ ...errors, ['transaction']: '' });
            }
            if (selectedPlan.amountSaved >= item.amount) {
              selectedPlan.amountSaved -= item.amount;
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromTotal(item.amount);
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromUsed(item.amount);
            } else if (selectedPlan.amountSaved < item.amount) {
              let totalAmount = item.amount;
              let remainder = totalAmount - selectedPlan.amountSaved;
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromUsed(selectedPlan.amountSaved);
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromUnUsed(remainder);
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromTotal(totalAmount);
              selectedPlan.filterOutDate(item.dueDate);
            }

            // item.dueDate is formatted for reading.
          } else if (item.account === 'Expense Fund') {
            // GET PLANS THAT ARE OF THE SAME ACCOUNT
            const accountFilteredPlans = roughDraft.transactions.plannedTransactions.filter((plan) => plan.account === item.account);

            // FILTER PLANS TO SAME TYPE OF PLAN
            const typeFilteredPlans = accountFilteredPlans.filter((plan) => plan.subAccount === item.type);

            // FILTER PLANS TO THE SAME TIMING
            const timingFilteredPlans = typeFilteredPlans.filter((plan) => plan.timingOptions.paymentCycle === item.paymentCycle);

            const nameFilteredPlans = timingFilteredPlans.filter((plan) => plan.name.toLowerCase() === item.expense.toLowerCase());

            let selectedPlan = nameFilteredPlans[0];

            if (item.amount > roughDraft.accounts[getCamelCaseAccount(item.account)].amount.total) {
              numberOfErrors++;

              return setErrors({ ...errors, ['transaction']: 'There is not enough money allocated for this transaction.' });
            } else {
              setErrors({ ...errors, ['transaction']: '' });
            }

            if (selectedPlan.amountSaved >= item.amount) {
              selectedPlan.amountSaved -= item.amount;
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromTotal(item.amount);
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromUsed(item.amount);
            } else if (selectedPlan.amountSaved < item.amount) {
              let totalAmount = item.amount;
              let remainder = totalAmount - selectedPlan.amountSaved;
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromUsed(selectedPlan.amountSaved);
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromUnUsed(remainder);
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromTotal(totalAmount);
              selectedPlan.filterOutDate(item.dueDate);
            }
          } else if (item.account === 'Surplus') {
            // GET PLANS THAT ARE OF THE SAME ACCOUNT
            const accountFilteredPlans = roughDraft.transactions.plannedTransactions.filter((plan) => plan.account === item.account);

            // FILTER PLANS TO SAME TYPE OF PLAN
            const typeFilteredPlans = accountFilteredPlans.filter((plan) => plan.subAccount === item.type);

            // FILTER PLANS TO THE SAME TIMING
            const timingFilteredPlans = typeFilteredPlans.filter((plan) => plan.timingOptions.paymentCycle === item.paymentCycle);

            const nameFilteredPlans = timingFilteredPlans.filter((plan) => plan.name.toLowerCase() === item.expense.toLowerCase());

            let selectedPlan = nameFilteredPlans[0];
            if (item.amount > roughDraft.accounts[getCamelCaseAccount(item.account)].amount.total) {
              numberOfErrors++;

              return setErrors({ ...errors, ['transaction']: 'There is not enough money allocated for this transaction.' });
            } else {
              setErrors({ ...errors, ['transaction']: '' });
            }

            if (selectedPlan.amountSaved >= item.amount) {
              selectedPlan.amountSaved -= item.amount;
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromTotal(item.amount);
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromUsed(item.amount);
            } else if (selectedPlan.amountSaved < item.amount) {
              let totalAmount = item.amount;
              let remainder = totalAmount - selectedPlan.amountSaved;
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromUsed(selectedPlan.amountSaved);
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromUnUsed(remainder);
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromTotal(totalAmount);
              selectedPlan.filterOutDate(item.dueDate);
            }
          } else if (item.account === 'Investment Fund') {
            if (roughDraft.accounts[getCamelCaseAccount(item.account)].amount < item.amount) {
              numberOfErrors++;

              return setErrors({ ...errors, ['transaction']: 'There is not enough money allocated for this transaction.' });
            } else {
              setErrors({ ...errors, ['transaction']: '' });
            }

            let newInvestment = new Investment({
              investmentType: item.type,
              initialInvestment: item.amount,
              investmentDescription: item.description,
              investmentName: item.investment,
              currentValue: item.amount,
              settled: false,
              values: [item.amount],
              dates: [DateTime.now().toISO()],
            });

            roughDraft.investments.push(newInvestment);
            roughDraft.accounts[getCamelCaseAccount(item.account)].addInvestment(item.amount);
          } else if (item.account === 'Debt') {
            // GET PLANS THAT ARE OF THE SAME ACCOUNT
            const accountFilteredPlans = roughDraft.transactions.plannedTransactions.filter((plan) => plan.account === item.account);

            // FILTER PLANS TO SAME TYPE OF PLAN
            const typeFilteredPlans = accountFilteredPlans.filter((plan) => plan.subAccount === item.type);

            // FILTER PLANS TO THE SAME TIMING
            const timingFilteredPlans = typeFilteredPlans.filter((plan) => plan.timingOptions.paymentCycle === item.paymentCycle);

            const nameFilteredPlans = timingFilteredPlans.filter((plan) => plan.name.toLowerCase() === item.expense.toLowerCase());

            let selectedPlan = nameFilteredPlans[0];

            if (item.amount > roughDraft.accounts[getCamelCaseAccount(item.account)].amount.total) {
              numberOfErrors++;
              return setErrors({ ...errors, ['debt']: `There is not enough allocated for this transaction.` });
            } else {
              setErrors({ ...errors, ['debt']: `` });
            }

            let selectedDebt;
            roughDraft.debts.forEach((checkedDebt) => {
              if (checkedDebt.debtType === item.type && checkedDebt.lender === item.lender && selectedPlan.name === item.expense && checkedDebt.paidStatus !== 'Paid Off') {
                selectedDebt = checkedDebt;
              }
            });

            selectedDebt.amountOwed -= item.amount;
            if (selectedDebt.amountOwed <= 0) {
              selectedDebt.amountOwed = 0;
              selectedDebt.paidStatus = 'Paid Off';
              selectedDebt.updateDatePaid();
            }

            if (selectedPlan.amountSaved >= item.amount) {
              selectedPlan.amountSaved -= item.amount;
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromTotal(item.amount);
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromUsed(item.amount);
              let debtTotal = roughDraft.debts.reduce((total, current) => {
                return (total += current.amountOwed);
              }, 0);
              debtTotal -= item.amount;
              roughDraft.accounts.debt.debtAmount = Number(debtTotal);
              if (isNaN(debtTotal)) roughDraft.accounts.debtAmount = 0;
            } else if (selectedPlan.amountSaved < item.amount) {
              let totalAmount = item.amount;
              let remainder = totalAmount - selectedPlan.amountSaved;
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromUsed(selectedPlan.amountSaved);
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromUnUsed(remainder);
              roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromTotal(totalAmount);
              selectedPlan.filterOutDate(item.dueDate);
              let debtTotal = roughDraft.debts.reduce((total, current) => {
                return (total += current.amountOwed);
              }, 0);
              roughDraft.accounts.debt.debtAmount = Number(debtTotal);
              if (isNaN(debtTotal)) roughDraft.accounts.debtAmount = 0;
            }

            // GET CORRECT DEBT TO BE LOWERED IN ITS AMOUNT
          } else if (item.account === 'Tithing') {
            if (item.amount > roughDraft.accounts.tithing.amount) {
              numberOfErrors++;
              return setErrors({ ...errors, ['tithing']: `Unfortunately, you do not have enough allocated to the Tithing account to pay the requested amount.` });
            } else {
              setErrors({ ...errors, ['tithing']: `` });
            }
            roughDraft.accounts[getCamelCaseAccount(item.account)].removeFromAmountOwed(item.amount);
            roughDraft.accounts.tithing.removeFromAmount(item.amount);
          }
        });
        let total = 0;
        roughDraft.bankAccount = getFullCashAndAssetValue(total, roughDraft.accounts, roughDraft.investments);

        // THIS SHOULD NOT BE HAPPENING WHEN THE TRANSACTION IS NOT VALID.
        if (numberOfErrors === 0) {
          roughDraft.transactions.recentTransactions.push(transaction);
        }
      });
    if (numberOfErrors === 0) {
      setBudget(updated);
      updated.updateBudget(
        {
          budgetId: updated._id,
          userId: user._id,
          lastUpdated: DateTime.now().toISO(),
          bankAccount: updated.bankAccount,
          accounts: updated.accounts,
          mainCategories: updated.mainCategories,
          transactions: updated.transactions,
          investments: updated.investments,
          debts: updated.debts,
        },
        'Enter-Transaction'
      );
    }
    setTransaction(new Transaction());
  }

  submitDeposit(event, transaction, setTransaction, receiptItem, setReceiptItem, user, setUser, errors, setErrors, budget, setBudget) {
    event.preventDefault();

    // PREVENT TRANSACTIONS WITHOUT A TRANSACTION TYPE
    if (!transaction.transactionType) return setErrors({ ...errors, ['transactionType']: 'All transactions are either a deposit or withdrawal.' });

    // PREVENT TRANSACTIONS WITHOUT A DATE.
    if (!transaction.transactionDate) {
      return setErrors({ ...errors, ['transactionDate']: 'Every transaction needs a date.' });
    } else if (!transaction.location) {
      return setErrors({ ...errors, ['transactionLocation']: 'Every transaction needs a location.' });
    } else {
      setErrors({ ...errors, ['transactionLocation']: '' });
    }

    // INITIALIZE RECEIPT OBJECT
    let receiptObject = {
      account: 'Un-Allocated',
      grossIncome: receiptItem.grossIncome,
      netIncome: receiptItem.netIncome,
      amount: receiptItem.unAllocated,
      description: 'Paycheck',
    };

    // ADD RECEIPT OBJECT'S TITHED SETTING
    receiptObject.tithed = setTithed(receiptItem, user, budget);

    // UPDATE THE TRANSACTION WITH ITS TOTAL AND THE DEPOSIT AS PART OF THE RECEIPT.
    const updated = produce(transaction, (roughDraft) => {
      roughDraft.receipt.push(receiptObject);
      roughDraft.total = Number(receiptItem.netIncome);
    });

    // USE THE SETTERS TO UPDATE THE TRANSACTION AND RESET THE RECEIPT ITEM.
    setTransaction(updated);
    setReceiptItem(new ReceiptItem());

    // PREVENT ANYTHING BEING UPDATED WITHOUT THE GROSS AND NET INCOMES AS WELL AS THE RECEIPT BEING LESS THAN ONE IN LENGTH.
    if (receiptObject.netIncome === 0) {
      return setErrors({ ...errors, ['netIncome']: 'Every income has a net income value.' });
    }

    if (receiptObject.grossIncome === 0) {
      return setErrors({ ...errors, ['grossIncome']: 'Every income has a gross income value.' });
    }

    if (updated.receipt.length === 0) {
      return setErrors({ ...errors, ['transactionReceipt']: 'Receipts need to have at least one item.' });
    }

    let tithing;

    // IF USER IS A LATTER DAY SAINT -- CALCULATE TITHING
    if (user.latterDaySaint === true) {
      if (budget.accounts.tithing.tithingSetting === 'Gross') {
        tithing = calculateTithing(budget.accounts.tithing.tithingSetting, receiptItem.grossIncome);
      } else if (budget.accounts.tithing.tithingSetting === 'Net') {
        tithing = calculateTithing(budget.accounts.tithing.tithingSetting, receiptItem.netIncome);
      } else {
        tithing = calculateTithing(budget.accounts.tithing.tithingSetting, receiptItem.netIncome, budget);
      }
    }

    // ADJUST BUDGET'S ACCOUNTS ACCORDINGLY.
    const updatedTwo = produce(budget, (roughDraft) => {
      roughDraft.accounts.unAllocated.amount += receiptItem.unAllocated;
      roughDraft.accounts.savingsFund.amount.total += receiptItem.saved;
      roughDraft.accounts.savingsFund.amount.unUsed += receiptItem.saved;
      roughDraft.accounts.investmentFund.amount += receiptItem.invested;
      roughDraft.accounts.surplus.amount.total = calculateSurplus(roughDraft);
      if (roughDraft.accounts.surplus.amount.total < 0) roughDraft.accounts.surplus.amount = 0;
      if (user.latterDaySaint === true) {
        if (roughDraft.accounts.tithing.tithingSetting !== 'Surplus') {
          if (receiptObject.tithed === true) tithing = 0;
          roughDraft.accounts.tithing.amountOwed += tithing;
        } else if (roughDraft.accounts.tithing.tithingSetting === 'Surplus') {
          roughDraft.accounts.tithing.amountOwed = tithing;
          roughDraft.accounts.surplus.amount.total -= tithing;
        }
      }
      roughDraft.accounts.surplus.amount.unUsed = roughDraft.accounts.surplus.amount.total - roughDraft.accounts.surplus.amount.used;
      let total = 0;
      roughDraft.bankAccount = getFullCashAndAssetValue(total, roughDraft.accounts, roughDraft.investments);

      // ADD TRANSACTION TO BUDGET'S RECENT TRANSACTIONS
      let newRecentTransaction = new RecentTransaction();
      newRecentTransaction.makeRecentTransaction(updated);

      roughDraft.transactions.recentTransactions.push(newRecentTransaction);
    });

    // UPDATE THE BUDGET
    setBudget(updatedTwo);

    let updateObject = {
      budgetId: budget._id,
      userId: user._id,
      lastUpdated: DateTime.now().toISO(),
      currentMonth: updatedTwo.currentMonth,
      previousMonth: updatedTwo.previousMonth || updatedTwo.currentMonth,
      bankAccount: updatedTwo.bankAccount,
      accounts: {
        unAllocated: {
          amount: updatedTwo.accounts.unAllocated.amount,
        },
        monthlyBudget: updatedTwo.accounts.monthlyBudget,
        emergencyFund: updatedTwo.accounts.emergencyFund,
        savingsFund: {
          savingsGoal: updatedTwo.accounts.savingsFund.savingsGoal,
          savingsPercentage: updatedTwo.accounts.savingsFund.savingsPercentage,
          amount: {
            total: updatedTwo.accounts.savingsFund.amount.total,
            unUsed: updatedTwo.accounts.savingsFund.amount.unUsed,
            used: updatedTwo.accounts.savingsFund.amount.used,
          },
        },
        expenseFund: updatedTwo.accounts.expenseFund,
        surplus: updatedTwo.accounts.surplus,
        investmentFund: {
          investmentGoal: updatedTwo.accounts.investmentFund.investmentGoal,
          investmentPercentage: updatedTwo.accounts.investmentFund.investmentPercentage,
          amount: updatedTwo.accounts.investmentFund.amount,
          investedAmount: updatedTwo.accounts.investmentFund.investedAmount,
        },
        debt: {
          amount: {
            total: updatedTwo.accounts.debt.amount.total,
            used: updatedTwo.accounts.debt.amount.used,
            unUsed: updatedTwo.accounts.debt.amount.unUsed,
          },
          debtAmount: updatedTwo.accounts.debt.debtAmount,
        },
      },
      transactions: updatedTwo.transactions,
    };
    if (user.latterDaySaint === true) {
      updateObject.accounts.tithing = {
        tithingSetting: updatedTwo.accounts.tithing.tithingSetting,
        amount: updatedTwo.accounts.tithing.amount,
        amountOwed: updatedTwo.accounts.tithing.amountOwed,
      };
    }

    budget.updateBudget(updateObject, 'Enter-Income');

    const updatedThree = produce(transaction, (roughDraft) => {
      roughDraft.receipt = [];
    });

    setTransaction(updatedThree);
  }

  addToReceipt(event, transaction, setTransaction, receiptItem, setReceiptItem) {
    event.preventDefault();
    if (transaction.transactionType === 'Withdrawal') {
      if (receiptItem.account === 'Monthly Budget') {
        const updated = produce(transaction, (roughDraft) => {
          roughDraft.receipt = [
            ...roughDraft.receipt,
            {
              account: receiptItem.account,
              mainCategory: receiptItem.mainCategory,
              subCategory: receiptItem.subCategory,
              description: receiptItem.description,
              amount: receiptItem.amount,
            },
          ];
          roughDraft.total = roughDraft.receipt.reduce((total, current) => {
            return (total += current.amount);
          }, 0);
        });
        setTransaction(updated);
        setReceiptItem(new ReceiptItem());
      } else if (receiptItem.account === 'Emergency Fund') {
        const updated = produce(transaction, (roughDraft) => {
          roughDraft.receipt = [
            ...roughDraft.receipt,
            {
              account: receiptItem.account,
              description: receiptItem.description,
              amount: receiptItem.amount,
            },
          ];
          roughDraft.total = roughDraft.receipt.reduce((total, current) => {
            return (total += current.amount);
          }, 0);
        });
        setTransaction(updated);
        setReceiptItem(new ReceiptItem());
      } else if (receiptItem.account === 'Savings Fund') {
        const updated = produce(transaction, (roughDraft) => {
          roughDraft.receipt = [
            ...roughDraft.receipt,
            {
              account: receiptItem.account,
              type: receiptItem.subAccount,
              paymentCycle: receiptItem.timing,
              dueDate: receiptItem.dueDate,
              expense: receiptItem.item,
              description: receiptItem.description,
              amount: receiptItem.amount,
            },
          ];
          roughDraft.total = roughDraft.receipt.reduce((total, current) => {
            return (total += current.amount);
          }, 0);
        });
        setTransaction(updated);
        setReceiptItem(new ReceiptItem());
      } else if (receiptItem.account === 'Expense Fund') {
        const updated = produce(transaction, (roughDraft) => {
          roughDraft.receipt = [
            ...roughDraft.receipt,
            {
              account: receiptItem.account,
              type: receiptItem.subAccount,
              paymentCycle: receiptItem.timing,
              dueDate: receiptItem.dueDate,
              expense: receiptItem.item,
              description: receiptItem.description,
              amount: receiptItem.amount,
            },
          ];
          roughDraft.total = roughDraft.receipt.reduce((total, current) => {
            return (total += current.amount);
          }, 0);
        });
        setTransaction(updated);
        setReceiptItem(new ReceiptItem());
      } else if (receiptItem.account === 'Surplus') {
        const updated = produce(transaction, (roughDraft) => {
          roughDraft.receipt = [
            ...roughDraft.receipt,
            {
              account: receiptItem.account,
              type: receiptItem.subAccount,
              paymentCycle: receiptItem.timing,
              dueDate: receiptItem.dueDate,
              expense: receiptItem.item,
              description: receiptItem.description,
              amount: receiptItem.amount,
            },
          ];
          roughDraft.total = roughDraft.receipt.reduce((total, current) => {
            return (total += current.amount);
          }, 0);
        });
        setTransaction(updated);
        setReceiptItem(new ReceiptItem());
      } else if (receiptItem.account === 'Debt') {
        const updated = produce(transaction, (roughDraft) => {
          roughDraft.receipt = [
            ...roughDraft.receipt,
            {
              account: receiptItem.account,
              lender: receiptItem.lender,
              type: receiptItem.subAccount,
              paymentCycle: receiptItem.timing,
              dueDate: receiptItem.dueDate,
              expense: receiptItem.item,
              description: receiptItem.description,
              amount: receiptItem.amount,
            },
          ];
          roughDraft.total = roughDraft.receipt.reduce((total, current) => {
            return (total += current.amount);
          }, 0);
        });
        setTransaction(updated);
        setReceiptItem(new ReceiptItem());
      } else if (receiptItem.account === 'Tithing') {
        const updated = produce(transaction, (roughDraft) => {
          roughDraft.receipt = [
            ...roughDraft.receipt,
            {
              account: receiptItem.account,
              description: receiptItem.description,
              amount: receiptItem.amount,
            },
          ];
          roughDraft.total = roughDraft.receipt.reduce((total, current) => {
            return (total += current.amount);
          }, 0);
        });
        setTransaction(updated);
        setReceiptItem(new ReceiptItem());
      } else if (receiptItem.account === 'Investment Fund') {
        const updated = produce(transaction, (roughDraft) => {
          roughDraft.receipt = [
            ...roughDraft.receipt,
            {
              account: receiptItem.account,
              type: receiptItem.subAccount,
              investment: receiptItem.name,
              description: receiptItem.description,
              amount: receiptItem.amount,
            },
          ];
          roughDraft.total = roughDraft.receipt.reduce((total, current) => {
            return (total += current.amount);
          }, 0);
        });
        setTransaction(updated);
        setReceiptItem(new ReceiptItem());
      }
    }
  }
  removeFromReceipt(event, index, transaction, setTransaction) {
    event.preventDefault();
    const updated = produce(transaction, (roughDraft) => {
      roughDraft.receipt = roughDraft.receipt.filter((item) => item !== roughDraft.receipt[index]);
    });
    setTransaction(updated);
  }
}
