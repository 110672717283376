////////////////////////////////
// ICONS FOR MAIN CATEGORIES
export const icons = [
  `0`,
  `1`,
  `2`,
  `3`,
  `4`,
  `5`,
  `6`,
  `7`,
  `8`,
  `9`,
  `a`,
  `address-book`,
  `address-card`,
  `adjust`,
  `air-freshener`,
  `align-center`,
  `align-justify`,
  `align-left`,
  `align-right`,
  `allergies`,
  `ambulance`,
  `american-sign-language-interpreting`,
  `anchor`,
  `anchor-circle-check`,
  `anchor-circle-exclamation`,
  `anchor-circle-xmark`,
  `anchor-lock`,
  `angle-double-down`,
  `angle-double-left`,
  `angle-double-right`,
  `angle-double-up`,
  `angry`,
  `ankh`,
  `apple-whole`,
  `archive`,
  `archway`,
  `arrow-down`,
  `arrow-down-1-9`,
  `arrow-down-9-1`,
  `arrow-down-a-z`,
  `arrow-down-z-a`,
  `arrow-down-long`,
  `arrow-down-short-wide`,
  `arrow-down-up-across-line`,
  `arrow-down-up-lock`,
  `arrow-down-wide-short`,
  `arrow-left`,
  `arrow-left-long`,
  `arrow-pointer`,
  `arrow-right`,
  `arrow-right-arrow-left`,
  `arrow-right-from-bracket`,
  `arrow-right-to-city`,
  `arrow-rotate-left`,
  `arrow-rotate-right`,
  `arrow-trend-down`,
  `arrow-trend-up`,
  `arrow-turn-down`,
  `arrow-turn-up`,
  `arrow-up`,
  `arrow-up-1-9`,
  `arrow-up-9-1`,
  `arrow-up-a-z`,
  `arrow-up-z-a`,
  `arrow-up-from-bracket`,
  `arrow-up-from-ground-water`,
  `arrow-up-from-water-pump`,
  `arrow-up-long`,
  `arrow-up-right-dots`,
  `arrow-up-right-from-square`,
  `arrow-up-short-wide`,
  `arrow-up-wide-short`,
  `arrow-alt-circle-down`,
  `arrow-alt-circle-left`,
  `arrow-alt-circle-right`,
  `arrow-alt-circle-up`,
  `arrows-alt`,
  `arrows-alt-h`,
  `arrows-alt-v`,
  `arrows-down-to-line`,
  `arrows-down-to-people`,
  `arrows-left-right`,
  `arrows-left-right-to-line`,
  `arrows-rotate`,
  `arrows-spin`,
  `arrows-split-up-and-left`,
  `arrows-to-circle`,
  `arrows-to-dot`,
  `arrows-to-eye`,
  `arrows-turn-right`,
  `arrows-turn-to-dots`,
  `arrows-up-down`,
  `arrows-up-down-left-right`,
  `arrows-up-to-line`,
  `assistive-listening-systems`,
  `asterisk`,
  `at`,
  `atlas`,
  `atom`,
  `audio-description`,
  `austral-sign`,
  `award`,
  `b`,
  `baby`,
  `baby-carriage`,
  `backspace`,
  `backward`,
  `backward-fast`,
  `backward-step`,
  `bacon`,
  `bacteria`,
  `bacterium`,
  `bag-shopping`,
  `bahai`,
  `baht-sign`,
  `balance-scale`,
  `balance-scale-left`,
  `balance-scale-right`,
  `ban`,
  `ban-smoking`,
  `band-aid`,
  `bangladeshi-taka-sign`,
  `barcode`,
  `bars`,
  `bars-progress`,
  `bars-staggered`,
  `baseball`,
  `baseball-ball`,
  `baseball-bat-ball`,
  `basket-shopping`,
  `basketball`,
  `bath`,
  `battery-empty`,
  `battery-full`,
  `battery-half`,
  `battery-quarter`,
  `battery-three-quarters`,
  `bed`,
  `bed-pulse`,
  `beer`,
  `beer-mug-empty`,
  `bell`,
  `bell-concierge`,
  `bell-slash`,
  `bezier-curve`,
  `bible`,
  `bicycle`,
  `biking`,
  `binoculars`,
  `biohazard`,
  `birthday-cake`,
  `bitcoin-sign`,
  `blender`,
  `blender-phone`,
  `blind`,
  `blog`,
  `bold`,
  `bolt`,
  `bolt-lightning`,
  `bomb`,
  `bone`,
  `bong`,
  `book`,
  `book-atlas`,
  `book-bible`,
  `book-bookmark`,
  `book-dead`,
  `book-journal-whills`,
  `book-medical`,
  `book-open`,
  `book-quran`,
  `book-reader`,
  `book-skull`,
  `book-tanakh`,
  `bookmark`,
  `border-all`,
  `border-none`,
  `border-top-left`,
  `border-style`,
  `bore-hole`,
  `bottle-droplet`,
  `bottle-water`,
  `bowl-food`,
  `bowl-rice`,
  `bowling-ball`,
  `box`,
  `box-archive`,
  `boxes`,
  `box-open`,
  `box-tissue`,
  `boxes-packing`,
  `braille`,
  `brain`,
  `brazilian-real-sign`,
  `bread-slice`,
  `bridge`,
  `bridge-circle-check`,
  `bridge-circle-exclamation`,
  `bridge-circle-xmark`,
  `bridge-lock`,
  `bridge-water`,
  `briefcase`,
  `briefcase-medical`,
  `broadcast-tower`,
  `broom`,
  `brush`,
  `bucket`,
  `bug`,
  `bug-slash`,
  `bugs`,
  `building`,
  `building-circle-arrow-right`,
  `building-circle-check`,
  `building-circle-exclamation`,
  `building-circle-xmark`,
  `building-columns`,
  `building-flag`,
  `building-lock`,
  `building-ngo`,
  `building-shield`,
  `building-un`,
  `building-user`,
  `building-wheat`,
  `bullhorn`,
  `bullseye`,
  `burger`,
  `burn`,
  `burst`,
  `bus`,
  `bus-simple`,
  `business-time`,
  `c`,
  `cable-car`,
  `cake-candles`,
  `calculator`,
  `calendar`,
  `calendar-check`,
  `calendar-day`,
  `calendar-days`,
  `calendar-minus`,
  `calendar-plus`,
  `calendar-times`,
  `calendar-week`,
  `calendar-xmark`,
  `camera`,
  `camera-retro`,
  `camera-rotate`,
  `campground`,
  `candy-cane`,
  `cannabis`,
  `capsules`,
  `car`,
  `car-battery`,
  `car-on`,
  `car-rear`,
  `car-side`,
  `car-tunnel`,
  `caravan`,
  `car-crash`,
  `caret-down`,
  `caret-left`,
  `caret-right`,
  `caret-up`,
  `carrot`,
  `cart-arrow-down`,
  `cart-flatbed`,
  `cart-flatbed-suitcase`,
  `cart-plus`,
  `cart-shopping`,
  `cash-register`,
  `cat`,
  `cedi-sign`,
  `cent-sign`,
  `certificate`,
  `chair`,
  `chalkboard`,
  `chalkboard-teacher`,
  `champagne-glasses`,
  `charging-station`,
  `chart-area`,
  `chart-bar`,
  `chart-column`,
  `chart-gantt`,
  `chart-line`,
  `chart-pie`,
  `chart-simple`,
  `check`,
  `check-circle`,
  `check-double`,
  `check-to-slot`,
  `check-square`,
  `cheese`,
  `chess`,
  `chess-bishop`,
  `chess-board`,
  `chess-king`,
  `chess-knight`,
  `chess-pawn`,
  `chess-queen`,
  `chess-rook`,
  `chevron-down`,
  `chevron-left`,
  `chevron-right`,
  `chevron-up`,
  `chevron-circle-down`,
  `chevron-circle-left`,
  `chevron-circle-right`,
  `chevron-circle-up`,
  `child`,
  `child-combatant`,
  `child-dress`,
  `child-reaching`,
  `children`,
  `church`,
  `circle`,
  `circle-arrow-down`,
  `circle-arrow-left`,
  `circle-arrow-right`,
  `circle-arrow-up`,
  `circle-check`,
  `circle-dollar-to-slot`,
  `circle-dot`,
  `circle-down`,
  `circle-exclamation`,
  `circle-h`,
  `circle-half-stroke`,
  `circle-info`,
  `circle-left`,
  `circle-minus`,
  `circle-nodes`,
  `circle-notch`,
  `circle-pause`,
  `circle-play`,
  `circle-plus`,
  `circle-question`,
  `circle-radiation`,
  `circle-right`,
  `circle-stop`,
  `circle-up`,
  `circle-user`,
  `circle-xmark`,
  `city`,
  `clapperboard`,
  `clinic-medical`,
  `clipboard`,
  `clipboard-check`,
  `clipboard-list`,
  `clipboard-question`,
  `clipboard-user`,
  `clock`,
  `clock-rotate-left`,
  `clone`,
  `closed-captioning`,
  `cloud`,
  `cloud-arrow-down`,
  `cloud-arrow-up`,
  `cloud-bolt`,
  `cloud-download-alt`,
  `cloud-meatball`,
  `cloud-moon`,
  `cloud-moon-rain`,
  `cloud-rain`,
  `cloud-showers-heavy`,
  `cloud-showers-water`,
  `cloud-sun`,
  `cloud-sun-rain`,
  `clover`,
  `cocktail`,
  `code`,
  `code-branch`,
  `code-commit`,
  `code-compare`,
  `code-fork`,
  `code-merge`,
  `code-pull-request`,
  `coffee`,
  `cog`,
  `cogs`,
  `coins`,
  `colon-sign`,
  `columns`,
  `comment`,
  `comment-alt`,
  `comment-dollar`,
  `comment-dots`,
  `comment-medical`,
  `comment-sms`,
  `comments`,
  `comments-dollar`,
  `comment-slash`,
  `compact-disc`,
  `compass`,
  `compass-drafting`,
  `compress`,
  `compress-alt`,
  `compress-arrows-alt`,
  `computer`,
  `computer-mouse`,
  `concierge-bell`,
  `cookie`,
  `cookie-bite`,
  `copy`,
  `copyright`,
  `couch`,
  `cow`,
  `credit-card`,
  `crop`,
  `crop-alt`,
  `crop-simple`,
  `cross`,
  `crosshairs`,
  `crow`,
  `crown`,
  `crutch`,
  `cruzeiro-sign`,
  `cube`,
  `cubes`,
  `cubes-stacked`,
  `cut`,
  `d`,
  `database`,
  `deaf`,
  `delete-left`,
  `democrat`,
  `desktop`,
  `dharmachakra`,
  `diagram-next`,
  `diagram-predecessor`,
  `diagram-project`,
  `diagram-successor`,
  `diagnoses`,
  `diamond`,
  `diamond-turn-right`,
  `dice`,
  `dice-d6`,
  `dice-d20`,
  `dice-five`,
  `dice-four`,
  `dice-one`,
  `dice-six`,
  `dice-three`,
  `dice-two`,
  `digital-tachograph`,
  `directions`,
  `disease`,
  `display`,
  `divide`,
  `dizzy`,
  `dna`,
  `dog`,
  `dollar-sign`,
  `dolly`,
  `dolly-flatbed`,
  `donate`,
  `dong-sign`,
  `door-closed`,
  `door-open`,
  `dot-circle`,
  `dove`,
  `down-left-and-up-right-to-center`,
  `down-long`,
  `download`,
  `drafting-compass`,
  `dragon`,
  `draw-polygon`,
  `droplet`,
  `droplet-slash`,
  `drum`,
  `drum-steelpan`,
  `drumstick-bite`,
  `dumbbell`,
  `dumpster`,
  `dumpster-fire`,
  `dungeon`,
  `e`,
  `ear-deaf`,
  `ear-listen`,
  `earth-africa`,
  `earth-americas`,
  `earth-asia`,
  `earth-europe`,
  `earth-oceania`,
  `edit`,
  `egg`,
  `eject`,
  `elevator`,
  `ellipsis`,
  `ellipsis-vertical`,
  `envelope`,
  `envelope-circle-check`,
  `envelope-open`,
  `envelope-open-text`,
  `envelope-square`,
  `envelopes-bulk`,
  `equals`,
  `eraser`,
  `ethernet`,
  `euro-sign`,
  `exchange-alt`,
  `exclamation`,
  `exclamation-circle`,
  `exclamation-triangle`,
  `expand`,
  `expand-alt`,
  `expand-arrows-alt`,
  `explosion`,
  `external-link-alt`,
  `external-link-square-alt`,
  `eye`,
  `eye-dropper`,
  `eye-low-vision`,
  `eye-slash`,
  `f`,
  `face-angry`,
  `face-dizzy`,
  `face-flushed`,
  `face-frown`,
  `face-frown-open`,
  `face-grimace`,
  `face-grin`,
  `face-grin-beam`,
  `face-grin-beam-sweat`,
  `face-grin-hearts`,
  `face-grin-squint`,
  `face-grin-squint-tears`,
  `face-grin-stars`,
  `face-grin-tears`,
  `face-grin-tongue`,
  `face-grin-tongue-squint`,
  `face-grin-tongue-wink`,
  `face-grin-wide`,
  `face-grin-wink`,
  `face-kiss`,
  `face-kiss-beam`,
  `face-kiss-wink-heart`,
  `face-laugh`,
  `face-laugh-beam`,
  `face-laugh-squint`,
  `face-laugh-wink`,
  `face-meh`,
  `face-meh-blank`,
  `face-rolling-eyes`,
  `face-sad-cry`,
  `face-sad-tear`,
  `face-smile`,
  `face-smile-beam`,
  `face-smile-wink`,
  `face-surprise`,
  `face-tired`,
  `fan`,
  `faucet`,
  `faucet-drip`,
  `fast-backward`,
  `fast-forward`,
  `fax`,
  `feather`,
  `feather-pointed`,
  `female`,
  `ferry`,
  `file`,
  `file-archive`,
  `file-arrow-down`,
  `file-arrow-up`,
  `file-audio`,
  `file-circle-check`,
  `file-circle-exclamation`,
  `file-circle-minus`,
  `file-circle-plus`,
  `file-circle-question`,
  `file-circle-xmark`,
  `file-code`,
  `file-contract`,
  `file-csv`,
  `file-download`,
  `file-excel`,
  `file-export`,
  `file-image`,
  `file-import`,
  `file-invoice`,
  `file-invoice-dollar`,
  `file-lines`,
  `file-medical`,
  `file-medical-alt`,
  `file-pdf`,
  `file-pen`,
  `file-powerpoint`,
  `file-prescription`,
  `file-shield`,
  `file-signature`,
  `file-upload`,
  `file-video`,
  `file-waveform`,
  `file-word`,
  `file-zipper`,
  `fill`,
  `fill-drip`,
  `film`,
  `filter`,
  `filter-circle-xmark`,
  `fingerprint`,
  `fire`,
  `fire-alt`,
  `fire-burner`,
  `fire-extinguisher`,
  `fire-flame-curved`,
  `fire-flame-simple`,
  `first-aid`,
  `fish`,
  `fish-fins`,
  `fist-raised`,
  `flag`,
  `flag-checkered`,
  `flag-usa`,
  `flask`,
  `flask-vial`,
  `floppy-disk`,
  `florin-sign`,
  `folder`,
  `folder-closed`,
  `folder-minus`,
  `folder-open`,
  `folder-plus`,
  `folder-tree`,
  `font`,
  `football`,
  `forward-step`,
  `franc-sign`,
  `frog`,
  `funnel-dollar`,
  `futbol`,
  `g`,
  `gamepad`,
  `gas-pump`,
  `gauge`,
  `gauge-high`,
  `gauge-simple`,
  `gauge-simple-high`,
  `gavel`,
  `gear`,
  `gears`,
  `gem`,
  `genderless`,
  `ghost`,
  `gift`,
  `gifts`,
  `glass-cheers`,
  `glass-water`,
  `glass-water-droplet`,
  `glasses`,
  `globe`,
  `golf-ball-tee`,
  `gopuram`,
  `graduation-cap`,
  `greater-than`,
  `greater-than-equal`,
  `grip`,
  `grip-lines`,
  `grip-lines-vertical`,
  `grip-vertical`,
  `group-arrows-rotate`,
  `guarani-sign`,
  `guitar`,
  `gun`,
  `h`,
  `hammer`,
  `hamsa`,
  `hand`,
  `hand-back-fist`,
  `hand-dots`,
  `hand-fist`,
  `hand-holding`,
  `hand-holding-heart`,
  `hand-holding-dollar`,
  `hand-holding-droplet`,
  `hand-holding-hand`,
  `hand-holding-medical`,
  `hand-lizard`,
  `hand-middle-finger`,
  `hand-paper`,
  `hand-peace`,
  `hand-point-down`,
  `hand-pointer`,
  `hand-point-left`,
  `hand-point-right`,
  `hand-point-up`,
  `hand-rock`,
  `hands`,
  `hand-scissors`,
  `hand-sparkles`,
  `hands-helping`,
  `hand-spock`,
  `hands-bound`,
  `hands-bubbles`,
  `hands-clapping`,
  `hands-holding`,
  `hands-holding-child`,
  `hands-holding-circle`,
  `hands-praying`,
  `handshake`,
  `handshake-angle`,
  `handshake-simple`,
  `handshake-simple-slash`,
  `hanukiah`,
  `hard-drive`,
  `hard-hat`,
  `hashtag`,
  `hat-cowboy`,
  `hat-cowboy-side`,
  `hat-wizard`,
  `hdd`,
  `head-side-cough`,
  `head-side-cough-slash`,
  `head-side-mask`,
  `head-side-virus`,
  `heading`,
  `headphones`,
  `headphones-simple`,
  `headset`,
  `heart`,
  `heartbeat`,
  `heart-broken`,
  `heart-circle-bolt`,
  `heart-circle-check`,
  `heart-circle-exclamation`,
  `heart-circle-minus`,
  `heart-circle-plus`,
  `heart-circle-xmark`,
  `heart-pulse`,
  `helicopter`,
  `helicopter-symbol`,
  `helmet-un`,
  `highlighter`,
  `hill-avalanche`,
  `hill-rockslide`,
  `hiking`,
  `hippo`,
  `history`,
  `hockey-puck`,
  `holly-berry`,
  `horse`,
  `horse-head`,
  `hospital`,
  `hospital-alt`,
  `hospital-symbol`,
  `hospital-user`,
  `hotdog`,
  `hotel`,
  `hot-tub`,
  `hourglass-end`,
  `hourglass-half`,
  `hourglass-start`,
  `house`,
  `house-chimney`,
  `house-chimney-crack`,
  `house-chimney-medical`,
  `house-chimney-user`,
  `house-chimney-window`,
  `house-circle-check`,
  `house-circle-exclamation`,
  `house-circle-xmark`,
  `house-crack`,
  `house-fire`,
  `house-flag`,
  `house-flood-water`,
  `house-flood-water-circle-arrow-right`,
  `house-laptop`,
  `house-lock`,
  `house-medical`,
  `house-medical-circle-check`,
  `house-medical-circle-exclamation`,
  `house-medical-circle-xmark`,
  `house-medical-flag`,
  `house-signal`,
  `house-tsunami`,
  `house-user`,
  `hryvnia-sign`,
  `hurricane`,
  `h-square`,
  `i`,
  `ice-cream`,
  `icicles`,
  `icons`,
  `i-cursor`,
  `id-badge`,
  `id-card`,
  `id-card-alt`,
  `id-card-clip`,
  `igloo`,
  `image`,
  `image-portrait`,
  `images`,
  `inbox`,
  `indent`,
  `indian-rupee-sign`,
  `industry`,
  `infinity`,
  `info`,
  `info-circle`,
  `italic`,
  `j`,
  `jar`,
  `jar-wheat`,
  `jedi`,
  `jet-fighter`,
  `jet-fighter-up`,
  `joint`,
  `k`,
  `kaaba`,
  `key`,
  `keyboard`,
  `khanda`,
  `kip-sign`,
  `kit-medical`,
  `kitchen-set`,
  `kiwi-bird`,
  `l`,
  `landmine-on`,
  `landmark`,
  `landmark-dome`,
  `landmark-flag`,
  `language`,
  `laptop`,
  `laptop-code`,
  `laptop-file`,
  `laptop-medical`,
  `lari-sign`,
  `layer-group`,
  `leaf`,
  `left-long`,
  `left-right`,
  `lemon`,
  `less-than`,
  `less-than-equal`,
  `level-down-alt`,
  `level-up-alt`,
  `life-ring`,
  `lightbulb`,
  `lines-leaning`,
  `link`,
  `link-slash`,
  `lira-sign`,
  `list`,
  `list-alt`,
  `list-check`,
  `list-ol`,
  `list-ul`,
  `litecoin-sign`,
  `location-arrow`,
  `location-crosshairs`,
  `location-dot`,
  `location-pin`,
  `location-pin-lock`,
  `lock`,
  `lock-open`,
  `locust`,
  `long-arrow-alt-down`,
  `long-arrow-alt-left`,
  `long-arrow-alt-right`,
  `long-arrow-alt-up`,
  `luggage-cart`,
  `lungs`,
  `lungs-virus`,
  `m`,
  `magic`,
  `magnet`,
  `magnifying-glass`,
  `magnifying-glass-arrow-right`,
  `magnifying-glass-chart`,
  `magnifying-glass-dollar`,
  `magnifying-glass-location`,
  `magnifying-glass-minus`,
  `magnifying-glass-plus`,
  `mail-bulk`,
  `male`,
  `manat-sign`,
  `map`,
  `map-location`,
  `map-location-dot`,
  `map-marked`,
  `map-marked-alt`,
  `map-marker`,
  `map-marker-alt`,
  `map-pin`,
  `map-signs`,
  `marker`,
  `mars`,
  `mars-and-venus`,
  `mars-and-venus-burst`,
  `mars-double`,
  `mars-stroke`,
  `mars-stroke-right`,
  `mars-stroke-up`,
  `martini-glass`,
  `martini-glass-citrus`,
  `martini-glass-empty`,
  `mask`,
  `mask-face`,
  `mask-ventilator`,
  `masks-theater`,
  `mattress-pillow`,
  `maximize`,
  `medal`,
  `medkit`,
  `memory`,
  `menorah`,
  `mercury`,
  `message`,
  `meteor`,
  `microchip`,
  `microphone`,
  `microphone-lines`,
  `microphone-alt-slash`,
  `microphone-slash`,
  `microscope`,
  `mill-sign`,
  `minimize`,
  `minus`,
  `minus-circle`,
  `minus-square`,
  `mitten`,
  `mobile`,
  `mobile-button`,
  `mobile-retro`,
  `mobile-screen`,
  `mobile-screen-button`,
  `money-bill`,
  `money-bill-1`,
  `money-bill-1-wave`,
  `money-bill-alt`,
  `money-bill-transfer`,
  `money-bill-trend-up`,
  `money-bill-wave`,
  `money-bill-wheat`,
  `money-bills`,
  `money-check`,
  `money-check-dollar`,
  `monument`,
  `moon`,
  `mortar-pestle`,
  `mosque`,
  `mosquito`,
  `mosquito-net`,
  `motorcycle`,
  `mound`,
  `mountain`,
  `mountain-city`,
  `mountain-sun`,
  `mouse`,
  `mouse-pointer`,
  `mug-hot`,
  `mug-saucer`,
  `music`,
  `n`,
  `naira-sign`,
  `network-wired`,
  `neuter`,
  `newspaper`,
  `not-equal`,
  `notdef`,
  `note-sticky`,
  `notes-medical`,
  `o`,
  `object-group`,
  `object-ungroup`,
  `oil-can`,
  `om`,
  `otter`,
  `outdent`,
  `p`,
  `pager`,
  `paint-roller`,
  `paintbrush`,
  `palette`,
  `pallet`,
  `panorama`,
  `paper-plane`,
  `paperclip`,
  `parachute-box`,
  `paragraph`,
  `parking`,
  `passport`,
  `pastafarianism`,
  `paste`,
  `pause`,
  `pause-circle`,
  `paw`,
  `peace`,
  `pen`,
  `pen-alt`,
  `pen-clip`,
  `pencil-alt`,
  `pencil-ruler`,
  `pen-fancy`,
  `pen-nib`,
  `pen-to-square`,
  `people-arrows`,
  `people-carry-box`,
  `people-group`,
  `people-line`,
  `people-pulling`,
  `people-robbery`,
  `people-roof`,
  `pepper-hot`,
  `percent`,
  `person`,
  `person-arrow-down-to-line`,
  `person-arrow-up-from-line`,
  `person-biking`,
  `person-booth`,
  `person-breastfeeding`,
  `person-burst`,
  `person-cane`,
  `person-chalkboard`,
  `person-circle-check`,
  `person-circle-exclamation`,
  `person-circle-minus`,
  `person-circle-plus`,
  `person-circle-question`,
  `person-circle-xmark`,
  `person-digging`,
  `person-dots-from-line`,
  `person-dress`,
  `person-dress-burst`,
  `person-drowning`,
  `person-falling`,
  `person-falling-burst`,
  `person-half-dress`,
  `person-harassing`,
  `person-hiking`,
  `person-military-pointing`,
  `person-military-rifle`,
  `person-military-to-person`,
  `person-praying`,
  `person-pregnant`,
  `person-rays`,
  `person-rifle`,
  `person-running`,
  `person-shelter`,
  `person-skating`,
  `person-skating`,
  `person-skiing`,
  `person-skiing-nordic`,
  `person-snowboarding`,
  `person-swimming`,
  `person-through-window`,
  `person-walking`,
  `person-walking-arrow-loop-left`,
  `person-walking-arrow-right`,
  `person-walking-dashed-line-arrow`,
  `person-walking-luggage`,
  `person-walking-with-cane`,
  `peseta-sign`,
  `peso-sign`,
  `phone`,
  `phone-flip`,
  `phone-slash`,
  `phone-volume`,
  `photo-film`,
  `piggy-bank`,
  `pills`,
  `pizza-slice`,
  `place-of-worship`,
  `plane`,
  `plane-arrival`,
  `plane-circle-check`,
  `plane-circle-exclamation`,
  `plane-circle-xmark`,
  `plane-departure`,
  `plane-lock`,
  `plane-slash`,
  `plane-up`,
  `plant-wilt`,
  `plate-wheat`,
  `play`,
  `play-circle`,
  `plug`,
  `plug-circle-bolt`,
  `plug-circle-check`,
  `plug-circle-exclamation`,
  `plug-circle-minus`,
  `plug-circle-plus`,
  `plug-circle-xmark`,
  `plus`,
  `plus-circle`,
  `plus-square`,
  `plus-minus`,
  `podcast`,
  `poll`,
  `poll-h`,
  `poo`,
  `poop`,
  `poo-storm`,
  `portrait`,
  `pound-sign`,
  `power-off`,
  `prescription`,
  `prescription-bottle`,
  `prescription-bottle-alt`,
  `prescription-bottle-medical`,
  `print`,
  `procedures`,
  `project-diagram`,
  `pump-medical`,
  `pump-soap`,
  `puzzle-piece`,
  `q`,
  `qrcode`,
  `question`,
  `question-circle`,
  `quidditch`,
  `quote-left`,
  `quote-right`,
  `r`,
  `radiation`,
  `radiation-alt`,
  `radio`,
  `rainbow`,
  `random`,
  `ranking-star`,
  `receipt`,
  `record-vinyl`,
  `rectangle-ad`,
  `rectangle-list`,
  `recycle`,
  `redo`,
  `registered`,
  `remove-format`,
  `repeat`,
  `reply`,
  `reply-all`,
  `republican`,
  `restroom`,
  `retweet`,
  `ribbon`,
  `right-from-bracket`,
  `right-left`,
  `right-long`,
  `right-to-bracket`,
  `ring`,
  `road`,
  `road-barrier`,
  `road-bridge`,
  `road-circle-check`,
  `road-circle-exclamation`,
  `road-circle-xmark`,
  `road-lock`,
  `road-spikes`,
  `robot`,
  `rocket`,
  `rotate`,
  `rotate-left`,
  `rotate-right`,
  `route`,
  `rss`,
  `rss-square`,
  `ruble-sign`,
  `rug`,
  `ruler`,
  `ruler-combined`,
  `ruler-horizontal`,
  `ruler-vertical`,
  `running`,
  `rupee-sign`,
  `rupiah-sign`,
  `s`,
  `sack-dollar`,
  `sack-xmark`,
  `sailboat`,
  `satellite`,
  `satellite-dish`,
  `save`,
  `school`,
  `school-circle-check`,
  `school-circle-exclamation`,
  `school-circle-xmark`,
  `school-flag`,
  `school-lock`,
  `scissors`,
  `screwdriver`,
  `screwdriver-wrench`,
  `scroll`,
  `scroll-torah`,
  `sd-card`,
  `section`,
  `seedling`,
  `server`,
  `shapes`,
  `share`,
  `share-alt-square`,
  `share-from-square`,
  `share-nodes`,
  `sheet-plastic`,
  `shekel-sign`,
  `shield`,
  `shield-cat`,
  `shield-dog`,
  `shield-halved`,
  `shield-heart`,
  `shield-virus`,
  `ship`,
  `shipping-fast`,
  `shirt`,
  `shoe-prints`,
  `shop`,
  `shop-lock`,
  `shop-slash`,
  `shopping-bag`,
  `shopping-basket`,
  `shopping-cart`,
  `shower`,
  `shrimp`,
  `shuffle`,
  `shuttle-van`,
  `sign`,
  `signal`,
  `signature`,
  `sign-in-alt`,
  `sign-language`,
  `sign-out-alt`,
  `signs-post`,
  `sim-card`,
  `sink`,
  `sitemap`,
  `skull`,
  `skull-crossbones`,
  `slash`,
  `sleigh`,
  `sliders`,
  `smog`,
  `smoking`,
  `smoking-ban`,
  `sms`,
  `snowboarding`,
  `snowflake`,
  `snowman`,
  `snowplow`,
  `soap`,
  `socks`,
  `solar-panel`,
  `sort`,
  `sort-down`,
  `sort-up`,
  `spa`,
  `space-shuttle`,
  `spell-check`,
  `spider`,
  `spinner`,
  `splotch`,
  `spoon`,
  `spray-can`,
  `spray-can-sparkles`,
  `square`,
  `square-arrow-up-right`,
  `square-caret-down`,
  `square-caret-left`,
  `square-caret-right`,
  `square-caret-up`,
  `square-check`,
  `square-envelope`,
  `square-full`,
  `square-h`,
  `square-minus`,
  `square-nfi`,
  `square-parking`,
  `square-pen`,
  `square-person-confined`,
  `square-phone`,
  `square-phone-flip`,
  `square-plus`,
  `square-poll-horizontal`,
  `square-poll-vertical`,
  `square-root-variable`,
  `square-share-nodes`,
  `square-up-right`,
  `square-virus`,
  `square-xmark`,
  `staff-snake`,
  `stairs`,
  `stamp`,
  `stapler`,
  `star`,
  `star-and-crescent`,
  `star-half`,
  `star-half-stroke`,
  `star-of-david`,
  `star-of-life`,
  `step-backward`,
  `step-forward`,
  `sterling-sign`,
  `stethoscope`,
  `sticky-note`,
  `stop`,
  `stop-circle`,
  `stopwatch`,
  `stopwatch-20`,
  `store`,
  `store-alt`,
  `store-slash`,
  `stream`,
  `street-view`,
  `strikethrough`,
  `stroopwafel`,
  `subscript`,
  `subway`,
  `suitcase`,
  `suitcase-rolling`,
  `sun`,
  `sun-plant-wilt`,
  `superscript`,
  `swatchbook`,
  `swimmer`,
  `swimming-pool`,
  `synagogue`,
  `sync`,
  `syringe`,
  `t`,
  `table`,
  `table-cells`,
  `table-cells-large`,
  `table-columns`,
  `table-list`,
  `table-tennis-paddle-ball`,
  `tablet`,
  `tablet-button`,
  `tablet-screen-button`,
  `tablets`,
  `tachograph-digital`,
  `tachometer-alt`,
  `tag`,
  `tags`,
  `tape`,
  `tarp`,
  `tarp-droplet`,
  `tasks`,
  `taxi`,
  `teeth`,
  `teeth-open`,
  `temperature-arrow-down`,
  `temperature-arrow-up`,
  `temperature-empty`,
  `temperature-full`,
  `temperature-high`,
  `temperature-low`,
  `temperature-quarter`,
  `temperature-three-quarters`,
  `tenge-sign`,
  `tent`,
  `tent-arrow-down-to-line`,
  `tent-arrow-left-right`,
  `tent-arrow-turn-left`,
  `tent-arrows-down`,
  `tents`,
  `terminal`,
  `text-height`,
  `text-slash`,
  `text-width`,
  `theater-masks`,
  `thermometer`,
  `thumbs-down`,
  `thumbs-up`,
  `thumbtack`,
  `ticket`,
  `ticket-simple`,
  `timeline`,
  `times`,
  `times-circle`,
  `tint`,
  `tint-slash`,
  `tired`,
  `toggle-off`,
  `toggle-on`,
  `toilet`,
  `toilet-paper`,
  `toilet-paper-slash`,
  `toilet-portable`,
  `toilets-portable`,
  `toolbox`,
  `tools`,
  `tooth`,
  `torii-gate`,
  `tornado`,
  `tower-broadcast`,
  `tower-cell`,
  `tower-observation`,
  `tractor`,
  `trademark`,
  `traffic-light`,
  `trailer`,
  `train`,
  `train-subway`,
  `train-tram`,
  `transgender`,
  `transgender-alt`,
  `trash`,
  `trash-alt`,
  `trash-arrow-up`,
  `trash-can-arrow-up`,
  `trash-restore`,
  `trash-restore-alt`,
  `tree`,
  `tree-city`,
  `triangle-exclamation`,
  `trophy`,
  `trowel`,
  `trowel-bricks`,
  `truck`,
  `truck-arrow-right`,
  `truck-droplet`,
  `truck-fast`,
  `truck-field`,
  `truck-field-un`,
  `truck-front`,
  `truck-loading`,
  `truck-medical`,
  `truck-monster`,
  `truck-moving`,
  `truck-pickup`,
  `truck-plane`,
  `truck-ramp-box`,
  `tshirt`,
  `tty`,
  `turkish-lira-sign`,
  `turn-down`,
  `turn-up`,
  `tv`,
  `u`,
  `umbrella`,
  `umbrella-beach`,
  `underline`,
  `undo`,
  `universal-access`,
  `university`,
  `unlink`,
  `unlock`,
  `unlock-alt`,
  `unlock-keyhole`,
  `up-down`,
  `up-down-left-right`,
  `up-long`,
  `up-right-and-down-left-from-center`,
  `up-right-from-square`,
  `upload`,
  `user`,
  `user-astronaut`,
  `user-check`,
  `user-circle`,
  `user-clock`,
  `user-cog`,
  `user-doctor`,
  `user-edit`,
  `user-friends`,
  `user-gear`,
  `user-graduate`,
  `user-group`,
  `user-injured`,
  `user-large`,
  `user-large-slash`,
  `user-lock`,
  `user-minus`,
  `user-ninja`,
  `user-nurse`,
  `user-pen`,
  `user-plus`,
  `user-secret`,
  `user-shield`,
  `user-slash`,
  `user-tag`,
  `user-tie`,
  `user-xmark`,
  `users`,
  `users-between-lines`,
  `users-gear`,
  `users-line`,
  `users-rays`,
  `users-rectangle`,
  `users-slash`,
  `users-viewfinder`,
  `utensils`,
  `utensil-spoon`,
  `v`,
  `vault`,
  `vector-square`,
  `venus`,
  `venus-double`,
  `venus-mars`,
  `vest`,
  `vest-patches`,
  `vial`,
  `vial-circle-check`,
  `vial-virus`,
  `vials`,
  `video`,
  `video-slash`,
  `vihara`,
  `virus`,
  `virus-covid`,
  `virus-covid-slash`,
  `virus-slash`,
  `viruses`,
  `voicemail`,
  `volcano`,
  `volleyball`,
  `volume-down`,
  `volume-mute`,
  `volume-off`,
  `volume-up`,
  `vote-yea`,
  `vr-cardboard`,
  `w`,
  `walkie-talkie`,
  `walking`,
  `wallet`,
  `wand-magic`,
  `wand-magic-sparkles`,
  `wand-sparkles`,
  `warehouse`,
  `water`,
  `water-ladder`,
  `wave-square`,
  `weight`,
  `weight-hanging`,
  `weight-scale`,
  `wheat-awn`,
  `wheat-awn-circle-exclamation`,
  `wheelchair`,
  `wheelchair-move`,
  `whiskey-glass`,
  `wifi`,
  `wind`,
  `window-close`,
  `window-maximize`,
  `window-minimize`,
  `window-restore`,
  `wine-bottle`,
  `wine-glass`,
  `wine-glass-alt`,
  `wine-glass-empty`,
  `won-sign`,
  `worm`,
  `wrench`,
  `x`,
  `x-ray`,
  `xmark`,
  `xmarks-lines`,
  `y`,
  `yen-sign`,
  `yin-yang`,
  `z`,
];
