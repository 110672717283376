import React, { useState } from 'react';
import styled from 'styled-components';
import { BudgetPageSection } from '../../Global/Forms/Section';
import { BudgetContainerHeader } from '../../Global/Forms/Header';
import { ExtraSmallContainer } from '../../Global/Budget/BudgetContainers/ExtraSmallContainer';
import { StyledContentContainer } from '../../Global/Budget/BudgetContainers/BasicContainer';
import FormSection from '../../Global/Forms/Section';
import { StyledLargeContainer } from '../../Global/Budget/BudgetContainers/LargeContainer';
import { Form } from '../../Global/Forms/Form';
import { SubCategoryGoalInput } from '../../Global/Forms/Input';
import { SwitchLabel } from '../../Global/Forms/Label';
import { PlanningAccountSelect } from '../../Global/Forms/Select';
import { PrimaryButton, SmallBorderlessButton } from '../../Global/Button';
import { DebtElement } from '../../Global/Budget/Debt';
import { Debt } from '../../../Classes/Debt';
import { handleTransaction } from '../../Global/Forms/Algorithms/InputChange';
import ErrorContainer from '../../Global/Forms/Errors';

export const DebtManager = function (props) {
  const { theme, user, setUser, budget, setBudget, transaction, setTransaction, handleInputChange, errors, setErrors, disabledValues, setDisabledValues } = props;
  const [isAddingDebt, setIsAddingDebt] = useState(false);
  const [debt, setDebt] = useState(new Debt());

  const resetDebtPlanning = () => {
    setIsAddingDebt(!isAddingDebt);
    // setPlannedTransaction(new PlannedTransaction());
  };

  return (
    <BudgetPageSection minHeight="85%" flow="row wrap" justify="space-evenly" align="center">
      <BudgetContainerHeader budgetPage={true} height="10rem" justify="center" bgColor={'transparent'} textColor={theme.colors.tertiaryColor} className="budget-page-header">
        Debt Manager
      </BudgetContainerHeader>
      <ExtraSmallContainer
        theme={theme}
        user={user}
        budget={budget}
        title={`Debt`}
        icon={'hand-holding-dollar'}
        content={{
          content: (
            <StyledContentContainer containerType="transactional" height="100%" minHeight="100%" minWidth="100%" flow="column nowrap" justify="space-evenly" align="center">
              <FormSection minHeight="14rem" flow="row nowrap" justify="space-evenly" align="center">
                <p>{user && budget && budget.accounts && user.money.format(budget.accounts.debt.amount.total)}</p>
              </FormSection>
              <FormSection minHeight="6rem" flow="row nowrap" justify="space-evenly" align="center" borderTop={`.2rem solid ${theme.colors.primaryColor}`}>
                <FormSection minHeight="6rem" width="50%" flow="column nowrap" justify="space-evenly" align="center" appliedSection={true} textHeaderSize="1.6rem" textSize="1.2rem">
                  <p className="tp-header">Applied</p>
                  <p className="tp-text">{user && budget && budget.accounts && user.money.format(budget.accounts.debt.amount.used)}</p>
                </FormSection>
                <FormSection
                  minHeight="6rem"
                  width="50%"
                  flow="column nowrap"
                  justify="space-evenly"
                  align="center"
                  appliedSection={true}
                  textHeaderSize="1.6rem"
                  textSize="1.2rem"
                  borderLeft={`.2rem solid ${theme.colors.primaryColor}`}>
                  <p className="tp-header">Un-Applied</p>
                  <p className="tp-text">{user && budget && budget.accounts && user.money.format(budget.accounts.debt.amount.unUsed)}</p>
                </FormSection>
              </FormSection>
            </StyledContentContainer>
          ),
          margin: '0 0 3rem 0',
          bgColor: theme.colors.grayScale.offWhite,
        }}
      />
      <ErrorContainer>
        {errors && (
          <ol>
            {Object.keys(errors).map((error, index) => {
              if (errors[error] !== '') {
                return (
                  <li className="error" key={index}>
                    {errors[error]}
                  </li>
                );
              }
            })}
          </ol>
        )}
      </ErrorContainer>
      <StyledLargeContainer borderRadius=".9rem" inBudget={true} flow="column nowrap" justify="flex-start" align="center" margin="0 0 5rem">
        <BudgetContainerHeader minHeight="5rem" justify="space-between" align="center" iconSize="2.6rem" textSize="3rem" borderRadius=".5rem .5rem 0 0">
          <i className="fas fa-file-invoice-dollar" />
          <p>Unpaid Debts</p>
        </BudgetContainerHeader>
        {budget &&
          budget.debts &&
          budget.debts.length > 0 &&
          budget.debts
            .filter((debt) => debt.status !== 'Paid Off')
            .map((debt, index) => {
              return <DebtElement theme={theme} debt={debt} setDebt={setDebt} debtIndex={index} user={user} setUser={setUser} budget={budget} setBudget={setBudget} key={index} />;
            })}
        <FormSection
          modifier="transactional"
          sectionHeight="max-content"
          sectionWidth="100%"
          minHeight="5rem"
          flow="column nowrap"
          justify="flex-start"
          align="center"
          padding="0"
          borderBottom={`.2rem solid ${theme.colors.primaryColor}`}>
          {isAddingDebt === true && (
            <Form inBudget={true} formHeight="max-content" flow="row wrap" justify="center" align="center" bgColor={`${theme.colors.grayScale.offWhite}cc`}>
              <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
                <SubCategoryGoalInput
                  type="text"
                  name="lender"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  placeholder="Lender"
                  modifier="transaction"
                  onChange={(e) => handleTransaction(e, 'Debt', { user: user, budget: budget, debt: debt }, { setUser: setUser, setBudget: setBudget, setDebt: setDebt })}
                />
                <SwitchLabel modifier="transaction" width="20rem">
                  Lender
                </SwitchLabel>
              </FormSection>
              <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
                <PlanningAccountSelect
                  theme={theme}
                  user={user}
                  name="debtType"
                  options={user && [`Select Type`, `Credit Card`, `Loan`, `Taxes`, `Debt`, `Other`]}
                  content={{
                    width: '13rem',
                    formType: 'Debt',
                    values: { user: user, budget: budget, debt: debt },
                    setters: { setUser: setUser, setBudget: setBudget, setDebt: setDebt },
                  }}
                  setUser={setUser}
                  budget={budget}
                  setBudget={setBudget}
                />
                <SwitchLabel modifier="transaction" width="20rem">
                  Debt Type
                </SwitchLabel>
              </FormSection>
              <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
                <SubCategoryGoalInput
                  type="number"
                  name="initialDebt"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  min={0}
                  placeholder="Initial Debt"
                  modifier="transaction"
                  onChange={(e) =>
                    handleTransaction(e, 'Debt', { user: user, budget: budget, debt: debt, errors: errors }, { setUser: setUser, setBudget: setBudget, setDebt: setDebt, setErrors: setErrors })
                  }
                />
                <SwitchLabel modifier="transaction" width="20rem">
                  Initial Debt Amount
                </SwitchLabel>
              </FormSection>
              <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
                <SubCategoryGoalInput
                  type="number"
                  name="regularPayment"
                  minHeight="4rem"
                  maxHeight="4rem"
                  width="13rem"
                  min={0}
                  placeholder="Individual Payment"
                  modifier="transaction"
                  onChange={(e) =>
                    handleTransaction(e, 'Debt', { user: user, budget: budget, debt: debt, errors: errors }, { setUser: setUser, setBudget: setBudget, setDebt: setDebt, setErrors: setErrors })
                  }
                />
                <SwitchLabel modifier="transaction" width="20rem">
                  Individual Payment
                </SwitchLabel>
              </FormSection>
              <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse" justify="space-evenly" align="center" padding=".5rem">
                <PrimaryButton
                  size="small"
                  minWidth="10rem"
                  name="add-debt"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleTransaction(e, 'Debt', { user: user, budget: budget, debt: debt, errors: errors }, { setUser: setUser, setBudget: setBudget, setDebt: setDebt, setErrors: setErrors });
                  }}>
                  Create
                </PrimaryButton>
              </FormSection>
              <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column" justify="space-evenly" align="center" padding=".5rem">
                <PrimaryButton
                  size="small"
                  minWidth="10rem"
                  onClick={(e) => {
                    e.preventDefault();
                    resetDebtPlanning();
                  }}>
                  Close
                </PrimaryButton>
              </FormSection>
            </Form>
          )}

          {isAddingDebt === false && (
            <SmallBorderlessButton size="extra-small" onClick={() => setIsAddingDebt(!isAddingDebt)} disabled={user && user.permissions === 'Associate'}>
              + Add Debt
            </SmallBorderlessButton>
          )}
        </FormSection>
        <BudgetContainerHeader minHeight="5rem" justify="space-between" align="center" iconSize="2.6rem" textSize="3rem" borderRadius=".5rem .5rem 0 0">
          <i className="fas fa-handshake" />
          <p>Paid Off Debts</p>
        </BudgetContainerHeader>
        <FormSection
          modifier="transactional"
          sectionHeight="max-content"
          sectionWidth="100%"
          minHeight="5rem"
          flow="column nowrap"
          justify="flex-start"
          align="center"
          padding="0 0 3rem 0"
          borderBottom={`.2rem solid ${theme.colors.primaryColor}`}>
          {budget &&
            budget.debts &&
            budget.debts.length > 0 &&
            budget.debts
              .filter((debt) => debt.status === 'Paid Off')
              .map((debt, index) => {
                return <DebtElement theme={theme} debt={debt} setDebt={setDebt} debtIndex={index} user={user} setUser={setUser} budget={budget} setBudget={setBudget} key={index} />;
              })}
        </FormSection>
      </StyledLargeContainer>
    </BudgetPageSection>
  );
};
