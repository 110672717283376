import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Profile } from '../../Classes/Profile';
import { Person } from '../../Classes/Person';
import { Navigation, AccountLinks } from '../Global/Navigation';
import { Viewport } from './ApplicationDisplay';
import { getGridSize, addClasses, removeClasses, isMobileNavNeeded } from '../Utility';
import { PrimaryButton } from '../Global/Button';

export const StyledLoggedInContainer = styled.section`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  transform: translate(0, 0) rotate(0);

  background-color: ${(props) => props.theme.colors.grayScale.offWhite};

  @media ${(props) => props.theme.screens.largeTablet.portrait} {
    flex-flow: column nowrap;
    /* transform: translate(-3.5rem, -6.5rem) scale(0.75);
    ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)}; */
  }

  @media ${(props) => props.theme.screens.smallTablet.landscape} {
    flex-flow: row nowrap;
    /* transform: translate(-3.5rem, -6.5rem) scale(0.75);
    ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)}; */
  }

  @media ${(props) => props.theme.screens.smallTablet.portrait} {
    flex-flow: column nowrap;
    /* transform: translate(-3.5rem, -6.5rem) scale(0.75);
    ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)}; */
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    flex-flow: row nowrap;
    /* transform: translate(-3.5rem, -6.5rem) scale(0.75);
    ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)}; */
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    flex-flow: column nowrap;
    /* transform: translate(-3.5rem, -6.5rem) scale(0.75);
    ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)}; */
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    flex-flow: row nowrap;
    /* transform: translate(-3.5rem, -6.5rem) scale(0.75);
    ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)}; */
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    flex-flow: column nowrap;
    /* transform: translate(-3.5rem, -6.5rem) scale(0.75);
    ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)}; */
  }

  @media ${(props) => props.theme.screens.mediumMobile.landscape} {
    flex-flow: row nowrap;
    /* transform: translate(-3.5rem, -6.5rem) scale(0.75);
    ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)}; */
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    flex-flow: column nowrap;
    /* transform: translate(-3.5rem, -6.5rem) scale(0.75);
    ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)}; */
  }

  @media ${(props) => props.theme.screens.smallMobile.landscape} {
    flex-flow: row nowrap;
    /* transform: translate(-3.5rem, -6.5rem) scale(0.75);
    ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)}; */
  }

  @media ${(props) => props.theme.screens.smallMobile.portrait} {
    flex-flow: column nowrap;
    /* transform: translate(-3.5rem, -6.5rem) scale(0.75);
    ${(props) => (props.inputType === 'two-factor' ? `transform: translate(-3.5rem, -7rem) scale(0.75)` : null)}; */
  }
`;

export const LoggedIn = function (props) {
  const {
    theme,
    mobileNavigation,
    setMobileNavigation,
    profileLinks,
    setProfileLinks,
    user,
    setUser,
    budget,
    setBudget,
    categoryIndex,
    setCategoryIndex,
    changeMainCategory,
    currentCategory,
    setCurrentCategory,
    errors,
    setErrors,
    disabledValues,
    setDisabledValues,
    iconObject,
    setIconObject,
  } = props;
  const [profileInformation, setProfileInformation] = useState({});

  useEffect(() => {
    const loggedInContainer = document.querySelector('.logged-in-container');
    const budgetCardContainer = document.querySelector('.budget-card-container');
    window.addEventListener('resize', () => {
      setMobileNavigation(isMobileNavNeeded(theme));
      const replacementClasses = getGridSize('outer', theme).split(' ');
      const containerClasses = [...loggedInContainer.classList];
      containerClasses[3] = replacementClasses[0];
      containerClasses[4] = replacementClasses[1];
      removeClasses(loggedInContainer, [...loggedInContainer.classList]);
      addClasses(loggedInContainer, [...containerClasses]);

      const cardReplacementClasses = getGridSize('inner', theme).split(' ');
      const cardContainerClasses = [...budgetCardContainer.classList];

      cardContainerClasses[2] = cardReplacementClasses[0];
      cardContainerClasses[3] = cardReplacementClasses[1];

      removeClasses(budgetCardContainer, [...budgetCardContainer.classList]);
      addClasses(budgetCardContainer, [...cardContainerClasses]);
    });
  }, []);

  useEffect(() => {
    try {
      const getUser = async () => {
        let user = await new Person().build();
        setUser(user);
        setProfileInformation({
          personalInformation: {
            firstname: user.firstname,
            lastname: user.lastname,
            username: user.username,
            latterDaySaint: user.latterDaySaint,
          },
          communications: {
            email: user.email,
            newEmail: '',
            newEmailConfirmed: '',

            phoneNumber: user.phoneNumber,
            newPhoneNumber: '',
            newPhoneNumberConfirmed: '',

            communicationPreference: user.communicationPreference,
          },
          passwordManagement: {
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmed: '',
          },
        });
      };
      getUser();
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <StyledLoggedInContainer className={`logged-in-container ${getGridSize('outer', theme)}`}>
      <Navigation theme={theme} mobileNavigation={mobileNavigation} setMobileNavigation={setMobileNavigation} user={user} />
      {mobileNavigation && (
        <AccountLinks className="side-navigation__account-links" mobile={mobileNavigation}>
          <PrimaryButton size="medium" modifier="white" onClick={() => setProfileLinks(!profileLinks)}>
            Toggle Profile Links
          </PrimaryButton>
          {profileLinks && (
            <ul className="side-navigation__account-links__link-container">
              {['Personal Information', 'Communications', 'Password Management', 'Account Management'].map((buttonName, index) => {
                return (
                  <li className="side-navigation__account-links__link-container__link" key={index}>
                    <Link className="side-navigation__account-links__link-container__link--link" to={`${buttonName.split(' ').join('-')}`}>
                      {buttonName}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </AccountLinks>
      )}
      <Viewport
        theme={theme}
        mobileNavigation={mobileNavigation}
        user={user}
        setUser={setUser}
        profileInformation={profileInformation}
        setProfileInformation={setProfileInformation}
        budget={budget}
        setBudget={setBudget}
        categoryIndex={categoryIndex}
        setCategoryIndex={setCategoryIndex}
        changeMainCategory={changeMainCategory}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
        errors={errors}
        setErrors={setErrors}
        disabledValues={disabledValues}
        setDisabledValues={setDisabledValues}
        iconObject={iconObject}
        setIconObject={setIconObject}
      />
    </StyledLoggedInContainer>
  );
};
