import React from 'react';
import styled from 'styled-components';
import { SurplusSwitch } from '../Forms/Switch';

export const Category = styled.section`
  position: relative;
  height: ${(props) => props.height};
  min-height: ${(props) => props.minHeight};
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  border-radius: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: ${(props) => props.flow};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};

  &:nth-of-type(3),
  &:nth-of-type(4) {
    border-radius: 0 ${(props) => props.borderRadius} 0 0;
  }
`;

export const MainCategory = styled(Category)`
  position: relative;
  height: ${(props) => props.height};
  min-height: ${(props) => props.minHeight};
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  border-radius: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: ${(props) => props.flow};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  border: 0.2rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
  background-color: transparent;

  &:nth-of-type(3),
  &:nth-of-type(4) {
    border-radius: 0 ${(props) => props.borderRadius} 0 0;
  }

  & .main-category__icon {
    font-size: 4rem;
    color: ${(props) => `${props.theme.colors.primaryColor}cc`};
  }

  & .main-category__title {
    font-size: 2rem;
    font-family: ${(props) => props.theme.text.secondary};
    color: ${(props) => `${props.theme.colors.primaryColor}cc`};
  }

  & .main-category--delete {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.6rem;
    color: ${(props) => `${props.theme.colors.primaryColor}cc`};
  }

  &.main-category.selected {
    background-color: ${(props) => `${props.theme.colors.primaryColor}cc`};

    & .main-category__icon {
      font-size: 4rem;
      color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};
    }

    & .main-category__title {
      font-size: 2rem;
      font-family: ${(props) => props.theme.text.secondary};
      color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};
    }

    &:hover {
      & .main-category__icon {
        font-size: 4rem;
        color: ${(props) => `${props.theme.colors.grayScale.offWhite}`};
      }

      & .main-category__title {
        font-size: 2rem;
        font-family: ${(props) => props.theme.text.secondary};
        color: ${(props) => `${props.theme.colors.grayScale.offWhite}`};
      }
    }
  }

  &:hover {
    cursor: pointer;
    border: 0.2rem solid ${(props) => `${props.theme.colors.primaryColor}`};
    background-color: ${(props) => `${props.theme.colors.primaryColor}`};
    transition: border 0.5s, background-color 0.5s;

    & .main-category__icon,
    & .main-category__title {
      color: ${(props) => `${props.theme.colors.grayScale.offWhite}`};
      transition: color 0.5s;
    }

    & .main-category--delete {
      color: ${(props) => `${props.theme.colors.grayScale.offWhite}`};
      transition: color 0.5s;
      &:hover {
        cursor: pointer;
        color: ${(props) => `${props.theme.colors.secondaryColor}`};
        transition: color 0.5s;
      }
    }
  }
`;

export const StyledSubCategory = styled(Category)`
  &.sub-category {
    position: relative;
    height: ${(props) => props.height};
    min-height: ${(props) => props.minHeight};
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
    border-top: ${(props) => props.borderTop};
    border-right: ${(props) => props.borderRight};
    border-bottom: ${(props) => props.borderBottom};
    border-left: ${(props) => props.borderLeft};
    border: ${(props) => props.border};
    margin: ${(props) => props.margin};
    border-radius: 0;
    padding: 0.5rem;
    display: flex;
    flex-flow: ${(props) => props.flow};
    justify-content: ${(props) => props.justify};
    align-items: ${(props) => props.align};
    font-family: ${(props) => props.theme.text.secondary};

    &:hover {
      cursor: pointer;
      background-color: ${(props) => props.bgColor};
      transition: background-color 0.5s, color 0.5s, border 0.5s;
      & .sub-category__section {
        & button {
          color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};
          transition: color 0.5s;

          &:hover {
            color: ${(props) => props.theme.colors.grayScale.offWhite};
            transition: color 0.5s;
          }
        }
      }

      & .sub-category__title-container > .sub-category__title-container__title,
      & .sub-category__controller > .sub-category__controller__surplus-container > .sub-category__controller__surplus-container__title,
      .sub-category__controller > .sub-category__controller__icon {
        ${(props) => (props.inBudget === true ? `color: ${props.textColor}` : null)};
        transition: color 0.5s;
      }

      & .sub-category__controller > .sub-category__controller__icon:hover {
        ${(props) => (props.inBudget === true ? `color: ${props.theme.colors.tertiaryColor}` : null)};
      }
    }

    & .sub-category__title-container {
      position: relative;
      height: 100%;
      width: 70%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      transform: translate(0, 0) rotate(0);
      padding: 0 0 0 2rem;

      & .sub-category__title-container__title {
        font-size: 2rem;
        font-family: ${(props) => props.theme.text.secondary};
        color: ${(props) => props.theme.colors.primaryColor};
      }
    }

    & .sub-category__controller {
      position: relative;
      height: 100%;
      width: 30%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-evenly;
      align-items: center;
      transform: translate(0, 0) rotate(0);
      font-size: 1.6rem;

      & .sub-category__controller__surplus-container {
        position: relative;
        height: 100%;
        width: 50%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-items: center;
        transform: translate(0, 0) rotate(0);

        & .sub-category__controller__surplus-container__title {
          font-family: ${(props) => props.theme.text.secondary};
          color: ${(props) => props.theme.colors.primaryColor};
          ${(props) => (props.inBudget === true ? `font-size: ${props.textSize}` : null)};
        }
      }

      & .sub-category__controller__icon {
        color: ${(props) => `${props.theme.colors.primaryColor}cc`};
        font-size: 2rem;
        &:hover {
          cursor: pointer;
          color: ${(props) => props.theme.colors.primaryColor};
          transition: color 0.5s;
        }
      }
    }
  }
`;

export const SubCategory = function (props) {
  const { theme, user, budget, setBudget, processSubCategory, subCategory, mainCategoryIndex, content } = props;
  return (
    <StyledSubCategory
      data-category={mainCategoryIndex}
      height="max-content"
      minHeight="6rem"
      width="100%"
      flow="row nowrap"
      justify="space-around"
      align="center"
      borderBottom={`.3rem solid ${theme.colors.primaryColor}`}
      className="sub-category"
      bgColor={content && content.bgColor}
      inBudget={content && content.inBudget}
      textSize={content && content.textSize}
      textColor={content && content.textColor}>
      <section className="sub-category__title-container">
        <p className="sub-category__title-container__title">{subCategory.title}</p>
      </section>
      <section className={`sub-category__controller`}>
        <section className="sub-category__controller__surplus-container">
          <p className="sub-category__controller__surplus-container__title">Surplus?</p>
          <SurplusSwitch
            theme={theme}
            creating={content.creating || null}
            user={user}
            mainCategoryIndex={mainCategoryIndex}
            budget={budget}
            setBudget={setBudget}
            subCategory={subCategory}
            processSubCategory={processSubCategory}
          />
        </section>
        <i
          className={`fas fa-trash-alt sub-category__controller__icon ${user && user.permissions === 'Associate' ? 'closed' : ''}`}
          onClick={(e) => processSubCategory(e, 'delete', mainCategoryIndex, budget, setBudget, user)}
          data-cy={`deleteSubCategory${subCategory.title.split(' ').join('')}`}
        />
      </section>
    </StyledSubCategory>
  );
};
