import React from 'react';
import { produce } from 'immer';
import styled from 'styled-components';
import chroma from 'chroma-js';
import { toggleClasses } from '../../Utility';
import { handleInputChange, handleTransaction } from './Algorithms/InputChange';

const Toggle = styled.div`
  position: absolute;
  top: 50%;
  left: 0.1rem;
  height: 95%;
  width: 30%;
  border: 0.2rem solid transparent;
  border-radius: 5rem;
  background-color: transparent;
  transform: translate(0, -50%) rotate(0);
  transition: background-color 0.5s, transform 0.5s, width 0.25s;
`;

const LDSToggle = styled(Toggle)`
  border-color: ${(props) => chroma(`${props.theme.colors.primaryColor}`).darken(0.4).hex()};
  background-color: ${(props) => chroma(`${props.theme.colors.primaryColor}`).brighten(0.4).hex()};
`;

const CommsToggle = styled(Toggle)`
  height: 100%;
  width: 50%;
  border-color: ${(props) => chroma(`${props.theme.colors.secondaryColor}`).brighten(0.4).hex()};
  border-radius: 5rem 0 0 5rem;
  background-color: ${(props) => chroma(`${props.theme.colors.secondaryColor}`).darken(0.2).hex()};
  transition: background-color 0.5s, transform 0.5s, border-radius 0.5s, width 0.25s;
`;

const Switch = styled.section`
  position: relative;
  height: max-content;
  width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 10rem;
  border: 0.2rem solid transparent;
  border-radius: 5rem;
  background-color: transparent;
  padding: 0.1rem;
  margin: ${(props) => props.margin};

  & p {
    font-family: ${(props) => props.theme.text.secondary};
    &.tithed-text {
      ${(props) => (props.modifier === 'tithed' ? `font-size: 2.4rem` : null)};
      ${(props) => (props.modifier === 'tithed' ? `color: ${props.theme.colors.grayScale.offWhite}` : null)};
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

const LandingSwitch = styled(Switch)`
  min-height: 6rem;
  color: ${(props) => props.theme.colors.grayScale.offWhite};
  background-color: ${(props) => chroma(`${props.theme.colors.primaryColor}`).darken(0.4).hex()};
  border-color: ${(props) => props.theme.colors.primaryColor};

  & p {
    font-size: 3rem;
  }

  &.switched {
    background-color: ${(props) => chroma(`${props.theme.colors.secondaryColor}`).brighten(0.4).hex()};
    border-color: ${(props) => props.theme.colors.secondaryColor};

    & div {
      width: 99%;
      border-color: ${(props) => chroma(`${props.theme.colors.secondaryColor}`).brighten(0.4).hex()};
      background-color: ${(props) => chroma(`${props.theme.colors.secondaryColor}`).darken(0.4).hex()};
    }

    ${(props) => (props.modifier === 'tithed' ? `& div {width: 98.25%}` : null)};

    & p {
      z-index: 5;
      ${(props) => (props.modifier === 'tithed' ? `font-size: 2.4rem` : null)};
      ${(props) => (props.modifier === 'tithed' ? `color: ${props.theme.colors.grayScale.offWhite}` : null)};
    }
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    ${(props) => (props.time === 'updating' ? `height: 4rem` : null)};
    ${(props) => (props.time === 'updating' ? `min-height: 4rem` : null)};
    ${(props) => (props.time === 'updating' ? `width: 15rem` : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.landscape} {
    ${(props) => (props.time === 'signing-up' ? `height: 4rem` : null)};
    ${(props) => (props.time === 'signing-up' ? `min-height: 4rem` : null)};
    ${(props) => (props.time === 'signing-up' ? `width: 15rem` : null)};
  }

  ${(props) => (props.modifier === 'tithed' ? `min-height: ${props.minHeight}` : null)};
  ${(props) => (props.modifier === 'tithed' ? `width: ${props.width}` : null)};
`;

const SurplusToggle = styled(Toggle)`
  &.sub-category-controller__surplus-container__switch__toggle {
    position: absolute;
    height: 100%;
    width: 35%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${(props) => chroma(`${props.theme.colors.primaryColor}`).brighten(0.4).hex()};
    top: 50%;
    transform: translate(0, -50%) rotate(0);
    transition: background-color 0.5s, transform 0.5s, width 0.25s;

    & i {
      font-size: 1.4rem;
      color: ${(props) => props.theme.colors.grayScale.offWhite};
    }
  }
`;

const StyledSurplusSwitch = styled(Switch)`
  &.sub-category-controller__surplus-container__switch {
    position: relative;
    height: 55%;
    width: 75%;
    min-width: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    border: 0.2rem solid ${(props) => props.theme.colors.primaryColor};
    border-radius: 2rem;
    background-color: ${(props) => chroma(`${props.theme.colors.primaryColor}`).darken(0.4).hex()};
    padding: 0.1rem;
    transition: border 0.5s, background-color 0.5s, transform 0.5s;

    &:hover {
      cursor: pointer;
    }
  }

  &.sub-category-controller__surplus-container__switch--switched {
    position: relative;
    height: 55%;
    width: 75%;
    min-width: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    border-radius: 2rem;
    background-color: ${(props) => chroma(`${props.theme.colors.secondaryColor}`).darken(0.4).hex()};
    padding: 0.1rem;
    transition: border 0.5s, background-color 0.5s, transform 0.5s;

    &:hover {
      cursor: pointer;
    }

    & .sub-category-controller__surplus-container__switch__toggle {
      position: absolute;
      height: 100%;
      width: 35%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      background-color: ${(props) => chroma(`${props.theme.colors.secondaryColor}`).brighten(0.4).hex()};
      border-radius: 5rem;
      top: 50%;
      transform: translate(180%, -50%) rotate(0);
      border-radius: 50%;
      transition: background-color 0.5s, transform 0.5s, width 0.25s;

      & i {
        font-size: 1.4rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }
  }

  &.disabled {
    background-color: ${(props) => chroma(`${props.theme.colors.grayScale.raisinBlack}`).darken(0.4).hex()};
    border-color: ${(props) => props.theme.colors.grayScale.raisinBlack};
    & .sub-category-controller__surplus-container__switch__toggle {
      background-color: ${(props) => chroma(`${props.theme.colors.grayScale.raisinBlack}`).brighten(0.4).hex()};
    }
  }
`;

const StyledCommsSwitch = styled(Switch)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 6rem;
  color: ${(props) => props.theme.colors.grayScale.offWhite};
  & p {
    position: relative;
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    z-index: 5;
  }
  background-color: ${(props) => chroma(`${props.theme.colors.primaryColor}`).darken(0.4).hex()};
  border-color: ${(props) => props.theme.colors.grayScale.raisinBlack};

  &.switched {
    div {
      border-radius: 0 5rem 5rem 0;
      transform: translate(100%, -50%);
    }
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    ${(props) => (props.time === 'updating' ? `height: 4rem` : null)};
    ${(props) => (props.time === 'updating' ? `min-height: 4rem` : null)};
    ${(props) => (props.time === 'updating' ? `width: 15rem` : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.landscape} {
    ${(props) => (props.time === 'signing-up' ? `height: 4rem` : null)};
    ${(props) => (props.time === 'signing-up' ? `min-height: 4rem` : null)};
    ${(props) => (props.time === 'signing-up' ? `width: 15rem` : null)};
  }
`;

const TransactionPlanToggle = styled(Toggle)`
  &.transaction-plan-surplus-switch__toggle {
    position: absolute;
    height: 98%;
    width: 35%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 5rem;
    background-color: ${(props) => chroma(`${props.theme.colors.primaryColor}`).brighten(0.4).hex()};
    top: 50%;
    transform: translate(0, -50%) rotate(0);
    transition: background-color 0.5s, transform 0.5s, width 0.25s;

    & i {
      font-size: 1.4rem;
      color: ${(props) => props.theme.colors.grayScale.offWhite};
    }

    & + p {
      position: relative;
      font-size: 2rem;
      color: ${(props) => props.theme.colors.grayScale.offWhite};
      z-index: 5;
    }
  }
`;

export const StyledSurplusTransactionPlanSwitch = styled(Switch)`
  &.transaction-plan-surplus-switch {
    position: relative;
    height: 4rem;
    width: 10rem;
    min-width: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border: 0.2rem solid ${(props) => props.theme.colors.primaryColor};
    border-radius: 2rem;
    background-color: ${(props) => chroma(`${props.theme.colors.primaryColor}`).darken(0.4).hex()};
    padding: 0.1rem;
    transition: border 0.5s, background-color 0.5s, transform 0.5s;

    &:hover {
      cursor: pointer;
    }

    & p {
      position: relative;
      font-size: 2rem;
      color: ${(props) => props.theme.colors.grayScale.offWhite};
      z-index: 5;
    }
  }

  &.transaction-plan-surplus-switch--switched {
    position: relative;
    height: 4rem;
    width: 10rem;
    min-width: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
    background-color: ${(props) => chroma(`${props.theme.colors.secondaryColor}`).darken(0.4).hex()};
    padding: 0.1rem;
    transition: border 0.5s, background-color 0.5s, transform 0.5s;

    &:hover {
      cursor: pointer;
    }

    & .transaction-plan-surplus-switch__toggle {
      position: absolute;
      height: 98%;
      width: 98%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      background-color: ${(props) => chroma(`${props.theme.colors.secondaryColor}`).brighten(0.4).hex()};
      border-radius: 5rem;
      top: 50%;
      transform: translate(0%, -50%) rotate(0);
      transition: background-color 0.5s, transform 0.5s, width 0.25s;
    }
  }

  &.disabled {
    background-color: ${(props) => chroma(`${props.theme.colors.grayScale.raisinBlack}`).darken(0.4).hex()};
    border-color: ${(props) => props.theme.colors.grayScale.raisinBlack};
    & .transaction-plan-surplus-switch__toggle {
      background-color: ${(props) => chroma(`${props.theme.colors.grayScale.raisinBlack}`).brighten(0.4).hex()};
    }
  }
`;

export const SurplusTransactionSwitch = function (props) {
  const { theme, user, setUser, budget, setBudget, plannedTransaction, setPlannedTransaction } = props;
  return (
    <StyledSurplusTransactionPlanSwitch
      className={plannedTransaction.surplus === true ? `transaction-plan-surplus-switch--switched` : `transaction-plan-surplus-switch`}
      onClick={(e) =>
        handleTransaction(
          e,
          'Planning',
          { setting: 'surplus', user: user, budget: budget, plannedTransaction: plannedTransaction },
          { setUser: setUser, setBudget: setBudget, setPlannedTransaction: setPlannedTransaction }
        )
      }>
      <TransactionPlanToggle className="transaction-plan-surplus-switch__toggle" />
      <p>{plannedTransaction.surplus === true ? 'YES' : 'NO'}</p>
    </StyledSurplusTransactionPlanSwitch>
  );
};

const toggleLDS = (latterDaySaint, setLatterDaySaint, time, signupFormValues, setSignupFormValues) => {
  if (time === 'signing-up') {
    setLatterDaySaint(!latterDaySaint);
    const newValues = produce(signupFormValues, (roughDraft) => {
      roughDraft.pageTwo.latterDaySaint = !latterDaySaint;
    });
    setSignupFormValues(newValues);
  } else if (time === 'updating') {
    const updated = produce(latterDaySaint, (roughDraft) => {
      roughDraft.latterDaySaint = !roughDraft.latterDaySaint;
    });
    setLatterDaySaint(updated);
  }
};

const renderLDSSwitch = (latterDaySaint, setLatterDaySaint, time, signupFormValues, setSignupFormValues) => {
  if (latterDaySaint === false || latterDaySaint.latterDaySaint === false) {
    return (
      <LandingSwitch
        className="lds-switch"
        time={time}
        data-cy="ldsSwitch"
        margin="0 0 3rem 0"
        onClick={() => toggleLDS(latterDaySaint, setLatterDaySaint, time, signupFormValues, setSignupFormValues)}>
        <LDSToggle />
        <p>NO</p>
      </LandingSwitch>
    );
  } else if (latterDaySaint === true || latterDaySaint.latterDaySaint === true) {
    return (
      <LandingSwitch
        className="lds-switch switched"
        data-cy="ldsSwitchSwitched"
        margin="0 0 3rem 0"
        time={time}
        onClick={() => toggleLDS(latterDaySaint, setLatterDaySaint, time, signupFormValues, setSignupFormValues)}>
        <LDSToggle />
        <p>YES</p>
      </LandingSwitch>
    );
  }
};

export const LDSSwitch = function (props) {
  const { latterDaySaint, setLatterDaySaint, time, signupFormValues, setSignupFormValues } = props;
  return renderLDSSwitch(latterDaySaint, setLatterDaySaint, time, signupFormValues, setSignupFormValues);
};

export const TithedSwitch = function (props) {
  const { theme, user, setUser, budget, setBudget, transaction, setTransaction, receiptItem, setReceiptItem } = props;
  return (
    <LandingSwitch
      modifier="tithed"
      minHeight="4rem"
      width="15rem"
      name="tithed"
      className={`lds-switch ${receiptItem && receiptItem.tithed === true ? 'switched' : null}`}
      data-cy="tithingSwitch"
      onClick={(e) =>
        handleTransaction(
          e,
          'Deposit',
          { user: user, budget: budget, transaction: transaction, receiptItem: receiptItem },
          { setUser: setUser, setBudget: setBudget, setTransaction: setTransaction, setReceiptItem: setReceiptItem },
          'Tithing'
        )
      }>
      <LDSToggle />
      <p modifier="tithed" className="tithed-text">
        {receiptItem && receiptItem.tithed === true ? `YES` : `NO`}
      </p>
    </LandingSwitch>
  );
};

export const SurplusSwitch = function (props) {
  const { theme, creating, user, mainCategoryIndex, budget, setBudget, subCategory, processSubCategory } = props;
  return (
    <StyledSurplusSwitch
      className={
        subCategory.surplus === true && user && user.permissions === 'Admin'
          ? 'sub-category-controller__surplus-container__switch--switched'
          : subCategory.surplus === false && user && user.permissions === 'Admin'
          ? 'sub-category-controller__surplus-container__switch'
          : subCategory.surplus === true && user && user.permissions === 'Associate'
          ? 'sub-category-controller__surplus-container__switch--switched disabled'
          : subCategory.surplus === false && user && user.permissions === 'Associate'
          ? 'sub-category-controller__surplus-container__switch disabled'
          : subCategory.surplus === false && creating === true
          ? 'sub-category-controller__surplus-container__switch'
          : subCategory.surplus === true && creating === true
          ? 'sub-category-controller__surplus-container__switch--switched'
          : 'sub-category-controller__surplus-container__switch'
      }
      data-cy={`surplusSwitch${subCategory.title.split(' ').join('')}`}
      onClick={(e) => {
        if ((user && user.permissions === 'Admin') || creating === true) {
          processSubCategory(e, 'surplus', mainCategoryIndex, budget, setBudget, user);
        }
      }}>
      <SurplusToggle className="sub-category-controller__surplus-container__switch__toggle">
        <i className={`fas ${subCategory.surplus === true ? 'fa-check' : 'fa-times'} sub-category-controller__surplus-container__switch__toggle__icon`} />
      </SurplusToggle>
    </StyledSurplusSwitch>
  );
};

const toggleCommPreference = (event, user, setUser, profileInformation, setProfileInformation) => {
  event.preventDefault();
  const updated = produce(user, (roughDraft) => {
    roughDraft.communicationPreference === 'Email' ? (roughDraft.communicationPreference = 'Text') : (roughDraft.communicationPreference = 'Email');
  });
  setUser(updated);
  const updatedTwo = produce(profileInformation, (roughDraft) => {
    roughDraft.communications.communicationPreference === 'Email' ? (roughDraft.communications.communicationPreference = 'Text') : (roughDraft.communications.communicationPreference = 'Email');
  });
  setProfileInformation(updatedTwo);
};

export const CommsSwitch = function (props) {
  const { theme, user, setUser, profileInformation, setProfileInformation, time } = props;
  return (
    <StyledCommsSwitch
      className={user.communicationPreference === 'Text' ? 'commsSwitch switched' : 'commsSwitch'}
      time={time}
      onClick={(e) => toggleCommPreference(e, user, setUser, profileInformation, setProfileInformation)}>
      <CommsToggle></CommsToggle>
      <p>Email</p>
      <p>Text</p>
    </StyledCommsSwitch>
  );
};
