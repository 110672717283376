import { immerable, produce } from 'immer';
import axios from 'axios';
import qs from 'qs';
import { MainCategory } from './../Classes/Categories/Main-Category';
import { SubCategory } from './../Classes/Categories/Sub-Category';
import { Investment } from './Investment';
import { get, getAll, set, timeTillExpires, remove, useNamespace } from './../Classes/Cache';
import { DateTime } from 'luxon';
import { getCategoryIndex } from '../Application/Global/Budget/BudgetUtility';
import { PlannedTransaction } from './PlannedTransaction';
import { Debt } from './Debt';
import { RecentTransaction } from './RecentTransaction';
class Account {
  [immerable] = true;
  constructor(options) {
    this.amount = options.amount;
  }

  copyAccount(options) {
    for (let key in options) {
      this[key] = options[key];
    }
    return this;
  }

  getAmount() {
    return this.amount;
  }

  getTotal() {
    return this.amount.total;
  }

  getInvested() {
    return this.investedAmount;
  }

  getInvestmentFundTotal() {
    return this.amount + this.investedAmount;
  }

  addToAmount(value) {
    this.amount += value;
  }

  addToDebtAmount(value) {
    this.debtAmount += Number(value);
  }

  addToTotal(value) {
    this.amount.total += value;
  }

  addToUsed(value) {
    this.amount.used += value;
  }

  addToUnUsed(value) {
    this.amount.unUsed += value;
  }

  removeFromAmount(value) {
    this.amount -= value;
  }

  removeFromAmountOwed(value) {
    this.amountOwed -= value;
  }

  removeFromDebtAmount(value) {
    if (value === 0) {
      this.debtAmount -= 0;
    } else {
      this.debtAmount -= Number(value);
    }
  }

  removeFromTotal(value) {
    this.amount.total -= value;
  }

  removeFromUsed(value) {
    this.amount.used -= value;
  }

  removeFromUnUsed(value) {
    this.amount.unUsed -= value;
  }

  updateSavingsGoal(value) {
    this.savingsGoal = value;
  }

  updateSavingsPercentage(value) {
    this.savingsPercentage = Number(value / 100);
  }

  updateInvestmentGoal(value) {
    this.investmentGoal = Number(value);
  }

  updateInvestmentPercentage(value) {
    this.investmentPercentage = Number(value / 100);
  }

  updateEmergencyGoalTiming(value) {
    return (this.emergencyFundGoalTiming = value);
  }

  updateEmergencyGoal(value) {
    return (this.emergencyFundGoal = value);
  }

  updateEmergencyMeasurement(value) {
    return (this.emergencyGoalMeasurement = value);
  }

  updateTithingSetting(value) {
    return (this.tithingSetting = value);
  }

  addInvestment(value) {
    this.investedAmount += value;
    this.amount -= value;
  }

  subtractInvestment(value) {
    this.amount += value;
    this.investedAmount -= value;
  }
}
export class Budget {
  [immerable] = true;
  constructor(profile) {
    this.name = '';
    this.accounts = {};
    this.mainCategories = [];
    this.transactions = {
      recentTransactions: [],
      plannedTransactions: [],
    };
    this.investments = [];
    this.debts = [];
    this.currentMonth = DateTime.now().monthLong;
    this._addAccounts(profile);
  }

  async getBudget(id, user) {
    try {
      const response = await axios({
        method: `GET`,
        url: `/Users/${user._id}/Budgets/${id}/Dashboard`,
      });
      document.open(`text/html`).write(response.data);
      window.location.assign(`/Users/${user._id}/Budgets/${id}/Dashboard`);
    } catch (error) {
      console.log(error);
    }
  }

  copyBudget(budget, setBudget) {
    for (let key in budget) {
      if (key === 'accounts') {
        // * THE ACCOUNTS OBJECT HOLDS PROPERTIES WHICH ARE THE NAMES OF EACH ACCOUNT.
        let newAccountsObj = {};
        for (let subKey in budget[key]) {
          let accountCopy = new Account({ amount: 0 });
          accountCopy.copyAccount(budget[key][subKey]);
          newAccountsObj[subKey] = accountCopy;
        }
        // Object.assign(newAccountsObj, this[key]);
        this[key] = newAccountsObj;
      } else if (key === 'transactions') {
        let newTransactionsObj = {};
        for (let subKey in budget[key]) {
          let transactionArray = [];
          budget[key][subKey].forEach((transaction, i) => {
            if (subKey === 'plannedTransactions') {
              let newPlan = new PlannedTransaction();
              newPlan.copyPlan(transaction);
              transactionArray.push(newPlan);
            } else if (subKey === 'recentTransactions') {
              let newRecentTransaction = new RecentTransaction();
              newRecentTransaction.makeRecentTransaction(transaction);
              transactionArray.push(newRecentTransaction);
              transactionArray = transactionArray.sort((a, b) => (a.transactionDate > b.transactionDate ? 1 : -1));
            }
          });
          newTransactionsObj[subKey] = transactionArray;
        }
        this[key] = newTransactionsObj;
      } else if (key === 'mainCategories') {
        let newMainCategoryArray = [];
        budget[key].forEach((category, index) => {
          let newMainCategory = new MainCategory({ icon: category.icon, title: category.title });
          for (let subKey in category) {
            if (subKey !== 'icon' && subKey !== 'title' && subKey !== 'subCategories') {
              newMainCategory[subKey] = category[subKey];
            }
          }
          category.subCategories.forEach((sc, index) => {
            let newSubCategory = new SubCategory({ title: sc.title });
            for (let subKey in sc) {
              if (subKey !== 'title') {
                newSubCategory[subKey] = sc[subKey];
              }
            }
            newMainCategory.subCategories.push(newSubCategory);
          });
          newMainCategoryArray.push(newMainCategory);
        });
        this.mainCategories = newMainCategoryArray;
      } else if (key === 'investments') {
        this[key] = budget[key].map((investment, index) => {
          return new Investment(investment);
        });
      } else if (key === 'debts') {
        let newDebtsArray = [];
        budget[key].forEach((debt) => {
          let copiedDebt = new Debt();
          copiedDebt.copyDebt(debt);
          newDebtsArray.push(copiedDebt);
        });
        this.debts = newDebtsArray;
      } else {
        this[key] = budget[key];
      }
    }
    return this;
  }

  _addAccounts(profile) {
    this.accounts.unAllocated = new Account({ amount: 0 });
    this.accounts.monthlyBudget = new Account({ amount: 0 });
    this.accounts.emergencyFund = new Account({ amount: 0 });
    this.accounts.savingsFund = new Account({ amount: { total: 0, used: 0, unUsed: 0 } });
    this.accounts.expenseFund = new Account({ amount: { total: 0, used: 0, unUsed: 0 } });
    this.accounts.surplus = new Account({ amount: { total: 0, used: 0, unUsed: 0 } });
    this.accounts.investmentFund = new Account({ amount: 0 });
    this.accounts.debt = new Account({ amount: { total: 0, used: 0, unUsed: 0 }, debtAmount: 0 });
    if (profile && profile.latterDaySaint === true) this.accounts.tithing = new Account({ amount: 0, amountOwed: 0 });
  }

  updateName(value) {
    return (this.name = value);
  }

  // THIS WILL REPLACE THE VANILLA JAVASCRIPT WAY OF CREATING / EDITING MAIN CATEGORIES
  addMainCategory(event, category, budget, setBudget, toggleMainCategoryCreation) {
    const updated = produce(budget, (roughDraft) => {
      roughDraft.mainCategories.push(new MainCategory(category));
    });
    toggleMainCategoryCreation(event);
    return setBudget(updated);
  }

  removeMainCategory(title, budget, setBudget, categoryIndex, setCategoryIndex) {
    if (categoryIndex === budget.mainCategories.length - 1) {
      setCategoryIndex(categoryIndex - 1);
    }
    const updated = produce(budget, (roughDraft) => {
      roughDraft.mainCategories = roughDraft.mainCategories.filter((mc) => mc.title !== title);
    });
    console.log(`SUCCESSFUL DELETION`);
    return setBudget(updated);
  }

  selectIcon(event, iconObject, setIconObject) {
    const clicked = event.target.closest('.icon-container');
    let title = clicked.firstChild.nextSibling.textContent;
    const updated = produce(iconObject, (roughDraft) => {
      if (roughDraft.mostRecent) roughDraft.mostRecent.classList.remove('icon-container--clicked');
      roughDraft[title].classList.add('icon-container--clicked');
      roughDraft.mostRecent = roughDraft[title];
    });
    setIconObject(updated);
  }

  updateTithingSetting(value) {
    return this.accounts.tithing.updateTithingSetting(value);
  }

  addSubCategory(budget, setBudget, index, category) {
    const updated = produce(budget, (roughDraft) => {
      roughDraft.mainCategories[index].updateLastUpdated();
      roughDraft.mainCategories[index].subCategories.push(new SubCategory(category));
    });
    return setBudget(updated);
  }

  updateSubCategorySurplusSetting(budget, setBudget, index, subCategoryTitle) {
    const subIndex = getCategoryIndex('sub', budget, subCategoryTitle, index);
    const updated = produce(budget, (roughDraft) => {
      roughDraft.mainCategories[index].subCategories[subIndex].toggleSurplus();
      roughDraft.mainCategories[index].subCategories[subIndex].updateLastUpdated();
      roughDraft.mainCategories[index].updateLastUpdated();
    });
    return setBudget(updated);
  }

  updateSubCategoryTiming(options) {
    const updated = produce(options.budget, (roughDraft) => {
      roughDraft.mainCategories[options.index].subCategories[options.subCategoryIndex].updateTiming({
        paymentCycle: options.paymentCycle,
        paymentSchedule: options.paymentSchedule,
        index: options.index,
        subCategoryIndex: options.subCategoryIndex,
        statement: options.statement,
      });
    });
    options.setBudget(updated);
  }

  deleteSubCategory(budget, setBudget, index, subCategoryTitle) {
    const updated = produce(budget, (roughDraft) => {
      roughDraft.mainCategories[index].deleteSubCategory(subCategoryTitle);
      roughDraft.mainCategories[index].updateLastUpdated();
    });
    return setBudget(updated);
  }

  updateEmergencyMeasurement(value) {
    return this.accounts.emergencyFund.updateEmergencyMeasurement(value);
  }

  updateEmergencyGoal(value) {
    return this.accounts.emergencyFund.updateEmergencyGoal(value);
  }

  updateEmergencyGoalTiming(value) {
    return this.accounts.emergencyFund.updateEmergencyGoalTiming(value);
  }

  updateSavingsPercentage(value) {
    this.accounts.savingsFund.updateSavingsPercentage(value);
  }

  updateSavingsGoal(value) {
    this.accounts.savingsFund.updateSavingsGoal(Number(value));
  }

  updateInvestmentPercentage(value) {
    this.accounts.investmentFund.updateInvestmentPercentage(value);
  }

  updateInvestmentGoal(value) {
    this.accounts.investmentFund.updateInvestmentGoal(value);
  }

  sortRecentTransactionsByDate(transactions) {
    return [...transactions].sort((a, b) => (a.transactionDate > b.transactionDate ? 1 : -1));
  }

  async submitForCreation(budget, user) {
    try {
      const response = await axios({
        method: 'POST',
        url: `/Users/${user._id}/Budgets`,
        data: qs.stringify({
          budget: budget,
        }),
      });
      if (response.statusText === `Created`) {
        const response2 = await axios({
          method: 'GET',
          url: `/Users/${user._id}/Budgets/RetrieveBudget`,
        });
        if (response2.statusText === 'OK') {
          let budgetId = response2.data.data.budget._id;
          const response3 = await axios({
            method: 'GET',
            url: `/Users/${user._id}/Budgets/${budgetId}/Dashboard`,
          });
          document.open(`text/html`).write(response3.data);
          window.location.assign(`/Users/${user._id}/Budgets/${budgetId}/Dashboard`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Update Budget
  async updateBudget(options, pageLink) {
    try {
      const response = await axios({
        method: `PATCH`,
        url: `/Users/${options.userId}/Budgets/${options.budgetId}/${pageLink}`,
        data: {
          ...options,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  // UPDATING THE USER PROFILE PHOTO -- USED
  async updateCoverPhoto(options, user, budget, setBudget, setCoverPhoto) {
    try {
      const response = await axios({
        method: `PATCH`,
        url: `/Users/${options.userId}/Budgets/${options.budgetId}/Update-Cover-Photo`,
        data: options,
      });
      if (response.data.data.budget.coverPhoto) {
        let budget = response.data.data.budget;
        const updated = produce(budget, (roughDraft) => {
          roughDraft.coverPhoto = response.data.data.budget.coverPhoto;
        });
        setBudget(updated);
        setCoverPhoto(response.data.data.budget.coverPhoto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async deleteBudget(budget, user) {
    try {
      const response = await axios({
        method: `DELETE`,
        url: `/Users/${user._id}/Budgets/${budget._id}/Budget-Management`,
        data: qs.stringify({ id: budget._id }),
      });
      if (response.statusText === 'No Content') {
        window.location.assign(`/Users/${user._id}`);
      }
    } catch (error) {
      console.log(error);
    }
  }
  exitBudget(navigate, user) {
    navigate(`/Users/${user._id}`);
  }

  accountTransfer(from, to, amount) {
    if (from === 'monthlyBudget' || from === 'emergencyFund' || from === 'investmentFund' || from === 'tithing') {
      this.accounts[from].removeFromAmount(amount);
    } else if (from === 'debt' || from === 'surplus' || from === 'savingsFund' || from === 'expenseFund') {
      this.accounts[from].removeFromUnUsed(amount);
      this.accounts[from].removeFromTotal(amount);
    }

    if (to === 'monthlyBudget' || to === 'emergencyFund' || to === 'investmentFund' || to === 'tithing') {
      this.accounts[to].addToAmount(amount);
    } else if (to === 'debt' || to === 'surplus' || to === 'savingsFund' || to === 'expenseFund') {
      this.accounts[to].addToUnUsed(amount);
      this.accounts[to].addToTotal(amount);
    }
  }
}

export const userBudget = new Budget();
