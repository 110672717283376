import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { produce } from 'immer';
import { ChangeBudgetCoverPhotoButton } from '../Global/Button';
import { Budget } from '../../Classes/Budget';

const StyledBudgetCardFooter = styled.footer`
  &.budget-card-container__card__footer {
    position: absolute;
    height: 10%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0) rotate(0);
    bottom: 0;
    border-bottom-left-radius: 2.1rem;
    border-bottom-right-radius: 2.1rem;
    background-color: ${(props) => `${props.theme.colors.primaryColor}cc`};

    & p {
      position: relative;
      height: max-content;
      width: max-content;
      margin: 0.25rem 0;
      color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};
      font-size: 1.6rem;
    }
  }
`;

const BudgetPermissions = styled.div`
  position: absolute;
  height: 5rem;
  width: 50%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0) rotate(0);
  background-color: ${(props) => `${props.theme.colors.grayScale.offWhite}bf`};
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  & p {
    font-size: 1.6rem;
    color: ${(props) => `${props.theme.colors.primaryColor}cc`};
  }
`;

const StyledBudgetCardContent = styled.section`
  &.budget-card-container__card__content {
    position: absolute;
    height: 80%;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0) rotate(0);
    background-color: ${(props) => `${props.theme.colors.grayScale.raisinBlack}33`};
    opacity: 0.8;

    & .budget-card-container__card__content__cover-photo {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      transform: translate(0, 0) rotate(0);
    }

    & a {
      position: absolute;
      height: 10rem;
      width: 10rem;
      top: 4rem;
      left: 4rem;
      text-decoration: none;
      z-index: 15;
    }

    & a .budget-card-container__card__content__change-photo-button {
      font-size: 1.6rem;
      color: ${(props) => `${props.theme.colors.primaryColor}80`};
    }
  }
`;

export const StyledBudgetCard = styled.div`
  &.budget-card-container__card {
    position: relative;
    height: 100%;
    width: 25%;
    min-width: 38.4rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0) rotate(0);
    border: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
    border-radius: 2.5rem;
    background-color: transparent;
    margin: 0 1.5rem;
    font-family: ${(props) => props.theme.text.secondary};

    &:first-of-type {
      margin-left: 3rem;
    }
    &:last-of-type {
      margin-right: 3rem;
    }

    &:hover {
      cursor: pointer;
      border-color: ${(props) => props.theme.colors.primaryColor};
      transition: border 0.5s;

      .budget-card-container__card__header {
        background-color: ${(props) => props.theme.colors.primaryColor};
        transition: background-color 0.5s, color 0.5s;
        &__icon {
          color: ${(props) => props.theme.colors.grayScale.offWhite};
          transition: color 0.5s;
          &:hover {
            color: ${(props) => props.theme.colors.tertiaryColor};
            transition: color 0.5s;
          }
        }
      }

      .budget-card-container__card__content {
        opacity: 1;
        transition: opacity 0.5s;
        .budget-card-container__card__content__change-photo-button {
          &:hover {
            cursor: pointer;
            border: 0.25rem solid ${(props) => props.theme.colors.primaryColor};
            background-color: ${(props) => props.theme.colors.primaryColor};
            transition: border 0.5s, background-color 0.5s;
            .budget-card-container__card__content__change-photo-button__icon {
              color: ${(props) => props.theme.colors.grayScale.offWhite};
              transition: color 0.5s;
            }
          }
        }
        &__permissions {
          background-color: ${(props) => props.theme.colors.grayScale.offWhite};
          & p {
            color: ${(props) => props.theme.colors.primaryColor};
            transition: color 0.5s;
          }
        }
      }
      .budget-card-container__card__footer {
        background-color: ${(props) => props.theme.colors.primaryColor};
        transition: background-color 0.5s, color 0.5s;
        & p {
          color: ${(props) => props.theme.colors.grayScale.offWhite};
          transition: color 0.5s;
        }
      }
    }

    .budget-card-container__card__header {
      position: absolute;
      height: 10%;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      transform: translate(0, 0) rotate(0);
      top: 0;
      border-top-left-radius: 2.1rem;
      border-top-right-radius: 2.1rem;
      background-color: ${(props) => `${props.theme.colors.primaryColor}cc`};

      & .budget-card-container__card__header__text {
        font-size: 2.4rem;
        font-family: ${(props) => props.theme.text.primary};
        color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};

        @media ${(props) => props.theme.screens.largeMobile.landscape} {
          font-size: 1.6rem;
        }

        @media ${(props) => props.theme.screens.largeMobile.portrait} {
          font-size: 1.6rem;
        }
      }

      & .budget-card-container__card__header__icon {
        position: absolute;
        height: 3rem;
        width: auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        transform: translate(0, -50%) rotate(0);
        top: 50%;
        right: 2rem;
        font-size: 2rem;
        color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};
        &:hover {
          cursor: pointer;
          transition: color 0.5s;
        }

        @media ${(props) => props.theme.screens.largeMobile.landscape} {
          font-size: 1.6rem;
        }

        @media ${(props) => props.theme.screens.largeMobile.portrait} {
          font-size: 1.6rem;
        }
      }
    }

    @media ${(props) => props.theme.screens.largeTablet.landscape} {
      min-width: 45rem;
    }

    @media ${(props) => props.theme.screens.largeTablet.portrait} {
      min-width: 50rem;
    }

    @media ${(props) => props.theme.screens.smallTablet.landscape} {
      min-width: 38.4rem;
    }

    @media ${(props) => props.theme.screens.smallTablet.portrait} {
      min-width: 38.4rem;
    }

    @media ${(props) => props.theme.screens.largeMobile.landscape} {
      min-width: 25rem;
    }

    @media ${(props) => props.theme.screens.largeMobile.portrait} {
      min-width: 30rem;
    }

    @media ${(props) => props.theme.screens.mediumMobile.landscape} {
      min-width: 25rem;
    }

    @media ${(props) => props.theme.screens.mediumMobile.portrait} {
      min-width: 25rem;
    }

    @media ${(props) => props.theme.screens.smallMobile.landscape} {
      min-width: 20rem;
    }

    @media ${(props) => props.theme.screens.smallMobile.portrait} {
      min-width: 25rem;
    }
  }

  &.budget-card-container__card--create {
    position: relative;
    height: 100%;
    width: 25%;
    min-width: 38.4rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0) rotate(0);
    border: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
    border-radius: 2.5rem;
    background-color: transparent;
    margin-left: 1.5rem;

    & .budget-card-container__card--create__header {
      position: absolute;
      height: 10%;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      transform: translate(0, 0) rotate(0);
      top: 0;
      border-top-left-radius: 2.1rem;
      border-top-right-radius: 2.1rem;
      background-color: ${(props) => `${props.theme.colors.primaryColor}cc`};
      font-size: 2.4rem;
      color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};

      & .budget-card-container__card--create__header__text {
        @media ${(props) => props.theme.screens.largeMobile.landscape} {
          font-size: 1.6rem;
        }

        @media ${(props) => props.theme.screens.largeMobile.portrait} {
          font-size: 1.6rem;
        }
      }
    }

    & .budget-card-container__card--create__icon {
      font-size: 10rem;
      color: ${(props) => `${props.theme.colors.primaryColor}cc`};
    }
    &:hover {
      cursor: pointer;
      border: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
      transition: border 0.5s;
      .budget-card-container__card--create__header {
        background-color: ${(props) => props.theme.colors.primaryColor};
        color: ${(props) => props.theme.colors.grayScale.offWhite};
        transition: background-color 0.5s, color 0.5s;
      }
      .budget-card-container__card--create__icon {
        color: ${(props) => props.theme.colors.primaryColor};
        transition: color 0.5s;
      }
    }

    @media ${(props) => props.theme.screens.largeTablet.landscape} {
      min-width: 45rem;
    }

    @media ${(props) => props.theme.screens.largeTablet.portrait} {
      min-width: 50rem;
    }

    @media ${(props) => props.theme.screens.smallTablet.landscape} {
      min-width: 38.4rem;
    }

    @media ${(props) => props.theme.screens.smallTablet.portrait} {
      min-width: 38.4rem;
    }

    @media ${(props) => props.theme.screens.largeMobile.landscape} {
      min-width: 25rem;
    }

    @media ${(props) => props.theme.screens.largeMobile.portrait} {
      min-width: 30rem;
    }

    @media ${(props) => props.theme.screens.mediumMobile.landscape} {
      min-width: 25rem;
    }

    @media ${(props) => props.theme.screens.mediumMobile.portrait} {
      min-width: 25rem;
    }

    @media ${(props) => props.theme.screens.smallMobile.landscape} {
      min-width: 20rem;
    }

    @media ${(props) => props.theme.screens.smallMobile.portrait} {
      min-width: 25rem;
    }
  }
`;

export const BudgetCard = function (props) {
  const { theme, user, setUser, budget, coverPhoto, setCoverPhoto, budgetId, setBudgetId, isDisabled, setIsDisabled } = props;

  const deleteBudget = async (e) => {
    e.preventDefault();
    const selectedCardId = e.target.closest('.budget-card-container__card').dataset.budgetid;
    const updated = produce(user, (roughDraft) => {
      roughDraft.budgets = roughDraft.budgets.filter((budget) => {
        return budget._id !== selectedCardId;
      });
    });
    setUser(updated);
    const budgetProxy = new Budget();
    budgetProxy._id = selectedCardId;
    budgetProxy.deleteBudget(budgetProxy, user);
  };

  const selectBudget = (event) => {
    event.preventDefault();
    const budgetId = event.target.closest('.budget-card-container__card').dataset.budgetid;
    const budgetProxy = new Budget();
    budgetProxy.getBudget(budgetId, user);
  };

  const getPermissions = (type) => {
    if (type === `data`) {
      return budget.budgetAdmins.includes(user._id) ? `Admin` : `Associate`;
    } else if (type === `permission`) {
      let permissions = '';
      budget.budgetAdmins.includes(user._id) ? (permissions = `Admin | Associate`) : (permissions = `Associate`);
      return (
        <BudgetPermissions>
          <p>{permissions}</p>
        </BudgetPermissions>
      );
    }
  };

  return (
    <StyledBudgetCard
      className="budget-card-container__card r__budget-card-container__card"
      data-budgetid={budget._id}
      data-permissions={getPermissions('data')}
      onClick={async (e) => {
        if (e.target.closest('a') && e.target.closest('a').classList.contains('budget-card-container__card__content__change-photo-link')) {
          setCoverPhoto(budget.coverPhoto);
          setBudgetId(budget._id);
          if (!budget.budgetAdmins.includes(user._id)) {
            setIsDisabled(true);
          } else {
            setIsDisabled(false);
          }
          return;
        }
        if (e.target.classList.contains('budget-card-container__card__header__icon')) {
          await deleteBudget(e);
          return;
        }
        selectBudget(e);
      }}>
      <header className="budget-card-container__card__header r__budget-card-container__card__header">
        <p className="budget-card-container__card__header__text r__budget-card-container__card__header__text">{budget.name}</p>
        <i className="fas fa-trash-alt budget-card-container__card__header__icon r__budget-card-container__card__header__icon" />
      </header>
      <StyledBudgetCardContent className="budget-card-container__card__content r__budget-card-container__card__content">
        <img
          src={`./../../../DIST/CSS/Images/Budgets/${budget.coverPhoto}`}
          alt="Budget's Cover Photo"
          className="budget-card-container__card__content__cover-photo r__budget-card-container__card__content__cover-photo"
        />
        <Link to={'Update-Cover-Photo'} className="budget-card-container__card__content__change-photo-link" disabled={!budget.budgetAdmins.includes(user._id)}>
          <ChangeBudgetCoverPhotoButton
            className="budget-card-container__card__content__change-photo-button r__budget-card-container__card__content__change-photo-button"
            disabled={!budget.budgetAdmins.includes(user._id)}>
            <i className="fas fa-camera budget-card-container__card__content__change-photo-button__icon r__budget-card-container__card__content__change-photo-button__icon" />
          </ChangeBudgetCoverPhotoButton>
        </Link>
        {budget && getPermissions(`permission`)}
      </StyledBudgetCardContent>
      <StyledBudgetCardFooter className="budget-card-container__card__footer r__budget-card-container__card__footer">
        <p>{`Created At: ${DateTime.fromISO(budget.createdAt).toLocaleString(DateTime.DATE_HUGE)}`}</p>
        <p>{`Last Updated: ${DateTime.fromISO(budget.lastUpdated).toLocaleString(DateTime.DATE_HUGE)}`}</p>
      </StyledBudgetCardFooter>
    </StyledBudgetCard>
  );
};
