import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from '../Form';
import { FormContainer } from '../FormContainer';
import { LandingFormCloser, InvestmentChartCloser } from '../FormCloser';
import { LandingFormHeader } from '../Header';
import ErrorContainer from '../Errors';
import Page from '../Page';
import FormSection from '../Section';
import { LandingFormInput } from '../Input';
import { LandingFormLabel, SwitchLabel } from '../Label';
import { LDSSwitch } from '../Switch';
import { PrimaryButton, LDSHelpButton } from '../../Button';
import { handleInputChange } from '../Algorithms/InputChange';
import { signupSchema } from '../Validate';
import { toggleClasses } from '../../../Utility';
import { Person } from '../../../../Classes/Person';
import { StyledModal } from '../../Modal';

export const resetForm = (setter, values) => {
  setter(values);
};

export const SignupForm = function (props) {
  const { theme, flow, justify, align, initialSignupFormValues, signupFormValues, setSignupFormValues, latterDaySaint, setLatterDaySaint, errors, setErrors, disabledValues, setDisabledValues } =
    props;
  const [showLDSHelp, setShowLDSHelp] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    signupSchema.pageOne.isValid(signupFormValues.pageOne).then((valid) => {
      valid === true ? setDisabledValues({ ...disabledValues, signupDisabled: false }) : setDisabledValues({ ...disabledValues, signupDisabled: true });
    });
  }, [signupFormValues.pageOne]);

  useEffect(() => {
    signupSchema.pageTwo.isValid(signupFormValues.pageTwo).then((valid) => {
      valid === true ? setDisabledValues({ ...disabledValues, signupDisabled: false }) : setDisabledValues({ ...disabledValues, signupDisabled: true });
    });
  }, [signupFormValues.pageTwo]);

  useEffect(() => {
    signupSchema.pageThree.isValid(signupFormValues.pageThree).then((valid) => {
      valid === true ? setDisabledValues({ ...disabledValues, signupDisabled: false }) : setDisabledValues({ ...disabledValues, signupDisabled: true });
    });
  }, [signupFormValues.pageThree]);

  useEffect(() => {
    signupSchema.pageFour.isValid(signupFormValues.pageFour).then((valid) => {
      valid === true ? setDisabledValues({ ...disabledValues, signupDisabled: false }) : setDisabledValues({ ...disabledValues, signupDisabled: true });
    });
  }, [signupFormValues.pageFour]);

  const handleSignupClick = (e, signupFormValues, setSignupFormValues, disabledValues, setDisabledValues) => {
    e.preventDefault();
    const pages = [...document.querySelectorAll('.form__page')];
    const currentPage = pages.findIndex((page) => page.classList.contains('open'));
    if (pages[currentPage + 1] !== undefined) {
      setSignupFormValues({ ...signupFormValues, pageNumber: signupFormValues.pageNumber + 1 });

      if (currentPage === 0) {
        setDisabledValues({ ...disabledValues, signupDisabled: true });
      } else if (currentPage === 1) {
        setDisabledValues({ ...disabledValues, signupDisabled: true });
      } else if (currentPage === 2) {
        document.querySelector('.signup-submit').textContent = `Submit`;
        setDisabledValues({ ...disabledValues, signupDisabled: true });
      }
      toggleClasses(pages[currentPage], [`closed`, `open`]);
      toggleClasses(pages[currentPage + 1], [`closed`, `open`]);
    } else if (pages[currentPage + 1] === undefined) {
      let User = new Person();
      User.signup({
        firstname: signupFormValues.pageOne.firstname,
        lastname: signupFormValues.pageOne.lastname,
        username: signupFormValues.pageTwo.username,
        latterDaySaint: signupFormValues.pageTwo.latterDaySaint,
        email: signupFormValues.pageThree.email,
        emailConfirmed: signupFormValues.pageThree.emailConfirmed,
        password: signupFormValues.pageFour.password,
        passwordConfirmed: signupFormValues.pageFour.passwordConfirmed,
      });
      setSignupFormValues(initialSignupFormValues);
      document.querySelector('.lds-switch').classList.remove('switched');
      setTimeout(() => {
        resetForm(setSignupFormValues, { ...signupFormValues, pageNumber: 1 });
        navigate('/App');
      }, 2000);
    }
  };

  return (
    <FormContainer className="form-container r__form-container">
      <Form className="form r__form" position="relative" justify={justify} align={align} flow={flow} bgColor={`${theme.colors.grayScale.offWhite}f2`}>
        <Link
          to=".."
          onClick={(e) => {
            resetForm(setErrors, []);
            resetForm(setSignupFormValues, { ...signupFormValues, pageNumber: 1 });
          }}>
          <LandingFormCloser>
            <i className="fas fa-window-close" data-cy="signupFormCloser" />
          </LandingFormCloser>
        </Link>
        <LandingFormHeader className="form__header r__form__header" margin="0rem 0rem 2rem" headerType="signup">
          Signup
        </LandingFormHeader>
        <ErrorContainer errorContainerType="landing">
          {errors && (
            <ol>
              {Object.keys(errors).map((error, index) => {
                if (errors[error] !== '') {
                  return (
                    <li className="error" key={index}>
                      {errors[error]}
                    </li>
                  );
                }
              })}
            </ol>
          )}
        </ErrorContainer>
        <Page className="form__page r__form__page open" flow="column nowrap" justify="space-evenly" align="center">
          <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section" sectionType="signup">
            <LandingFormInput
              type="text"
              inputType="signup"
              name="firstname"
              minLength={2}
              maxLength={40}
              inputWidth="20%"
              className="form__input r__form__input"
              id="firstname"
              placeholder="Enter First Name"
              focusTransform="translate(-5.5rem, -7rem) scale(0.75)"
              data-cy="firstname"
              onChange={(e) => {
                handleInputChange(e, 'signup', signupFormValues, setSignupFormValues, errors, setErrors, disabledValues, setDisabledValues);
              }}
            />
            <LandingFormLabel htmlFor="firstname" data-cy="firstnameLabel" labelType="signup">
              First Name
            </LandingFormLabel>
          </FormSection>
          <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section" sectionType="signup">
            <LandingFormInput
              type="text"
              inputType="signup"
              name="lastname"
              minLength={2}
              maxLength={40}
              inputWidth="20%"
              className="form__input r__form__input"
              id="lastname"
              placeholder="Enter Last Name"
              focusTransform="translate(-5.5rem, -7rem) scale(0.75)"
              data-cy="lastname"
              onChange={(e) => {
                handleInputChange(e, 'signup', signupFormValues, setSignupFormValues, errors, setErrors, disabledValues, setDisabledValues);
              }}
            />
            <LandingFormLabel htmlFor="lastname" data-cy="lastnameLabel" labelType="signup">
              Last Name
            </LandingFormLabel>
          </FormSection>
        </Page>
        <Page className="form__page r__form__page closed" flow="column nowrap" justify="space-evenly" align="center">
          <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 2rem 0" className="form__section r__form__section" sectionType="signup--username">
            <LandingFormInput
              type="text"
              inputType="signup"
              transformType="signup--username"
              name="username"
              minLength={8}
              maxLength={40}
              inputWidth="20%"
              className="form__input r__form__input"
              id="username"
              placeholder="Enter Username"
              focusTransform="translate(-5.5rem, -7rem) scale(0.75)"
              data-cy="username"
              onChange={(e) => {
                handleInputChange(e, 'signup', signupFormValues, setSignupFormValues, errors, setErrors, disabledValues, setDisabledValues);
              }}
            />
            <LandingFormLabel htmlFor="username" data-cy="usernameLabel" labelType="signup">
              Username
            </LandingFormLabel>
          </FormSection>
          <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0" className="form__section r__form__section">
            <LDSSwitch latterDaySaint={latterDaySaint} setLatterDaySaint={setLatterDaySaint} time="signing-up" signupFormValues={signupFormValues} setSignupFormValues={setSignupFormValues} />
            <SwitchLabel htmlFor="lds" labelType="landing-lds">
              Are You A Latter Day Saint?
            </SwitchLabel>
            <LDSHelpButton
              type="button"
              minHeight="3rem"
              minWidth="3rem"
              transform="translate(13rem, -5rem)"
              borderRadius=".5rem"
              onClick={(e) => {
                e.preventDefault();
                setShowLDSHelp(!showLDSHelp);
              }}>
              ?
            </LDSHelpButton>
            {showLDSHelp && (
              <StyledModal
                position="absolute"
                height="20rem"
                width="50%"
                modalType="lds-help"
                flow="column nowrap"
                justify="center"
                align="center"
                borderRadius=".9rem"
                padding="5rem"
                bgColor={`${theme.colors.secondaryColor}f2`}
                transform="translate(0, -5rem)"
                ZIndex="10"
                textSize="2rem"
                textColor={theme.colors.grayScale.offWhite}
                textAlign="center">
                <InvestmentChartCloser
                  modifier="white"
                  closerType="lds-help"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowLDSHelp(!showLDSHelp);
                  }}>
                  <i className="fas fa-window-close" />
                </InvestmentChartCloser>
                <p className="lds-help-text">Not everyone needs to be a Latter Day Saint.</p>
                <p className="lds-help-text">Neither does it give one an advantage to their finances using this budget.</p>
                <p className="lds-help-text">This is only regarding a certain feature that can be useful to a Latter Day Saint beliefs concerning Tithing.</p>
              </StyledModal>
            )}
          </FormSection>
        </Page>
        <Page className="form__page r__form__page closed" flow="column nowrap" justify="space-evenly" align="center">
          <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section" sectionType="signup">
            <LandingFormInput
              type="email"
              inputType="signup"
              name="email"
              minLength={5}
              maxLength={40}
              inputWidth="20%"
              className="form__input r__form__input"
              id="email"
              placeholder="Enter Email Address"
              focusTransform="translate(-5.5rem, -7rem) scale(0.75)"
              data-cy="email"
              onChange={(e) => {
                handleInputChange(e, 'signup', signupFormValues, setSignupFormValues, errors, setErrors, disabledValues, setDisabledValues);
              }}
            />
            <LandingFormLabel htmlFor="email" data-cy="emailLabel" labelType="signup">
              Email Address
            </LandingFormLabel>
          </FormSection>
          <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section" sectionType="signup">
            <LandingFormInput
              type="email"
              inputType="signup"
              name="emailConfirmed"
              minLength={5}
              maxLength={40}
              inputWidth="20%"
              className="form__input r__form__input"
              id="emailConfirmed"
              placeholder="Enter Email Address"
              focusTransform="translate(-5.5rem, -7rem) scale(0.75)"
              data-cy="emailConfirmed"
              onChange={(e) => {
                handleInputChange(e, 'signup', signupFormValues, setSignupFormValues, errors, setErrors, disabledValues, setDisabledValues);
              }}
            />
            <LandingFormLabel htmlFor="emailConfirmed" data-cy="emailConfirmedLabel" labelType="signup">
              Confirm Email Address
            </LandingFormLabel>
          </FormSection>
        </Page>
        <Page className="form__page r__form__page closed" flow="column nowrap" justify="space-evenly" align="center">
          <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section" sectionType="signup">
            <LandingFormInput
              type="password"
              inputType="signup"
              name="password"
              minLength={8}
              maxLength={40}
              inputWidth="20%"
              className="form__input r__form__input"
              id="password"
              placeholder="Enter Password"
              focusTransform="translate(-5.5rem, -7rem) scale(0.75)"
              data-cy="password"
              onChange={(e) => {
                handleInputChange(e, 'signup', signupFormValues, setSignupFormValues, errors, setErrors, disabledValues, setDisabledValues);
              }}
            />
            <LandingFormLabel htmlFor="password" data-cy="passwordLabel" labelType="signup">
              Password
            </LandingFormLabel>
          </FormSection>
          <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section" sectionType="signup">
            <LandingFormInput
              type="password"
              inputType="signup"
              name="passwordConfirmed"
              minLength={8}
              maxLength={40}
              inputWidth="20%"
              className="form__input r__form__input"
              id="passwordConfirmed"
              placeholder="Enter Password"
              focusTransform="translate(-5.5rem, -7rem) scale(0.75)"
              data-cy="passwordConfirmed"
              onChange={(e) => {
                handleInputChange(e, 'signup', signupFormValues, setSignupFormValues, errors, setErrors, disabledValues, setDisabledValues);
              }}
            />
            <LandingFormLabel htmlFor="passwordConfirmed" data-cy="passwordConfirmedLabel" labelType="signup">
              Confirm Password
            </LandingFormLabel>
          </FormSection>
        </Page>
        <FormSection minHeight="5rem" flow="row nowrap" justify="center" align="center" className="form__section r__form__section">
          <p className="page-number">{document.querySelectorAll('.form__page').length === signupFormValues.pageNumber ? `Last Page` : `Page ${signupFormValues.pageNumber}`}</p>
          <PrimaryButton
            size="medium"
            type="submit"
            className="signup-submit"
            disabled={disabledValues.signupDisabled}
            data-cy="signupContinueSubmit"
            onClick={(e) => handleSignupClick(e, signupFormValues, setSignupFormValues, disabledValues, setDisabledValues)}>
            Continue
          </PrimaryButton>
        </FormSection>
      </Form>
    </FormContainer>
  );
};
