import React from 'react';
import styled from 'styled-components';

const FormLabel = styled.label`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  font-family: ${(props) => props.theme.text.secondary};
  color: transparent;
  transition: transform 0.5s, background-color 0.25s, border 0.25s, color 0.25s;

  &:hover {
    cursor: ${(props) => props.hoverCursor};
  }
`;

export const LandingFormLabel = styled(FormLabel)`
  position: absolute;
  height: 6rem;
  min-height: 3rem;
  width: 20%;
  min-width: 20%;
  top: 50%;
  left: calc(40% + 1rem);
  transform: rotate(0) translate(0, -50%);
  padding: 1rem;
  font-size: 2rem;
  color: ${(props) => `${props.theme.colors.primaryColor}cc`};

  @media ${(props) => props.theme.screens.largeTablet.landscape} {
    ${(props) => (props.labelType === 'login' ? `width: 30%` : null)};
    ${(props) => (props.labelType === 'login' ? `min-width: 30%` : null)};
    ${(props) => (props.labelType === 'login' ? `left: calc(35% + 1rem)` : null)};
    ${(props) => (props.labelType === 'signup' ? `width: 30%` : null)};
    ${(props) => (props.labelType === 'signup' ? `min-width: 30%` : null)};
    ${(props) => (props.labelType === 'signup' ? `left: calc(35% + 1rem)` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `width: 30%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `min-width: 30%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `left: calc(35% + 1rem)` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `width: 30%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 30%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 30%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(35% + 1rem)` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `width: 30%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `min-width: 30%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `left: calc(35% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.largeTablet.portrait} {
    ${(props) => (props.labelType === 'login' ? `width: 30%` : null)};
    ${(props) => (props.labelType === 'login' ? `min-width: 30%` : null)};
    ${(props) => (props.labelType === 'login' ? `left: calc(35% + 1rem)` : null)};
    ${(props) => (props.labelType === 'signup' ? `width: 30%` : null)};
    ${(props) => (props.labelType === 'signup' ? `min-width: 30%` : null)};
    ${(props) => (props.labelType === 'signup' ? `left: calc(35% + 1rem)` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `width: 30%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `min-width: 30%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `left: calc(35% + 1rem)` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `width: 30%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 30%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 30%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(35% + 1rem)` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `width: 30%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `min-width: 30%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `left: calc(35% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.smallTablet.landscape} {
    ${(props) => (props.labelType === 'login' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'login' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'login' ? `left: calc(32.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'signup' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'signup' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'signup' ? `left: calc(32.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `left: calc(32.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 35%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(32.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `left: calc(32.5% + 1rem)` : null)};

    ${(props) => (props.labelType === 'profile-photo' ? `width: max-content` : null)};
  }

  @media ${(props) => props.theme.screens.smallTablet.portrait} {
    ${(props) => (props.labelType === 'login' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'login' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'login' ? `left: calc(30.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'signup' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'signup' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'signup' ? `left: calc(30.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `left: calc(30.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 35%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(30.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `left: calc(30.5% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    ${(props) => (props.labelType === 'login' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'login' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'login' ? `left: calc(31.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'signup' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'signup' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'signup' ? `left: calc(31.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `left: calc(31.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 35%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(31.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `left: calc(31.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'personal-info' ? 'width: max-content' : null)};
    ${(props) => (props.labelType === 'personal-info' ? 'font-size: 1.6rem' : null)};
    ${(props) => (props.labelType === 'personal-info--sub' ? 'width: 40%' : null)};
    ${(props) => (props.labelType === 'personal-info--sub' ? 'font-size: 1.4rem' : null)};
    ${(props) => (props.labelType === 'personal-info--sub' ? `left: calc(35.5% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    ${(props) => (props.labelType === 'login' ? `width: 75%` : null)};
    ${(props) => (props.labelType === 'login' ? `min-width: 75%` : null)};
    ${(props) => (props.labelType === 'login' ? `left: calc(8% + 1rem)` : null)};
    ${(props) => (props.labelType === 'signup' ? `width: 75%` : null)};
    ${(props) => (props.labelType === 'signup' ? `min-width: 75%` : null)};
    ${(props) => (props.labelType === 'signup' ? `left: calc(8% + 1rem)` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `width: 75%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `min-width: 75%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `left: calc(8% + 1rem)` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `width: 75%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 75%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 75%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(8% + 1rem)` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `width: 75%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `min-width: 75%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `left: calc(8% + 1rem)` : null)};
    ${(props) => (props.labelType === 'personal-info' ? 'width: max-content' : null)};
    ${(props) => (props.labelType === 'personal-info--sub' ? 'font-size: 1.4rem' : null)};
    ${(props) => (props.labelType === 'personal-info--sub' ? 'width: 70%' : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.landscape} {
    ${(props) => (props.labelType === 'login' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'login' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'login' ? `left: calc(30.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'login' ? `font-size: 1.6rem` : null)};
    ${(props) => (props.labelType === 'signup' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'signup' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'signup' ? `left: calc(30.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'signup' ? `font-size: 1.6rem` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `left: calc(30.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `font-size: 1.6rem` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 35%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(30.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `font-size: 1.6rem` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `left: calc(30.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `font-size: 1.6rem` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    ${(props) => (props.labelType === 'login' ? `width: 80%` : null)};
    ${(props) => (props.labelType === 'login' ? `min-width: 80%` : null)};
    ${(props) => (props.labelType === 'login' ? `left: calc(5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'signup' ? `width: 80%` : null)};
    ${(props) => (props.labelType === 'signup' ? `min-width: 80%` : null)};
    ${(props) => (props.labelType === 'signup' ? `left: calc(5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `width: 80%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `min-width: 80%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `left: calc(5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `width: 80%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 80%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 80%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `width: 80%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `min-width: 80%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `left: calc(5% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.portrait} {
    ${(props) => (props.labelType === 'login' ? `width: 85%` : null)};
    ${(props) => (props.labelType === 'login' ? `min-width: 85%` : null)};
    ${(props) => (props.labelType === 'login' ? `left: calc(3% + 1rem)` : null)};
    ${(props) => (props.labelType === 'signup' ? `width: 85%` : null)};
    ${(props) => (props.labelType === 'signup' ? `min-width: 85%` : null)};
    ${(props) => (props.labelType === 'signup' ? `left: calc(3% + 1rem)` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `width: 85%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `min-width: 85%` : null)};
    ${(props) => (props.labelType === 'forgot-password' ? `left: calc(3% + 1rem)` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `width: 85%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 85%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 85%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(3% + 1rem)` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `width: 85%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `min-width: 85%` : null)};
    ${(props) => (props.labelType === 'reset-password' ? `left: calc(3% + 1rem)` : null)};
  }

  ${(props) => (props.modifier === 'white' ? `color: ${props.theme.colors.grayScale.offWhite}cc` : null)}
  ${(props) => (props.inBudget === true ? `left: ${props.left}` : null)}
`;

export const UserProfileLabel = styled(LandingFormLabel)`
  color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};
  left: calc(38% + 1rem);
  transform: ${(props) => props.transform};

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    ${(props) => (props.labelType === 'personal-info' ? `left: calc(33.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'personal-info--sub' ? 'font-size: 1.2rem' : null)};
    ${(props) => (props.labelType === 'personal-info--sub' ? `left: calc(34.5% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    ${(props) => (props.labelType === 'personal-info' ? `left: calc(17% + 1rem)` : null)};
    /* ${(props) => (props.labelType === 'personal-info--sub' ? 'font-size: 1.2rem' : null)}; */
    ${(props) => (props.labelType === 'personal-info--sub' ? `left: calc(18% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.landscape} {
    ${(props) => (props.labelType === 'personal-info' ? `left: calc(30.5% + 1rem)` : null)};
    ${(props) => (props.labelType === 'personal-info--sub' ? 'font-size: 1.2rem' : null)};
    ${(props) => (props.labelType === 'personal-info--sub' ? `left: calc(32% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    ${(props) => (props.labelType === 'personal-info' ? `left: calc(13% + 1rem)` : null)};
    ${(props) => (props.labelType === 'personal-info--sub' ? 'font-size: 1.2rem' : null)};
    ${(props) => (props.labelType === 'personal-info--sub' ? `left: calc(13% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.landscape} {
    ${(props) => (props.labelType === 'personal-info' ? `left: calc(30.5% + 1rem)` : null)};
    /* ${(props) => (props.labelType === 'personal-info--sub' ? 'font-size: 1.2rem' : null)}; */
    ${(props) => (props.labelType === 'personal-info--sub' ? `left: calc(32% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.portrait} {
    ${(props) => (props.labelType === 'personal-info' ? `left: calc(7% + 1rem)` : null)};
    /* ${(props) => (props.labelType === 'personal-info--sub' ? 'font-size: 1.2rem' : null)}; */
    ${(props) => (props.labelType === 'personal-info--sub' ? `left: calc(8% + 1rem)` : null)};
  }
`;

export const SwitchLabel = styled(FormLabel)`
  position: relative;
  height: max-content;
  min-height: 2rem;
  width: 20%;
  min-width: 20%;
  justify-content: center;
  font-family: ${(props) => props.theme.text.secondary};
  font-size: 2rem;
  color: ${(props) => `${props.theme.colors.primaryColor}cc`};
  transform: scale(0.75);

  @media ${(props) => props.theme.screens.largeTablet.landscape} {
    ${(props) => (props.labelType === 'profile--lds' ? 'width: 30%' : null)};
    ${(props) => (props.labelType === 'profile--lds' ? 'min-width: 30%' : null)};
  }

  @media ${(props) => props.theme.screens.largeTablet.portrait} {
    ${(props) => (props.labelType === 'landing-lds' ? 'width: 30%' : null)};
    ${(props) => (props.labelType === 'landing-lds' ? 'min-width: 30%' : null)};
    ${(props) => (props.labelType === 'profile--lds' ? 'width: 30%' : null)};
    ${(props) => (props.labelType === 'profile--lds' ? 'min-width: 30%' : null)};
  }

  @media ${(props) => props.theme.screens.smallTablet.landscape} {
    ${(props) => (props.kind === 'choose-photo' ? 'width: max-content' : null)};
    ${(props) => (props.labelType === 'profile--lds' ? 'width: 40%' : null)};
    ${(props) => (props.labelType === 'profile--lds' ? 'min-width: 40%' : null)};
  }

  @media ${(props) => props.theme.screens.smallTablet.portrait} {
    ${(props) => (props.labelType === 'landing-lds' ? 'width: 40%' : null)};
    ${(props) => (props.labelType === 'landing-lds' ? 'min-width: 40%' : null)};
    ${(props) => (props.labelType === 'profile--lds' ? 'width: 40%' : null)};
    ${(props) => (props.labelType === 'profile--lds' ? 'min-width: 40%' : null)};
    ${(props) => (props.kind === 'choose-photo' ? 'width: max-content' : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    ${(props) => (props.labelType === 'profile--lds' ? 'width: 60%' : null)};
    ${(props) => (props.labelType === 'profile--lds' ? 'min-width: 60%' : null)};
    ${(props) => (props.labelType === 'profile--lds' ? 'margin-bottom: .5rem' : null)};
    ${(props) => (props.labelType === 'profile--communications' ? 'width: 60%' : null)};
    ${(props) => (props.labelType === 'profile--communications' ? 'min-width: 60%' : null)};
    ${(props) => (props.labelType === 'profile--communications' ? 'margin-bottom: .5rem' : null)};
    ${(props) => (props.labelType === 'profile--communications' ? 'font-size: 1.6rem' : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    ${(props) => (props.labelType === 'landing-lds' ? 'width: 100%' : null)};
    ${(props) => (props.labelType === 'landing-lds' ? 'min-width: 100%' : null)};
    ${(props) => (props.labelType === 'profile--lds' ? 'width: 60%' : null)};
    ${(props) => (props.labelType === 'profile--lds' ? 'min-width: 60%' : null)};
    ${(props) => (props.labelType === 'profile--communications' ? 'width: 80%' : null)};
    ${(props) => (props.labelType === 'profile--communications' ? 'min-width: 80%' : null)};
    ${(props) => (props.labelType === 'profile--communications' ? 'margin-bottom: .5rem' : null)};
    ${(props) => (props.labelType === 'profile--communications' ? 'font-size: 1.6rem' : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    ${(props) => (props.kind === 'choose-photo' ? 'font-size: 1.6rem' : null)};
    ${(props) => (props.labelType === 'profile--lds' ? 'width: 70%' : null)};
    ${(props) => (props.labelType === 'profile--lds' ? 'min-width: 70%' : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.portrait} {
    ${(props) => (props.kind === 'choose-photo' ? 'font-size: 1.6rem' : null)};
    ${(props) => (props.labelType === 'profile--lds' ? 'width: 80%' : null)};
    ${(props) => (props.labelType === 'profile--lds' ? 'min-width: 80%' : null)};
  }

  ${(props) => (props.modifier === 'white' ? `color: ${props.theme.colors.grayScale.offWhite}` : null)};
  ${(props) => (props.modifier === 'white comms' ? `width: ${props.width}` : null)};
  ${(props) => (props.modifier === 'white comms' ? `color: ${props.theme.colors.grayScale.offWhite}` : null)};
  ${(props) => (props.modifier === 'transaction' ? `width: ${props.width}` : null)};
  ${(props) => (props.kind === 'choose-photo' ? 'position: absolute' : null)};
  ${(props) => (props.kind === 'choose-photo' ? '& i { margin-right: 1rem}' : null)};
`;

export const TransactionLabel = styled(SwitchLabel)`
  font-family: ${(props) => props.theme.text.secondary};
  position: absolute;
  transform: translate(0, -50%) scale(1);
  top: 50%;
  left: 1rem;
`;

export const TwoFactorLabel = styled(FormLabel)`
  position: absolute;
  height: 6rem;
  min-height: 3rem;
  width: 20%;
  min-width: 20%;
  top: 50%;
  left: calc(40% + 1rem);
  transform: rotate(0) translate(0, -50%);
  padding: 1rem;
  font-size: 2rem;
  color: ${(props) => props.theme.colors.grayScale.offWhite};
  margin-bottom: 2rem;

  @media ${(props) => props.theme.screens.largeTablet.landscape} {
    ${(props) => (props.labelType === 'two-factor' ? `width: 30%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 30%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 30%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(35% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.largeTablet.portrait} {
    ${(props) => (props.labelType === 'two-factor' ? `width: 30%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 30%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 30%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(35% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.smallTablet.landscape} {
    ${(props) => (props.labelType === 'two-factor' ? `width: 35%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 35%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 35%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(32.5% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.smallTablet.portrait} {
    ${(props) => (props.labelType === 'two-factor' ? `width: 40%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 40%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 40%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(29.5% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    ${(props) => (props.labelType === 'two-factor' ? `width: 40%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 40%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 40%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(31.5% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    ${(props) => (props.labelType === 'two-factor' ? `width: 75%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 75%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 75%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(9% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.landscape} {
    ${(props) => (props.labelType === 'two-factor' ? `width: 40%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 40%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 40%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(29.5% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    ${(props) => (props.labelType === 'two-factor' ? `width: 85%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 85%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 85%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(5% + 1rem)` : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.portrait} {
    ${(props) => (props.labelType === 'two-factor' ? `width: 95%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `min-width: 95%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `max-width: 95%` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `font-size: 1.6rem` : null)};
    ${(props) => (props.labelType === 'two-factor' ? `left: calc(3% + 1rem)` : null)};
  }
`;

export const CreateBudgetFormLabel = styled(LandingFormLabel)`
  height: max-content;
  min-height: 7rem;
  width: max-content;
  min-width: 40%;
  left: calc(30% + 1rem);
  margin: ${(props) => props.margin};
`;

export const TithingLabel = styled(FormLabel)`
  position: ${(props) => props.position};
  min-height: 3rem;
  min-width: 10%;
  padding: 1rem;
  font-size: 2rem;
  color: ${(props) => `${props.theme.colors.primaryColor}cc`};
  transform: ${(props) => props.transform};
  ${(props) => (props.inBudget === true ? `font-size: ${props.textSize}` : null)};
`;

export const TimingLabel = styled(FormLabel)`
  justify-content: space-evenly;
  color: ${(props) => props.theme.colors.primaryColor};
  transform: scale(0.8);
  margin: 0 0 1rem 0;

  & p {
    font-size: 1.6rem;
    margin-right: 1rem;
  }
`;

export const EmeregencyLabel = styled(FormLabel)`
  position: ${(props) => props.position};
  min-height: 3rem;
  min-width: 10%;
  padding: 1rem;
  font-size: 2rem;
  color: ${(props) => `${props.theme.colors.primaryColor}cc`};
  transform: ${(props) => props.transform};
  ${(props) => (props.inBudget === true ? `font-size: ${props.textSize}` : null)}
`;

export const SmallContainerLabel = styled(FormLabel)`
  position: absolute;
  height: ${(props) => props.labelHeight};
  min-height: 3rem;
  width: ${(props) => props.labelWidth};
  min-width: 20%;
  top: 50%;
  left: ${(props) => props.fromLeft};
  transform: rotate(0) translate(0, -50%);
  padding: 1rem;
  font-size: 2rem;
  color: ${(props) => props.textColor};

  ${(props) => (props.modifier === 'white' ? `color: ${props.theme.colors.grayScale.offWhite}cc` : null)}
`;

export const SearchLabel = styled(TithingLabel)``;
