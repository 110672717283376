import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import qs from 'qs';
import { FormContainer } from '../FormContainer';
import { Form } from '../Form';
import { LandingFormCloser } from '../FormCloser';
import { LandingFormHeader } from '../Header';
import ErrorContainer from '../Errors';
import FormSection from '../Section';
import { LandingFormInput } from '../Input';
import { LandingFormLabel } from '../Label';
import { PrimaryButton } from '../../Button';
import { handleInputChange } from '../Algorithms/InputChange';

const initialPasswordValues = {
  newPassword: '',
  newPasswordConfirmed: '',
};

export const ResetPassword = function (props) {
  const { theme, errors, setErrors, disabledValues, setDisabledValues, initialDisabledValues } = props;
  const [resetPasswordValues, setResetPasswordValues] = useState(initialPasswordValues);

  const resetPassword = async () => {
    if (!newPassword || !newPasswordConfirmed) return;
    let token = window.location.href.split('/')[6];
    try {
      const response = await axios({
        method: 'PATCH',
        url: `/Users/ResetPassword/${token}`,
        data: qs.stringify({
          password: resetPasswordValues.newPassword,
          passwordConfirmed: resetPasswordValues.newPasswordConfirmed,
        }),
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormContainer className="form-container r__form-container">
      <Form position="relative" justify={'center'} align={'center'} flow={'column nowrap'} bgColor={`${theme.colors.grayScale.offWhite}f2`} className="form r__form">
        <Link to="..">
          <LandingFormCloser onClick={() => setErrors([])}>
            <i className="fas fa-window-close" data-cy="forgottenPasswordFormCloser" />
          </LandingFormCloser>
        </Link>
        <LandingFormHeader className="form__header r__form__header" margin="0rem 0rem 2rem" headerType="reset-password">
          Reset Password
        </LandingFormHeader>
        <ErrorContainer errorContainerType="landing">
          {errors && (
            <ol>
              {Object.keys(errors).map((error, index) => {
                if (errors[error] !== '') {
                  return (
                    <li className="error" key={index}>
                      {errors[error]}
                    </li>
                  );
                }
              })}
            </ol>
          )}
        </ErrorContainer>
        <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section" sectionType="reset-password">
          <LandingFormInput
            type="password"
            name="newPassword"
            inputType="reset-password"
            minLength={8}
            maxLength={40}
            inputWidth="20%"
            className="form__input r__form__input"
            id="newPassword"
            placeholder="Enter Password"
            focusTransform="translate(-5.5rem, -7rem) scale(0.75)"
            data-cy="newPassword"
            onChange={(e) => {
              handleInputChange(e, 'reset-password', resetPasswordValues, setResetPasswordValues, errors, setErrors, disabledValues, setDisabledValues);
            }}
          />
          <LandingFormLabel htmlFor="newPassword" data-cy="newPasswordLabel" labelType="reset-password">
            New Password
          </LandingFormLabel>
        </FormSection>
        <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section">
          <LandingFormInput
            type="password"
            name="newPasswordConfirmed"
            inputType="reset-password"
            minLength={8}
            maxLength={40}
            inputWidth="20%"
            className="form__input r__form__input"
            id="newPasswordConfirmed"
            placeholder="Enter Password"
            focusTransform="translate(-5.5rem, -7rem) scale(0.75)"
            data-cy="newPasswordConfirmed"
            onChange={(e) => {
              handleInputChange(e, 'reset-password', resetPasswordValues, setResetPasswordValues, errors, setErrors, disabledValues, setDisabledValues);
            }}
          />
          <LandingFormLabel htmlFor="newPasswordConfirmed" data-cy="passwordConfirmedLabel" labelType="reset-password">
            New Password Confirmed
          </LandingFormLabel>
        </FormSection>
        <FormSection minHeight="5rem" flow="row nowrap" justify="center" align="center" className="form__section r__form__section">
          <PrimaryButton
            size="medium"
            type="submit"
            className="reset-password-submit"
            // disabled={disabledValues.signupDisabled}
            data-cy="resetPasswordSubmit"
            onClick={(e) => {
              e.preventDefault();
              resetPassword();
            }}>
            Submit
          </PrimaryButton>
        </FormSection>
      </Form>
    </FormContainer>
  );
};
