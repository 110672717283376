import React, { useState } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import chroma from 'chroma-js';
import { produce } from 'immer';
import { handleTransaction } from '../Forms/Algorithms/InputChange';
import { PrimaryButton } from '../Button';
import FormSection from '../Forms/Section';
import { SubCategoryGoalInput } from '../Forms/Input';
import { SwitchLabel } from '../Forms/Label';
import { myCalendar } from '../../../Classes/FrontEnd-Calendar';
import { getCamelCaseAccount } from './BudgetUtility';

const StyledTransactionPlan = styled.section`
  &.transaction-plan {
    position: relative;
    height: max-content;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    border: 0.2rem solid ${(props) => props.theme.colors.primaryColor};
    margin-bottom: 1rem;
    font-family: ${(props) => props.theme.text.secondary};

    & .transaction-plan__header {
      position: relative;
      height: max-content;
      min-height: 6rem;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
      background-color: ${(props) => chroma(props.theme.colors.primaryColor).darken(0.4).hex()};

      & p {
        font-size: 2rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }
    & .transaction-plan__sub-header {
      position: relative;
      height: max-content;
      min-height: 4rem;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
      background-color: ${(props) => props.theme.colors.primaryColor};

      & p {
        font-size: 1.6rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }
    & .transaction-plan__action-container {
      position: relative;
      height: max-content;
      min-height: 6rem;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
      background-color: ${(props) => props.theme.colors.grayScale.offWhite};

      & p {
        font-size: 2rem;
        color: ${(props) => props.theme.colors.primaryColor};
      }
    }
  }
`;

export const TransactionPlan = function (props) {
  const { theme, user, setUser, budget, setBudget, transaction, transactionIndex, errors, setErrors } = props;
  const [amountSaved, setAmountSaved] = useState(0);

  const getPlanStatement = (plan) => {
    if (plan.timingOptions.paymentCycle === 'Once') {
      return `The ${DateTime.fromISO(plan.timingOptions.dueDates[0]).day}${myCalendar.getDaySuffix(DateTime.fromISO(plan.timingOptions.dueDates[0]).day)} of ${
        DateTime.fromISO(plan.timingOptions.dueDates[0]).monthLong
      }`;
    } else if (plan.timingOptions.paymentCycle === 'Weekly') {
      return `Every ${DateTime.fromISO(plan.timingOptions.dueDates[0]).weekdayLong}, starting the ${DateTime.fromISO(plan.timingOptions.dueDates[0]).day}${myCalendar.getDaySuffix(
        DateTime.fromISO(plan.timingOptions.dueDates[0]).day
      )} of ${DateTime.fromISO(plan.timingOptions.dueDates[0]).monthLong}`;
    } else if (plan.timingOptions.paymentCycle === 'Bi-Weekly') {
      return `Every two weeks, starting the ${DateTime.fromISO(plan.timingOptions.dueDates[0]).day}${myCalendar.getDaySuffix(DateTime.fromISO(plan.timingOptions.dueDates[0]).day)} of ${
        DateTime.fromISO(plan.timingOptions.dueDates[0]).monthLong
      }`;
    } else if (plan.timingOptions.paymentCycle === 'Bi-Monthly') {
      return `Every ${DateTime.fromISO(plan.timingOptions.dueDates[0]).day}${myCalendar.getDaySuffix(DateTime.fromISO(plan.timingOptions.dueDates[0]).day)} and ${
        DateTime.fromISO(plan.timingOptions.dueDates[1]).day
      }${myCalendar.getDaySuffix(DateTime.fromISO(plan.timingOptions.dueDates[1]).day)} of each month`;
    } else if (plan.timingOptions.paymentCycle === 'Monthly') {
      return `Every month on the ${DateTime.fromISO(plan.timingOptions.dueDates[0]).day}${myCalendar.getDaySuffix(DateTime.fromISO(plan.timingOptions.dueDates[0]).day)}`;
    } else if (plan.timingOptions.paymentCycle === 'Quarterly') {
      return `Every three months, starting the ${DateTime.fromISO(plan.timingOptions.dueDates[0]).day}${myCalendar.getDaySuffix(DateTime.fromISO(plan.timingOptions.dueDates[0]).day)} of ${
        DateTime.fromISO(plan.timingOptions.dueDates[0]).monthLong
      }`;
    } else if (plan.timingOptions.paymentCycle === 'Bi-Annual') {
      return `On the ${DateTime.fromISO(plan.timingOptions.dueDates[0]).day}${myCalendar.getDaySuffix(DateTime.fromISO(plan.timingOptions.dueDates[0]).day)} of ${
        DateTime.fromISO(plan.timingOptions.dueDates[0]).monthLong
      } and ${DateTime.fromISO(plan.timingOptions.dueDates[1]).day}${myCalendar.getDaySuffix(DateTime.fromISO(plan.timingOptions.dueDates[1]).day)} of ${
        DateTime.fromISO(plan.timingOptions.dueDates[1]).monthLong
      } each year.`;
    } else if (plan.timingOptions.paymentCycle === 'Annual') {
      return `Every year on the ${DateTime.fromISO(plan.timingOptions.dueDates[0]).day}${myCalendar.getDaySuffix(DateTime.fromISO(plan.timingOptions.dueDates[0]).day)} of ${
        DateTime.fromISO(plan.timingOptions.dueDates[0]).monthLong
      }`;
    }
  };

  return (
    <StyledTransactionPlan className="transaction-plan">
      <header className="transaction-plan__header">
        <p>{transaction && DateTime.fromISO(transaction.date).toLocaleString(DateTime.DATE_HUGE)}</p>
        <p>{transaction && `Payment Location: ${transaction.location || `${user.firstname}'s Choice`}`}</p>
        <p>{transaction && `Expense: ${transaction.name}`}</p>
        <p>{user && transaction && `Payment Amount: ${user.money.format(transaction.payment)}`}</p>
      </header>
      <div className="transaction-plan__sub-header">
        <p>{transaction && `Account: ${transaction.account}`}</p>
        <p>{transaction && `Sub-Account: ${transaction.subAccount}`}</p>
        {transaction && transaction.lender && <p>{`Lender: ${transaction.lender}`}</p>}
        <p>{transaction && transaction.timingOptions && transaction.timingOptions.dueDates && `Due Date(s): ${getPlanStatement(transaction)}`}</p>
      </div>
      <div className="transaction-plan__action-container">
        <p>{user && transaction && `Saved: ${user.money.format(transaction.amountSaved)}`}</p>
        <FormSection modifier="transactional" minHeight="5rem" sectionHeight="100%" sectionWidth="max-content" flow="column-reverse nowrap" justify="space-evenly" align="center" padding=".5rem">
          {transaction && (
            <FormSection modifier="transactional" minHeight="5rem" sectionHeight="50%" sectionWidth="max-content" flow="row nowrap" justify="space-evenly" align="center">
              <SubCategoryGoalInput
                type="number"
                name="applied"
                minHeight="4rem"
                maxHeight="4rem"
                width="13rem"
                placeholder="Amount To Apply"
                modifier="transaction"
                className="individual-saved-amount"
                min={0}
                onChange={(e) =>
                  handleTransaction(e, 'Planning', { user: user, budget: budget, transactionIndex: transactionIndex, errors: errors }, { setUser: setUser, setBudget: setBudget, setErrors: setErrors })
                }
              />
              <PrimaryButton
                size="small"
                minWidth="10rem"
                maxWidth="10rem"
                margin="0 0 0 2rem"
                onClick={(e) => {
                  e.preventDefault();
                  const savedAmounts = document.querySelectorAll('.individual-saved-amount');
                  const valueToUse = Number(savedAmounts[transactionIndex].value);
                  if (isNaN(valueToUse)) valueToUse = 0;
                  if (valueToUse < 0) {
                    return setErrors({ ...errors, ['apply-money']: 'You cannot apply a negative amount to your transaction plans.' });
                  } else {
                    setErrors({ ...errors, ['apply-money']: '' });
                  }
                  const account = getCamelCaseAccount(transaction.account);

                  if (valueToUse > budget.accounts[account].amount.unUsed) {
                    return setErrors({ ...errors, ['apply-money']: 'You do not have enough to apply money to this plan.' });
                  } else {
                    setErrors({ ...errors, ['apply-money']: '' });
                  }
                  const updated = produce(budget, (roughDraft) => {
                    roughDraft.transactions.plannedTransactions[transactionIndex].amountSaved += valueToUse;
                    roughDraft.accounts[account].addToUsed(valueToUse);
                    roughDraft.accounts[account].removeFromUnUsed(valueToUse);
                  });
                  let updateObject = {
                    budgetId: budget._id,
                    userId: user._id,
                    lastUpdated: DateTime.now().toISO(),
                    currentMonth: budget.currentMonth,
                    previousMonth: budget.previousMonth || budget.currentMonth,
                    accounts: {
                      unAllocated: budget.accounts.unAllocated,
                      monthlyBudget: budget.accounts.monthlyBudget,
                      emergencyFund: budget.accounts.emergencyFund,
                      investmentFund: budget.accounts.investmentFund,
                    },
                  };

                  // THERE IS AN ERROR IN THE addToUsed function below, which mostly stems from the fact that state should NOT be mutated, even for the server request for an update.

                  if (account === 'savingsFund') {
                    updateObject.accounts[account] = {
                      savingsGoal: budget.accounts[account].savingsGoal,
                      savingsPercentage: budget.accounts[account].savingsPercentage,
                      amount: { total: budget.accounts[account].getTotal(), used: updated.accounts[account].amount.used, unUsed: updated.accounts[account].amount.unUsed },
                    };
                    updateObject.accounts.expenseFund = budget.accounts.expenseFund;
                    updateObject.accounts.surplus = budget.accounts.surplus;
                    updateObject.accounts.debt = budget.accounts.debt;
                  }

                  if (account === 'expenseFund') {
                    updateObject.accounts[account] = {
                      amount: { total: budget.accounts[account].getTotal(), used: updated.accounts[account].amount.used, unUsed: updated.accounts[account].amount.unUsed },
                    };
                    updateObject.accounts.savingsFund = budget.accounts.savingsFund;
                    updateObject.accounts.surplus = budget.accounts.surplus;
                    updateObject.accounts.debt = budget.accounts.debt;
                  }

                  if (account === 'surplus') {
                    updateObject.accounts[account] = {
                      amount: { total: budget.accounts[account].getTotal(), used: updated.accounts[account].amount.used, unUsed: updated.accounts[account].amount.unUsed },
                    };
                    updateObject.accounts.savingsFund = budget.accounts.savingsFund;
                    updateObject.accounts.expenseFund = budget.accounts.expenseFund;
                    updateObject.accounts.debt = budget.accounts.debt;
                  }

                  if (account === 'debt') {
                    updateObject.accounts[account] = {
                      debtAmount: budget.accounts[account].debtAmount,
                      amount: { total: budget.accounts[account].getTotal(), used: updated.accounts[account].amount.used, unUsed: updated.accounts[account].amount.unUsed },
                    };
                    updateObject.accounts.savingsFund = budget.accounts.savingsFund;
                    updateObject.accounts.expenseFund = budget.accounts.expenseFund;
                    updateObject.accounts.surplus = budget.accounts.surplus;
                  }

                  if (user.latterDaySaint === true) {
                    updateObject.accounts.tithing = {
                      tithingSetting: budget.accounts.tithing.tithingSetting,
                      amount: budget.accounts.tithing.amount,
                      amountOwed: budget.accounts.tithing.amountOwed,
                    };
                  }
                  updateObject.transactions = {
                    recentTransactions: budget.transactions.recentTransactions,
                    plannedTransactions: updated.transactions.plannedTransactions,
                  };
                  setBudget(updated);
                  budget.updateBudget(updateObject, 'Transaction-Planner');
                  savedAmounts[transactionIndex].value = 0;
                }}
                disabled={user && user.permissions === 'Associate'}>
                Apply
              </PrimaryButton>
            </FormSection>
          )}
          {transaction && (
            <SwitchLabel modifier="transaction" width="20rem">
              Apply Money
            </SwitchLabel>
          )}
        </FormSection>
        <PrimaryButton
          size="small"
          minWidth="10rem"
          maxWidth="20rem"
          onClick={(e) => {
            e.preventDefault();
            const account = getCamelCaseAccount(transaction.account);
            const updated = produce(budget, (roughDraft) => {
              roughDraft.accounts[account].removeFromUsed(roughDraft.transactions.plannedTransactions[transactionIndex].amountSaved);
              roughDraft.accounts[account].addToUnUsed(roughDraft.transactions.plannedTransactions[transactionIndex].amountSaved);
              roughDraft.transactions.plannedTransactions = roughDraft.transactions.plannedTransactions.filter((transaction, index) => transactionIndex !== index);
            });
            let updateObject = {
              budgetId: budget._id,
              userId: user._id,
              lastUpdated: DateTime.now().toISO(),
              currentMonth: budget.currentMonth,
              previousMonth: budget.previousMonth || budget.currentMonth,
              accounts: {
                unAllocated: budget.accounts.unAllocated,
                monthlyBudget: budget.accounts.monthlyBudget,
                emergencyFund: budget.accounts.emergencyFund,
                investmentFund: budget.accounts.investmentFund,
              },
            };

            if (account === 'savingsFund') {
              updateObject.accounts[account] = {
                savingsGoal: budget.accounts[account].savingsGoal,
                savingsPercentage: budget.accounts[account].savingsPercentage,
                amount: {
                  total: budget.accounts[account].amount.total,
                  used: budget.accounts[account].amount.used - budget.transactions.plannedTransactions[transactionIndex].amountSaved,
                  unUsed: budget.accounts[account].amount.unUsed + budget.transactions.plannedTransactions[transactionIndex].amountSaved,
                },
              };
              updateObject.accounts.expenseFund = budget.accounts.expenseFund;
              updateObject.accounts.surplus = budget.accounts.surplus;
              updateObject.accounts.debt = budget.accounts.debt;
            }

            if (account === 'expenseFund') {
              updateObject.accounts[account] = {
                amount: {
                  total: budget.accounts[account].amount.total,
                  used: budget.accounts[account].amount.used - budget.transactions.plannedTransactions[transactionIndex].amountSaved,
                  unUsed: budget.accounts[account].amount.unUsed + budget.transactions.plannedTransactions[transactionIndex].amountSaved,
                },
              };
              updateObject.accounts.savingsFund = budget.accounts.savingsFund;
              updateObject.accounts.surplus = budget.accounts.surplus;
              updateObject.accounts.debt = budget.accounts.debt;
            }

            if (account === 'surplus') {
              updateObject.accounts[account] = {
                amount: {
                  total: budget.accounts[account].amount.total,
                  used: budget.accounts[account].amount.used - budget.transactions.plannedTransactions[transactionIndex].amountSaved,
                  unUsed: budget.accounts[account].amount.unUsed + budget.transactions.plannedTransactions[transactionIndex].amountSaved,
                },
              };
              updateObject.accounts.savingsFund = budget.accounts.savingsFund;
              updateObject.accounts.expenseFund = budget.accounts.expenseFund;
              updateObject.accounts.debt = budget.accounts.debt;
            }

            if (account === 'debt') {
              updateObject.accounts[account] = {
                debtAmount: budget.accounts[account].debtAmount,
                amount: {
                  total: budget.accounts[account].amount.total,
                  used: budget.accounts[account].amount.used - budget.transactions.plannedTransactions[transactionIndex].amountSaved,
                  unUsed: budget.accounts[account].amount.unUsed + budget.transactions.plannedTransactions[transactionIndex].amountSaved,
                },
              };
              updateObject.accounts.savingsFund = budget.accounts.savingsFund;
              updateObject.accounts.expenseFund = budget.accounts.expenseFund;
              updateObject.accounts.surplus = budget.accounts.surplus;
            }

            if (user.latterDaySaint === true) {
              updateObject.accounts.tithing = {
                tithingSetting: budget.accounts.tithing.tithingSetting,
                amount: budget.accounts.tithing.amount,
              };
            }
            updateObject.transactions = {
              recentTransactions: budget.transactions.recentTransactions,
              plannedTransactions: updated.transactions.plannedTransactions,
            };
            setBudget(updated);
            budget.updateBudget(updateObject, 'Transaction-Planner');
          }}
          disabled={user && user.permissions === 'Associate'}>
          Paid In Full? | Remove
        </PrimaryButton>
        <p>{transaction && transaction.paidStatus}</p>
      </div>
    </StyledTransactionPlan>
  );
};
