import React from 'react';
import styled from 'styled-components';

const Radio = styled.input`
  position: relative;
`;

const InvisibleRadio = styled(Radio)`
  appearance: none;
  -webkit-appearance: none;
  display: none;
`;

export const TithingRadio = styled(InvisibleRadio)`
  & + label {
    position: relative;
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0) rotate(0);
    top: 0;
    left: 0;
    &:hover {
      cursor: pointer;
      background-color: ${(props) => props.theme.colors.primaryColor};
      color: ${(props) => props.theme.colors.grayScale.offWhite};
    }

    &.grossOptionLabel {
      border-top: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-left: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-bottom: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-top-left-radius: 1.25rem;
      border-bottom-left-radius: 1.25rem;
      &:hover {
        border-top: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        border-left: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        border-bottom: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        transition: border 0.5s, background-color 0.5s, color 0.5s;
      }
    }

    &.netOptionLabel {
      border-top: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-bottom: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      &:hover {
        border-top: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        border-bottom: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        transition: border 0.5s, background-color 0.5s, color 0.5s;
      }
    }

    &.surplusOptionLabel {
      border-top: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-right: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-bottom: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-top-right-radius: 1.25rem;
      border-bottom-right-radius: 1.25rem;
      &:hover {
        border-top: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        border-right: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        border-bottom: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        transition: border 0.5s, background-color 0.5s, color 0.5s;
      }
    }
  }
  &:checked + label {
    background-color: ${(props) => props.theme.colors.primaryColor};
    color: ${(props) => props.theme.colors.grayScale.offWhite};
    transition: background-color 0.5s, color 0.5s;
  }
`;

export const EmergencyRadio = styled(InvisibleRadio)`
  & + label {
    position: relative;
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0) rotate(0);
    top: 0;
    left: 0;
    &:hover {
      cursor: pointer;
      background-color: ${(props) => props.theme.colors.primaryColor};
      color: ${(props) => props.theme.colors.grayScale.offWhite};
    }

    &.lengthOfTimeLabel {
      border-top: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-left: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-bottom: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-top-left-radius: 1.25rem;
      border-bottom-left-radius: 1.25rem;
      &:hover {
        border-top: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        border-left: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        border-bottom: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        transition: border 0.5s, background-color 0.5s, color 0.5s;
      }
    }

    &.totalAmountLabel {
      border-top: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-right: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-bottom: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-top-right-radius: 1.25rem;
      border-bottom-right-radius: 1.25rem;
      &:hover {
        border-top: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        border-right: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        border-bottom: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        transition: border 0.5s, background-color 0.5s, color 0.5s;
      }
    }
  }
  &:checked + label {
    background-color: ${(props) => props.theme.colors.primaryColor};
    color: ${(props) => props.theme.colors.grayScale.offWhite};
    transition: background-color 0.5s, color 0.5s;
  }
`;

export const SearchRadio = styled(TithingRadio)`
  & + label {
    &.firstnameLabel {
      border-top: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-left: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-bottom: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-top-left-radius: 1.25rem;
      border-bottom-left-radius: 1.25rem;
      &:hover {
        border-top: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        border-left: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        border-bottom: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        transition: border 0.5s, background-color 0.5s, color 0.5s;
      }
    }

    &.lastnameLabel,
    &.fullnameLabel {
      border-top: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-bottom: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      &:hover {
        border-top: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        border-bottom: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        transition: border 0.5s, background-color 0.5s, color 0.5s;
      }
    }

    &.usernameLabel {
      border-top: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-right: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-bottom: 0.3rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
      border-top-right-radius: 1.25rem;
      border-bottom-right-radius: 1.25rem;
      &:hover {
        border-top: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        border-right: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        border-bottom: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
        transition: border 0.5s, background-color 0.5s, color 0.5s;
      }
    }
  }
  &:checked + label {
    background-color: ${(props) => props.theme.colors.primaryColor};
    color: ${(props) => props.theme.colors.grayScale.offWhite};
    transition: background-color 0.5s, color 0.5s;
  }
`;
