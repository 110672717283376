import React, { useState } from 'react';
import { produce } from 'immer';
import { DateTime } from 'luxon';
import { BudgetPageSection } from '../Global/Forms/Section';
import { BudgetContainerHeader } from '../Global/Forms/Header';
import { Form } from '../Global/Forms/Form';
import { ExtraSmallContainer } from '../Global/Budget/BudgetContainers/ExtraSmallContainer';
import FormSection from '../Global/Forms/Section';
import { LandingFormInput } from '../Global/Forms/Input';
import { LandingFormLabel } from '../Global/Forms/Label';
import { PrimaryButton } from '../Global/Button';
import { getFullCashAndAssetValue } from '../Global/Forms/Algorithms/Tithing';
import ErrorContainer from '../Global/Forms/Errors';

const initialAllocationObject = {
  monthlyBudget: 0,
  emergencyFund: 0,
  savingsFund: 0,
  expenseFund: 0,
  debt: 0,
  investmentFund: 0,
  tithing: 0,
};

export const AllocateIncome = function (props) {
  const { theme, user, setUser, budget, setBudget, handleTransaction, errors, setErrors, disabledValues, setDisabledValues } = props;
  const [allocation, setAllocation] = useState(initialAllocationObject);
  return (
    <BudgetPageSection minHeight="85%" flow="row wrap" justify="space-evenly" align="center">
      <BudgetContainerHeader budgetPage={true} height="10rem" justify="center" bgColor={'transparent'} textColor={theme.colors.tertiaryColor} className="budget-page-header">
        Allocate Income
      </BudgetContainerHeader>
      <ErrorContainer>
        {errors && (
          <ol>
            {Object.keys(errors).map((error, index) => {
              if (errors[error] !== '') {
                return (
                  <li className="error" key={index}>
                    {errors[error]}
                  </li>
                );
              }
            })}
          </ol>
        )}
      </ErrorContainer>
      <Form inBudget={true} flow="column nowrap" justify="flex-start" align="center">
        <ExtraSmallContainer
          theme={theme}
          user={user}
          budget={budget}
          title={`Un-Allocated Income`}
          icon={'wallet'}
          content={{
            content: <p>{user && budget && budget.accounts && user.money.format(budget.accounts.unAllocated.amount)}</p>,
            margin: '0 0 3rem 0',
            bgColor: theme.colors.grayScale.offWhite,
          }}
        />
        <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section">
          <LandingFormInput
            type="number"
            name="monthlyBudget"
            min={0}
            inputWidth="23%"
            className="form__input r__form__input"
            id="monthlyBudget"
            placeholder="Monthly Budget Allocation"
            focusTransform="translate(-3.5rem, -7rem) scale(0.75)"
            value={allocation.monthlyBudget}
            data-cy="monthlyBudget"
            onChange={(e) => {
              handleTransaction(
                e,
                'Allocate',
                { user: user, budget: budget, allocation: allocation, errors: errors },
                { setUser: setUser, setBudget: setBudget, setAllocation: setAllocation, setErrors: setErrors }
              );
            }}
          />
          <LandingFormLabel htmlFor="monthlyBudget" data-cy="monthlyBudgetAllocationLabel" inBudget={true} left={`calc(38% + 1rem)`}>
            Monthly Budget
          </LandingFormLabel>
        </FormSection>
        <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section">
          <LandingFormInput
            type="number"
            name="emergencyFund"
            min={0}
            inputWidth="23%"
            className="form__input r__form__input"
            id="emergencyFund"
            placeholder="Emergency Fund Allocation"
            focusTransform="translate(-3.5rem, -7rem) scale(0.75)"
            value={allocation.emergencyFund}
            data-cy="emergencyFund"
            onChange={(e) => {
              handleTransaction(
                e,
                'Allocate',
                { user: user, budget: budget, allocation: allocation, errors: errors },
                { setUser: setUser, setBudget: setBudget, setAllocation: setAllocation, setErrors: setErrors }
              );
            }}
          />
          <LandingFormLabel htmlFor="emergencyFund" data-cy="emergencyFundAllocationLabel" inBudget={true} left={`calc(38% + 1rem)`}>
            Emergency Fund
          </LandingFormLabel>
        </FormSection>
        <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section">
          <LandingFormInput
            type="number"
            name="savingsFund"
            min={0}
            inputWidth="23%"
            className="form__input r__form__input"
            id="savingsFund"
            placeholder="Savings Account Allocation"
            focusTransform="translate(-3.5rem, -7rem) scale(0.75)"
            value={allocation.savingsFund}
            data-cy="savingsFund"
            onChange={(e) => {
              handleTransaction(
                e,
                'Allocate',
                { user: user, budget: budget, allocation: allocation, errors: errors },
                { setUser: setUser, setBudget: setBudget, setAllocation: setAllocation, setErrors: setErrors }
              );
            }}
          />
          <LandingFormLabel htmlFor="savingsFund" data-cy="savingsAccountAllocationLabel" inBudget={true} left={`calc(38% + 1rem)`}>
            Savings Account
          </LandingFormLabel>
        </FormSection>
        <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section">
          <LandingFormInput
            type="number"
            name="expenseFund"
            min={0}
            inputWidth="23%"
            className="form__input r__form__input"
            id="expenseFund"
            placeholder="Expense Fund Allocation"
            focusTransform="translate(-3.5rem, -7rem) scale(0.75)"
            value={allocation.expenseFund}
            data-cy="expenseFund"
            onChange={(e) => {
              handleTransaction(
                e,
                'Allocate',
                { user: user, budget: budget, allocation: allocation, errors: errors },
                { setUser: setUser, setBudget: setBudget, setAllocation: setAllocation, setErrors: setErrors }
              );
            }}
          />
          <LandingFormLabel htmlFor="expenseFund" data-cy="expenseFundAllocationLabel" inBudget={true} left={`calc(38% + 1rem)`}>
            Expense Fund
          </LandingFormLabel>
        </FormSection>
        <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section">
          <LandingFormInput
            type="number"
            name="debt"
            min={0}
            inputWidth="23%"
            className="form__input r__form__input"
            id="debt"
            placeholder="Debt Allocation"
            focusTransform="translate(-3.5rem, -7rem) scale(0.75)"
            value={allocation.debt}
            data-cy="debt"
            onChange={(e) => {
              handleTransaction(
                e,
                'Allocate',
                { user: user, budget: budget, allocation: allocation, errors: errors },
                { setUser: setUser, setBudget: setBudget, setAllocation: setAllocation, setErrors: setErrors }
              );
            }}
          />
          <LandingFormLabel htmlFor="debt" data-cy="debtAllocationLabel" inBudget={true} left={`calc(38% + 1rem)`}>
            Debt
          </LandingFormLabel>
        </FormSection>
        <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section">
          <LandingFormInput
            type="number"
            name="investmentFund"
            min={0}
            inputWidth="23%"
            className="form__input r__form__input"
            id="investmentFund"
            placeholder="Investment Fund Allocation"
            focusTransform="translate(-3.5rem, -7rem) scale(0.75)"
            value={allocation.investmentFund}
            data-cy="investmentFund"
            onChange={(e) => {
              handleTransaction(
                e,
                'Allocate',
                { user: user, budget: budget, allocation: allocation, errors: errors },
                { setUser: setUser, setBudget: setBudget, setAllocation: setAllocation, setErrors: setErrors }
              );
            }}
          />
          <LandingFormLabel htmlFor="investmentFund" data-cy="investmentFundAllocationLabel" inBudget={true} left={`calc(38% + 1rem)`}>
            Investment Fund
          </LandingFormLabel>
        </FormSection>
        <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section">
          <LandingFormInput
            type="number"
            name="tithing"
            min={0}
            inputWidth="23%"
            className="form__input r__form__input"
            id="tithing"
            placeholder="Tithing Allocation"
            focusTransform="translate(-3.5rem, -7rem) scale(0.75)"
            value={allocation.tithing}
            data-cy="tithing"
            onChange={(e) => {
              handleTransaction(
                e,
                'Allocate',
                { user: user, budget: budget, allocation: allocation, errors: errors },
                { setUser: setUser, setBudget: setBudget, setAllocation: setAllocation, setErrors: setErrors }
              );
            }}
          />
          <LandingFormLabel htmlFor="investmentFund" data-cy="investmentFundAllocationLabel" inBudget={true} left={`calc(38% + 1rem)`}>
            Tithing
          </LandingFormLabel>
        </FormSection>
        <PrimaryButton
          size="medium"
          margin="2rem 0 5rem"
          onClick={(e) => {
            e.preventDefault();
            let allocatedTotal = 0;
            for (let key in allocation) {
              allocatedTotal += Number(allocation[key]);
            }
            if (Number(allocatedTotal) > budget.accounts.unAllocated.amount) {
              return setErrors({ ...errors, ['allocation']: `You do not have enough un-allocated money to allocate ${user && user.money.format(allocatedTotal)}.` });
            } else {
              setErrors({ ...errors, ['allocation']: `` });
            }

            const updated = produce(budget, (roughDraft) => {
              roughDraft.accounts.unAllocated.addToAmount(Number(allocatedTotal));
              roughDraft.accounts.monthlyBudget.addToAmount(allocation.monthlyBudget);
              roughDraft.accounts.emergencyFund.addToAmount(allocation.emergencyFund);
              roughDraft.accounts.savingsFund.addToTotal(allocation.savingsFund);
              roughDraft.accounts.savingsFund.addToUnUsed(allocation.savingsFund);
              roughDraft.accounts.expenseFund.addToTotal(allocation.expenseFund);
              roughDraft.accounts.expenseFund.addToUnUsed(allocation.expenseFund);
              roughDraft.accounts.debt.addToTotal(allocation.debt);
              roughDraft.accounts.debt.addToUnUsed(allocation.debt);
              roughDraft.accounts.investmentFund.addToAmount(allocation.investmentFund);
              roughDraft.accounts.tithing.addToAmount(allocation.tithing);
              let total = 0;
              roughDraft.bankAccount = getFullCashAndAssetValue(total, roughDraft.accounts);
            });
            setBudget(updated);
            budget.updateBudget(
              {
                budgetId: budget._id,
                userId: user._id,
                lastUpdated: DateTime.now().toISO(),
                currentMonth: updated.currentMonth,
                previousMonth: updated.previousMonth || updated.currentMonth,
                bankAccount: updated.bankAccount,
                accounts: updated.accounts,
              },
              'Allocate-Income'
            );
            setAllocation(initialAllocationObject);
          }}
          disabled={user && user.permissions === 'Associate'}>
          Allocate
        </PrimaryButton>
      </Form>
    </BudgetPageSection>
  );
};
