import axios from 'axios';
import qs from 'qs';
import * as Utility from '../Utility';

//////////////////////
// USER SIGN UP
export const signup = async (person) => {
  try {
    const response1 = await axios({
      method: `POST`,
      url: `/Users/Signup`,
      data: qs.stringify({
        firstname: person.firstname,
        lastname: person.lastname,
        username: person.username,
        latterDaySaint: person.latterDaySaint,
        email: person.email,
        emailConfirmed: person.emailConfirmed,
        password: person.password,
        passwordConfirmed: person.passwordConfirmed,
      }),
    });
    if (response1.statusText === `OK`) {
      const { user } = response1.data.data;
      const response2 = await axios({
        method: `POST`,
        url: `Users/${user._id}`,
        data: qs.stringify({
          username: person.username,
          password: person.password,
        }),
      });
      if (response2.statusText === 'OK') {
        document.open(`text/html`).write(response2.data);
        window.location.assign(`/Users/${user._id}`);
      }
    }
  } catch (error) {
    const signupFormHeader = document.querySelector('.form__header');
    Utility.showError(signupFormHeader, `${error.response.data.message}`, `Signup`, `error--centered`, 5000);
    console.log(error);
  }
};
