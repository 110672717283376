import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BudgetCard, StyledBudgetCard } from './BudgetCard';
import { Budget } from '../../Classes/Budget';
import { getGridSize } from '../Utility';

const StyledBudgetCardContainer = styled.section`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  transform: translate(0, 0) rotate(0);
  overflow-x: auto;
  padding: 0 5rem;

  & a {
    position: relative;
    height: 100%;
    width: max-content;
    text-decoration: none;
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    & a {
      height: 70%;
    }
  }
`;

export const BudgetCardContainer = function (props) {
  const { theme, user, setUser, budget, setBudget, coverPhoto, setCoverPhoto, budgetId, setBudgetId, isDisabled, setIsDisabled, creatingBudget, setCreatingBudget } = props;

  return (
    <StyledBudgetCardContainer className={`${getGridSize('inner', theme)} budget-card-container r__budget-card-container`}>
      {user &&
        user.budgets &&
        user.budgets.map((budget, index) => {
          return (
            <BudgetCard
              key={index}
              theme={theme}
              user={user}
              setUser={setUser}
              budget={budget}
              coverPhoto={coverPhoto}
              setCoverPhoto={setCoverPhoto}
              budgetId={budgetId}
              setBudgetId={setBudgetId}
              isDisabled={isDisabled}
              setIsDisabled={setIsDisabled}
            />
          );
        })}
      <Link
        to="Create-Budget"
        data-cy="createBudgetLink"
        onClick={() => {
          setCreatingBudget(true);
          setBudget(new Budget(user));
        }}>
        <StyledBudgetCard className="budget-card-container__card--create r__budget-card-container__card--create">
          <header className="budget-card-container__card--create__header r__budget-card-container__card--create__header">
            <p className="budget-card-container__card--create__header__text r__budget-card-container__card--create__header__text">Create Budget</p>
          </header>
          <i className="fas fa-plus budget-card-container__card--create__icon r__budget-card-container__card--create__icon" />
        </StyledBudgetCard>
      </Link>
    </StyledBudgetCardContainer>
  );
};
