import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Application from './Landing/Application';
import Theme from './Theme';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './Global/Style';
import { enableAllPlugins } from 'immer';
import '../../SCSS/Main/main.scss';
enableAllPlugins();

const router = createBrowserRouter(createRoutesFromElements(<Route path="/*" element={<Application theme={Theme} />}></Route>));

const root = ReactDOM.createRoot(document.getElementById('application'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// ~ I place these here to look at later on.
// Utility.build(profile, moment, translate); // * I will need to determine what of the utility items could be deemed useful for later on.
// let utility = get(`utility`);

// utility.commaLocales.includes(profile.locale) ? profile.addTo(`commaFormat`, true) : profile.addTo(`commaFormat`, false); // * This will be useful for translations.
