import { DateTime, Info } from 'luxon';
import { capitalize, toggleClasses } from '../../Utility';

// OVERALL BUDGET UTILITIES
export const buildCategory = (type, title, icon) => {
  if (type === 'main') {
    return { icon: icon, title: title };
  } else if (type === 'sub') {
    return { title: title };
  } else return;
};

export const regulate = (type, value) => {
  if (type === 'title') {
    return value
      .split(' ')
      .map((part) => capitalize(part))
      .join(' ');
  }
};

export const getCategoryIndex = (type, budget, title, index) => {
  let returnedIndex;
  if (type === 'main') {
    budget.mainCategories.forEach((category, i) => {
      if (category.title.toLowerCase() === title.toLowerCase()) returnedIndex = i;
    });
    return returnedIndex;
  } else if (type === 'sub') {
    budget.mainCategories[index].subCategories.forEach((sc, i) => {
      if (sc.title === title) returnedIndex = i;
    });
    return returnedIndex;
  } else return;
};

// MAIN CATEGORY UTILITY FUNCTIONS
export const toggleMainCategoryCreation = (event) => {
  event.preventDefault();
  const mainCategorySelectContainer = document.querySelector('.main-category-select');
  const mainCategoryStartCreation = mainCategorySelectContainer.querySelector('.main-category-start-create');
  const iconsContainer = mainCategorySelectContainer.querySelector('.icons-container');
  const mainCategoryTitleCreation = mainCategorySelectContainer.querySelector('.main-category-title-creation');
  toggleClasses(iconsContainer, [`closed`, `open`]);
  toggleClasses(mainCategoryTitleCreation, [`closed`, `open`]);
  toggleClasses(mainCategoryStartCreation, [`closed`, `open`]);
  if (document.querySelector('.main-category')) {
    document.querySelectorAll('.main-category').forEach((category) => toggleClasses(category, [`closed`, `open`]));
  }
};

export const processMainCategory = (event, type, budget, setBudget, iconObject, creating, setCreating) => {
  event.preventDefault();
  if (type === 'create') {
    // VERIFY
    const titleInput = document.querySelector('.main-category-title-input');
    let filtered = budget.mainCategories.filter((mc) => mc.title.toLowerCase() === titleInput.value.toLowerCase());
    if (filtered.length >= 1 || titleInput.value === '') return;
    // BUILD
    let title = regulate('title', titleInput.value);
    let icon = iconObject.mostRecent.firstChild.classList[1];
    let newCategory = buildCategory('main', title, icon);
    // HAND OVER
    budget.addMainCategory(event, newCategory, budget, setBudget, toggleMainCategoryCreation);
    titleInput.value = '';
    setCreating(!creating);
  } else if (type === 'delete') {
    const clicked = event.target.closest('.main-category');
    let title = clicked.firstChild.textContent;
    budget.removeMainCategory(title, budget, setBudget);
  } else return;
};

// SUB CATEGORY UTILITY FUNCTIONS
export const toggleSubCategoryCreation = (event) => {
  event.preventDefault();
  const subCategoryCreationContainer = document.querySelector('.sub-category-title-creation');
  const subCategoryStartCreation = document.querySelector('.sub-category-start-creation');

  toggleClasses(subCategoryCreationContainer, [`closed`, `open`]);
  toggleClasses(subCategoryStartCreation, [`closed`, `open`]);
};

export const processSubCategory = (event, type, categoryIndex, budget, setBudget, utility) => {
  event.preventDefault();
  // if (user && user.permissions === 'Associate') return;
  if (type === 'create') {
    console.log(`CREATING`);
    // VERIFY
    const subCategoryTitleInput = document.querySelector('.sub-category-title-input');
    const title = regulate('title', subCategoryTitleInput.value);
    let filtered = budget.mainCategories[categoryIndex].subCategories.filter((sc) => sc.title.toLowerCase() === title.toLowerCase());
    if (filtered.length >= 1 || subCategoryTitleInput.value === '') return;
    // BUILD
    const category = buildCategory('sub', title);
    // HAND OVER
    budget.addSubCategory(budget, setBudget, categoryIndex, category);
    subCategoryTitleInput.value = '';
  } else if (type === 'surplus') {
    console.log(`SURPLUS`);
    const subCategoryTitle = event.target.closest('.sub-category').firstChild.firstChild.textContent;
    budget.updateSubCategorySurplusSetting(budget, setBudget, categoryIndex, subCategoryTitle);
  } else if (type === 'delete') {
    console.log(`DELETING`);
    const subCategoryTitle = event.target.closest('.sub-category').firstChild.firstChild.textContent;
    budget.deleteSubCategory(budget, setBudget, categoryIndex, subCategoryTitle);
  } else return;
};

// SUB CATEGORY TIMING UTILITY FUNCTIONS
export const verifyDate = (date) => {
  const currentDate = DateTime.now().toISO();
  return date < currentDate;
};

export const verifyBiMonthlyDates = (dateOne, dateTwo) => {
  return DateTime.fromISO(dateOne).monthLong === DateTime.fromISO(dateTwo).monthLong;
};

export const buildPaymentSchedule = (timing, date) => {
  let startingPayment = 0;
  let numberOfPayments;
  let paymentSchedule = [];
  if (timing === 'Monthly') {
    numberOfPayments = 12;
    let selectedDate = DateTime.fromISO(date);
    while (startingPayment < numberOfPayments) {
      paymentSchedule.push(selectedDate.toISO());
      selectedDate = selectedDate.plus({ month: 1 });

      if (
        (DateTime.fromISO(paymentSchedule[startingPayment]).day === 28 && DateTime.fromISO(paymentSchedule[paymentSchedule.length - 2]).day > 28) ||
        (DateTime.fromISO(paymentSchedule[startingPayment]).day === 29 && DateTime.fromISO(paymentSchedule[paymentSchedule.length - 2]).day > 28)
      ) {
        selectedDate = selectedDate.set({ day: DateTime.fromISO(paymentSchedule[paymentSchedule.length - 2]).day });
      }
      startingPayment++;
    }
  } else if (timing === 'Bi-Monthly') {
    numberOfPayments = 12;
    let selectedDateOne = DateTime.fromISO(date[0]);
    let selectedDateTwo = DateTime.fromISO(date[1]);
    while (startingPayment < numberOfPayments) {
      paymentSchedule.push([selectedDateOne.toISO(), selectedDateTwo.toISO()]);
      selectedDateOne = selectedDateOne.plus({ month: 1 });
      selectedDateTwo = selectedDateTwo.plus({ month: 1 });
      startingPayment++;
    }
  } else if (timing === 'Bi-Weekly') {
    let selectedDate = DateTime.fromISO(date);
    numberOfPayments = 26;
    while (startingPayment < numberOfPayments) {
      paymentSchedule.push(selectedDate.toISO());
      selectedDate = selectedDate.plus({ days: 14 });
      startingPayment++;
    }
  } else if (timing === 'Weekly') {
    numberOfPayments = 52;
    let selectedDate = DateTime.now();
    while (startingPayment < numberOfPayments) {
      while (selectedDate.weekdayLong !== date && selectedDate.weekdayLong !== date.value) {
        selectedDate = selectedDate.plus({ days: 1 });
      }
      paymentSchedule.push(selectedDate.toISO());
      selectedDate = selectedDate.plus({ days: 7 });
      startingPayment++;
    }
  } else if (timing === 'Once' || timing === 'Annual') {
    numberOfPayments = 1;
    let selectedDate = DateTime.fromISO(date);
    while (startingPayment < numberOfPayments) {
      paymentSchedule.push(selectedDate.toISO());
      startingPayment++;
    }
  } else if (timing === 'Quarterly') {
    numberOfPayments = 4;
    let selectedDate = DateTime.fromISO(date);
    while (startingPayment < numberOfPayments) {
      paymentSchedule.push(selectedDate.toISO());
      selectedDate = selectedDate.plus({ month: 3 });
      startingPayment++;
    }
  } else if (timing === 'Bi-Annual') {
    numberOfPayments = 1;
    let selectedDateOne = DateTime.fromISO(date[0]);
    let selectedDateTwo = DateTime.fromISO(date[1]);
    while (startingPayment < numberOfPayments) {
      paymentSchedule.push([selectedDateOne.toISO(), selectedDateTwo.toISO()]);
      startingPayment++;
    }
  }
  return paymentSchedule;
};

export const getMostRecentPayment = (schedule, timing) => {
  let recentPayment;
  if (timing !== 'Bi-Monthly') {
    schedule.forEach((payment) => {
      if (payment < DateTime.now()) recentPayment = payment;
    });
    return recentPayment;
  } else if (timing === 'Bi-Monthly') {
    schedule.forEach((payment) => {
      payment.forEach((p) => {
        if (p < DateTime.now()) recentPayment = p;
      });
    });
    return recentPayment;
  }
};

export const getIndividualPayment = (user, account, cycle, amount) => {
  if (account === 'Monthly Budget') {
    return user.money.format(amount);
  } else {
    if (cycle === 'Once') {
      return user.money.format(amount);
    } else if (cycle === 'Weekly') {
      return user.money.format(amount / 52);
    } else if (cycle === 'Bi-Weekly') {
      return user.money.format(amount / 26);
    } else if (cycle === 'Bi-Monthly') {
      return user.money.format(amount / 24);
    } else if (cycle === 'Monthly') {
      return user.money.format(amount / 12);
    } else if (cycle === 'Quarterly') {
      return user.money.format(amount / 4);
    } else if (cycle === 'Bi-Annual') {
      return user.money.format(amount / 2);
    } else if (cycle === 'Annual') {
      return user.money.format(amount);
    }
  }
};

export const getCamelCaseAccount = (account) => {
  return `${account.split(' ').join('')[0].toLowerCase()}${account.split(' ').join('').slice(1)}`;
};

export const getOverallBudget = (budget) => {};
