import React, { useEffect, useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../Global/Logo';
import { PrimaryButton } from '../Global/Button';
import { forgotPasswordSchema } from '../Global/Forms/Validate';
import { Footer } from '../Global/Footer';
import { LoginForm } from '../Global/Forms/CompleteForms/Login';
import { SignupForm } from '../Global/Forms/CompleteForms/Signup';
import { ForgotPasswordForm } from '../Global/Forms/CompleteForms/ForgotPassword';
import { TwoFactorForm } from '../Global/Forms/CompleteForms/TwoFactor';
import { getGridSize, addClasses, removeClasses } from '../Utility';

const StyledLanding = styled.section`
  &.application-landing {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0) rotate(0);

    background-color: ${(props) => props.theme.colors.grayScale.offWhite};

    @media ${(props) => props.theme.screens.largeMobile.landscape} {
      justify-content: space-between;
    }
  }
`;

const StyledLogoWrapper = styled.div`
  &.logo-wrapper {
    position: relative;
    height: max-content;
    width: 50%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0) rotate(0);

    @media ${(props) => props.theme.screens.largeTablet.landscape} {
      width: 70%;
    }

    @media ${(props) => props.theme.screens.largeTablet.portrait} {
      width: 100%;
    }

    @media ${(props) => props.theme.screens.largeMobile.landscape} {
      height: 80%;
      width: 50%;
    }

    @media ${(props) => props.theme.screens.mediumMobile.landscape} {
      height: 80%;
      width: 50%;
    }

    @media ${(props) => props.theme.screens.mediumMobile.portrait} {
      width: 100%;
    }
  }
`;

const StyledButtonContainer = styled.div`
  &.button-container {
    position: relative;
    height: max-content;
    width: 50%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    transform: translate(0, 0) rotate(0);
    margin: 2rem 0;
    z-index: 0;

    & a {
      text-decoration: none;
    }

    @media ${(props) => props.theme.screens.smallTablet.portrait} {
      width: 60%;
    }

    @media ${(props) => props.theme.screens.largeMobile.landscape} {
      margin: 1rem 0;
      width: 100%;
    }

    @media ${(props) => props.theme.screens.mediumMobile.landscape} {
      margin: 1rem 0;
      width: 100%;
    }

    @media ${(props) => props.theme.screens.mediumMobile.portrait} {
      margin: 2rem 0;
      width: 100%;
    }

    @media ${(props) => props.theme.screens.smallMobile.landscape} {
      margin: 1rem 0;
      width: 100%;
    }
  }
`;

const StyledSlogan = styled.p`
  &.application-slogan {
    position: relative;
    height: 5vh;
    width: 50%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0) rotate(0);
    z-index: 0;

    font-family: ${(props) => props.theme.text.secondary};
    font-size: 3.2rem;
    color: ${(props) => props.theme.colors.primaryColor};

    @media ${(props) => props.theme.screens.largeMobile.landscape} {
      height: 10vh;
      width: 100%;
    }

    @media ${(props) => props.theme.screens.largeMobile.portrait} {
      width: 100%;
    }

    @media ${(props) => props.theme.screens.smallMobile.landscape} {
      font-size: 2rem;
    }

    @media ${(props) => props.theme.screens.smallMobile.portrait} {
      font-size: 2.6rem;
    }
  }
`;

const initialForgotPasswordValues = {
  forgottenEmail: '',
};

const initialSignupFormValues = {
  pageOne: {
    firstname: '',
    lastname: '',
  },
  pageTwo: {
    username: '',
    latterDaySaint: false,
  },
  pageThree: {
    email: '',
    emailConfirmed: '',
  },
  pageFour: {
    password: '',
    passwordConfirmed: '',
  },
  pageNumber: 1,
};

const initialLoginValues = {
  username: '',
  password: '',
};

export default function Landing(props) {
  const [loginFormValues, setLoginFormValues] = useState(initialLoginValues);
  const [signupFormValues, setSignupFormValues] = useState(initialSignupFormValues);
  const [forgotPasswordValues, setForgotPasswordValues] = useState(initialForgotPasswordValues);
  const { theme, mobileNavigation, setMobileNavigation, isLoggedIn, latterDaySaint, setLatterDaySaint, errors, setErrors, disabledValues, setDisabledValues, initialDisabledValues } = props;

  useEffect(() => {
    forgotPasswordSchema.isValid(forgotPasswordValues).then((valid) => {
      valid === true ? setDisabledValues({ ...disabledValues, forgotPasswordDisabled: false }) : setDisabledValues({ ...disabledValues, forgotPasswordDisabled: true });
    });
  }, [forgotPasswordValues]);

  useEffect(() => {
    const applicationLanding = document.querySelector('.application-landing');
    window.addEventListener('resize', () => {
      const replacementClasses = getGridSize('outer', theme).split(' ');
      const containerClasses = [...applicationLanding.classList];
      containerClasses[4] = replacementClasses[0];
      containerClasses[5] = replacementClasses[1];
      removeClasses(applicationLanding, [...applicationLanding.classList]);
      addClasses(applicationLanding, [...containerClasses]);
    });
  }, []);

  return (
    isLoggedIn === false && (
      <StyledLanding className={`application-landing r__application-landing ${getGridSize('outer', theme)}`}>
        <Routes>
          <Route
            path="Login"
            element={
              <LoginForm
                theme={theme}
                flow={`column nowrap`}
                justify={`center`}
                align={`center`}
                loginFormValues={loginFormValues}
                setLoginFormValues={setLoginFormValues}
                errors={errors}
                setErrors={setErrors}
                disabledValues={disabledValues}
                setDisabledValues={setDisabledValues}
                initialDisabledValues={initialDisabledValues}
              />
            }
          />
          <Route
            path="Signup"
            element={
              <SignupForm
                theme={theme}
                flow={`column nowrap`}
                justify={`center`}
                align={`center`}
                initialSignupFormValues={initialSignupFormValues}
                signupFormValues={signupFormValues}
                setSignupFormValues={setSignupFormValues}
                latterDaySaint={latterDaySaint}
                setLatterDaySaint={setLatterDaySaint}
                errors={errors}
                setErrors={setErrors}
                disabledValues={disabledValues}
                setDisabledValues={setDisabledValues}
                initialDisabledValues={initialDisabledValues}
              />
            }
          />
          <Route
            path="Forgot-Password"
            element={
              <ForgotPasswordForm
                theme={theme}
                flow={`column nowrap`}
                justify={`center`}
                align={`center`}
                forgotPasswordValues={forgotPasswordValues}
                setForgotPasswordValues={setForgotPasswordValues}
                errors={errors}
                setErrors={setErrors}
                disabledValues={disabledValues}
                setDisabledValues={setDisabledValues}
                initialDisabledValues={initialDisabledValues}
              />
            }
          />
          <Route path="Two-Factor" element={<TwoFactorForm />} />
        </Routes>
        <StyledLogoWrapper className="logo-wrapper r__logo-wrapper">
          <Logo theme={theme} />
          <StyledButtonContainer className="button-container r__button-container">
            <Link to="Login">
              <PrimaryButton buttonType="landing" data-cy="login" size="medium">
                Login
              </PrimaryButton>
            </Link>
            <Link to="Signup">
              <PrimaryButton buttonType="landing" data-cy="signup" size="medium">
                Signup
              </PrimaryButton>
            </Link>
          </StyledButtonContainer>
          <StyledSlogan className="application-slogan r__application-slogan">Budget Like Royalty</StyledSlogan>
        </StyledLogoWrapper>
        <Footer theme={theme} content={{ position: 'absolute', fontSize: '2.4rem', fontDifference: '0.75em', bottom: '2rem', left: '50%', transform: 'translate(-50%, 0)' }} />
      </StyledLanding>
    )
  );
}
