import { myCalendar } from '../../../../Classes/FrontEnd-Calendar';

const getSurplusAmount = (bankAccount, totalNeededIncome) => {
  return bankAccount - totalNeededIncome;
};

const includeNewestIncome = (bankAccount, income) => {
  return (bankAccount += income);
};

export const getFullCashAndAssetValue = (bankAccount, accounts, investments) => {
  for (let key in accounts) {
    if (key !== 'savingsFund' && key !== 'expenseFund' && key !== 'surplus' && key !== 'investmentFund' && key !== 'tithing' && key !== 'debt') {
      bankAccount += accounts[key].getAmount();
    } else if (key === 'savingsFund' || key === 'expenseFund' || key === 'debt') {
      bankAccount += accounts[key].getTotal();
    } else if (key === 'investmentFund') {
      bankAccount += accounts[key].getInvestmentFundTotal();
    }
  }
  investments.forEach((investment) => {
    if (investment.settled === false) {
      bankAccount += investment.valueDifference;
    }
  });
  return bankAccount;
};

const addDebtToNeededIncome = (total, budget) => {
  return (total += budget.accounts.debt.debtAmount);
};

const getTotalAmountNeededForPlannedTransactions = (transactions, total) => {
  transactions.forEach((transaction) => {
    if (transaction.account === `Expense Fund` || transaction.account === `Savings Fund`) {
      if (transaction.surplus === false) {
        transaction.timingOptions.paymentSchedule.forEach((date) => {
          if (myCalendar.compareEndOfYear(date) === true) {
            total += transaction.payment;
          }
        });
      }
    }
  });
  return total;
};

const getRemainderOfYearNeededIncome = (currentTotal, futureTotal, months) => {
  return currentTotal + (futureTotal *= months);
};

const getFutureNeededIncome = (total, categories) => {
  categories.forEach((category) => {
    category.subCategories.forEach((subCategory) => {
      if (subCategory.surplus === false) {
        total += subCategory.goalAmount;
      }
    });
  });
  return total;
};

const getCurrentMonthRemainingNeededIncome = (total, categories) => {
  categories.forEach((category) => {
    category.subCategories.forEach((subCategory) => {
      if (subCategory.surplus === false) {
        total += subCategory.amountRemaining;
      }
    });
  });
  return total;
};

export const calculateSurplus = (budget, income) => {
  let bankAccount = 0;
  let total = 0;
  let currentNeed = 0;
  let futureNeed = 0;
  let surplus = 0;

  // GETTING NEEDED FUNDS FOR THE REMAINDER OF THE YEAR.
  currentNeed = getCurrentMonthRemainingNeededIncome(currentNeed, budget.mainCategories);
  futureNeed = getFutureNeededIncome(futureNeed, budget.mainCategories);

  let numberOfMonths = myCalendar.getRemainingMonths();

  total = getRemainderOfYearNeededIncome(currentNeed, futureNeed, numberOfMonths);

  total = getTotalAmountNeededForPlannedTransactions(budget.transactions.plannedTransactions, total);

  total = addDebtToNeededIncome(total, budget);

  // GETTING THE TOTAL YOU HAVE RIGHT NOW.
  bankAccount = getFullCashAndAssetValue(bankAccount, budget.accounts, budget.investments);

  if (income) {
    bankAccount = includeNewestIncome(bankAccount, Number(income));
  }

  surplus = getSurplusAmount(bankAccount, total);
  return surplus;
};

export const calculateTithing = (setting, income, budget) => {
  // * IF TITHING SETTING IS NOT SURPLUS
  if (setting === `Gross` || setting === 'Net') {
    return income * 0.1;
  }

  // * IF TITHING SETTING IS SURPLUS
  if (setting === `Surplus`) {
    let surplus = calculateSurplus(budget, income);
    return surplus >= 0.1 ? Math.round(surplus * 0.1 * 100) / 100 : 0;
  }
};

export const setTithed = (receiptItem, user, budget) => {
  if (receiptItem.tithed) {
    return receiptItem.tithed;
  } else if (user.latterDaySaint && !receiptItem.tithed && budget.accounts.tithing.tithingSetting !== 'Surplus') {
    return false;
  }
};
