import React from 'react';
import styled from 'styled-components';
import chroma from 'chroma-js';

export const Button = styled.button`
  position: relative;
  display: flex;
  min-height: ${(props) => props.minHeight};
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  font-family: ${(props) => props.theme.text.secondary};
  border: 0.2rem solid transparent;
  background-color: transparent;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  color: ${(props) => `${props.color}cc`};

  & i {
    font-size: ${(props) => props.iconFontSize};
    margin: ${(props) => props.iconMargin};
  }

  &:hover {
    cursor: pointer;
    color: ${(props) => props.hoverColor};
    transition: background-color 0.5s, color 0.5s, border 0.5s;
    & p {
      color: ${(props) => props.hoverColor};
      transition: color 0.5s;
    }
  }

  &:active {
    color: ${(props) => props.activeColor};
  }

  &:disabled {
    background-color: ${(props) => `${props.theme.colors.grayScale.outerSpace}80`};
    border-color: ${(props) => `${props.theme.colors.grayScale.darkCharcoal}80`};
    color: ${(props) => `${props.theme.colors.grayScale.chineseSilver}cc`};

    & p {
      color: ${(props) => `${props.theme.colors.grayScale.chineseSilver}cc`};
    }

    &:hover {
      background-color: ${(props) => `${props.theme.colors.grayScale.outerSpace}cc`};
      border-color: ${(props) => `${props.theme.colors.grayScale.darkCharcoal}cc`};
      color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};
    }
  }
  ${(props) => (props.size === 'large long' ? 'height: 6rem' : null)};
  ${(props) => (props.size === 'large long' ? 'width: max-content' : null)};
  ${(props) => (props.size === 'large long' ? 'min-width: 30rem' : null)};

  ${(props) => (props.size === 'large' ? 'height: 6rem' : null)};
  ${(props) => (props.size === 'large' ? 'width: 10vw' : null)};
  ${(props) => (props.size === 'large' ? 'min-width: 30rem' : null)};

  ${(props) => (props.size === 'medium' ? 'height: 5rem' : null)};
  ${(props) => (props.size === 'medium' ? 'width: 10vw' : null)};
  ${(props) => (props.size === 'medium' ? 'min-width: 20rem' : null)};

  ${(props) => (props.size === 'medium square' ? 'height: 25%' : null)};
  ${(props) => (props.size === 'medium square' ? 'width: 25%' : null)};
  ${(props) => (props.size === 'medium square' ? 'flex-flow: column nowrap' : null)};
  ${(props) => (props.size === 'medium square' ? 'justify-content: space-evenly' : null)};

  ${(props) => (props.size === 'small' ? 'height: max-content' : null)};
  ${(props) => (props.size === 'small' ? 'min-height: 4rem' : null)};
  ${(props) => (props.size === 'small' ? 'width: 25%' : null)};
  ${(props) => (props.size === 'small' ? 'padding: .5rem' : null)};

  ${(props) => (props.size === 'extra-small' ? 'height: max-content' : null)};
  ${(props) => (props.size === 'extra-small' ? 'width: max-content' : null)};
`;

export const PrimaryButton = styled(Button)`
  color: ${(props) => props.theme.colors.primaryColor + 'cc'};
  border-color: ${(props) => props.theme.colors.primaryColor + 'cc'};
  border-radius: 1.5rem;
  font-size: 1.6rem;
  transform: ${(props) => props.transform};

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryColor};
    border-color: ${(props) => props.theme.colors.primaryColor};
    color: ${(props) => props.theme.colors.grayScale.offWhite};

    ${(props) => (props.modifier === 'white' ? `background-color: ${props.theme.colors.grayScale.offWhite}` : null)};
    ${(props) => (props.modifier === 'white' ? `border-color: ${props.theme.colors.grayScale.offWhite}` : null)};
    ${(props) => (props.modifier === 'white' ? `color: ${props.theme.colors.primaryColor}` : null)};

    ${(props) => (props.modifier === 'timing' ? `background-color: ${props.theme.colors.secondaryColor}` : null)};
    ${(props) => (props.modifier === 'timing' ? `border-color: ${props.theme.colors.secondaryColor}` : null)};
    ${(props) => (props.modifier === 'timing' ? `color: ${props.theme.colors.grayScale.white}` : null)};
  }

  &:active {
    ${(props) => (props.modifier === 'timing' ? `background-color: ${props.theme.colors.tertiaryColor}` : null)};
    ${(props) => (props.modifier === 'timing' ? `border-color: ${props.theme.colors.tertiaryColor}` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    ${(props) => (props.buttonType === 'landing' ? `height: 4rem` : null)};
    ${(props) => (props.buttonType === 'landing' ? `min-width: 15rem` : null)};
    ${(props) => (props.buttonType === 'personal-info' ? `height: 5rem` : null)};
    ${(props) => (props.buttonType === 'personal-info' ? `min-height: 5rem` : null)};
    ${(props) => (props.buttonType === 'personal-info' ? `margin: 0 0 2rem` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    ${(props) => (props.buttonType === 'landing' ? `height: 4rem` : null)};
    ${(props) => (props.buttonType === 'landing' ? `min-width: 10rem` : null)};
    ${(props) => (props.buttonType === 'profile-photo' ? `width: 50%` : null)};
    ${(props) => (props.buttonType === 'profile-photo' ? `min-width: 50%` : null)};
    ${(props) => (props.buttonType === 'profile-photo' ? `font-size: 1.4rem` : null)};
    ${(props) => (props.buttonType === 'profile-photo' ? `margin: 0 0 0 2rem` : null)};
    ${(props) => (props.buttonType === 'personal-info' ? `height: 5rem` : null)};
    ${(props) => (props.buttonType === 'personal-info' ? `min-height: 5rem` : null)};
    ${(props) => (props.buttonType === 'personal-info' ? `margin: 0 0 2rem` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    ${(props) => (props.buttonType === 'personal-info' ? `height: 5rem` : null)};
    ${(props) => (props.buttonType === 'personal-info' ? `min-height: 5rem` : null)};
    ${(props) => (props.buttonType === 'personal-info' ? `margin: 0 0 2rem` : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.portrait} {
    ${(props) => (props.buttonType === 'personal-info' ? `height: 5rem` : null)};
    ${(props) => (props.buttonType === 'personal-info' ? `min-height: 5rem` : null)};
    ${(props) => (props.buttonType === 'personal-info' ? `margin: 0 0 2rem` : null)};
  }

  ${(props) => (props.modifier === 'white' ? `background-color: transparent` : null)};
  ${(props) => (props.modifier === 'white' ? `border-color: ${props.theme.colors.grayScale.offWhite}` : null)};
  ${(props) => (props.modifier === 'white' ? `color: ${props.theme.colors.grayScale.offWhite}` : null)};

  ${(props) => (props.modifier === 'timing' ? 'justify-content: space-evenly' : null)};

  ${(props) => (props.modifier === 'square' ? 'border-radius: .9rem 0 0 0' : null)};
  ${(props) => (props.modifier === 'transaction' ? 'width: 75%' : null)};

  ${(props) => (props.modifier === 'medium square' ? `position: ${props.position}` : null)};
  ${(props) => (props.modifier === 'medium square' ? `top: ${props.top}` : null)};
  ${(props) => (props.modifier === 'medium square' ? `right: ${props.right}` : null)};
  ${(props) => (props.modifier === 'medium square' ? `bottom: ${props.bottom}` : null)};
  ${(props) => (props.modifier === 'medium square' ? `left: ${props.left}` : null)};
  ${(props) => (props.modifier === 'medium square' ? `border-radius: ${props.borderRadius}` : null)};
  ${(props) => (props.modifier === 'medium square' ? `& i {font-size: ${props.textSize}}` : null)};
  ${(props) => (props.modifier === 'medium square' ? `& p.delete-text {font-size: ${props.textSize}}` : null)};
  ${(props) =>
    props.modifier === 'medium square'
      ? `&:hover {
    p.delete-text {color: ${props.theme.colors.grayScale.offWhite}}

  }`
      : null};
  ${(props) => (props.modifier === 'medium square' ? `border-radius: ${props.borderRadius}` : null)};
  ${(props) => (props.modifier === 'investment' ? `border-radius: ${props.borderRadius}` : null)};

  & .main-category-create-icon,
  .main-category-create-text {
    font-size: 2rem;
  }
`;

export const SmallBorderlessButton = styled(Button)`
  color: ${(props) => props.theme.colors.primaryColor + 'cc'};
  font-size: 1.6rem;
  transform: ${(props) => props.transform};

  & p.view-graph-text {
    color: ${(props) => props.investmentColor};
    font-size: 1.4rem;
  }

  &:disabled {
    background-color: transparent;
    border-color: transparent;
    color: ${(props) => `${props.theme.colors.grayScale.outerSpace}cc`};
    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: ${(props) => `${props.theme.colors.grayScale.chineseSilver}cc`};
    }
    ${(props) => (props.modifier === 'timing' ? `color: ${props.textColor}` : null)};
  }

  &:hover {
    color: ${(props) => props.theme.colors.primaryColor};
    ${(props) => (props.modifier === 'white' ? `color: ${props.theme.colors.grayScale.offWhite}` : null)};
    ${(props) => (props.modifier === 'white small-text' ? `color: ${props.theme.colors.grayScale.offWhite}` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    ${(props) => (props.buttonType === 'update-photo' ? `font-size: 1.4rem` : null)};
    ${(props) => (props.buttonType === 'personal-info--sub' ? `font-size: 1.2rem` : null)};
    ${(props) => (props.buttonType === 'personal-info--sub' ? `transform: translate(3rem, -4rem)` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    ${(props) => (props.buttonType === 'personal-info--sub' ? `transform: translate(3rem, -3rem)` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.landscape} {
    ${(props) => (props.buttonType === 'update-photo' ? `font-size: 1.2rem` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    ${(props) => (props.buttonType === 'personal-info--sub' ? `transform: translate(3rem, -3rem)` : null)};
  }

  ${(props) => (props.modifier === 'white' ? `color: ${props.theme.colors.grayScale.offWhite}cc` : null)};

  ${(props) => (props.modifier === 'white small-text' ? `color: ${props.theme.colors.grayScale.offWhite}cc` : null)};
  ${(props) => (props.modifier === 'white small-text' ? `& > p { font-size: 1.2rem }` : null)};

  ${(props) => (props.modifier === 'timing' ? `font-size: 1.2rem` : null)};
  ${(props) => (props.modifier === 'timing' ? `color: ${props.textColor}` : null)};

  & i {
    margin-right: 1rem;
  }
  ${(props) => (props.modifier === 'timing' ? `color: ${props.textColor}` : null)};
`;

export const ChangeBudgetCoverPhotoButton = styled(Button)`
  position: absolute;
  height: 4rem;
  width: 4rem;
  transform: translate(0, 0) rotate(0);
  top: 0rem;
  left: 0rem;
  border: 0.25rem solid ${(props) => `${props.theme.colors.primaryColor}cc`};
  background-color: ${(props) => `${props.theme.colors.grayScale.raisinBlack}40`};
  border-radius: 5rem;
`;

export const NavigationButton = styled(Button)`
  &.navigation-button {
    transform: translate(0, 0) rotate(0);
    justify-content: space-around;

    & p {
      font-size: 2rem;
      font-family: ${(props) => props.theme.text.secondary};
      color: ${(props) => `${props.theme.colors.primaryColor}cc`};
    }

    &:hover {
      cursor: pointer;
      & p {
        color: ${(props) => `${props.theme.colors.primaryColor}`};
        transition: color 0.5s;
      }
      & .navigation-button__icon {
        width: 25%;
        border: none;
        background-color: ${(props) => `${props.theme.colors.primaryColor}`};
        &:before {
          width: 100%;
          background-color: ${(props) => props.theme.colors.primaryColor};
        }
        &:after {
          width: 100%;
          background-color: ${(props) => props.theme.colors.primaryColor};
        }
      }
    }

    &.clicked {
      & .navigation-button__icon {
        background-color: transparent;
        &:before {
          transform: translate(0.5rem, 0) rotate(45deg);
          background-color: ${(props) => props.theme.colors.grayScale.offWhite};
          width: 90%;
        }
        &:after {
          transform: translate(0.5rem, 0) rotate(-45deg);
          background-color: ${(props) => props.theme.colors.grayScale.offWhite};
          width: 90%;
        }
        &:hover {
          background-color: transparent;
          &:before,
          &:after {
            width: 100%;
            background-color: ${(props) => props.theme.colors.grayScale.offWhite};
          }
        }
      }
    }

    & .navigation-button__icon {
      height: 0.75rem;
      width: 30%;
      background-color: ${(props) => `${props.theme.colors.primaryColor}cc`};
      transform: translate(0, 0);
      border-radius: 0.4rem;
      border: none;
      transition: background-color 0.5s, width 0.5s;
      &:before,
      &:after {
        content: '';
        z-index: 10;
        background-color: ${(props) => `${props.theme.colors.primaryColor}cc`};
        border-radius: 0.4rem;
        border: none;
        left: 0;
      }

      &:before {
        position: absolute;
        height: 0.75rem;
        width: 50%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        transform: translate(0, -1.5rem) rotate(0);
        transition: background-color 0.5s, width 0.5s, transform 0.5s;
      }
      &:after {
        position: absolute;
        height: 0.75rem;
        width: 75%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        transform: translate(0, 1.5rem) rotate(0);
        transition: background-color 0.5s, width 0.5s, transform 0.5s;
      }
    }
  }
`;

export const LDSHelpButton = styled(Button)`
  position: absolute;
  transform: ${(props) => props.transform};
  background-color: ${(props) => `${props.theme.colors.primaryColor}f2`};
  border-color: ${(props) => `${chroma(props.theme.colors.secondaryColor).darken(0.2).hex()}f2`};
  border-radius: ${(props) => props.borderRadius};
  color: ${(props) => props.theme.colors.grayScale.offWhite};

  &:hover {
    background-color: ${(props) => `${props.theme.colors.secondaryColor}`};
    transition: background-color 0.5s, color 0.5s, border 0.5s;
  }

  @media ${(props) => props.theme.screens.smallMobile.landscape} {
    transform: translate(13rem, -2rem);
  }
`;
