import { produce, immerable } from 'immer';
import { DateTime } from 'luxon';

export class Investment {
  [immerable] = true;
  constructor(options) {
    this.investmentType = options.investmentType;
    this.investmentName = options.investmentName;
    this.investmentDescription = options.investmentDescription;
    this.initialInvestment = options.initialInvestment;
    this.currentValue = options.currentValue;
    this.settled = options.settled;
    this.valueDifference = this.currentValue - this.initialInvestment;
    this.values = options.values; // Goes into the database as an array of numbers.
    this.dates = options.dates; // Goes into the database as an array of ISO dates.
  }

  updateCurrentValue(value) {
    this.currentValue = value;
  }

  updateSettled() {
    this.settled = !this.settled;
  }
}
