import React, { useEffect, useState } from 'react';
import { Routes, Route, Link, useMatches, useParams } from 'react-router-dom';
import styled from 'styled-components';
import chroma from 'chroma-js';
import { PrimaryButton, SmallBorderlessButton } from './Button';
import { Footer } from './Footer';
import { BudgetNavigationLinkSection } from './Forms/Section';
import { ProfileFormCloser } from './Forms/FormCloser';

const SideNavSection = styled.section`
  position: relative;
  height: 30%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  transform: translate(0, 0) rotate(0);
  font-family: ${(props) => props.theme.text.secondary};

  @media ${(props) => props.theme.screens.largeTablet.portrait} {
    height: 90%;
    width: 40%;
  }

  @media ${(props) => props.theme.screens.smallTablet.landscape} {
    height: 40%;
    width: 90%;
    justify-content: center;
  }

  @media ${(props) => props.theme.screens.smallTablet.portrait} {
    height: 90%;
    width: 40%;
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    height: 45%;
    width: 90%;
    justify-content: center;
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    height: 90%;
    width: 50%;

    ${(props) => (props.mobile === true ? `position: absolute` : null)};
    ${(props) => (props.mobile === true ? `height: max-content` : null)};
    ${(props) => (props.mobile === true ? `width: 100%` : null)};
    ${(props) => (props.mobile === true ? `padding: 1rem` : null)};
    ${(props) => (props.mobile === true ? `background-color: ${chroma(props.theme.colors.primaryColor).darken(0.4).hex()}` : null)};
    ${(props) => (props.mobile === true ? `top: 15.8rem` : null)};
    ${(props) => (props.mobile === true ? `z-index: 10` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    /* height: 90%;
    width: 50%; */

    /* ${(props) => (props.mobile === true ? `position: absolute` : null)};
    ${(props) => (props.mobile === true ? `height: max-content` : null)};
    ${(props) => (props.mobile === true ? `width: 100%` : null)};
    ${(props) => (props.mobile === true ? `padding: 1rem` : null)};
    ${(props) => (props.mobile === true ? `background-color: ${chroma(props.theme.colors.primaryColor).darken(0.4).hex()}` : null)}; */
    ${(props) => (props.mobile === true ? `top: 13.6rem` : null)};
    /* ${(props) => (props.mobile === true ? `z-index: 10` : null)}; */
  }

  @media ${(props) => props.theme.screens.smallMobile.portrait} {
    /* height: 90%;
    width: 50%; */
    /* 
    ${(props) => (props.mobile === true ? `position: absolute` : null)};
    ${(props) => (props.mobile === true ? `height: max-content` : null)};
    ${(props) => (props.mobile === true ? `width: 100%` : null)};
    ${(props) => (props.mobile === true ? `padding: 1rem` : null)};
    ${(props) => (props.mobile === true ? `background-color: ${chroma(props.theme.colors.primaryColor).darken(0.4).hex()}` : null)}; */
    ${(props) => (props.mobile === true ? `top: 13.3rem` : null)};
    /* ${(props) => (props.mobile === true ? `z-index: 10` : null)}; */
  }
`;

const UserInformation = styled(SideNavSection)`
  height: 35%;
  & .side-navigation {
    &__user-info {
      &__link {
        text-decoration: none;
      }
      &__profile-picture-container {
        position: relative;
        height: 74%;
        width: 60%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-bottom: 1rem;
        transform: translate(0, 0) rotate(0);
        overflow: hidden;

        &__picture {
          position: relative;
          height: 100%;
          width: 100%;
          border: 0.5rem solid ${(props) => props.theme.colors.grayScale.offWhite};
          border-radius: 50%;
        }
      }

      &__username {
        font-size: 3.6rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }
  }

  @media ${(props) => props.theme.screens.largeTablet.landscape} {
    height: 30%;
  }

  @media ${(props) => props.theme.screens.largeTablet.portrait} {
    height: 90%;
    width: 27.5%;

    & .side-navigation {
      &__user-info {
        &__profile-picture-container {
          &__picture {
            border-width: 0.3rem;
          }
        }
      }
    }
  }

  @media ${(props) => props.theme.screens.smallTablet.landscape} {
    height: 30%;
    width: 90%;
    & .side-navigation {
      &__user-info {
        &__link {
        }
        &__profile-picture-container {
          height: 60%;
          width: 55%;
          &__picture {
            border-width: 0.3rem;
          }
        }

        &__username {
          font-size: 3rem;
          color: ${(props) => props.theme.colors.grayScale.offWhite};
        }
      }
    }
  }

  @media ${(props) => props.theme.screens.smallTablet.portrait} {
    height: 90%;
    & .side-navigation {
      &__user-info {
        &__link {
        }
        &__profile-picture-container {
          height: 60%;
          width: 55%;
          &__picture {
            border-width: 0.3rem;
          }
        }

        &__username {
          font-size: 3rem;
          color: ${(props) => props.theme.colors.grayScale.offWhite};
        }
      }
    }
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    height: 30%;
    width: 90%;
    & .side-navigation {
      &__user-info {
        &__link {
        }
        &__profile-picture-container {
          height: 55%;
          width: 45%;
          &__picture {
            border-width: 0.3rem;
          }
        }

        &__username {
          font-size: 2rem;
          color: ${(props) => props.theme.colors.grayScale.offWhite};
        }
      }
    }
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    height: 90%;
    width: 50%;
    & .side-navigation {
      &__user-info {
        &__link {
        }
        &__profile-picture-container {
          height: 60%;
          width: 45%;
          &__picture {
            border-width: 0.2rem;
          }
        }

        &__username {
          font-size: 1.6rem;
          color: ${(props) => props.theme.colors.grayScale.offWhite};
        }
      }
    }
  }

  @media ${(props) => props.theme.screens.mediumMobile.landscape} {
    height: 30%;
    width: 90%;
    & .side-navigation {
      &__user-info {
        &__link {
        }
        &__profile-picture-container {
          height: 55%;
          width: 40%;
          margin-bottom: 0.5rem;
          &__picture {
            border-width: 0.2rem;
          }
        }

        &__username {
          font-size: 1.6rem;
          color: ${(props) => props.theme.colors.grayScale.offWhite};
        }
      }
    }
  }

  @media ${(props) => props.theme.screens.smallMobile.landscape} {
    height: 30%;
    width: 90%;
    & .side-navigation {
      &__user-info {
        &__link {
        }
        &__profile-picture-container {
          height: 45%;
          width: 35%;
          margin-bottom: 0.5rem;
          &__picture {
            border-width: 0.2rem;
          }
        }

        &__username {
          font-size: 1.6rem;
          color: ${(props) => props.theme.colors.grayScale.offWhite};
        }
      }
    }
  }
`;

export const AccountLinks = styled(SideNavSection)`
  & .side-navigation {
    &__account-links {
      &__link-container {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        transform: translate(0, 0) rotate(0);

        @media ${(props) => props.theme.screens.largeTablet.portrait} {
          flex-flow: row wrap;

          & .side-navigation__account-links__link-container__link {
            height: 40%;
            width: 45%;

            &:not(:last-of-type) {
              margin-bottom: 0rem;
            }
            margin: 0 1rem;
            text-align: center;
          }
        }

        @media ${(props) => props.theme.screens.smallTablet.landscape} {
          flex-flow: column nowrap;

          & .side-navigation__account-links__link-container__link {
            height: 20%;
            width: 100%;

            margin: 0;

            &:not(:last-of-type) {
              margin-bottom: 1rem;
            }

            text-align: center;
          }
        }

        @media ${(props) => props.theme.screens.smallTablet.portrait} {
          flex-flow: row wrap;

          & .side-navigation__account-links__link-container__link {
            height: 45%;
            width: 45%;

            &:not(:last-of-type) {
              margin-bottom: 0rem;
            }
            margin: 0 0.5rem;
            text-align: center;

            & .side-navigation__account-links__link-container__link--link {
              font-size: 1.6rem;
            }
          }
        }

        @media ${(props) => props.theme.screens.largeMobile.landscape} {
          flex-flow: column nowrap;

          & .side-navigation__account-links__link-container__link {
            height: 20%;
            width: 100%;

            margin: 0;

            &:not(:last-of-type) {
              margin-bottom: 1rem;
            }

            text-align: center;

            & .side-navigation__account-links__link-container__link--link {
              font-size: 1.2rem;
            }
          }
        }

        @media ${(props) => props.theme.screens.largeMobile.portrait} {
          flex-flow: row wrap;

          & .side-navigation__account-links__link-container__link {
            height: 45%;
            width: 45%;

            &:not(:last-of-type) {
              margin-bottom: 0rem;
            }
            margin: 0 0.5rem;
            text-align: center;

            & .side-navigation__account-links__link-container__link--link {
              font-size: 1.6rem;

              ${(props) => (props.mobile === true ? `border-radius: 1rem` : null)};
              ${(props) => (props.mobile === true ? `border-width: .1rem` : null)};
              ${(props) => (props.mobile === true ? `height: 100%` : null)};
              ${(props) => (props.mobile === true ? `min-height: 4rem` : null)};
              ${(props) => (props.mobile === true ? `width: 50%` : null)};
              ${(props) => (props.mobile === true ? `font-size: 1.2rem` : null)};
            }

            ${(props) => (props.mobile === true ? `height: 100%` : null)};
            ${(props) => (props.mobile === true ? `width: 100%` : null)};
            ${(props) => (props.mobile === true ? `min-height: 4rem` : null)};
            ${(props) => (props.mobile === true ? `margin: 1.5rem 0` : null)};
          }

          ${(props) => (props.mobile === true ? `flex-flow: column nowrap` : null)};
        }

        @media ${(props) => props.theme.screens.mediumMobile.landscape} {
          /* flex-flow: row wrap; */

          & .side-navigation__account-links__link-container__link {
            /* height: 45%;
            width: 45%; */

            /* &:not(:last-of-type) {
              margin-bottom: 0rem;
            }
            margin: 0 0.5rem;
            text-align: center; */

            & .side-navigation__account-links__link-container__link--link {
              /* font-size: 1.6rem; */
              border-width: 0.1rem;
              /* ${(props) => (props.mobile === true ? `border-radius: 1rem` : null)};
              ${(props) => (props.mobile === true ? `border-width: .1rem` : null)};
              ${(props) => (props.mobile === true ? `height: 100%` : null)};
              ${(props) => (props.mobile === true ? `min-height: 4rem` : null)};
              ${(props) => (props.mobile === true ? `width: 50%` : null)};
              ${(props) => (props.mobile === true ? `font-size: 1.2rem` : null)}; */
            }

            /* ${(props) => (props.mobile === true ? `height: 100%` : null)};
            ${(props) => (props.mobile === true ? `width: 100%` : null)};
            ${(props) => (props.mobile === true ? `min-height: 4rem` : null)};
            ${(props) => (props.mobile === true ? `margin: 1.5rem 0` : null)}; */
          }

          /* ${(props) => (props.mobile === true ? `flex-flow: column nowrap` : null)}; */
        }

        &__link {
          position: relative;
          height: 20%;
          width: 80%;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-evenly;
          align-items: center;
          transform: translate(0, 0) rotate(0);
          &:not(:last-of-type) {
            margin-bottom: 1.5rem;
          }
          &--link {
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-evenly;
            align-items: center;
            transform: translate(0, 0) rotate(0);
            border: 0.3rem solid ${(props) => props.theme.colors.grayScale.offWhite};
            border-radius: 1.25rem;
            background-color: transparent;
            font-size: 2rem;
            & button {
              height: 100%;
              width: 100%;
              border-radius: 1.25rem;
            }
            &:link,
            &:visited {
              text-decoration: none;
              color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};
              & button {
                color: ${(props) => `${props.theme.colors.grayScale.offWhite}cc`};
              }
            }
            &:hover {
              cursor: pointer;
              background-color: ${(props) => props.theme.colors.secondaryColor};
              color: ${(props) => props.theme.colors.grayScale.offWhite};
              transition: color 0.5s, background-color 0.5s;
              & button {
                color: ${(props) => props.theme.colors.grayScale.offWhite};
                transition: color 0.5s, background-color 0.5s;
              }
            }
            &:active {
              background-color: ${(props) => props.theme.colors.tertiaryColor};
              color: ${(props) => props.theme.colors.grayScale.darkCharcoal};
              & button {
                color: ${(props) => props.theme.colors.grayScale.darkCharcoal};
              }
            }
            @media ${(props) => props.theme.screens.smallTablet.landscape} {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
`;

const ApplicationLogo = styled(SideNavSection)`
  height: 15%;
  & .side-navigation {
    &__logo {
      position: relative;
      height: 80%;
      width: auto;
      margin-bottom: 1rem;
      transform: translateX(-15%);
    }
    &__logo-text {
      font-family: ${(props) => props.theme.text.primary};
      font-size: 3rem;
      color: ${(props) => props.theme.colors.grayScale.offWhite};
    }
  }

  @media ${(props) => props.theme.screens.largeTablet.portrait} {
    height: 90%;
    width: 30%;

    & .side-navigation {
      &__logo {
        width: 40%;
        height: auto;
        margin-bottom: 0;
      }
      &__logo-text {
        font-family: ${(props) => props.theme.text.primary};
        font-size: 3rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }
  }

  @media ${(props) => props.theme.screens.smallTablet.landscape} {
    height: 30%;
    width: 90%;

    & .side-navigation {
      &__logo {
        height: 50%;
        width: auto;
        margin-bottom: 1rem;
      }
      &__logo-text {
        font-family: ${(props) => props.theme.text.primary};
        font-size: 3rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }
  }

  @media ${(props) => props.theme.screens.smallTablet.portrait} {
    height: 90%;
    width: 20%;

    & .side-navigation {
      &__logo {
        width: 40%;
        height: auto;
        margin-bottom: 0;
      }
      &__logo-text {
        font-family: ${(props) => props.theme.text.primary};
        font-size: 2.4rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    height: 15%;
    width: 90%;

    & .side-navigation {
      &__logo {
        height: 60%;
        width: auto;
        margin-bottom: 1rem;
      }
      &__logo-text {
        font-family: ${(props) => props.theme.text.primary};
        font-size: 1.6rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    height: 90%;
    width: 50%;

    & .side-navigation {
      &__logo {
        width: 30%;
        height: auto;
        margin-bottom: 0;
      }
      &__logo-text {
        font-family: ${(props) => props.theme.text.primary};
        font-size: 2.4rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }
  }

  @media ${(props) => props.theme.screens.mediumMobile.landscape} {
    height: 20%;
    width: 90%;

    & .side-navigation {
      &__logo {
        width: 20%;
        height: auto;
        margin-bottom: 1rem;
      }
      &__logo-text {
        font-family: ${(props) => props.theme.text.primary};
        font-size: 1.6rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    height: 90%;
    width: 50%;

    & .side-navigation {
      &__logo {
        width: 30%;
        height: auto;
        margin-bottom: 1rem;
      }
      &__logo-text {
        font-family: ${(props) => props.theme.text.primary};
        font-size: 2rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }
  }

  @media ${(props) => props.theme.screens.smallMobile.landscape} {
    height: 20%;
    width: 90%;

    & .side-navigation {
      &__logo {
        width: 20%;
        height: auto;
        margin-bottom: 1rem;
      }
      &__logo-text {
        font-family: ${(props) => props.theme.text.primary};
        font-size: 1.6rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }
  }

  @media ${(props) => props.theme.screens.smallMobile.portrait} {
    height: 90%;
    width: 50%;

    & .side-navigation {
      &__logo {
        width: 30%;
        height: auto;
        margin-bottom: 1rem;
      }
      &__logo-text {
        font-family: ${(props) => props.theme.text.primary};
        font-size: 2rem;
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }
  }
`;

const StyledNavigation = styled.nav`
  position: relative;
  height: 100%;
  width: ${(props) => props.width};
  display: flex;
  flex-flow: column nowrap;
  justify-content: ${(props) => props.justify};
  align-items: center;
  transform: translate(0, 0) rotate(0);
  background-color: ${(props) => props.bgColor};
  opacity: ${(props) => props.opacity};

  @media ${(props) => props.theme.screens.largeTablet.landscape} {
    width: 25%;
  }

  @media ${(props) => props.theme.screens.largeTablet.portrait} {
    height: 25%;
    width: 100%;
    flex-flow: row nowrap;
    ${(props) => (props.borderRight ? `border-right: none` : null)};
    ${(props) => (props.borderRight ? `border-bottom: ${props.borderRight}` : null)};
  }

  @media ${(props) => props.theme.screens.smallTablet.landscape} {
    height: 100%;
    width: 27.5%;
    ${(props) => (props.borderRight ? `border-right: ${props.borderRight}` : null)};
    ${(props) => (props.borderRight ? `border-bottom: none` : null)};
    flex-flow: column nowrap;
  }

  @media ${(props) => props.theme.screens.smallTablet.portrait} {
    height: 25%;
    width: 100%;
    flex-flow: row nowrap;
    ${(props) => (props.borderRight ? `border-right: none` : null)};
    ${(props) => (props.borderRight ? `border-bottom: ${props.borderRight}` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.landscape} {
    height: 100%;
    width: 25%;
    ${(props) => (props.borderRight ? `border-right: ${props.borderRight}` : null)};
    ${(props) => (props.borderRight ? `border-bottom: none` : null)};
    flex-flow: column nowrap;
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    height: 20%;
    width: 100%;
    flex-flow: row nowrap;
    ${(props) => (props.borderRight ? `border-right: none` : null)};
    ${(props) => (props.borderRight ? `border-bottom: ${props.borderRight}` : null)};
  }

  ${(props) => (props.borderRight ? `border-right: ${props.borderRight}` : null)};
  ${(props) => (props.budgetNavigation === true ? `position: absolute` : null)};
`;

export const Navigation = function (props) {
  const { theme, mobileNavigation, setMobileNavigation, user } = props;

  useEffect(() => {}, [user.photo]);

  return (
    <StyledNavigation
      width="20%"
      justify="space-evenly"
      bgColor={chroma(props.theme.colors.primaryColor).darken(0.25).hex()}
      opacity={1}
      borderRight={`.4rem groove ${props.theme.colors.tertiaryColor}`}
      className="side-navigation r__side-navigation">
      <UserInformation className="side-navigation__user-info">
        <section className="side-navigation__user-info__profile-picture-container r__side-navigation__user-info__profile-picture-container">
          {user && user.photo && (
            <img
              className="side-navigation__user-info__profile-picture-container__picture r__side-navigation__user-info__profile-picture-container__picture"
              src={`/DIST/CSS/Images/Users/${user.photo}`}
              alt="User Profile Picture"></img>
          )}
        </section>
        <Link to="Update-Photo" className="side-navigation__user-info__link">
          <SmallBorderlessButton size="extra-small" modifier="white small-text" buttonType="update-photo">
            <i className="fas fa-camera" />
            <p>Update Photo</p>
          </SmallBorderlessButton>
        </Link>
        {user && <p className="side-navigation__user-info__username">{`${user.firstname} ${user.lastname}`}</p>}
      </UserInformation>
      {!mobileNavigation && (
        <AccountLinks className="side-navigation__account-links" mobile={mobileNavigation}>
          <ul className="side-navigation__account-links__link-container">
            {['Personal Information', 'Communications', 'Password Management', 'Account Management'].map((buttonName, index) => {
              return (
                <li className="side-navigation__account-links__link-container__link" key={index}>
                  <Link className="side-navigation__account-links__link-container__link--link" to={`${buttonName.split(' ').join('-')}`}>
                    {buttonName}
                  </Link>
                </li>
              );
            })}
          </ul>
        </AccountLinks>
      )}

      <ApplicationLogo className="side-navigation__application-logo">
        <img className="side-navigation__logo" src={theme.logo.imageWhite} alt="King Richard Budget Logo" />
        <p className="side-navigation__logo-text">King Richard</p>
      </ApplicationLogo>
      <Footer theme={theme} position="relative" content={{ fontSize: '2rem', fontDifference: '0.75em', bottom: '2rem', left: '50%', transform: 'translate(0, 0)', modifier: 'white' }} />
    </StyledNavigation>
  );
};

const BudgetNavigationLinkSectionContainer = styled.div`
  position: relative;
  height: 80%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

export const BudgetNavigation = function (props) {
  const { theme, user, budget, previousLocation, setPreviousLocation, errors, setErrors } = props;
  const matches = useMatches();
  const budgetURL = matches[0].pathname.split('/').slice(0, 6).join('/');
  const navigationURL = matches[0].pathname.split('/').slice(0, 7).join('/');
  const location = useParams()['*'];
  return (
    <StyledNavigation budgetNavigation={true} width="100%" justify="center" bgColor={`${chroma(`${theme.colors.primaryColor}cc`).darken(0.4).hex()}`}>
      {/* THIS WILL NEED TO GO STRAIGHT BACK TO THE ORIGINAL PATH THAT THE NAVIGATION WAS CLICKED AT */}
      <Link to={`../${previousLocation}`}>
        <ProfileFormCloser>
          <i className="fas fa-window-close" data-cy="formCloser" />
        </ProfileFormCloser>
      </Link>
      <BudgetNavigationLinkSectionContainer>
        <BudgetNavigationLinkSection flow="column nowrap" justify="space-around" align="center" margin="1rem 0">
          <Link to={`${budgetURL}/Dashboard`}>
            <PrimaryButton
              size="large"
              modifier="white"
              onClick={() => {
                setErrors([]);
                setPreviousLocation('Dashboard');
              }}>
              Budget Dashboard
            </PrimaryButton>
          </Link>
        </BudgetNavigationLinkSection>
        <BudgetNavigationLinkSection flow="column nowrap" justify="space-around" align="center" margin="1rem 0">
          <Link to={`${budgetURL}/Budget-Management`}>
            <PrimaryButton
              size="large"
              modifier="white"
              onClick={() => {
                setErrors([]);
                setPreviousLocation('Budget-Management');
              }}>
              Budget Management
            </PrimaryButton>
          </Link>
        </BudgetNavigationLinkSection>
        <BudgetNavigationLinkSection flow="column nowrap" justify="space-around" align="center" margin="1rem 0">
          <Link to="Edit-Budget">
            <PrimaryButton size="large" modifier="white">
              Edit Budget
            </PrimaryButton>
          </Link>

          <Routes>
            <Route
              path="Edit-Budget"
              element={
                <BudgetNavigationLinkSection flow="column nowrap" justify="space-around" align="center" margin="2rem 0 0">
                  <Link to={`${budgetURL}/Edit-Category-Goals`}>
                    <PrimaryButton
                      size="medium"
                      modifier="white"
                      margin=".5rem 0"
                      onClick={() => {
                        setErrors([]);
                        setPreviousLocation('Edit-Category-Goals');
                      }}>
                      Edit Category Goals
                    </PrimaryButton>
                  </Link>
                  <Link to={`${budgetURL}/Manage-Categories`}>
                    <PrimaryButton
                      size="medium"
                      modifier="white"
                      margin=".5rem 0"
                      onClick={() => {
                        setErrors([]);
                        setPreviousLocation('Manage-Categories');
                      }}>
                      Manage Categories
                    </PrimaryButton>
                  </Link>
                </BudgetNavigationLinkSection>
              }
            />
          </Routes>
        </BudgetNavigationLinkSection>
        <BudgetNavigationLinkSection flow="column nowrap" justify="space-around" align="center" margin="1rem 0">
          <Link to="Income-Management">
            <PrimaryButton size="large" modifier="white">
              Income Management
            </PrimaryButton>
          </Link>
          <Routes>
            <Route
              path="Income-Management"
              element={
                <BudgetNavigationLinkSection flow="column nowrap" justify="space-around" align="center" margin="2rem 0 0">
                  <Link to={`${budgetURL}/Allocate-Income`}>
                    <PrimaryButton
                      size="medium"
                      modifier="white"
                      margin=".5rem 0"
                      onClick={() => {
                        setErrors([]);
                        setPreviousLocation('Allocate-Income');
                      }}>
                      Allocate Income
                    </PrimaryButton>
                  </Link>
                </BudgetNavigationLinkSection>
              }
            />
          </Routes>
        </BudgetNavigationLinkSection>
        <BudgetNavigationLinkSection flow="column nowrap" justify="space-around" align="center" margin="1rem 0">
          <Link to="Transactions">
            <PrimaryButton size="large" modifier="white">
              Transactions
            </PrimaryButton>
          </Link>
          <Routes>
            <Route
              path="Transactions"
              element={
                <BudgetNavigationLinkSection flow="column nowrap" justify="space-around" align="center" margin="2rem 0 0">
                  <Link to={`${budgetURL}/Transaction-Planner`}>
                    <PrimaryButton
                      size="medium"
                      modifier="white"
                      margin=".5rem 0"
                      onClick={() => {
                        setErrors([]);
                        setPreviousLocation('Transaction-Planner');
                      }}>
                      Transaction Planner
                    </PrimaryButton>
                  </Link>
                  <Link to={`${budgetURL}/Investment-Manager`}>
                    <PrimaryButton
                      size="medium"
                      modifier="white"
                      margin=".5rem 0"
                      onClick={() => {
                        setErrors([]);
                        setPreviousLocation('Investment-Manager');
                      }}>
                      Investment Manager
                    </PrimaryButton>
                  </Link>
                  <Link to={`${budgetURL}/Debt-Manager`}>
                    <PrimaryButton
                      size="medium"
                      modifier="white"
                      margin=".5rem 0"
                      onClick={() => {
                        setErrors([]);
                        setPreviousLocation('Debt-Manager');
                      }}>
                      Debt Manager
                    </PrimaryButton>
                  </Link>
                  <Link to={`${budgetURL}/Recent-Transactions`}>
                    <PrimaryButton
                      size="medium"
                      modifier="white"
                      margin=".5rem 0"
                      onClick={() => {
                        setErrors([]);
                        setPreviousLocation('Recent-Transactions');
                      }}>
                      Recent Transactions
                    </PrimaryButton>
                  </Link>
                </BudgetNavigationLinkSection>
              }
            />
          </Routes>
        </BudgetNavigationLinkSection>
        <BudgetNavigationLinkSection flow="column nowrap" justify="space-around" align="center" margin="1rem 0">
          <Link to={`${budgetURL}/Budget-Account-Management`}>
            <PrimaryButton
              size="large"
              modifier="white"
              onClick={() => {
                setErrors([]);
                setPreviousLocation('Budget-Account-Management');
              }}>
              Account Management
            </PrimaryButton>
          </Link>
        </BudgetNavigationLinkSection>
        {user && user.permissions === 'Admin' && (
          <BudgetNavigationLinkSection flow="column nowrap" justify="space-around" align="center" margin="1rem 0">
            <Link to={`${budgetURL}/Invite-Users`}>
              <PrimaryButton
                size="large"
                modifier="white"
                onClick={() => {
                  setErrors([]);
                  setPreviousLocation('Invite-Users');
                }}>
                Invite Users
              </PrimaryButton>
            </Link>
          </BudgetNavigationLinkSection>
        )}
      </BudgetNavigationLinkSectionContainer>
    </StyledNavigation>
  );
};

// Regular Navigation
// @include SizeAndPositioning(relative, 100%, 100%, flex, column, nowrap, center, center, 0, 0, 0);
// background-color: rgba($primaryColorDark, 0.9);
// opacity: 0;
// transition: opacity 0.5s;
