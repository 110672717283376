import React from 'react';
import styled from 'styled-components';

export const StyledModal = styled.section`
  position: ${(props) => props.position};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  display: flex;
  flex-flow: ${(props) => props.flow};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  z-index: ${(props) => props.ZIndex};
  border-radius: ${(props) => props.borderRadius};
  background-color: ${(props) => props.bgColor};
  transform: ${(props) => props.transform};
  text-align: ${(props) => props.textAlign};
  font-size: ${(props) => props.textSize};
  color: ${(props) => props.textColor};
  padding: ${(props) => props.padding};

  & p.lds-help-text {
    font-family: ${(props) => props.theme.text.secondary};
    margin: 0.5rem 0;
  }

  @media ${(props) => props.theme.screens.largeTablet.portrait} {
    ${(props) => (props.modalType === 'lds-help' ? `width: 100%` : null)};
  }

  @media ${(props) => props.theme.screens.largeMobile.portrait} {
    ${(props) => (props.modalType === 'lds-help' ? `width: 100%` : null)};
    ${(props) => (props.modalType === 'lds-help' ? `font-size: 1.6rem` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.landscape} {
    ${(props) => (props.modalType === 'lds-help' ? `height: 15rem` : null)};
    ${(props) => (props.modalType === 'lds-help' ? `width: 90%` : null)};
    ${(props) => (props.modalType === 'lds-help' ? `font-size: 1.8rem` : null)};
    ${(props) => (props.modalType === 'lds-help' ? `padding: 2rem` : null)};
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    ${(props) => (props.modalType === 'lds-help' ? `width: 100%` : null)};
    ${(props) => (props.modalType === 'lds-help' ? `font-size: 1.4rem` : null)};
    ${(props) => (props.modalType === 'lds-help' ? `padding: 2rem` : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.landscape} {
    ${(props) => (props.modalType === 'lds-help' ? `height: 14rem` : null)};
    ${(props) => (props.modalType === 'lds-help' ? `width: 90%` : null)};
    ${(props) => (props.modalType === 'lds-help' ? `font-size: 1.4rem` : null)};
    ${(props) => (props.modalType === 'lds-help' ? `padding: 1rem` : null)};
  }

  @media ${(props) => props.theme.screens.smallMobile.portrait} {
    ${(props) => (props.modalType === 'lds-help' ? `width: 110%` : null)};
    ${(props) => (props.modalType === 'lds-help' ? `font-size: 1.2rem` : null)};
    ${(props) => (props.modalType === 'lds-help' ? `padding: 2rem` : null)};
  }
`;
