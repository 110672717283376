import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import { Form } from '../Form';
import { FormContainer } from '../FormContainer';
import { LandingFormCloser } from '../FormCloser';
import { LandingFormHeader } from '../Header';
import ErrorContainer from '../Errors';
import FormSection from '../Section';
import { LandingFormInput } from '../Input';
import { LandingFormLabel } from '../Label';
import { PrimaryButton } from '../../Button';
import { handleInputChange } from '../Algorithms/InputChange';
import { forgotPassword } from '../../../API-Requests/ForgotPassword';

export const ForgotPasswordForm = function (props) {
  const { theme, flow, justify, align, forgotPasswordValues, setForgotPasswordValues, errors, setErrors, disabledValues, setDisabledValues } = props;

  const sendResetPasswordRequest = async () => {
    if (forgotPasswordValues === '') return;
    await forgotPassword(forgotPasswordValues.forgottenEmail);
  };

  return (
    <FormContainer className="form-container r__form-container">
      <Form position="absolute" flow={flow} justify={justify} align={align} bgColor={`${theme.colors.grayScale.white}f2`} className="form r__form" zIndex="5">
        <Link to="../Login">
          <LandingFormCloser onClick={() => setErrors([])}>
            <i className="fas fa-window-close" data-cy="forgottenPasswordFormCloser" />
          </LandingFormCloser>
        </Link>
        <LandingFormHeader className="form__header r__form__header" margin="0rem 0rem 2rem" headerType="forgot-password">
          Forgot Username Or Password
        </LandingFormHeader>
        <ErrorContainer errorContainerType="landing">
          {errors && (
            <ol>
              {Object.keys(errors).map((error, index) => {
                if (errors[error] !== '') {
                  return (
                    <li className="error" key={index}>
                      {errors[error]}
                    </li>
                  );
                }
              })}
            </ol>
          )}
        </ErrorContainer>
        <FormSection minHeight="5rem" flow="column-reverse nowrap" justify="center" align="center" margin="0 0 3.5rem 0" className="form__section r__form__section" sectionType="forgot-password">
          <LandingFormInput
            type="email"
            name="forgottenEmail"
            inputType="forgot-password"
            minLength={3}
            maxLength={40}
            inputWidth="20%"
            className="form__input r__form__input"
            id="forgottenEmail"
            placeholder="Enter Email Address"
            focusTransform="translate(-5.5rem, -7rem) scale(0.75)"
            data-cy="forgotPasswordEmail"
            onChange={(e) => {
              handleInputChange(e, 'forgot-password', forgotPasswordValues, setForgotPasswordValues, errors, setErrors, disabledValues, setDisabledValues);
            }}
          />
          <LandingFormLabel htmlFor="forgottenEmail" className="form__label r__form__label" data-cy="forgotPasswordEmailLabel" labelType="forgot-password">
            Email Address
          </LandingFormLabel>
        </FormSection>
        <PrimaryButton
          size="medium"
          margin="0 0 1rem 0"
          type="submit"
          data-cy="forgotPasswordSubmit"
          disabled={disabledValues.forgotPasswordDisabled}
          onClick={(e) => {
            e.preventDefault();
            sendResetPasswordRequest();
          }}>
          Submit
        </PrimaryButton>
      </Form>
    </FormContainer>
  );
};
