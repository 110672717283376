import React from 'react';
import styled from 'styled-components';

export const Receipt = styled.section`
  &.receipt {
    position: relative;
    height: 100%;
    width: 100%;
    background-image: url('/DIST/CSS/Images/receipt.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    & .receipt__contents {
      position: relative;
      height: 50%;
      width: 40%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;

      & .receipt__contents__top {
        position: relative;
        height: max-content;
        min-height: 8rem;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        align-items: center;
        border-bottom: 0.2rem solid ${(props) => props.theme.colors.primaryColor};

        & p.receipt__contents__top__location {
          font-family: ${(props) => props.theme.text.secondary};
          font-size: 8rem;
          color: ${(props) => props.theme.colors.primaryColor};
          font-weight: bold;
        }

        & .receipt__contents__top__headers {
          position: relative;
          height: max-content;
          min-height: 6rem;
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-around;
          align-items: center;

          & p.receipt__contents__top__headers__header {
            font-family: ${(props) => props.theme.text.secondary};
            font-size: 3rem;
            color: ${(props) => props.theme.colors.primaryColor};
          }
        }
      }

      & .receipt__contents__middle {
        position: relative;
        height: max-content;
        width: 100%;
        overflow-y: auto;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        & .receipt__contents__middle__item {
          position: relative;
          height: max-content;
          width: 100%;
          min-height: 6rem;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-around;
          align-items: center;
          border-top: 0.2rem solid ${(props) => `${props.theme.colors.primaryColor}80`};
          border-bottom: 0.2rem solid ${(props) => `${props.theme.colors.primaryColor}80`};
          & .receipt__contents__middle__item__section {
            position: relative;
            height: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            &:first-of-type {
              width: 70%;
              justify-content: flex-start;
            }
            &:last-of-type {
              width: 30%;
              justify-content: flex-end;
            }
            & .receipt__contents__middle__item__section__text {
              font-size: 2rem;
              color: ${(props) => props.theme.colors.primaryColor};
              margin: 0 1rem;
            }
          }

          &:hover {
            & .receipt__contents__middle__item__section {
              &:hover {
                background-color: transparent;
              }
              transition: background-color 0.5s;
              & .receipt__contents__middle__item__section__text {
                font-size: 2rem;
                color: ${(props) => props.theme.colors.grayScale.offWhite};
                transition: color 0.5s;
              }
            }
          }
        }
      }

      & .receipt__contents__bottom {
        position: relative;
        height: max-content;
        min-height: 8rem;
        max-height: 90rem;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
        border-top: 0.2rem solid ${(props) => props.theme.colors.primaryColor};

        & .receipt__contents__bottom__text {
          font-family: ${(props) => props.theme.text.secondary};
          font-size: 5rem;
          color: ${(props) => props.theme.colors.primaryColor};
        }

        & .receipt__contents__bottom__cost {
          font-family: ${(props) => props.theme.text.secondary};
          font-size: 4rem;
          color: ${(props) => props.theme.colors.primaryColor};
        }
      }
    }
  }
`;
