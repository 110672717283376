import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, PrimaryButton, SmallBorderlessButton } from '../Global/Button';
import { BudgetCreationErrorContainer } from '../Global/Forms/Errors';
import { handleInputChange } from '../Global/Forms/Algorithms/InputChange';
import { Form } from '../Global/Forms/Form';
import { LandingFormHeader } from '../Global/Forms/Header';
import { CreateBudgetFormInput, CategoryTitleCreationInput } from '../Global/Forms/Input';
import { CreateBudgetFormLabel, EmeregencyLabel, TithingLabel } from '../Global/Forms/Label';
import Page from '../Global/Forms/Page';
import FormSection from '../Global/Forms/Section';
import { MainCategory, SubCategory } from '../Global/Budget/Categories';
import { icons } from '../../Budget-Creation/Category-Icons';
import { toggleClasses } from '../Utility';
import { processMainCategory, processSubCategory, toggleMainCategoryCreation, toggleSubCategoryCreation } from '../Global/Budget/BudgetUtility';
import { EmergencyRadio, TithingRadio } from '../Global/Forms/Radio';
import { Month } from '../Global/Budget/Month';
import { CreateBudgetSelect } from '../Global/Forms/Select';
import { createBudgetSchema } from '../Global/Forms/Validate';

export const MainCategoryDisplay = styled.div`
  &.main-category-display {
    position: relative;
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0) rotate(0);

    & .category-container {
      position: relative;
      display: flex;
      height: 100%;
      width: max-content;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      margin: 0 2rem;
      color: ${(props) => props.color};
      font-family: ${(props) => props.theme.text.secondary};
      & i {
        font-size: ${(props) => props.iconFontSize};
        margin-bottom: 2rem;
      }
      & p {
        font-size: ${(props) => props.fontSize};
      }
    }
  }
`;

export const SubCategoryDisplay = styled(MainCategoryDisplay)`
  &.sub-category-display {
    position: relative;
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    transform: translate(0, 0) rotate(0);
    border-top: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
    overflow-y: auto;

    ${(props) => (props.type === 'month' ? 'border-top: none' : null)};
  }
`;

export const SubCategoryCreationContainer = styled.div`
  &.sub-category-creation-container {
    position: relative;
    height: 75%;
    min-height: 50rem;
    max-height: 50rem;
    width: 40%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    transform: translate(0, 0) rotate(0);
    border: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
    border-radius: 1rem;

    ${(props) => (props.inBudget === true ? `height: ${props.height}` : null)};
    ${(props) => (props.inBudget === true ? `min-height: ${props.minHeight}` : null)};
    ${(props) => (props.inBudget === true ? `max-height: ${props.maxHeight}` : null)};
    ${(props) => (props.inBudget === true ? `width: ${props.width}` : null)};
    ${(props) => (props.inBudget === true ? `border: ${props.border}` : null)};
    ${(props) => (props.inBudget === true ? `border-radius: ${props.borderRadius}` : null)};
  }
`;

export const IconsContainer = styled.section`
  &.icons-container {
    position: relative;
    height: 80%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-top-left-radius: 0.9rem;
    border-top-right-radius: 0.9rem;
    overflow-y: auto;
    ${(props) => (props.inBudget === true ? `display: flex` : null)};
    ${(props) => (props.inBudget === true ? `flex-flow: row wrap` : null)};

    & .icon-container {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-evenly;
      align-items: center;
      transform: translate(0, 0) rotate(0);
      padding: 3rem 0.5rem;
      z-index: 3;
      font-family: ${(props) => props.theme.text.secondary};
      font-size: 1.6rem;
      color: ${(props) => props.theme.colors.primaryColor};
      text-align: center;
      &:first-of-type {
        border-top-left-radius: 0.5rem;
      }

      &:hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.colors.primaryColor};
        color: ${(props) => props.theme.colors.grayScale.offWhite};
        transition: background-color 0.5s, color 0.5s;

        & p {
          color: ${(props) => props.theme.colors.grayScale.offWhite};
          transition: color 0.5s;
          ${(props) => (props.inBudget === true ? `font-size: 1.6rem` : null)};
        }
      }

      &__icon {
        position: relative;
        z-index: 0;
        margin-bottom: 0.5rem;
        font-size: 4rem;
        ${(props) => (props.inBudget === true ? `font-size: 3rem` : null)};
      }

      & p {
        ${(props) => (props.inBudget === true ? `font-size: 1.6rem` : null)};
      }

      ${(props) => (props.inBudget === true ? `height: 25%` : null)};
      ${(props) => (props.inBudget === true ? `width: 25%` : null)};
    }
    & .icon-container--clicked {
      background-color: ${(props) => props.theme.colors.primaryColor};
      color: ${(props) => props.theme.colors.grayScale.offWhite};

      & .icon-container__icon {
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }

      & p {
        color: ${(props) => props.theme.colors.grayScale.offWhite};
      }
    }
  }
`;

export const MainCategorySelectContainer = styled.div`
  &.main-category-select {
    position: relative;
    height: 75%;
    min-height: 50rem;
    max-height: 50rem;
    width: 50%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    transform: translate(0, 0) rotate(0);
    border: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
    border-radius: 1.25rem;
    overflow-y: auto;

    ${(props) => (props.inBudget === true ? `height: ${props.height}` : null)};
    ${(props) => (props.inBudget === true ? `min-height: ${props.minHeight}` : null)};
    ${(props) => (props.inBudget === true ? `max-height: ${props.maxHeight}` : null)};
    ${(props) => (props.inBudget === true ? `width: ${props.width}` : null)};
    ${(props) => (props.inBudget === true ? `border: ${props.border}` : null)};
    ${(props) => (props.inBudget === true ? `border-radius: ${props.borderRadius}` : null)};
  }

  &.main-category-select--creating {
    position: relative;
    height: 75%;
    min-height: 50rem;
    width: 50%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    transform: translate(0, 0) rotate(0);
    border: 0.3rem solid ${(props) => props.theme.colors.primaryColor};
    border-radius: 1.25rem;
    overflow-y: auto;
    flex-direction: column;

    ${(props) => (props.inBudget === true ? `height: ${props.height}` : null)};
    ${(props) => (props.inBudget === true ? `min-height: ${props.minHeight}` : null)};
    ${(props) => (props.inBudget === true ? `max-height: ${props.maxHeight}` : null)};
    ${(props) => (props.inBudget === true ? `width: ${props.width}` : null)};
    ${(props) => (props.inBudget === true ? `border: ${props.border}` : null)};
    ${(props) => (props.inBudget === true ? `border-radius: ${props.borderRadius}` : null)};
  }
`;

const budgetCreationValidationObject = {
  pageOne: {
    budgetName: '',
  },
  pageTwo: {
    emergencySetting: '',
    emergencyGoal: '',
  },
  pageThree: {
    savingsPercentage: '',
    savingsGoal: '',
  },
  pageFour: {
    investmentPercentage: '',
    investmentGoal: '',
  },
};

export const BudgetCreationForm = function (props) {
  const {
    theme,
    user,
    budget,
    setBudget,
    categoryIndex,
    setCategoryIndex,
    changeMainCategory,
    page,
    setPage,
    currentCategory,
    setCurrentCategory,
    errors,
    setErrors,
    disabledValues,
    setDisabledValues,
    iconObject,
    setIconObject,
  } = props;

  const [budgetInfo, setBudgetInfo] = useState(budgetCreationValidationObject);

  useEffect(() => {
    let iconObj = {};
    const iconContainers = document.querySelectorAll('.icon-container');
    iconContainers.forEach((container, i) => (iconObj[container.firstChild.nextSibling.textContent] = container));
    setIconObject(iconObj);
  }, []);

  useEffect(() => {
    createBudgetSchema.pageOne.isValid(budgetInfo.pageOne).then((valid) => {
      valid === true ? setDisabledValues({ ...disabledValues, createBudgetDisabled: false }) : setDisabledValues({ ...disabledValues, createBudgetDisabled: true });
    });
  }, [budgetInfo.pageOne]);

  useEffect(() => {
    createBudgetSchema.pageTwo.isValid(budgetInfo.pageTwo).then((valid) => {
      valid === true ? setDisabledValues({ ...disabledValues, createBudgetDisabled: false }) : setDisabledValues({ ...disabledValues, createBudgetDisabled: true });
    });
  }, [budgetInfo.pageTwo]);

  useEffect(() => {
    createBudgetSchema.pageThree.isValid(budgetInfo.pageThree).then((valid) => {
      valid === true ? setDisabledValues({ ...disabledValues, createBudgetDisabled: false }) : setDisabledValues({ ...disabledValues, createBudgetDisabled: true });
    });
  }, [budgetInfo.pageThree]);

  useEffect(() => {
    createBudgetSchema.pageFour.isValid(budgetInfo.pageFour).then((valid) => {
      valid === true ? setDisabledValues({ ...disabledValues, createBudgetDisabled: false }) : setDisabledValues({ ...disabledValues, createBudgetDisabled: true });
    });
  }, [budgetInfo.pageFour]);

  const handleCreateBudgetSubmitClick = (e) => {
    e.preventDefault();

    const pages = [...document.querySelectorAll('.form__page')];
    const currentPage = pages.findIndex((pageElement) => pageElement.classList.contains('open'));

    if ((user && user.latterDaySaint === true && currentPage === 4) || (user && user.latterDaySaint === true && currentPage === 5) || (user && user.latterDaySaint === true && currentPage === 6)) {
      setDisabledValues({ ...disabledValues, createBudgetDisabled: true });
    } else if (
      (user && user.latterDaySaint === false && currentPage === 3) ||
      (user && user.latterDaySaint === false && currentPage === 4) ||
      (user && user.latterDaySaint === false && currentPage === 5)
    ) {
      setDisabledValues({ ...disabledValues, createBudgetDisabled: true });
    }

    toggleClasses(pages[currentPage], [`closed`, `open`]);
    if (pages[currentPage + 1] === undefined) {
      budget.submitForCreation(budget, user);
      return;
    }
    toggleClasses(pages[currentPage + 1], [`closed`, `open`]);
    setPage(page + 1);
  };

  return (
    <Form flow="column nowrap" justify="center" align="center" zIndex="9" bgColor={`${(props) => `${props.theme.colors.grayScale.offWhite}f2`}`}>
      <LandingFormHeader className="form__header" margin="0 0 2rem 0">
        Create Budget
      </LandingFormHeader>
      <BudgetCreationErrorContainer>
        {errors && (
          <ol>
            {Object.keys(errors).map((error, index) => {
              if (errors[error] !== '') {
                return (
                  <li className="error" key={index}>
                    {errors[error]}
                  </li>
                );
              }
            })}
          </ol>
        )}
      </BudgetCreationErrorContainer>
      <Page flow="column nowrap" justify="center" align="center" className="form__page open">
        <FormSection flow="column-reverse nowrap" justify="center" align="center" margin="0" minHeight="10rem">
          <CreateBudgetFormInput
            type="text"
            id="budgetName"
            name="budgetName"
            placeholder={`Enter Budget's Name`}
            minWidth="40%"
            borderRadius="0.75rem"
            focusTransform="translate(-8.5rem, -8rem) scale(0.75)"
            data-cy="budgetName"
            required
            onChange={(e) => handleInputChange(e, 'create-budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues, budgetInfo, setBudgetInfo)}
          />
          <CreateBudgetFormLabel htmlFor="budgetName" data-cy="budgetNameLabel">
            What will be the name of your budget?
          </CreateBudgetFormLabel>
        </FormSection>
      </Page>
      {user.latterDaySaint && (
        <Page flow="row nowrap" justify="center" align="center" minHeight="15rem" className="form__page closed">
          <TithingLabel position="absolute" transform="translate(-5rem, -5.5rem)">
            What will you be paying tithing on?
          </TithingLabel>
          <TithingRadio
            type="radio"
            name="tithingSetting"
            className="grossOption"
            id="grossOption"
            height="8rem"
            width="10%"
            value="Gross"
            checked={budget && budget.accounts && budget.accounts.tithing.tithingSetting === 'Gross'}
            onChange={(e) => handleInputChange(e, 'create-budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues)}
          />
          <TithingLabel className="grossOptionLabel" htmlFor="grossOption">
            Gross
          </TithingLabel>

          <TithingRadio
            type="radio"
            name="tithingSetting"
            className="netOption"
            id="netOption"
            height="8rem"
            width="10%"
            value="Net"
            checked={budget && budget.accounts && budget.accounts.tithing.tithingSetting === 'Net'}
            onChange={(e) => handleInputChange(e, 'create-budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues)}
          />
          <TithingLabel className="netOptionLabel" htmlFor="netOption">
            Net
          </TithingLabel>
          <TithingRadio
            type="radio"
            name="tithingSetting"
            className="surplusOption"
            id="surplusOption"
            height="8rem"
            width="10%"
            value="Surplus"
            checked={budget && budget.accounts && budget.accounts.tithing.tithingSetting === 'Surplus'}
            onChange={(e) => handleInputChange(e, 'create-budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues)}
          />
          <TithingLabel className="surplusOptionLabel" htmlFor="surplusOption" data-cy="surplusTithingLabel">
            Surplus
          </TithingLabel>
        </Page>
      )}
      <Page flow="column nowrap" justify="center" align="center" className="form__page closed">
        <MainCategorySelectContainer className="main-category-select">
          <PrimaryButton
            className="main-category-start-create open"
            size="medium square"
            modifier="square"
            borderTopLeftRadius=".9rem"
            hoverColor={theme.colors.grayScale.offWhite}
            onClick={(e) => toggleMainCategoryCreation(e)}
            data-cy="createMainCategoryButton">
            <i className="fas fa-plus main-category-create-icon" />
            <p className="main-category-create-text">Create</p>
          </PrimaryButton>
          {budget &&
            budget.mainCategories &&
            budget.mainCategories.map((category, index) => (
              <MainCategory
                key={index}
                className={`main-category open`}
                height="25%"
                width="25%"
                border={`.2rem solid ${theme.colors.primaryColor}cc`}
                borderRadius=".9rem"
                flow="column-reverse nowrap"
                justify="space-evenly"
                align="center"
                data-cy="mainCategory">
                <p className="main-category__title">{category.title}</p>
                <i className={`fas ${category.icon} main-category__icon`} />
                <i
                  className={`fas fa-trash-alt main-category--delete category-delete-icon--${category.icon}`}
                  onClick={(e) => processMainCategory(e, 'delete', budget, setBudget, iconObject)}
                  data-cy="mainCategoryDelete"
                />
              </MainCategory>
            ))}
          <IconsContainer className="icons-container closed">
            {icons &&
              icons.map((iconImage, index) => (
                <section className="icon-container" key={index} onClick={(e) => budget.selectIcon(e, iconObject, setIconObject)}>
                  <i className={`fas fa-${iconImage} icon-container__icon`} />
                  <p className="icon-container__text" data-cy={icons[index].split('-').join(' ')}>
                    {icons[index].split('-').join(' ')}
                  </p>
                </section>
              ))}
          </IconsContainer>
          <FormSection className="main-category-title-creation closed" minHeight="20%" flow="row nowrap" justify="space-evenly" align="center" borderTop={`.3rem solid ${theme.colors.primaryColor}`}>
            <CategoryTitleCreationInput type="text" className="main-category-title-input" data-cy="mainCategoryTitleInput" />
            <PrimaryButton size="small" onClick={(e) => processMainCategory(e, 'create', budget, setBudget, iconObject)} data-cy="mainCategoryTitleCreateButton">
              Create
            </PrimaryButton>
            <PrimaryButton size="small" onClick={(e) => toggleMainCategoryCreation(e)} data-cy="mainCategoryTitleCloseButton">
              Close
            </PrimaryButton>
          </FormSection>
        </MainCategorySelectContainer>
      </Page>
      <Page flow="column nowrap" justify="center" align="center" className="form__page closed">
        <SubCategoryCreationContainer className="sub-category-creation-container">
          <MainCategoryDisplay className="main-category-display" height="55%" width="100%" iconFontSize="12rem" fontSize="3rem" color={theme.colors.primaryColor}>
            <Button
              size="extra-small"
              iconFontSize="6rem"
              color={theme.colors.primaryColor}
              hoverColor={theme.colors.primaryColor}
              activeColor={theme.colors.secondaryColor}
              onClick={(e) => changeMainCategory(e, 'decrease')}
              data-cy="changeMainCategoryLeft">
              <i
                className="far fa-circle-left"
                onMouseOver={(e) => toggleClasses(e.target.closest('button').firstChild, [`far`, `fas`])}
                onMouseOut={(e) => toggleClasses(e.target.closest('button').firstChild, [`far`, `fas`])}
              />
            </Button>
            {budget && budget.mainCategories && budget.mainCategories.length > 0 && (
              <div className="category-container">
                <i className={`fas ${budget && budget.mainCategories && budget.mainCategories[categoryIndex].icon}`} />
                <p>{budget && budget.mainCategories && budget.mainCategories[categoryIndex].title}</p>
              </div>
            )}
            <Button
              size="extra-small"
              iconFontSize="6rem"
              color={theme.colors.primaryColor}
              hoverColor={theme.colors.primaryColor}
              activeColor={theme.colors.secondaryColor}
              onClick={(e) => changeMainCategory(e, 'increase')}
              data-cy="changeMainCategoryRight">
              <i
                className="far fa-circle-right"
                onMouseOver={(e) => toggleClasses(e.target.closest('button').firstChild, [`far`, `fas`])}
                onMouseOut={(e) => toggleClasses(e.target.closest('button').firstChild, [`far`, `fas`])}
              />
            </Button>
          </MainCategoryDisplay>
          <SubCategoryDisplay className="sub-category-display" height="45%" width="100%">
            {budget &&
              budget.mainCategories &&
              budget.mainCategories[categoryIndex] &&
              budget.mainCategories[categoryIndex].subCategories.map((subCategory, index) => {
                return (
                  <SubCategory
                    theme={theme}
                    budget={budget}
                    setBudget={setBudget}
                    subCategory={subCategory}
                    processSubCategory={processSubCategory}
                    mainCategoryIndex={categoryIndex}
                    content={{ creating: true }}
                    key={index}
                  />
                );
              })}
            <FormSection
              className="sub-category-title-creation closed"
              minHeight="30%"
              flow="row nowrap"
              justify="space-evenly"
              align="center"
              borderBottom={`.3rem solid ${theme.colors.primaryColor}`}>
              <CategoryTitleCreationInput className="sub-category-title-input" data-cy="subCategoryTitleInput" />
              <PrimaryButton size="small" onClick={(e) => processSubCategory(e, 'create', categoryIndex, budget, setBudget)} data-cy="subCategoryCreationButton">
                Create
              </PrimaryButton>
              <PrimaryButton size="small" onClick={(e) => toggleSubCategoryCreation(e)} data-cy="subCategoryCreationClose">
                Close
              </PrimaryButton>
            </FormSection>
            <SmallBorderlessButton margin="1rem 0 0 0" className="sub-category-start-creation" onClick={(e) => toggleSubCategoryCreation(e)} data-cy="startSubCategoryCreation">
              + Add Sub Category
            </SmallBorderlessButton>
          </SubCategoryDisplay>
        </SubCategoryCreationContainer>
      </Page>
      <Page flow="column nowrap" justify="center" align="center" className="form__page closed">
        <Month
          theme={theme}
          user={user}
          budget={budget}
          setBudget={setBudget}
          categoryIndex={categoryIndex}
          changeMainCategory={changeMainCategory}
          currentCategory={currentCategory}
          setCurrentCategory={setCurrentCategory}
          handleInputChange={handleInputChange}
          errors={errors}
          setErrors={setErrors}
          disabledValues={disabledValues}
          setDisabledValues={setDisabledValues}
          content={{ margin: '0', monthType: 'create-budget' }}
        />
      </Page>
      <Page flow="column nowrap" minHeight="30rem" justify="center" align="center" className="form__page closed">
        <EmeregencyLabel position="absolute" transform="translate(1rem, -11.5rem)">
          Which way would you like to measure your emergency goal?
        </EmeregencyLabel>
        <FormSection flow="row norwap" justify="center" align="center">
          <EmergencyRadio
            type="radio"
            name="emergencySetting"
            className="lengthOfTime"
            id="lengthOfTime"
            height="8rem"
            width="15%"
            value="Length Of Time"
            checked={budget && budget.accounts && budget.accounts.emergencyFund.emergencyGoalMeasurement === 'Length Of Time'}
            onChange={(e) => handleInputChange(e, 'create-budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues, budgetInfo, setBudgetInfo)}
          />
          <EmeregencyLabel className="lengthOfTimeLabel" htmlFor="lengthOfTime" data-cy="emergencySettingTime">
            Length Of Time
          </EmeregencyLabel>
          <EmergencyRadio
            type="radio"
            name="emergencySetting"
            className="totalAmount"
            id="totalAmount"
            height="8rem"
            width="15%"
            value="Total Amount"
            checked={budget && budget.accounts && budget.accounts.emergencyFund.emergencyGoalMeasurement === 'Total Amount'}
            onChange={(e) => handleInputChange(e, 'create-budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues, budgetInfo, setBudgetInfo)}
          />
          <EmeregencyLabel className="totalAmountLabel" htmlFor="totalAmount" data-cy="emergencySettingTotal">
            Total Amount
          </EmeregencyLabel>
        </FormSection>
        {budget && budget.accounts && budget.accounts.emergencyFund.emergencyGoalMeasurement === 'Length Of Time' && (
          <FormSection flow="row norwap" justify="center" align="center">
            <CreateBudgetFormInput
              type="number"
              id="emergencyGoalTime"
              name="emergencyGoalTime"
              placeholder={`Enter Goal Time`}
              margin="2rem 0"
              minWidth="15%"
              borderRadius=".75rem 0 0 .75rem"
              focusTransform="translate(-7.5rem, -8rem) scale(0.75)"
              data-cy="emergencyGoalTime"
              required
              onChange={(e) => handleInputChange(e, 'create-budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues, budgetInfo, setBudgetInfo)}
            />
            <CreateBudgetSelect
              id="emergencyGoalTiming"
              name="emergencyGoalTiming"
              data-cy="emergencyGoalTiming"
              minHeight="7rem"
              width="max-content"
              minWidth="15%"
              borderRadius="0 .75rem .75rem 0"
              onChange={(e) => handleInputChange(e, 'create-budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues)}>
              {[`Months`, `Years`].map((option, index) => {
                return (
                  <option key={index} value={option}>
                    {option}
                  </option>
                );
              })}
            </CreateBudgetSelect>
          </FormSection>
        )}
        {budget && budget.accounts && budget.accounts.emergencyFund.emergencyGoalMeasurement === 'Total Amount' && (
          <FormSection flow="row norwap" justify="center" align="center">
            <CreateBudgetFormInput
              type="number"
              id="emergencyGoalTotal"
              name="emergencyGoalTotal"
              placeholder={`Enter Goal Amount`}
              margin="2rem 0"
              minWidth="30%"
              borderRadius=".75rem"
              focusTransform="translate(-7.5rem, -8rem) scale(0.75)"
              data-cy="emergencyGoalTotal"
              required
              onChange={(e) => handleInputChange(e, 'create-budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues, budgetInfo, setBudgetInfo)}
            />
          </FormSection>
        )}
      </Page>
      <Page flow="column nowrap" minHeight="30rem" justify="center" align="center" className="form__page closed">
        <FormSection flow="column norwap" justify="center" align="center">
          <CreateBudgetFormInput
            type="number"
            min={0}
            max={100}
            id="savingsGoalPercentage"
            name="savingsGoalPercentage"
            placeholder={`Enter Your Savings Goal Percentage`}
            minWidth="40%"
            borderRadius="0.75rem"
            focusTransform="translate(-7.5rem, -8rem) scale(0.75)"
            data-cy="savingsGoalPercentage"
            required
            onChange={(e) => handleInputChange(e, 'create-budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues, budgetInfo, setBudgetInfo)}
          />
          <CreateBudgetFormLabel htmlFor="savingsGoalPercentage" data-cy="savingsGoalPercentageLabel">
            What percentage is going towards savings?
          </CreateBudgetFormLabel>
        </FormSection>
        <FormSection flow="column norwap" justify="center" align="center">
          <CreateBudgetFormInput
            type="number"
            id="savingsGoal"
            name="savingsGoal"
            placeholder={`Enter Your Savings Goal`}
            minWidth="40%"
            borderRadius="0.75rem"
            focusTransform="translate(-7.5rem, -8rem) scale(0.75)"
            margin="4rem 0 0 0"
            data-cy="savingsGoal"
            required
            onChange={(e) => handleInputChange(e, 'create-budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues, budgetInfo, setBudgetInfo)}
          />
          <CreateBudgetFormLabel htmlFor="savingsGoal" data-cy="savingsGoalLabel" margin="2rem 0 0 0">
            What will be your savings fund goal amount?
          </CreateBudgetFormLabel>
        </FormSection>
      </Page>
      <Page flow="column nowrap" minHeight="30rem" justify="center" align="center" className="form__page closed">
        <FormSection flow="column norwap" justify="center" align="center">
          <CreateBudgetFormInput
            type="number"
            min={0}
            max={100}
            id="investmentGoalPercentage"
            name="investmentGoalPercentage"
            placeholder={`Enter Your Investment Goal Percentage`}
            minWidth="40%"
            borderRadius="0.75rem"
            focusTransform="translate(-7.5rem, -8rem) scale(0.75)"
            data-cy="investmentGoalPercentage"
            required
            onChange={(e) => handleInputChange(e, 'create-budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues, budgetInfo, setBudgetInfo)}
          />
          <CreateBudgetFormLabel htmlFor="investmentGoalPercentage" data-cy="investmentGoalPercentageLabel">
            What percentage is going towards investments?
          </CreateBudgetFormLabel>
        </FormSection>
        <FormSection flow="column norwap" justify="center" align="center">
          <CreateBudgetFormInput
            type="number"
            id="investmentGoal"
            name="investmentGoal"
            placeholder={`Enter Your Investment Goal`}
            minWidth="40%"
            borderRadius="0.75rem"
            focusTransform="translate(-7.5rem, -8rem) scale(0.75)"
            margin="4rem 0 0 0"
            data-cy="investmentGoal"
            required
            onChange={(e) => handleInputChange(e, 'create-budget', budget, setBudget, errors, setErrors, disabledValues, setDisabledValues, budgetInfo, setBudgetInfo)}
          />
          <CreateBudgetFormLabel htmlFor="investmentGoal" data-cy="investmentGoalLabel" margin="2rem 0 0 0">
            What will be your investment fund goal amount?
          </CreateBudgetFormLabel>
        </FormSection>
      </Page>
      <FormSection flow="row norwap" justify="center" align="center" margin="0" minHeight="7rem">
        {document.querySelector('.form__page') && <p className="create-budget-page-number">{`Page ${page} / ${document.querySelectorAll('.form__page').length}`}</p>}
        <PrimaryButton size="medium" data-cy="createBudgetSubmit" onClick={(e) => handleCreateBudgetSubmitClick(e)} disabled={disabledValues.createBudgetDisabled}>
          {page !== document.querySelectorAll('.form__page').length ? 'Continue' : 'Submit'}
        </PrimaryButton>
      </FormSection>
    </Form>
  );
};
