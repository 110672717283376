import React from 'react';
import styled from 'styled-components';

const Page = styled.section`
  position: relative;
  height: max-content;
  min-height: ${(props) => props.minHeight};
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-flow: ${(props) => props.flow};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  transform: translate(0, 0) rotate(0);
  overflow-y: auto;

  @media ${(props) => props.theme.screens.mediumMobile.landscape} {
    height: 50%;
    padding: 0;
  }

  @media ${(props) => props.theme.screens.mediumMobile.portrait} {
    padding: 2rem;
  }

  @media ${(props) => props.theme.screens.smallMobile.landscape} {
    height: 50%;
    padding: 0;
  }
`;

export default Page;
