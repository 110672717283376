import React, { useState, useEffect } from 'react';
import chroma from 'chroma-js';
import { produce } from 'immer';
import { StyledContainer } from './BasicContainer';
import { BudgetContainerHeader } from '../../Forms/Header';
import { TransactionCalendarSection } from '../../Forms/Section';
import { DateTime, Info } from 'luxon';
import { myCalendar } from '../../../../Classes/FrontEnd-Calendar';
import { addClasses, removeClasses } from '../../../Utility';
import { TransactionContainer } from '../TransactionContainer';

export const SmallWideContainer = function (props) {
  const { theme, titles, icons, user, setUser, budget, setBudget, transaction, setTransaction, receiptItem, setReceiptItem, currentDate, setCurrentDate, changeDate, weekdays, setWeekdays } = props;
  const [weeklyTransactions, setWeeklyTransactions] = useState([]);
  const [weeklyTransactionDates, setWeeklyTransactionDates] = useState([]);

  useEffect(() => {
    const monthDays = document.querySelectorAll('.weekday__day');
    let currentDay;
    let currentWeek;
    monthDays.forEach((day) => {
      if (day.classList.contains('current-day')) {
        currentDay = Number(day.textContent);
        currentWeek = Number(day.closest('.week').dataset.week);
      }
      if (day.classList.contains('un-used-day')) {
        removeClasses(day, ['un-used-day']);
      }
    });
    const sameWeek = document.querySelectorAll('.week')[currentWeek - 1];
    sameWeek.childNodes.forEach((day) => {
      if (Number(day.textContent) !== currentDay) {
        addClasses(day, ['same-week']);
      }
    });
    monthDays.forEach((day) => {
      if (day.classList.contains('same-week')) {
        if (Number(day.closest('.week').dataset.week) !== currentWeek || day.textContent === '') {
          removeClasses(day, [`same-week`]);
        }
      }
      if (day.classList.contains('un-used-day')) {
        removeClasses(day, ['un-used-day']);
      }
    });

    monthDays.forEach((day) => {
      if (day.textContent === '') {
        addClasses(day, [`un-used-day`]);
      }
    });

    let tempWeeklyTransactions = [];
    monthDays.forEach((day) => {
      if (day.classList.contains('current-day') || day.classList.contains('same-week')) {
        tempWeeklyTransactions.push({ date: DateTime.local(DateTime.fromISO(currentDate).year, DateTime.fromISO(currentDate).month, Number(day.textContent)).toISO(), transactions: [] });
      }
    });
    const updated = produce(weeklyTransactions, (roughDraft) => {
      if (roughDraft.length === 0) {
        tempWeeklyTransactions.forEach((temp) => roughDraft.push(temp));
      } else {
        roughDraft.forEach((transaction, i) => {
          roughDraft[i] = tempWeeklyTransactions[i];
        });
      }
      roughDraft.filter((transaction) => {
        return transaction !== null && transaction !== undefined;
      });
    });
    setWeeklyTransactions(updated);
  }, [currentDate]);

  return (
    <StyledContainer minHeight="45rem" minWidth="111.6rem" maxWidth="120rem" borderRadius=".5rem" bgColor={theme.colors.grayScale.offWhite} margin="0 0 5rem 0">
      <BudgetContainerHeader minHeight="5rem" justify="space-between" borderRadius=".25rem .25rem 0 0" iconSize="2.4rem" textSize="2rem">
        <TransactionCalendarSection
          height="100%"
          width="35%"
          flow="row nowrap"
          justify="center"
          align="center"
          bgColor={chroma(theme.colors.primaryColor).darken(0.2).hex()}
          fontSizing="2.4rem"
          iconPosition="absolute"
          iconTop="50%"
          iconLeft="1rem"
          iconTransform="translate(0, -50%)">
          <i className={`fas fa-${icons.one}`} />
          <p>{titles.one}</p>
        </TransactionCalendarSection>
        <TransactionCalendarSection
          height="100%"
          width="65%"
          flow="row nowrap"
          justify="center"
          align="center"
          bgColor={chroma(theme.colors.primaryColor).darken(0.2).hex()}
          fontSizing="2.4rem"
          iconPosition="absolute"
          iconTop="50%"
          iconLeft="1rem"
          iconTransform="translate(0, -50%)">
          <i className={`fas fa-${icons.two}`} />
          <p>{titles.two}</p>
        </TransactionCalendarSection>
      </BudgetContainerHeader>
      <TransactionCalendarSection height="100%" width="100%" flow="row nowrap" justify="center" align="center" fontSizing="2.4rem">
        <TransactionCalendarSection
          height="45rem"
          width="35%"
          flow="column nowrap"
          justify="center"
          align="center"
          borderRight={`.1rem solid ${theme.colors.primaryColor}cc`}
          fontSizing="2.4rem"
          iconPosition="relative">
          <BudgetContainerHeader
            minHeight="5rem"
            justify="center"
            borderRadius=".25rem .25rem 0 0"
            iconSize="2.4rem"
            textSize="2rem"
            textMargin="0 1rem"
            modifier="bill-calendar"
            modifierTwo="purple"
            bgColor="transparent">
            <i className="fas fa-chevron-left" onClick={(e) => changeDate(e, 'month', 'decrease')} />
            <p>{`${DateTime.fromISO(currentDate).monthLong} | ${DateTime.fromISO(currentDate).year}`}</p>
            <i className="fas fa-chevron-right" onClick={(e) => changeDate(e, 'month', 'increase')} />
          </BudgetContainerHeader>
          <TransactionCalendarSection
            height="70%"
            width="90%"
            flow="column nowrap"
            justify="center"
            align="center"
            border={`.2rem solid ${theme.colors.primaryColor}`}
            margin="1rem 0 0 0"
            padding=".2rem"
            borderRadius=".5rem">
            <BudgetContainerHeader
              minHeight="5rem"
              justify="space-around"
              textSize="1.6rem"
              bgColor="transparent"
              modifier="bill-calendar"
              weekdayWidth={`${100 / 7}%`}
              modifierTwo="weekday"
              weekdayPadding="0">
              {weekdays.map((day, index) => {
                return (
                  <p className="weekday" key={index}>
                    {`${day.slice(0, 2)[0].toUpperCase()}${day.slice(0, 2)[1]}`}
                  </p>
                );
              })}
            </BudgetContainerHeader>
            <TransactionCalendarSection height="100%" width="100%" modifier="purple" flow="column nowrap" justify="flex-start" align="center">
              {/* I AM TRYING TO FIGURE OUT HOW TO FILL OUT THE REST OF THE MONTH */}
              {myCalendar.buildMonth(weekdays, currentDate).map((week, index) => {
                return (
                  <BudgetContainerHeader
                    minHeight="4rem"
                    justify="space-around"
                    textSize="1.6rem"
                    bgColor="transparent"
                    modifier="bill-calendar"
                    modifierTwo="weekday purple"
                    className="week"
                    week={true}
                    weekdayHeight="100%"
                    weekdayWidth={`${100 / 7}%`}
                    weekdayPadding="0"
                    data-week={index + 1}
                    key={index}>
                    {week.map((day, dayIndex) => {
                      return (
                        <p className={`${day === DateTime.fromISO(currentDate).day ? 'weekday__day current-day' : 'weekday__day'}`} key={dayIndex} onClick={(e) => changeDate(e, 'day', day)}>
                          {day}
                        </p>
                      );
                    })}
                  </BudgetContainerHeader>
                );
              })}
            </TransactionCalendarSection>
          </TransactionCalendarSection>
        </TransactionCalendarSection>
        <TransactionCalendarSection
          height="45rem"
          width="65%"
          flow="column nowrap"
          justify="flex-start"
          align="center"
          borderLeft={`.2rem solid ${theme.colors.primaryColor}cc`}
          fontSizing="2.4rem"
          iconPosition="absolute"
          iconTop="50%"
          iconLeft="1rem"
          iconTransform="translate(0, -50%)"
          overflowY="auto">
          {weeklyTransactions.length > 0 &&
            user &&
            weeklyTransactions.map((transaction, index) => {
              return (
                <TransactionContainer
                  theme={theme}
                  user={user}
                  transaction={transaction}
                  budget={budget}
                  content={{
                    flow: 'column nowrap',
                    justify: 'space-evenly',
                    align: 'center',
                  }}
                  key={index}
                />
              );
            })}
        </TransactionCalendarSection>
      </TransactionCalendarSection>
    </StyledContainer>
  );
};
